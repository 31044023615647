import {ApolloError, LazyQueryExecFunction, useLazyQuery} from '@apollo/client'
import {Exact, GetEmployeeQuery} from 'graphql/generatedTypes/graphql'
import {GET_EMPLPOYEE} from 'graphql/queries/employee.queries'
import {useAppDispatch} from 'clientDashboard/clientDashboard.store'

import {useAuth} from 'auth/AuthContext'
import {LocalStorageKeys} from 'constants/constants'
import useLocalStorage from './useLocalStorage'
import useNotify from 'hooks/useNotify'
import moment from 'moment-timezone'
import _isEmpty from 'lodash/isEmpty'
import {
  applyTopBarLocationGroups,
  resetLocationGroupsForTopBar, setAppliedGroups,
  setEmployeeDetails,
  updateSelectedLocation
} from 'clientDashboard/employee.slice'
import {setBusinessDetails} from 'clientDashboard/business.slice'
import {getFromLocalStorage} from 'libs/localStorage.helpers'
import {APPLIED_LOCATION_GROUPS} from 'clientDashboard/constants/localStorage.constants'

type UseEmployeeType = {
  getEmployee: LazyQueryExecFunction<
    GetEmployeeQuery,
    Exact<{[key: string]: never}>
  >
  loadingEmployee: boolean
  errorInGetEmployee: ApolloError | undefined
}

const useEmployee = (): UseEmployeeType => {
  const dispatch = useAppDispatch()
  const notify = useNotify()
  const {updateAuthInfo} = useAuth()
  const [storeNumberInStore, setStoreNumberInStore] = useLocalStorage(
    LocalStorageKeys.STORE_NUMBER,
    ''
  )
  const [, , clearToteAccessToken] = useLocalStorage(
    LocalStorageKeys.TOTE_ACCESS_TOKEN,
    ''
  )

  const handleEmployeeData = (data: any) => {
    if (!data?.getEmployee) {
      notify.show('Employee not found', 'error')
      return
    }
    if (!data?.getEmployee?.business) {
      notify.show('Business not found', 'error')
      window.location.href = '/login'
      return
    }
    const employeeData = {...data.getEmployee}
    dispatch(setEmployeeDetails(employeeData))
    dispatch(setBusinessDetails(data.getEmployee.business))

    const savedGroups = getFromLocalStorage(APPLIED_LOCATION_GROUPS)
    if (!_isEmpty(savedGroups)) {
      dispatch(setAppliedGroups(savedGroups))
    } else {
      dispatch(resetLocationGroupsForTopBar())
      setTimeout(() => dispatch(applyTopBarLocationGroups()))
    }

    const storeNumbersMap = data?.getEmployee?.locations?.reduce(
      (acc: any, location: any) => {
        acc[location.storeNumber] = location
        return acc
      },
      {}
    )
    let storeNumber = storeNumberInStore || ''
    if (
      storeNumbersMap &&
      (!storeNumber || !Object.keys(storeNumbersMap).includes(storeNumber))
    ) {
      storeNumber = Object.keys(storeNumbersMap)?.[0]
      setStoreNumberInStore(storeNumber)
    }
    const selectedStore = storeNumbersMap[storeNumber]
    let locationId = storeNumbersMap && storeNumbersMap[storeNumber]?.id
    let timezone = storeNumbersMap && storeNumbersMap[storeNumber]?.timeZone

    updateAuthInfo({
      employeeId: data?.getEmployee?.id as string,
      userName: data?.getEmployee?.name as string,
      userEmail: data?.getEmployee?.email as string,
      businessLogo: data?.getEmployee?.business?.logo as string,
      businessName: data?.getEmployee?.business?.brand as string,
      businessId: data?.getEmployee?.business?.id as string,
      storeNumbersMap,
      storeNumber,
      storeName: storeNumbersMap && storeNumbersMap[storeNumber]?.name,
      locationId: locationId,
      featureFlags: data?.getEmployee?.business?.featureFlags || {},
      role: data?.getEmployee?.role?.name,
      permissions: data?.getEmployee?.role?.permissions || [],
      timeZone: timezone || moment.tz.guess() || 'UTC',
      primaryColor:
        data?.getEmployee?.business?.configurations?.colors?.primary,
      secondaryColor:
        data?.getEmployee?.business?.configurations?.colors?.secondary,
      cashManagementConfig:
        (storeNumbersMap &&
          storeNumbersMap[storeNumber]?.locationConfig?.cashManagement) ||
        {}
    })

    setTimeout(() => {
      // Note: delaying redux dispatch so that AuthContext has all the data first
      dispatch(
        updateSelectedLocation({
          selectedLocationId: selectedStore.id,
          selectedStoreNumber: selectedStore.storeNumber
        })
      )
    })
  }
  const handleEmployeeDataError = (error: any) => {
    console.error('Error while fetching employee info: ', error)
    notify.show(error.message, 'error')
    clearToteAccessToken()
    window.location.href = '/login'
  }

  const [getEmployee, {loading: loadingEmployee, error: errorInGetEmployee}] =
    useLazyQuery(GET_EMPLPOYEE, {
      fetchPolicy: 'no-cache',
      onCompleted: handleEmployeeData,
      onError: handleEmployeeDataError
    })

  return {getEmployee, loadingEmployee, errorInGetEmployee}
}

export default useEmployee
