import {Grid, IconButton, Typography} from '@mui/material'
import {space} from 'config/theme'
import StyledSearchBar from 'ui/molecules/StyledSearchBar'
import StyledSelect, {StyledMenuItem} from 'ui/molecules/StyledSelect'
import {
  ALL_DEVICES,
  ALL_REGISTERS,
  ALL_ZONES,
  UNASSIGNED
} from 'constants/constants'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {t} from 'i18next'
import StyledCheckBox from 'ui/atoms/StyledCheckbox'
import React, {useEffect} from 'react'
import {
  useAppDispatch,
  useAppSelector
} from 'clientDashboard/clientDashboard.store'
import {
  selectDevices,
  selectEditZoneModalData,
  selectRegisters,
  selectZones
} from 'clientDashboard/peripheralHub/peripheralHub.selectors'
import {AvailableElementType} from 'types/peripheral-hub.types'
import {ZoneType} from 'clientDashboard/peripheralHub/types/peripheral-hub.types'
import {useMutation} from '@apollo/client'
import {DELETE_ZONE} from 'graphql/mutations/peripheral-hub.mutations'
import usePermission from 'clientDashboard/usePermission'
import {Permission} from 'constants/permissions'
import useZoneData from 'clientDashboard/peripheralHub/api/useZoneData'
import {
  closeEditZoneModal,
  setEditZoneModalSelectedItems
} from 'clientDashboard/peripheralHub/peripheralHub.slice'
import {
  getAvailableElements,
  getFilteredAvailableElements,
  getFilteredElementsBySearch
} from 'clientDashboard/peripheralHub/peripheralHubOverview/AddOrEditZoneModal/addOrEditZoneModal.helpers'

const ZoneDevicesTab = () => {
  const hasManageZoneAccess = usePermission(Permission.MANAGE_ZONES)

  const dispatch = useAppDispatch()
  const editZone = useAppSelector(selectEditZoneModalData) as ZoneType

  const zoneData = useAppSelector(selectZones)
  const registerData = useAppSelector(selectRegisters)
  const deviceData = useAppSelector(selectDevices)

  const [zoneOptions, setZoneOptions] = React.useState([ALL_ZONES])
  const [registerOptions, setRegisterOptions] = React.useState([ALL_REGISTERS])
  const [deviceOptions, setDeviceOptions] = React.useState([ALL_DEVICES])

  const [searchTerm, setSearchTerm] = React.useState('')
  const [filteredZoneId, setFilteredZoneId] = React.useState(ALL_ZONES)
  const [filteredDeviceId, setFilteredDeviceId] = React.useState(ALL_DEVICES)
  const [filteredRegisterId, setFilteredRegisterId] =
    React.useState(ALL_REGISTERS)

  const [availableElements, setAvailableElements] = React.useState<
    AvailableElementType[]
  >([])

  const {fetchZoneData} = useZoneData()

  const isNewZone = !editZone?.id
  // @ts-ignore
  const selectedItems = editZone.selectedItems || {}

  let filteredAvailableElements
  if (searchTerm) {
    filteredAvailableElements =
      getFilteredElementsBySearch(availableElements, searchTerm) || []
  } else {
    filteredAvailableElements =
      getFilteredAvailableElements(
        availableElements,
        filteredZoneId,
        filteredDeviceId,
        filteredRegisterId
      ) || []
  }

  useEffect(() => {
    setZoneOptions([
      ALL_ZONES,
      ...zoneData
        .filter((zone) => !zone.isNew)
        .map((zone) => zone.id || zone.name)
    ])
    setDeviceOptions([ALL_DEVICES, ...Object.keys(deviceData)])
    setRegisterOptions([ALL_REGISTERS, ...Object.keys(registerData)])

    const _availableElements = getAvailableElements(
      registerData,
      zoneData,
      deviceData,
      editZone
    )
    setAvailableElements(_availableElements)
    dispatch(
      setEditZoneModalSelectedItems({
        items: _availableElements,
        isTransformPending: true
      })
    )
  }, [zoneData, deviceData, registerData])

  const [deleteZone] = useMutation(DELETE_ZONE)

  const deleteZoneUsingId = async (zoneId: string) => {
    await deleteZone({
      variables: {
        zoneId: zoneId
      }
    })

    fetchZoneData(() => {
      dispatch(closeEditZoneModal())
    })
  }

  return (
    <Grid
      style={{
        padding: space.SMALL,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: space.MEDIUM,
        alignContent: 'center',
        width: '100%'
      }}
    >
      <Grid item>
        <StyledSearchBar
          fullWidth
          placeholder='Search'
          style={{
            marginBottom: space.MEDIUM
          }}
          value={searchTerm}
          onChange={(text: string) => {
            setSearchTerm(text)

            setFilteredDeviceId(ALL_DEVICES)
            setFilteredRegisterId(ALL_REGISTERS)
            setFilteredZoneId(ALL_ZONES)
          }}
        />
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={2}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: space.MEDIUM
          }}
        >
          <Grid item>
            <StyledSelect
              size='medium'
              label=''
              value={filteredZoneId}
              onChange={(e) => {
                setFilteredZoneId(e.target.value as string)
              }}
            >
              {zoneOptions.map((zoneOption, index) => (
                <StyledMenuItem key={index} value={zoneOption}>
                  {zoneData.find((zone) => zone.id === zoneOption)?.name ||
                    ALL_ZONES}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </Grid>

          <Grid item>
            <StyledSelect
              size='medium'
              label=''
              value={filteredDeviceId}
              onChange={(e) => {
                setFilteredDeviceId(e.target.value as string)
              }}
            >
              {deviceOptions.map((deviceOption, index) => (
                <StyledMenuItem key={index} value={deviceOption}>
                  {deviceData[deviceOption]?.device_name || ALL_DEVICES}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </Grid>

          <Grid item>
            <StyledSelect
              size='medium'
              label=''
              value={filteredRegisterId}
              onChange={(e) => {
                setFilteredRegisterId(e.target.value as string)
              }}
            >
              {registerOptions.map((registerOption, index) => (
                <StyledMenuItem key={index} value={registerOption}>
                  {registerData[registerOption]?.register_name || ALL_REGISTERS}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: space.MEDIUM
          }}
        >
          <Grid item>
            <Typography variant='subtitle2'>
              {Object.keys(selectedItems).length} registers selected
            </Typography>
          </Grid>

          {!isNewZone && (
            <Grid
              item
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <IconButton
                style={{
                  padding: '0px',
                  marginRight: space.MEDIUM
                }}
                disabled={!hasManageZoneAccess}
                size='small'
                onClick={() => {
                  editZone.id && deleteZoneUsingId(editZone.id)
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'trash',
                    family: 'classic',
                    style: 'solid'
                  })}
                />
                <Typography marginLeft={space.XS} variant='subtitle2'>
                  {t('peripheral-hub.zone-manager.delete-zone')}
                </Typography>
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={4} justifyContent={'center'}>
          {filteredAvailableElements.map((item) => (
            <Grid
              key={`${item.registerId}_${item.deviceId}`}
              item
              xs={12}
              sm={6}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  border: '1px solid rgba(0, 0, 0, 0.20)',
                  borderRadius: '12px',
                  padding: '16px',
                  alignItems: 'center'
                }}
              >
                <Grid item>
                  <StyledCheckBox
                    checked={Boolean(
                      selectedItems[
                        item.deviceId !== UNASSIGNED
                          ? item.deviceId
                          : item.registerId
                      ]
                    )}
                    onChange={(event) => {
                      const _selectedItems = {...selectedItems}
                      if (event.target.checked) {
                        _selectedItems[
                          item.deviceId !== UNASSIGNED
                            ? item.deviceId
                            : item.registerId
                        ] = item
                      } else {
                        delete _selectedItems[
                          item.deviceId !== UNASSIGNED
                            ? item.deviceId
                            : item.registerId
                        ]
                      }
                      dispatch(
                        setEditZoneModalSelectedItems({
                          items: _selectedItems
                        })
                      )
                    }}
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    width: '80%'
                  }}
                >
                  <Typography
                    variant='h6'
                    style={{
                      maxWidth: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {item.deviceId !== UNASSIGNED
                      ? deviceData[item.deviceId]?.device_name
                      : registerData[item.registerId]?.register_name}
                  </Typography>
                  <Typography variant='body1'>
                    Zone:{' '}
                    {zoneData.find((zone) => zone.id === item.zoneId)?.name ||
                      ALL_ZONES}
                    , Type: {item.deviceId !== UNASSIGNED ? '(D)' : '(R)'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ZoneDevicesTab
