import {graphql} from 'graphql/generatedTypes'

export const GENIE_QUERY_EVENT_FOR_CONSUMER_AGENT = graphql(`
  subscription genieQueryEventConsumerAgent(
    $locationId: String
    $chatSessionId: String
    $lastReadTimestamp: DateTime
  ) {
    genieQueryEventConsumerAgent(
      locationId: $locationId
      chatSessionId: $chatSessionId
      lastReadTimestamp: $lastReadTimestamp
    ) {
      messageId
      queryType
      answer
      query
      status
      chatSessionId
      followups
    }
  }
`)
