import {Grid, Typography} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import InsightCharts from 'clientDashboard/cashManagement/InsightCharts'
import {t} from 'i18next'
import {useEffect, useState} from 'react'
import {AGGREGRATE_CASH_TRANSACTIONS} from 'graphql/queries/cash-management.queries'
import {useLazyQuery, useSubscription} from '@apollo/client'
import CashTransactionListing from 'clientDashboard/cashManagement/CashManagerTransactionListing'
import DurationPicker from 'clientDashboard/cashManagement/DurationPicker'
import {convertToDollars, formatCurrency} from 'utils/common'
import {CASH_EVENT_SUBSCRIPTION} from 'graphql/subscriptions/cash-event-subscriptions'
import CashManagementStatisticsHeader from 'clientDashboard/cashManagement/cashManagerInsights/CashManagementStatisticsHeader'
import CashManagementKeyStats from 'clientDashboard/cashManagement/cashManagerInsights/CashManagementKeyStats'
import InsightsLocationPicker from 'clientDashboard/cashManagement/InsightsLocationPicker'
import {
  CASH_ADJUSTMENT_EVENT_TYPE,
  INSIGHTS_CHART_CASH_MANAGEMENT_CATEGORY_SALES,
  INSIGHTS_TYPE_CASH_MANAGEMENT,
  SALE_EVENT
} from 'constants/constants'
import {GET_INSIGHT_CHARTS} from 'graphql/queries/insights.queries'
import {
  generateRangeFromDuration,
  getDurationTypeByDurationId
} from 'utils/date.utils'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectCurrentStore} from 'clientDashboard/employee.selectors'

function CashManagementSalesInsights() {
  const currentStore = useAppSelector(selectCurrentStore) as any
  const [baseStore, setBaseStore] = useState<any>(currentStore)
  const [compareStore, setCompareStore] = useState(
    {} as {
      name: string
      id: string
      storeNumber: string
    }
  )
  const [duration, setDuration] = useState('today')
  const [cashSalesStats, setCashSalesStats] = useState({
    cashSales: '',
    percentageChange: ''
  })
  const [cashVarianceStats, setCashVarianceStats] = useState({
    cashVariance: '',
    percentageChange: ''
  })
  const [atvStats, setAtvStats] = useState({
    averageTransactionValue: '',
    percentageChange: ''
  })
  const [chartData, setChartData] = useState([] as any[])
  const [insightChart, setInsightsChart] = useState(null as any)

  const [
    aggregrateCashTransactions,
    {loading: aggregrateCashTransactionsLoading}
  ] = useLazyQuery(AGGREGRATE_CASH_TRANSACTIONS, {
    onCompleted: (data) => {
      setCashSalesStats({
        cashSales: formatCurrency(
          convertToDollars(
            data?.aggregrateCashTransactions?.cashSalesStats?.cashSales
          )
        ),
        percentageChange:
          data?.aggregrateCashTransactions?.cashSalesStats?.percentageChange
      })
      setCashVarianceStats({
        cashVariance: formatCurrency(
          convertToDollars(
            data?.aggregrateCashTransactions?.cashVarianceStats?.cashVariance
          )
        ),
        percentageChange:
          data?.aggregrateCashTransactions?.cashVarianceStats?.percentageChange
      })
      setAtvStats({
        averageTransactionValue: formatCurrency(
          convertToDollars(
            data?.aggregrateCashTransactions?.averageTransactionValueStats
              ?.averageTransactionValue
          )
        ),
        percentageChange:
          data?.aggregrateCashTransactions?.averageTransactionValueStats
            ?.percentageChange
      })
    },
    onError: (error) => {
      console.log('Error getting aggregrateCashTransactions', error)
    }
  })

  const {data} = useSubscription(CASH_EVENT_SUBSCRIPTION, {
    variables: {
      locationId: currentStore?.id as string
    }
  })

  useEffect(() => {
    if (data) {
      const cashEvent = data.webCashEvent?.cashEvent
      let dateRange = generateRangeFromDuration(duration)
      if (cashEvent === 'refresh') {
        aggregrateCashTransactions({
          variables: {
            locationIds: [baseStore.id],
            transactionTimeRange: dateRange,
            eventType: [SALE_EVENT, CASH_ADJUSTMENT_EVENT_TYPE]
          }
        })
      }
    }
  }, [data])

  const [getBusinessInsights] = useLazyQuery(GET_INSIGHT_CHARTS, {
    onCompleted: (data) => {
      let chartConfig = data.getInsightCharts?.charts?.filter(
        (chart: any) => chart.enableChart
      )
      if (chartConfig && chartConfig.length > 0) {
        setInsightsChart(chartConfig[0])
      }
    },
    onError: (error) => {
      console.log('Error getting business insights', error)
    }
  })

  useEffect(() => {
    let dateRange = generateRangeFromDuration(duration)
    let storeNumbers = []
    let location_ids = []
    if (baseStore.storeNumber) {
      storeNumbers.push(baseStore.storeNumber)
      location_ids.push(baseStore.id)
    }
    if (compareStore.storeNumber) {
      storeNumbers.push(compareStore.storeNumber)
      location_ids.push(compareStore.id)
    }
    aggregrateCashTransactions({
      variables: {
        locationIds: [baseStore.id],
        transactionTimeRange: dateRange,
        eventType: [SALE_EVENT, CASH_ADJUSTMENT_EVENT_TYPE]
      }
    })
    getBusinessInsights({
      variables: {
        chartCategory: INSIGHTS_CHART_CASH_MANAGEMENT_CATEGORY_SALES,
        insightType: INSIGHTS_TYPE_CASH_MANAGEMENT,
        durationType: getDurationTypeByDurationId(duration),
        storeNumbers: storeNumbers.join(','),
        locationIds: location_ids.join(','),
        duration: dateRange.join(',')
      }
    })
  }, [baseStore.storeNumber, compareStore.storeNumber, duration])

  return (
    <Grid
      key='main-grid'
      container
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
        alignSelf: 'stretch',
        alignItems: 'flex-start',
        gap: '24px',
        flex: '1 0 0'
      }}
    >
      <Grid
        key={'filter-grid'}
        item
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '12px',
          flex: '1 0 0'
        }}
      >
        <Grid
          item
          style={{
            width: '200px'
          }}
        >
          <InsightsLocationPicker
            label={'Location'}
            store={baseStore}
            setStore={setBaseStore}
          />
        </Grid>
        <Grid>
          <Typography
            style={{
              fontSize: '14px'
            }}
          >
            vs
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            width: '200px'
          }}
        >
          <InsightsLocationPicker
            label='Compare: Location'
            store={compareStore}
            setStore={setCompareStore}
          />
        </Grid>
        <Grid
          item
          style={{
            width: '200px'
          }}
        >
          <DurationPicker duration={duration} setDuration={setDuration} />
        </Grid>
      </Grid>

      <Grid
        key='chart-and-stats-grid'
        item
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          gap: '24px'
        }}
      >
        <Grid
          key={'chart-grid'}
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #EFEFEF',
            borderRadius: '12px'
          }}
          xs={9}
        >
          <CashManagementStatisticsHeader
            title={t('insights.cash-management.sales-report.header-title')}
            subtitle={t(
              'insights.cash-management.sales-report.header-subtitle'
            )}
          />
          {insightChart && (
            <InsightCharts
              chartConfig={insightChart}
              setChartData={setChartData}
            />
          )}
        </Grid>
        <Grid
          key={'stats-grid'}
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '24px'
          }}
          xs={3}
        >
          <CashManagementKeyStats
            label={t('insights.cash-management.sales-report.stats.cash-sales')}
            value={cashSalesStats?.cashSales}
            percentageChange={cashSalesStats?.percentageChange}
            isLoading={aggregrateCashTransactionsLoading}
          />
          <CashManagementKeyStats
            label={t(
              'insights.cash-management.sales-report.stats.cash-variance'
            )}
            value={cashVarianceStats?.cashVariance}
            percentageChange={cashVarianceStats?.percentageChange}
            isLoading={aggregrateCashTransactionsLoading}
          />
          <CashManagementKeyStats
            label={t('insights.cash-management.sales-report.stats.atv')}
            value={atvStats?.averageTransactionValue}
            percentageChange={atvStats?.percentageChange}
            isLoading={aggregrateCashTransactionsLoading}
          />
        </Grid>
      </Grid>
      <Grid
        item
        key={'data-table'}
        style={{
          width: '100%'
        }}
      >
        <CashTransactionListing
          locationId={baseStore.id}
          dateRange={generateRangeFromDuration(duration)}
          eventType={SALE_EVENT}
          chartData={chartData}
          subcriptionData={data}
        />
      </Grid>
    </Grid>
  )
}

export default CashManagementSalesInsights
