import React from 'react'
import {Grid, Typography} from '@mui/material'

interface CashManagementStatisticsHeaderProps {
  title: string
  subtitle: string
}

const CashManagementStatisticsHeader: React.FC<
  CashManagementStatisticsHeaderProps
> = ({title, subtitle}) => {
  return (
    <Grid
      key={'chart-header'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '20px 15px',
        gap: '6px'
      }}
    >
      <Grid item>
        <Typography
          style={{
            fontSize: '20px',
            fontFamily: 'Poppins',
            fontWeight: 500,
            lineHeight: '20px'
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        style={{
          fontFamily: 'Poppins',
          fontSize: '14px',
          color: '#656565'
        }}
      >
        <Typography>{subtitle}</Typography>
      </Grid>
    </Grid>
  )
}

export default CashManagementStatisticsHeader
