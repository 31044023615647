import {useMutation} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Grid, IconButton, Tooltip, Typography} from '@mui/material'
import {UNASSIGNED} from 'constants/constants'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {useAuth} from 'auth/AuthContext'
import {RESOLVE_ERROR_NOTIFICATION} from 'graphql/mutations/peripheral-hub.mutations'
import theme from 'config/theme'

interface NotificationBarProps {
  deviceName: string
  location: string
  lastActive: string
  issue: string
  action: string
  time: string
  activityId: string
  getErrorNotifications: any
}

const NotificationBar: React.FC<NotificationBarProps> = ({
  deviceName,
  location,
  lastActive,
  issue,
  action,
  time,
  activityId,
  getErrorNotifications
}) => {
  const {authInfo} = useAuth()
  const {t} = useTranslation()

  const [resolveErrorNotification, {error: resolveNotificationDataError}] =
    useMutation(RESOLVE_ERROR_NOTIFICATION)
  useEffect(() => {
    if (resolveNotificationDataError) {
      console.log(resolveNotificationDataError)
    }
  }, [resolveNotificationDataError])

  const handleResolve = () => {
    resolveErrorNotification({
      variables: {
        activityId: activityId
      }
    }).then(() => {
      getErrorNotifications({
        variables: {
          businessId: authInfo.businessId as string,
          locationId: authInfo.locationId as string
        }
      })
    })
  }

  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '16px',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: '1 0 0',
        borderRadius: '12px',
        border: '1px solid rgba(0, 0, 0, 0.20)',
        backgroundColor: theme.palette.error.light,
        width: '100%',
        height: 'auto',
        minHeight: '50px',
        boxSizing: 'border-box',
        cursor: 'pointer'
      }}
    >
      <Grid
        item
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
        gap={2}
      >
        <Grid item>
          <FontAwesomeIcon
            icon={icon({
              name: 'exclamation-triangle',
              family: 'classic',
              style: 'solid'
            })}
          />
        </Grid>
        <Grid
          item
          style={{
            // hide the overflow text
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100px'
          }}
        >
          <Typography variant='h3'>{deviceName}</Typography>
        </Grid>
      </Grid>

      <Grid
        item
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
        gap={2}
      >
        <Grid item>
          <FontAwesomeIcon
            icon={icon({
              name: 'location-dot',
              family: 'classic',
              style: 'solid'
            })}
          />
        </Grid>
        <Grid item>
          <Typography variant='subtitle2'>{location || UNASSIGNED}</Typography>
        </Grid>
      </Grid>

      <Grid
        item
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
        gap={2}
      >
        <Grid item>
          <Typography variant='h3'>
            {t('peripheral-hub.notifications.last-active')}{' '}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='subtitle2'>{lastActive}</Typography>
        </Grid>
      </Grid>

      <Grid
        item
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
        gap={2}
      >
        <Grid item>
          <Typography variant='h3'>Issue: </Typography>
        </Grid>
        <Grid item>
          <Typography variant='subtitle2'>{issue}</Typography>
        </Grid>
      </Grid>

      <Grid
        item
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
        gap={2}
      >
        <Grid item>
          <Typography variant='h3'>Action: </Typography>
        </Grid>
        <Grid item>
          <Typography variant='subtitle2'>{action}</Typography>
        </Grid>
      </Grid>

      <Grid
        item
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
        gap={2}
      >
        <Grid item>
          <Typography variant='subtitle2'>{time}</Typography>
        </Grid>

        {activityId && (
          <Grid item>
            <Tooltip title={t('peripheral-hub.notifications.resolve-tooltip')}>
              <>
                <IconButton
                  disabled={!activityId}
                  onClick={() => {
                    handleResolve()
                  }}
                >
                  <FontAwesomeIcon
                    icon={icon({
                      name: 'close',
                      family: 'classic',
                      style: 'solid'
                    })}
                  />
                </IconButton>
              </>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default NotificationBar
