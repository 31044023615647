import {Grid} from '@mui/material'
import StyledInput from 'ui/atoms/StyledInput'
import {space} from 'config/theme'
import {set} from 'lodash'
import {useEffect, useMemo} from 'react'
import useConsumerAgent from 'consumerAgent/ConsumerAgentContext'

const ConsumerAgentAddressInput = () => {
  const {address, setAddress, setAddressFields} = useConsumerAgent()

  const handleChange = useMemo(
    () => (path: string, value: string) => {
      setAddressFields((prev) => {
        return set({...prev}, path, value)
      })
    },
    []
  )

  let addressHTMLInputElement: HTMLInputElement = document.querySelector(
    '#consumer-agent-address-input'
  ) as HTMLInputElement

  useEffect(() => {
    if (!addressHTMLInputElement || !window?.google?.maps) return
    const fillInAddress = () => {
      const place = autocomplete.getPlace()
      let _address = ''

      let _zipcode = ''
      let _street = ''
      let _city = ''
      let _state = ''
      let _country = ''

      if (place && place.address_components) {
        for (const component of place?.address_components as google.maps.GeocoderAddressComponent[]) {
          _address = `${_address} ${component.long_name || ''}`
          const componentType = component.types[0]

          switch (componentType) {
            case 'street_number': {
              _street = `${component.long_name}`
              break
            }

            case 'route': {
              _street += ' ' + component.short_name
              break
            }

            case 'postal_code': {
              _zipcode = `${component.long_name}`
              break
            }

            case 'locality' || 'sublocality_level_1':
              _city = component.long_name
              break

            case 'administrative_area_level_1': {
              _state = component.short_name
              break
            }

            case 'country': {
              _country = component.long_name
              break
            }
          }
        }

        handleChange('city', _city)
        handleChange('state', _state)
        handleChange('zipCode', _zipcode)
        handleChange('street', _street)
        handleChange('country', _country)
      }
      setAddress(_address)
    }

    const autocomplete = new window.google.maps.places.Autocomplete(
      addressHTMLInputElement
    )
    autocomplete.setFields(['address_components'])
    autocomplete.addListener('place_changed', fillInAddress)
  }, [addressHTMLInputElement])

  return (
    <Grid
      container
      style={{
        marginTop: space.SMALL
      }}
    >
      <StyledInput
        id='consumer-agent-address-input'
        value={address}
        label='Delivery Address'
        size='medium'
        onChange={(e) => setAddress(e.target.value)}
        placeholder='Enter address'
        fullWidth
        style={{marginTop: '10px'}}
        InputProps={{
          style: {
            fontSize: '16px'
          }
        }}
        InputLabelProps={{
          style: {
            fontSize: '16px'
          }
        }}
      />
    </Grid>
  )
}

export default ConsumerAgentAddressInput
