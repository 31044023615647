import {
  useAppDispatch,
  useAppSelector
} from 'clientDashboard/clientDashboard.store'
import {useLazyQuery} from '@apollo/client'
import {GET_ERROR_NOTIFICATION} from 'graphql/queries/peripheral-hub.queries'
import {setErrorNotifications} from 'clientDashboard/peripheralHub/peripheralHub.slice'
import {selectBusinessAndLocation} from 'clientDashboard/employee.selectors'
import {REFETCH_INTERVAL} from 'constants/constants'

const useErrorNotifications = () => {
  const dispatch = useAppDispatch()
  const {businessId, locationId} = useAppSelector(selectBusinessAndLocation)

  const [_fetchErrorNotifications, {loading, error}] = useLazyQuery(
    GET_ERROR_NOTIFICATION,
    {
      pollInterval: REFETCH_INTERVAL,
      onCompleted: (data) => {
        dispatch(setErrorNotifications(data.getErrorNotifications))
      }
    }
  )

  const fetchErrorNotifications = () => {
    _fetchErrorNotifications({
      variables: {
        businessId,
        locationId,
        live: true
      }
    })
  }

  return {fetchErrorNotifications, loading, error}
}

export default useErrorNotifications
