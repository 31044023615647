import {LocalStorageKeys} from 'constants/constants'
import useLocalStorage from 'hooks/useLocalStorage'
import {jwtDecode, JwtPayload} from 'jwt-decode'

const useAdminAccessToken = () => {
  const [toteAccessToken] = useLocalStorage(
    LocalStorageKeys.TOTE_ADMIN_ACCESS_TOKEN,
    ''
  )
  let isAuthenticated = !!toteAccessToken

  if (toteAccessToken) {
    try {
      const decodedToken: JwtPayload = jwtDecode(toteAccessToken)
      isAuthenticated &&=
        Number(decodedToken.exp) > Math.round(Date.now() / 1000)
    } catch (error) {
      isAuthenticated = false
      console.error('Error while decoding token: ', error)
    }
  }

  return {isAuthenticated}
}

export default useAdminAccessToken
