import {graphql} from 'graphql/generatedTypes'

export const ORDER_UPDATE_SUBSCRIPTION = graphql(`
  subscription orderUpdateEventsConsumerAgent(
    $cartId: String!
    $businessId: String!
    $locationId: String!
  ) {
    orderUpdateEventsConsumerAgent(
      cartId: $cartId
      requestContext: {businessId: $businessId, locationId: $locationId}
    ) {
      order {
        id
        paymentStatus
        refundStatus
        totalAmount
        currency
        amountPaid
        amountRefunded
      }
    }
  }
`)
