import {graphql} from 'graphql/generatedTypes'

export const GET_BUSINESSES = graphql(`
  query getBusinesses {
    getBusinesses {
      brand
      id
      logo
      name
      okta {
        oktaClientId
        oktaIssuer
      }
      contactDetails {
        contactPhone
        contactEmail
        supportPhone
        supportEmail
      }
      featureFlags
      address {
        city
        lat
        long
        state
        street
        zipCode
        country
      }
      authMethod
      storeGroupClusters {
        id
        name
        hierarchyLevel
      }
    }
  }
`)

export const GET_ALL_ROLES_EMPLOYEES_LOCATIONS = graphql(`
  query getAllRolesEmployeesLocations(
    $businessId: String!
    $status: String
    $fetchAllEmployees: Boolean
  ) {
    getAllRoles(businessId: $businessId) {
      id
      name
      description
      isActive
      permissions
      createdAt
      updatedAt
    }

    getAllEmployees(businessId: $businessId, fetchAll: $fetchAllEmployees) {
      employees {
        id
        name
        email
        phoneNumber
        employeeCode
        fullAddress {
          street
          city
          state
          zipCode
          country
        }
        role {
          id
          name
          permissions
          description
        }
        toteSuperAdmin
      }
      totalCount
    }

    getAllLocations(businessId: $businessId, status: $status) {
      id
      name
      address {
        street
        city
        state
        zipCode
        country
      }
      timeZone
      status
      storeNumber
    }
  }
`)

export const GET_ORDER_RECEIPT_TEMPLATE_CONFIG = graphql(`
  query getOrderReceiptTemplateConfig($businessId: String!) {
    getBusiness(businessId: $businessId) {
      id
      configurations {
        emailReceiptEnabled
        templates {
          orderReceipt
        }
      }
    }
  }
`)
