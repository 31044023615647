import {useMutation} from '@apollo/client'
import {Grid, Typography} from '@mui/material'
import useNotify from 'hooks/useNotify'
import StyledDialog from 'ui/molecules/StyledDialog'
import {CREATE_CASH_TRANSACTION} from 'graphql/mutations/cash-management.mutations'
import {t} from 'i18next'
import {LocationType} from 'types/location.types'
import {TransactionType} from 'constants/constants'
import {v4 as uuid4} from 'uuid'
import React from 'react'

interface FilterSelectProps {
  selectedItem: any
  location: LocationType
  onUpdate: () => void
}

const CLOSE = 'close'

const CashTillClose = ({
  selectedItem,
  location,
  onUpdate
}: FilterSelectProps) => {
  const notify = useNotify()
  const [eventId] = React.useState<string>(uuid4())
  const [performMutation] = useMutation(CREATE_CASH_TRANSACTION)

  const handleTillCloseClick = async () => {
    try {
      await performMutation({
        variables: {
          amount: selectedItem.totalCash,
          location: location.id,
          till: selectedItem.till,
          register: selectedItem.register,
          transactionType: TransactionType.DEBIT,
          eventType: CLOSE,
          description: 'Till Close',
          eventId: eventId
        }
      })
      onUpdate()
      notify.show(t('cash-management.transactions.success'), 'success')
    } catch (error) {
      notify.show(
        (error as any)?.message || 'Something went wrong. Please try again.',
        'error'
      )
    }
  }

  return (
    <StyledDialog
      open={true}
      title={'Register ' + selectedItem?.register}
      body={
        <Grid>
          <Grid container spacing={2} padding={4}>
            <Grid item xs={12} sm={12}>
              <Typography variant='h3' style={{textAlign: 'left'}}>
                Are you sure you want to close Till {selectedItem.till}?
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      }
      successButtonName='Confirm'
      cancelButtonName='Dismiss'
      cancelCallback={() => {
        onUpdate()
      }}
      onClose={() => {}}
      successCallback={() => {
        handleTillCloseClick()
      }}
    />
  )
}

export default CashTillClose
