import {combineSlices, configureStore} from '@reduxjs/toolkit'
import {useDispatch, useSelector} from "react-redux";

const rootReducer = combineSlices();

export const store = configureStore({
  reducer: rootReducer,
  devTools: {
    name: 'Admin Panel'
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()
