import {graphql} from 'graphql/generatedTypes'

export const TRANSACTION_EVENT_SUBSCRIPTION = graphql(`
  subscription transactionEventSubscription(
    $locationId: String!
    $businessId: String!
  ) {
    webCashEvent(locationId: $locationId, businessId: $businessId) {
      cashEvent
    }
  }
`)
