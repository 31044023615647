import {useLazyQuery, useQuery, useSubscription} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Box, Grid, IconButton, Typography} from '@mui/material'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {useAuth} from 'auth/AuthContext'
import BankDepositHistory from 'clientDashboard/cashManagement/BankDepositHistory'
import CashDepositForm from 'clientDashboard/cashManagement/CashDepositForm'
import CashDrawerContainer from 'clientDashboard/cashManagement/CashDrawerContainer'
import CashDropDrawerContainer from 'clientDashboard/cashManagement/CashDropDrawerContainer'
import CashReviewDrawerContainer from 'clientDashboard/cashManagement/CashReviewDrawerContainer'
import StyledButton from 'ui/atoms/StyledButton'
import StyledChip from 'ui/atoms/StyledChip'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledTable from 'ui/molecules/StyledTable'
import theme, {colors, customPadding} from 'config/theme'
import {TillStatus} from 'constants/constants'
import {Permission} from 'constants/permissions'
import {
  GET_TILL_TRANSACTIONS,
  GET_TRANSACTION_SUMMARY
} from 'graphql/queries/cash-management.queries'
import {GET_REGISTER_AND_TILL} from 'graphql/queries/cash-register-till.queries'
import {CASH_EVENT_SUBSCRIPTION} from 'graphql/subscriptions/cash-event-subscriptions'
import usePermission from 'hooks/usePermission'
import {MRT_ColumnDef, MRT_ColumnFiltersState} from 'material-react-table'
import moment from 'moment'
import React, {useEffect, useMemo} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import classes from 'clientDashboard/cashManagement/cash-manager.styles.module.css'
import {CashDetailType} from 'types/cashSummary.types'
import {centsToDollar, formatCurrency} from 'utils/common'
import StoreSafeReconcileForm from 'clientDashboard/cashManagement/StoreSafeReconcileForm'
import CashSummary from 'clientDashboard/cashManagement/CashSummary'
import {t} from 'i18next'
import CashTillClose from 'clientDashboard/cashManagement/CashTillClose'
import CashBulkActionForm from 'clientDashboard/cashManagement/CashBulkActionForm'

type tillStatus = {
  status: string
  description: string
}

const statusData: tillStatus[] = Object.values(TillStatus).map((status) => ({
  status: t(`till-status-value.${status}`),
  description: t(`till-status.${status}.description`)
}))

const CashManagementSummary = () => {
  const propLocation = useLocation()
  const enableHistory = (propLocation.state as {enableHistory?: boolean})
    ?.enableHistory

  const {authInfo} = useAuth()

  const history = useHistory()

  const [selectedItem, setSelectedItem] = React.useState<any>({})
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [drawerDropOpen, setDropDrawerOpen] = React.useState(false)
  const [drawerReviewOpen, setReviewDrawerOpen] = React.useState(false)
  const [bankDepositOpen, setBankDepositOpen] = React.useState(false)
  const [tillClose, setTillClose] = React.useState(false)
  const [openRegisters, setOpenRegisters] = React.useState(false)
  const [closeRegisters, setCloseRegisters] = React.useState(false)

  const [reconcileSafeOpen, setReconcileSafeOpen] = React.useState(false)

  const [statusInfo, setStatusInfo] = React.useState(false)

  const [selectedDate, setSelectedDate] = React.useState<string>(
    moment().local().format('YYYY-MM-DD')
  )

  const statusOrder = {review: 1, open: 2, closed: 3}

  const [selectedRegister, setSelectedRegister] = React.useState<string>('all')
  const [selectedTill, setSelectedTill] = React.useState<string>('all')

  const [columnFilters, setColumnFilters] =
    React.useState<MRT_ColumnFiltersState>([])

  const hasReadBankDepositAccess = usePermission(Permission.READ_BANK_DEPOSIT)
  const hasCreateBankDepositAccess = usePermission(
    Permission.CREATE_BANK_DEPOSIT
  )

  const hasCreateTransactionAccess = usePermission(
    Permission.CREATE_CASH_TRANSACTION
  )

  const handleRedirect = () => {
    if (!hasReadBankDepositAccess) return
    history.push(`/${authInfo.businessId}/cash-management/summary`, {
      enableHistory: true
    })
  }

  const [getRegisterTill, {data: registerTillData}] = useLazyQuery(
    GET_REGISTER_AND_TILL,
    {fetchPolicy: 'no-cache'}
  )

  const {
    loading: loadingRegister,
    error: registerError,
    data: registerData,
    refetch: refetchRegister
  } = useQuery(GET_TILL_TRANSACTIONS, {
    variables: {
      register: selectedRegister,
      till: selectedTill,
      date: selectedDate,
      locationId: authInfo.locationId
    }
  })

  useEffect(() => {
    getRegisterTill({
      variables: {
        businessId: authInfo.businessId as string,
        locationId: authInfo.locationId as string
      }
    })
  }, [])

  const {
    loading: loadingAllSummary,
    data: summaryAllData,
    refetch: refetchAllSummary
  } = useQuery(GET_TRANSACTION_SUMMARY, {
    variables: {
      register: 'all',
      till: 'all',
      date: selectedDate,
      locationId: authInfo.locationId
    }
  })

  const {data: _subData} = useSubscription(CASH_EVENT_SUBSCRIPTION, {
    variables: {
      locationId: authInfo.locationId
    }
  })

  useEffect(() => {
    refetchRegister()
    refetchAllSummary()
  }, [
    selectedDate,
    drawerReviewOpen,
    drawerOpen,
    drawerDropOpen,
    tillClose,
    openRegisters,
    closeRegisters
  ])

  const refresh = () => {
    refetchRegister()
    refetchAllSummary()
  }

  useEffect(() => {
    if (_subData) {
      const cashEvent = _subData.webCashEvent?.cashEvent
      if (cashEvent === 'refresh') {
        refetchRegister()
        refetchAllSummary()
      }
    }
  }, [_subData])

  const nodes = registerData?.tillTransactions?.nodes
  const registerTotalCash =
    registerData?.tillTransactions?.registerTotalCash || 0
  const registerNetCash = registerData?.tillTransactions?.registerNetCash || 0

  const activeTill = registerData?.tillTransactions?.activeTill || 0

  const summary = summaryAllData?.tillTransactionsSummary

  const safeTotalAmount = summary?.safeTotalCash
  const registerTotalAmount = summary?.registerTotalCash
  const storeTotalAmountState = summary?.storeTotalCash
  const reserveTotalCash = summary?.reserveTotalCash

  const handleStatusClick = (item: any) => {
    setSelectedItem(item)
    setDrawerOpen(true)
  }

  const handleCancelCashPickup = () => {
    setSelectedItem({})
    setDrawerOpen(false)
  }

  const handleCancelCashDrop = () => {
    setSelectedItem({})
    setDropDrawerOpen(false)
  }

  const handleCancelReconcile = () => {
    setSelectedItem({})
    setReviewDrawerOpen(false)
  }

  const handleCancelTillClose = () => {
    setSelectedItem({})
    setTillClose(false)
  }

  const handleDropStatusClick = (item: any) => {
    setSelectedItem(item)
    setDropDrawerOpen(true)
  }

  const handleReviewStatusClick = (item: any) => {
    setSelectedItem(item)
    setReviewDrawerOpen(true)
  }

  const handleTillClose = (item: any) => {
    setSelectedItem(item.original)
    setTillClose(true)
  }

  const handleDateChange = (date: string) => {
    setColumnFilters((prevFilters) => {
      const otherFilters = prevFilters.filter((filter) => filter.id !== 'date')
      return [...otherFilters, {id: 'date', value: moment(date)}]
    })
  }

  const columns = useMemo<MRT_ColumnDef<CashDetailType>[]>(() => {
    let filteredColumns = [
      {
        accessorKey: 'openTime',
        header: 'Open Date',
        footer: 'Total',
        enableColumnFilter: false,
        Cell: ({cell}: any) => {
          return cell.getValue()
            ? moment(cell.getValue()).local().format('MM/DD/YYYY')
            : '-'
        }
      },
      {
        accessorKey: 'date',
        header: 'Business Date',
        enableColumnFilter: false,
        Cell: ({cell}: any) => {
          return cell.getValue()
            ? moment(cell.getValue()).local().format('MM/DD/YYYY')
            : '-'
        }
      },
      {
        accessorKey: 'register',
        header: 'Register',
        enableColumnFilter: true,
        filterVariant: 'multi-select',
        muiFilterTextFieldProps: {
          placeholder: 'All',
          sx: {
            minWidth: '100px'
          }
        },
        filterSelectOptions: registerTillData?.fetchRegisterList?.map(
          (register: any) => ({
            label: register.name,
            value: register.name
          })
        ),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },

      {
        accessorKey: 'till',
        header: 'Till',
        enableColumnFilter: true,
        muiFilterTextFieldProps: {
          placeholder: 'All',
          sx: {
            minWidth: '100px'
          }
        },
        filterVariant: 'multi-select',
        filterSelectOptions: registerTillData?.fetchTills?.map((till: any) => ({
          label: till.name,
          value: till.name
        })),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },

      {
        accessorKey: 'employee.name',
        header: 'Employee',
        enableColumnFilter: false,
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'totalCash',
        header: 'Total Cash',
        enableColumnFilter: false,

        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {formatCurrency(centsToDollar(cell.getValue()))}
            </Typography>
          )
        },
        muiTableBodyCellProps: {
          align: 'right'
        },
        muiTableFooterCellProps: {
          align: 'right'
        },

        footer: formatCurrency(centsToDollar(registerTotalCash))
      },
      {
        accessorKey: 'netCash',
        header: 'Net Cash',
        enableColumnFilter: false,
        Cell: ({cell, row}: any) => {
          return cell.getValue() < 0 ? (
            <Typography variant='subtitle2' className={classes.tableCellOrange}>
              ({formatCurrency(centsToDollar(Math.abs(cell.getValue())))})
            </Typography>
          ) : (
            <Typography
              variant='subtitle2'
              className={
                cell.getValue() >= row.original.tillAmountUpperLimit
                  ? classes.tableCellOrange
                  : classes.tableCell
              }
            >
              {formatCurrency(centsToDollar(cell.getValue()))}
            </Typography>
          )
        },
        footer: formatCurrency(centsToDollar(Math.abs(registerNetCash))),
        muiTableBodyCellProps: {
          align: 'right'
        },
        muiTableFooterCellProps: {
          align: 'right'
        }
      },
      {
        accessorKey: 'status',
        Header: () => (
          <>
            Status
            {
              <IconButton size='small' onClick={() => setStatusInfo(true)}>
                <FontAwesomeIcon
                  icon={icon({
                    name: 'info-circle',
                    family: 'classic',
                    style: 'solid'
                  })}
                  color={theme.palette.text.secondary}
                />
              </IconButton>
            }
          </>
        ),
        header: 'Status',
        enableColumnFilter: false,
        enableSorting: false,

        Cell: ({cell, row}: any) => {
          return <span>{t(`till-status-value.${cell.getValue()}`)}</span>
        }
      }
    ]
    if (
      !authInfo.permissions?.includes(Permission.READ_TILL_TOTAL_CASH_COLUMN)
    ) {
      filteredColumns = filteredColumns.filter(
        (column) => column.accessorKey !== 'totalCash'
      )
    }
    return filteredColumns as MRT_ColumnDef<CashDetailType>[]
  }, [
    registerTotalCash,
    registerNetCash,
    registerTillData,
    authInfo.permissions
  ])

  useEffect(() => {
    columnFilters.forEach((filter: any) => {
      if (filter?.id === 'date') {
        setSelectedDate(filter?.value.format('YYYY-MM-DD') || '')
      }
      if (filter?.id === 'register') {
        setSelectedRegister(filter?.value?.join(','))
      }

      if (filter?.id === 'till') {
        setSelectedTill(filter?.value?.join(','))
      }
    })

    if (!columnFilters.some((filter: any) => filter?.id === 'register')) {
      setSelectedRegister('all')
    }

    if (!columnFilters.some((filter: any) => filter?.id === 'till')) {
      setSelectedTill('all')
    }
  }, [columnFilters, registerTotalCash, registerNetCash])

  const statusColumns = useMemo<MRT_ColumnDef<tillStatus>[]>(
    () => [
      {
        accessorKey: 'status',
        header: 'Register & Till Status',
        size: 100
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 150
      }
    ],
    []
  )

  const mainContainer = () => {
    return (
      <>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 12
          }}
        >
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4
            }}
          >
            <Grid item>
              <Typography variant='h6'>Live Cash Registers</Typography>
            </Grid>
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  color: theme.palette.text.secondary,
                  lineHeight: '140%'
                }}
              >
                {activeTill} Register(s) Opened today or left unreconciled.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 12
            }}
          >
            <Grid item>
              <StyledButton
                fullWidth={false}
                disabled={!hasReadBankDepositAccess}
                variant='outlined'
                size='medium'
                onClick={handleRedirect}
              >
                Bank Deposit History
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                fullWidth={false}
                id='successButton'
                disabled={!hasCreateBankDepositAccess}
                size='medium'
                onClick={() => setBankDepositOpen(true)}
              >
                Create Bank Deposit
              </StyledButton>
            </Grid>

            <Grid item>
              <StyledButton
                fullWidth={false}
                id='successButton'
                disabled={!hasCreateBankDepositAccess}
                size='medium'
                onClick={() => setReconcileSafeOpen(true)}
              >
                Reconcile Safe
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                fullWidth={false}
                id='successButton'
                disabled={!hasCreateTransactionAccess}
                size='medium'
                onClick={() => setOpenRegisters(true)}
              >
                Open Registers
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                fullWidth={false}
                id='successButton'
                disabled={!hasCreateTransactionAccess}
                size='medium'
                onClick={() => setCloseRegisters(true)}
              >
                Close Registers
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
        {itemContainer()}
        <Grid item container style={{gap: 20}}>
          <Grid
            item
            container
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 24,
              alignSelf: 'stretch'
            }}
          >
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 6
              }}
            >
              <Grid item>
                <Typography variant='h6'>Summary</Typography>
                <Typography
                  variant='subtitle2'
                  style={{
                    color: theme.palette.text.secondary,
                    lineHeight: '140%'
                  }}
                >
                  See the store totals for your selected date
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                padding: '9px 16px',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 8
              }}
            >
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    value={moment(selectedDate)}
                    onChange={(newValue) =>
                      handleDateChange(
                        newValue ? newValue.format('YYYY-MM-DD') : ''
                      )
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        required: true
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            style={{
              display: 'flex',
              padding: customPadding.LARGE,
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              gap: 24,
              borderTop: '1px solid',
              borderBottom: '1px solid',
              borderColor: colors.grey300
            }}
          >
            <Grid
              item
              style={{
                display: 'flex',
                padding: '10px 22px',
                flex: '1 0 0',
                alignSelf: 'stretch',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid',
                borderColor: colors.grey300
              }}
            >
              <Grid
                item
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: 12,
                  flex: '1 0 0',
                  alignSelf: 'stretch'
                }}
              >
                <Typography
                  variant='subtitle2'
                  style={{
                    color: theme.palette.text.secondary,
                    lineHeight: '140%'
                  }}
                >
                  Store Total
                </Typography>
                <Typography variant='h6'>
                  {formatCurrency(centsToDollar(storeTotalAmountState))}
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  flexDirection: 'column',
                  alignSelf: 'stretch'
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'store',
                    family: 'classic',
                    style: 'regular'
                  })}
                  fontSize={32}
                  color={colors.lightGray}
                />
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                padding: '10px 22px',
                flex: '1 0 0',
                alignSelf: 'stretch',
                justifyContent: 'center',
                borderRight: '1px solid',
                borderColor: colors.grey300
              }}
            >
              <Grid
                item
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: 12,
                  flex: '1 0 0',
                  alignSelf: 'stretch'
                }}
              >
                <Typography
                  variant='subtitle2'
                  style={{
                    color: theme.palette.text.secondary,
                    lineHeight: '140%'
                  }}
                >
                  Register Total
                </Typography>
                <Typography variant='h6'>
                  {formatCurrency(centsToDollar(registerTotalAmount))}
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  flexDirection: 'column',
                  alignSelf: 'stretch'
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'cash-register',
                    family: 'classic',
                    style: 'regular'
                  })}
                  fontSize={32}
                  color={colors.lightGray}
                />
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                padding: '10px 22px',
                flex: '1 0 0',
                alignSelf: 'stretch',
                justifyContent: 'center',
                borderRight: '1px solid',
                borderColor: colors.grey300
              }}
            >
              <Grid
                item
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: 12,
                  flex: '1 0 0',
                  alignSelf: 'stretch'
                }}
              >
                <Typography
                  variant='subtitle2'
                  style={{
                    color: theme.palette.text.secondary,
                    lineHeight: '140%'
                  }}
                >
                  Till Safe Total
                </Typography>
                <Typography variant='h6'>
                  {formatCurrency(centsToDollar(safeTotalAmount))}
                </Typography>
              </Grid>

              <Grid
                item
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  flexDirection: 'column',
                  alignSelf: 'stretch'
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'lock-keyhole',
                    family: 'classic',
                    style: 'regular'
                  })}
                  fontSize={32}
                  color={colors.lightGray}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              style={{
                display: 'flex',
                padding: '10px 22px',
                flex: '1 0 0',
                alignSelf: 'stretch',
                justifyContent: 'center'
              }}
            >
              <Grid
                item
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: 12,
                  flex: '1 0 0',
                  alignSelf: 'stretch'
                }}
              >
                <Typography
                  variant='subtitle2'
                  style={{
                    color: theme.palette.text.secondary,
                    lineHeight: '140%'
                  }}
                >
                  Reserve Safe Total
                </Typography>
                <Typography variant='h6'>
                  {formatCurrency(centsToDollar(reserveTotalCash || 0))}
                </Typography>
              </Grid>

              <Grid
                item
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  flexDirection: 'column',
                  alignSelf: 'stretch'
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'lock-keyhole',
                    family: 'classic',
                    style: 'regular'
                  })}
                  fontSize={32}
                  color={colors.lightGray}
                />
              </Grid>
            </Grid>
          </Grid>
          {<CashSummary summary={summary} />}
        </Grid>
        <CashDepositForm
          openModel={bankDepositOpen}
          setModelOpen={setBankDepositOpen}
          safeTotalAmount={safeTotalAmount}
        />
        {reconcileSafeOpen && (
          <StoreSafeReconcileForm
            setModelOpen={setReconcileSafeOpen}
            safeTotalAmount={safeTotalAmount}
            reserveTotalCash={reserveTotalCash}
          />
        )}
        <Grid
          item
          container
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 10,
            flex: '1 0 0',
            alignSelf: 'stretch'
          }}
        >
          {statusInfoContainer()}
          {drawerOpen && openDrawerContainer()}
          {drawerDropOpen && openDropDrawerContainer()}
          {drawerReviewOpen && (
            <CashReviewDrawerContainer
              selectedItem={selectedItem}
              registerList={(nodes || []).filter(
                (entry: any) => entry.status === TillStatus.REVIEW
              )}
              setReviewDrawerOpen={handleCancelReconcile}
            />
          )}
          {tillClose && (
            <CashTillClose
              selectedItem={selectedItem}
              location={
                authInfo.storeNumbersMap?.[authInfo?.storeNumber as string]
              }
              onUpdate={handleCancelTillClose}
            />
          )}
          {openRegisters && (
            <CashBulkActionForm
              registerList={nodes}
              onUpdate={setOpenRegisters}
              setReviewDrawerOpen={setReviewDrawerOpen}
              locationId={authInfo.locationId as string}
              refetchData={refresh}
            />
          )}
          {closeRegisters && (
            <CashBulkActionForm
              registerList={nodes}
              onUpdate={setCloseRegisters}
              setReviewDrawerOpen={setReviewDrawerOpen}
              locationId={authInfo.locationId as string}
              isClose={true}
              refetchData={refresh}
            />
          )}
        </Grid>
      </>
    )
  }

  const actionsButtons = (row: any) => {
    return (
      <>
        <Box sx={{display: 'flex', gap: '1rem', padding: '10px'}}>
          {row.original.status === TillStatus.OPEN &&
            ((row.original.netCash >= row.original.tillAmountUpperLimit && (
              <StyledButton
                fullWidth={false}
                color='primary'
                size='small'
                endIcon={
                  <FontAwesomeIcon
                    icon={icon({
                      name: 'money-bill-transfer'
                    })}
                    style={{fontSize: '12px'}}
                  />
                }
                onClick={() => handleStatusClick(row.original)}
              >
                Cash Pickup
              </StyledButton>
            )) ||
              ((row.original.totalCash < 0 || row.original.netCash < 0) && (
                <StyledButton
                  fullWidth={false}
                  size='small'
                  color='primary'
                  endIcon={
                    <FontAwesomeIcon
                      icon={icon({
                        name: 'money-bill-transfer'
                      })}
                      style={{fontSize: '12px'}}
                    />
                  }
                  onClick={() => handleDropStatusClick(row.original)}
                >
                  Cash Drop
                </StyledButton>
              )))}

          {row.original.status === TillStatus.OPEN && (
            <StyledButton
              fullWidth={false}
              id='successButton'
              size='small'
              endIcon={
                <FontAwesomeIcon
                  icon={icon({
                    name: 'greater-than'
                  })}
                  style={{fontSize: '12px'}}
                />
              }
              onClick={() => handleTillClose(row)}
            >
              Close
            </StyledButton>
          )}
          {row.original.status === TillStatus.CLOSED && (
            <StyledChip
              label='Complete'
              color='success'
              icon={
                <FontAwesomeIcon
                  icon={icon({
                    name: 'check'
                  })}
                />
              }
            />
          )}
          {row.original.status === TillStatus.REVIEW && (
            <StyledButton
              fullWidth={false}
              id='successButton'
              size='small'
              endIcon={
                <FontAwesomeIcon
                  icon={icon({
                    name: 'greater-than'
                  })}
                  style={{fontSize: '12px'}}
                />
              }
              onClick={() => handleReviewStatusClick(row.original)}
            >
              Reconcile
            </StyledButton>
          )}
        </Box>
      </>
    )
  }

  const itemContainer = () => {
    return (
      <>
        <Grid
          item
          container
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 0 0',
            alignSelf: 'stretch',
            borderRadius: 12
          }}
        >
          <StyledTable
            columns={columns}
            data={(nodes || []).sort(
              (transaction_a: any, transaction_b: any) =>
                statusOrder[transaction_a.status as keyof typeof statusOrder] -
                statusOrder[transaction_b.status as keyof typeof statusOrder]
            )}
            enableTableFooter
            manualFiltering
            enableStickyHeader
            enableRowActions={true}
            state={{
              columnFilters,
              showSkeletons: loadingRegister || loadingAllSummary
            }}
            onColumnFiltersChange={setColumnFilters as any}
            initialState={{
              density: 'compact',
              showColumnFilters: false,
              columnVisibility: {
                netCash: false,
                date: false
              },
              columnPinning: {
                left: ['mrt-row-select'],
                right: ['mrt-row-actions']
              }
            }}
            renderRowActions={(row) => {
              return actionsButtons(row.row)
            }}
            muiTableBodyCellProps={({row}) => ({
              sx: {
                background:
                  row.original.status === TillStatus.REVIEW
                    ? theme.palette.error.light
                    : '',
                align: 'left',
                border: '1px solid',
                borderColor: colors.grayBorder,
                fontSize: '14px',
                fontWeight: 400
              }
            })}
            positionToolbarAlertBanner={'bottom'}
          />
        </Grid>
      </>
    )
  }

  const openDrawerContainer = () => {
    return (
      <CashDrawerContainer
        modelOpen={drawerOpen}
        selectedItem={selectedItem}
        location={authInfo.storeNumbersMap?.[authInfo?.storeNumber as string]}
        onUpdate={handleCancelCashPickup}
      />
    )
  }

  const openDropDrawerContainer = () => {
    return (
      <CashDropDrawerContainer
        modelOpen={drawerDropOpen}
        selectedItem={selectedItem}
        location={authInfo.storeNumbersMap?.[authInfo?.storeNumber as string]}
        tillSafeAmount={safeTotalAmount}
        onUpdate={handleCancelCashDrop}
      />
    )
  }

  const statusInfoContainer = () => {
    return (
      <>
        <StyledDialog
          open={statusInfo}
          title='Status Information'
          body={
            <Grid>
              <StyledTable
                columns={statusColumns}
                data={(statusData || []) as any}
                enableColumnActions={false}
                enableColumnFilters={false}
                enablePagination={false}
                enableSorting={false}
                enableHiding={false}
                renderToolbarInternalActions={() => null}
              />
            </Grid>
          }
          cancelButtonName='Dismiss'
          cancelCallback={() => {
            setStatusInfo(false)
          }}
          onClose={() => {}}
        />
      </>
    )
  }

  if (registerError) {
    return <p>Error fetching data</p>
  }

  return <>{enableHistory ? <BankDepositHistory /> : mainContainer()}</>
}

export default CashManagementSummary
