import {Card, CardContent, CardMedia, Grid, Typography} from '@mui/material'
import React from 'react'

interface Image {
  id: string
  url: string
  title: string
}

interface ImageGridProps {
  images: Image[]
}

const MultipleImages: React.FC<ImageGridProps> = ({images}) => {
  return (
    <Grid container spacing={3}>
      {images.map((image) => (
        <Grid item xs={12} sm={6} md={4} key={image.id}>
          <Card>
            <CardMedia component='img' image={image.url} alt={image.title} />
            {image.title && (
              <CardContent>
                <Typography variant='h5' component='h2'>
                  {image.title}
                </Typography>
              </CardContent>
            )}
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

export default MultipleImages
