import {ApolloError, useMutation} from '@apollo/client'
import OktaAuth, {toRelativeUrl} from '@okta/okta-auth-js'
import {VERIFY_OKTA_TOKEN_AND_GENERATE_SESSION_TOKEN} from 'graphql/mutations/login.mutation'
import {jwtDecode} from 'jwt-decode'
import {useEffect, useState} from 'react'

import {useAuth} from 'auth/AuthContext'
import client from 'config/apollo.config'
import config from 'config/okta.config'
import {LocalStorageKeys} from 'constants/constants'
import useEmployee from './useEmployee'
import useLocalStorage from './useLocalStorage'

export type OktaConfigType = {
  oktaConfig: OktaAuth
  signOut: (oktaAuth: OktaAuth) => Promise<void>
  onAuthRequired: (oktaAuth: OktaAuth) => Promise<void>
  restoreOriginalUri: (oktaAuth: OktaAuth, originalUri: string) => Promise<void>
}

const useOktaAuth = (): OktaConfigType => {
  const {authInfo, updateAuthInfo} = useAuth()
  const [oktaConfig, setOktaConfig] = useState<any>(null)

  const [businessId] = useLocalStorage(LocalStorageKeys.BUSINESS_ID, '')

  const [toteAccessToken, setToteAccessToken, clearToteAccessToken] =
    useLocalStorage(LocalStorageKeys.TOTE_ACCESS_TOKEN, '')

  const [oktaConfigInStorage, setOktaConfigInStorage] = useLocalStorage(
    LocalStorageKeys.OKTA_CONFIG,
    {
      oktaIssuer: '',
      oktaClientId: ''
    }
  )

  const {getEmployee} = useEmployee()

  const [verifyOktaTokenAndGenerateSessionToken] = useMutation(
    VERIFY_OKTA_TOKEN_AND_GENERATE_SESSION_TOKEN,
    {
      fetchPolicy: 'no-cache'
    }
  )

  // Extract the okta issuer and clientId from the query params or local storage
  useEffect(() => {
    // If the user is a tote super admin, update the authInfo
    if (authInfo.isToteSuperAdmin === null && toteAccessToken) {
      try {
        const decodedToken: any = jwtDecode(toteAccessToken)
        updateAuthInfo({
          isToteSuperAdmin: Boolean(decodedToken?.is_tote_super_admin)
        })
      } catch (error) {
        console.error('Error while decoding token: ', error)
        signOut(oktaConfig)
      }
    }

    // Early exit if authInfo.okta is already set
    if (authInfo.okta) return

    // Attempt to extract Okta config from localStorage
    let {oktaIssuer, oktaClientId} = oktaConfigInStorage || {
      oktaIssuer: '',
      oktaClientId: ''
    }

    // Update authInfo with Okta config if both values are present
    if (oktaIssuer && oktaClientId) {
      updateAuthInfo({okta: {oktaIssuer, oktaClientId}})
    }
  }, [])

  useEffect(() => {
    const {oktaIssuer, oktaClientId} = authInfo.okta || {}
    if (!oktaIssuer || !oktaClientId || oktaConfig) return

    setOktaConfig(
      new OktaAuth({
        ...config.oidc,
        issuer: oktaIssuer,
        clientId: oktaClientId
      })
    )
    setOktaConfigInStorage(authInfo.okta)
  }, [authInfo.okta?.oktaIssuer])

  // Function to sign out
  const signOut = async (oktaAuth: OktaAuth) => {
    if (!oktaAuth?.authStateManager?.getAuthState?.()?.isAuthenticated) return

    await oktaAuth?.signOut({
      postLogoutRedirectUri: window.location.origin
    })
    // clear the tote_access_token from local storage
    clearToteAccessToken()
  }

  const restoreOriginalUri = async (
    oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    if (!oktaAuth?.authStateManager?.getAuthState?.()?.isAuthenticated) return
    const tokens = await oktaAuth.tokenManager.getTokens()
    await verifyOktaTokenAndGenerateSessionToken({
      fetchPolicy: 'no-cache',
      variables: {
        businessId: businessId as string,
        accessToken: tokens.accessToken?.accessToken as string,
        idToken: tokens.idToken?.idToken as string,
        oktaClientId: oktaAuth.options.clientId as string,
        oktaIssuer: oktaAuth.options.issuer as string
      },

      onCompleted: async (data: any) => {
        if (data.verifyOktaTokenAndGenerateSessionToken) {
          setToteAccessToken(data.verifyOktaTokenAndGenerateSessionToken)
          await client?.clearStore?.()
          await getEmployee()
        }
        window.location.href = toRelativeUrl(
          originalUri || `/${businessId || authInfo.businessId}`,
          window.location.origin
        )
      },
      onError: async (error: ApolloError) => {
        signOut(oktaAuth)
      }
    })
  }

  const onAuthRequired = async (oktaAuth: OktaAuth) => {
    clearToteAccessToken()
    await client?.clearStore?.()

    await oktaAuth.signInWithRedirect({
      state: JSON.stringify({
        businessId: `${businessId}`,
        okta: authInfo.okta
      })
    })
  }

  return {oktaConfig, signOut, onAuthRequired, restoreOriginalUri}
}

export default useOktaAuth
