import {Grid, Typography} from '@mui/material'
import StyledButton from 'ui/atoms/StyledButton'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledTable from 'ui/molecules/StyledTable'
import {useMemo} from 'react'
import {downloadJsonAsCsv} from 'utils/common'

interface ChartDataFormProps {
  data: any
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
}
const ChartDataForm = ({data, modalOpen, setModalOpen}: ChartDataFormProps) => {
  const generateChartFormColumns = useMemo(() => {
    const columns = Object.entries(data?.fields).map(([key, value]) => ({
      key: key,
      name: value
    }))
    return columns.map((column: any) => ({
      accessorKey: column.key,
      header: column.name,
      Cell: ({cell}: any) => {
        return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
      }
    }))
  }, [])

  const replaceKeys = (
    objArray: Array<Record<string, string>>,
    keyMap: Record<string, string>
  ): Array<Record<string, string>> => {
    return objArray.map((obj) => {
      const newObj: Record<string, string> = {}
      Object.keys(obj).forEach((key) => {
        const newKey = keyMap[key] || key
        newObj[newKey] = obj[key]
      })
      return newObj
    })
  }

  const exportChartCsvData = () => {
    const csvData = replaceKeys(data?.documents, data?.fields || {})
    downloadJsonAsCsv(csvData, 'chart_data.csv')
  }

  return (
    <>
      <StyledDialog
        open={modalOpen}
        title='Chart Data'
        body={
          <Grid>
            <StyledTable
              columns={generateChartFormColumns}
              data={(data?.documents || []) as any}
              enableColumnActions={false}
              enableColumnFilters={false}
              enablePagination={false}
              enableSorting={false}
              enableHiding={false}
              enableTopToolbar={false}
              muiTableContainerProps={() =>
                ({sx: {maxHeight: '400px', overflowY: 'auto'}}) as any
              }
              renderToolbarInternalActions={() => null}
              enableBottomToolbar={true}
              enableToolbarInternalActions={false}
              renderBottomToolbarCustomActions={() => {
                return (
                  <Grid container>
                    <Grid>
                      <StyledButton
                        onClick={() => {
                          exportChartCsvData()
                        }}
                      >
                        Download-CSV
                      </StyledButton>
                    </Grid>
                  </Grid>
                )
              }}
            />
          </Grid>
        }
        cancelButtonName='Dismiss'
        cancelCallback={() => {
          setModalOpen(false)
        }}
        onClose={() => {
          setModalOpen(false)
        }}
      />
    </>
  )
}
export default ChartDataForm
