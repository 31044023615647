import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  Divider,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

import {useAuth} from 'auth/AuthContext'
import theme, {space} from 'config/theme'
import {LocalStorageKeys} from 'constants/constants'
import useLocalStorage from 'hooks/useLocalStorage'
import LocationGroupSelector from 'clientDashboard/LocationGroupSelector'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectFeatureFlags} from 'clientDashboard/business.selectors'
import {selectBusinessId} from 'clientDashboard/employee.selectors'

const TopBar = ({signOut}: {signOut: () => void}) => {
  const featureFlags = useAppSelector(selectFeatureFlags)
  const businessId = useAppSelector(selectBusinessId)
  const listOfAllowedURIsForLocationGroups = [
    '/' + businessId + '/location-settings',
    '/' + businessId + '/employee-management'
  ]
  const showLocationGroupsSelector = () => {
    let uri = window.location.pathname
    return (
      featureFlags.locationGrouping &&
      listOfAllowedURIsForLocationGroups.includes(uri)
    )
  }

  const [storeMenuAnchorEl, setStoreMenuAnchorEl] =
    useState<null | HTMLElement>(null)
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(
    null
  )
  const storeMenuOpen = Boolean(storeMenuAnchorEl)
  const userMenuOpen = Boolean(userMenuAnchorEl)

  const {authInfo, updateAuthInfo} = useAuth()
  const {pathname} = useLocation()
  const [address, setAddress] = useState('')

  const [, setStoreNumber] = useLocalStorage(LocalStorageKeys.STORE_NUMBER)

  useEffect(() => {
    const pathArray = pathname.split('/').filter((_, index) => index > 1)

    const capitalizedPath = pathArray
      .map((segment) =>
        segment
          .split('-')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      )
      .join(' > ')

    setAddress(capitalizedPath)
  }, [pathname])

  const handleStoreMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setStoreMenuAnchorEl(event.currentTarget)
  }

  const handleUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenuAnchorEl(event.currentTarget)
  }

  const handleStoreMenuClose = () => {
    setStoreMenuAnchorEl(null)
  }

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null)
  }

  return (
    <>
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: space.SMALL
        }}
      >
        <Grid item>
          <Typography variant='h2'>{address}</Typography>
        </Grid>

        <Grid
          item
          style={{
            display: 'flex',
            gap: space.XS
          }}
        >
          {showLocationGroupsSelector() ? (
            <LocationGroupSelector />
          ) : (
            <Grid
              item
              style={{
                display: 'flex',
                padding: `${space.XS} ${space.SMALL}`,
                alignItems: 'center',
                gap: 12,
                borderRadius: '100px',
                background: theme.palette.primary.light
              }}
              onClick={handleStoreMenuClick}
            >
              <FontAwesomeIcon
                icon={icon({
                  name: 'location-dot',
                  family: 'classic',
                  style: 'solid'
                })}
                color='black'
              />

              <Typography variant='subtitle2'>
                Store #{authInfo.storeNumber}{' '}
                {authInfo.storeName ? `, ${authInfo.storeName}` : ''}
              </Typography>

              <FontAwesomeIcon
                icon={icon({
                  name: 'chevron-down',
                  family: 'classic',
                  style: 'light'
                })}
                color='black'
              />
            </Grid>
          )}

          <Grid
            item
            style={{
              display: 'flex',
              padding: `${space.XS} ${space.SMALL}`,
              alignItems: 'center',
              gap: 12,
              borderRadius: '100px',
              backgroundColor: theme.palette.background.default
            }}
            onClick={handleUserMenuClick}
          >
            <FontAwesomeIcon
              icon={icon({
                name: 'user',
                family: 'classic',
                style: 'solid'
              })}
              color='black'
            />

            <Grid item>
              <Typography variant='h3'>{authInfo.userName}</Typography>
            </Grid>

            <Grid item>
              <Typography variant='subtitle2' color='text.secondary'>
                {authInfo.role}
              </Typography>
            </Grid>

            <FontAwesomeIcon
              icon={icon({
                name: 'chevron-down',
                family: 'classic',
                style: 'light'
              })}
              color='black'
            />
          </Grid>
        </Grid>
      </Grid>

      <Menu
        anchorEl={storeMenuAnchorEl}
        id='store-menu'
        open={storeMenuOpen}
        onClose={handleStoreMenuClose}
        onClick={handleStoreMenuClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'auto',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              marginTop: space.XS,
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: space.SMALL,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)'
              }
            }
          }
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        {(
          Object.keys(authInfo.storeNumbersMap || ({} as object)) || [
            authInfo.storeNumber
          ]
        ).map((storeNumber) => (
          <MenuItem
            key={storeNumber}
            onClick={() => {
              setStoreNumber(storeNumber)
              updateAuthInfo({
                storeNumber: storeNumber as string,
                locationId: authInfo?.storeNumbersMap?.[storeNumber]
                  ?.id as string
              })
              handleStoreMenuClose()
              window.location.reload()
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                icon={icon({
                  name: 'shop',
                  family: 'classic',
                  style: 'light'
                })}
                size='lg'
                color='black'
              />
            </ListItemIcon>
            <Typography variant='subtitle1'>
              Store #{storeNumber},{' '}
              {authInfo.storeNumbersMap?.[storeNumber]?.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={userMenuAnchorEl}
        id='user-menu'
        open={userMenuOpen}
        onClose={handleUserMenuClose}
        onClick={handleUserMenuClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              minWidth: 300,
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              marginTop: space.XS,
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: space.SMALL,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)'
              }
            }
          }
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <Grid>
          <Grid padding={space.SMALL}>
            <Typography variant='h3'>{authInfo.userName}</Typography>
            <Typography variant='h4' color={'text.secondary'}>
              {authInfo.userEmail}
            </Typography>
          </Grid>
          <Divider />

          <MenuItem
            onClick={signOut}
            style={{
              marginTop: space.XS
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                icon={icon({
                  name: 'sign-out-alt',
                  family: 'classic',
                  style: 'regular'
                })}
                style={{
                  fontSize: '20px'
                }}
                color='red'
              />
            </ListItemIcon>
            <Typography variant='h3' color='red'>
              Logout
            </Typography>
          </MenuItem>
        </Grid>
      </Menu>
    </>
  )
}

export default TopBar
