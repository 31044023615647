import {graphql} from 'graphql/generatedTypes'

export const CREATE_ROLE = graphql(`
  mutation createRole(
    $businessId: String!
    $name: String!
    $description: String!
    $permissions: [String!]!
  ) {
    createRole(
      businessId: $businessId
      name: $name
      description: $description
      permissions: $permissions
    )
  }
`)

export const UPDATE_ROLE = graphql(`
  mutation updateRole(
    $businessId: String!
    $id: String!
    $name: String!
    $description: String!
    $isActive: Boolean!
    $permissions: [String!]!
  ) {
    updateRole(
      businessId: $businessId
      id: $id
      name: $name
      description: $description
      isActive: $isActive
      permissions: $permissions
    )
  }
`)

export const DELETE_ROLE = graphql(`
  mutation deleteRole($businessId: String!, $id: String!) {
    deleteRole(businessId: $businessId, id: $id)
  }
`)

export const ASSIGN_ROLE = graphql(`
  mutation assignRole($employeeId: String!, $roleId: String) {
    assignRole(employeeId: $employeeId, roleId: $roleId)
  }
`)
