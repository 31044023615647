import {ApolloError, useMutation} from '@apollo/client'
import {toRelativeUrl} from '@okta/okta-auth-js'
import {
  LOGIN_USER,
  RESEND_OTP,
  VERIFY_OTP_AND_GENERATE_SESSION_TOKEN
} from 'graphql/mutations/login.mutation'
import {jwtDecode} from 'jwt-decode'
import {useEffect, useState} from 'react'

import {useAuth} from 'auth/AuthContext'
import client from 'config/apollo.config'
import {LocalStorageKeys} from 'constants/constants'
import useEmployee from './useEmployee'
import useLocalStorage from './useLocalStorage'

type ToteAuthType = {
  loginUser: any
  verifyOTP: any
  resendOTP: any
  signOut: () => void
  errorMessage: string
}

const useToteAuth = (): ToteAuthType => {
  const {authInfo, updateAuthInfo} = useAuth()
  const {getEmployee} = useEmployee()

  const [errorMessage, setErrorMessage] = useState('')

  const [toteAccessToken, setToteAccessToken, clearToteAccessToken] =
    useLocalStorage(LocalStorageKeys.TOTE_ACCESS_TOKEN, '')

  const [loginUser] = useMutation(LOGIN_USER, {
    fetchPolicy: 'no-cache'
  })

  const [verifyOTP] = useMutation(VERIFY_OTP_AND_GENERATE_SESSION_TOKEN, {
    fetchPolicy: 'no-cache',
    onCompleted: async (data) => {
      setToteAccessToken(
        data?.verifyOtpAndGenerateSessionToken?.toteAccessToken as string
      )
      const businessId = data.verifyOtpAndGenerateSessionToken.businessId
      await client?.clearStore?.()
      await getEmployee()
      window.location.href = window.location.origin + `/${businessId}`
    },
    onError: (error: ApolloError) => {
      setErrorMessage(error.message)
    }
  })

  const [resendOTP] = useMutation(RESEND_OTP, {
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => {
      setErrorMessage(error.message)
    }
  })

  // Extract the okta issuer and clientId from the query params or local storage
  useEffect(() => {
    // If the user is a tote super admin, update the authInfo
    if (authInfo.isToteSuperAdmin === null && toteAccessToken) {
      try {
        const decodedToken: any = jwtDecode(toteAccessToken)
        updateAuthInfo({
          isToteSuperAdmin: Boolean(decodedToken?.is_tote_super_admin)
        })
      } catch (error) {
        console.error('Error while decoding token: ', error)
        signOut()
      }
    }
  }, [])

  // Function to sign out
  const signOut = async () => {
    clearToteAccessToken()
    window.location.href = toRelativeUrl(`/login`, window.location.origin)
  }

  return {loginUser, verifyOTP, signOut, resendOTP, errorMessage}
}

export default useToteAuth
