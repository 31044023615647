import 'react-notifications-component/dist/theme.css'

import {useMediaQuery} from '@mui/material'
import {Security, useOktaAuth} from '@okta/okta-react'
import {LocalStorageKeys} from 'constants/constants'
import useAccessToken from 'hooks/useAccessToken'
import useLocalStorage from 'hooks/useLocalStorage'
import {useEffect} from 'react'
import {Route, Switch, useHistory, useLocation} from 'react-router-dom'
import ClientInterface from 'clientDashboard/ClientInterface'

import LoginCallback from 'auth/okta/LoginCallback'
import Loading from 'ui/molecules/Loading'
import useBusiness from 'hooks/useBusiness'
import useCustomOktaAuth from 'hooks/useOktaAuth'

function ClientOktaRouteHandler() {
  const {pathname} = useLocation()
  const history = useHistory()
  const {isValidBusiness} = useBusiness()
  const isOktaLoginCallback = pathname === '/login/callback'
  const isLoginRoute = pathname === '/login'

  const {oktaConfig, onAuthRequired, restoreOriginalUri} = useCustomOktaAuth()

  if (!isValidBusiness && !isOktaLoginCallback && !isLoginRoute) {
    history.replace('/login')
  }

  if (!oktaConfig || (isOktaLoginCallback && !window.location.search)) {
    return <Loading />
  }

  return (
    <Security
      oktaAuth={oktaConfig}
      restoreOriginalUri={restoreOriginalUri}
      onAuthRequired={onAuthRequired}
    >
      <OktaHelperComponent isValidBusiness={isValidBusiness} />
    </Security>
  )
}

function OktaHelperComponent({isValidBusiness}: {isValidBusiness: boolean}) {
  const {authState, oktaAuth} = useOktaAuth()
  const {isAuthenticated} = useAccessToken()
  const isMobileScreen = useMediaQuery('(max-width:800px)')

  const [, , clearToteAccessToken] = useLocalStorage(
    LocalStorageKeys.TOTE_ACCESS_TOKEN,
    ''
  )

  const signOut = () => {
    oktaAuth?.signOut({
      postLogoutRedirectUri: window.location.origin
    })
    clearToteAccessToken()
  }

  useEffect(() => {
    !isAuthenticated && authState?.isAuthenticated && signOut()
  }, [])

  return (
    <Switch>
      <Route path={`/login/callback`} component={LoginCallback} />
      <Route>
        <ClientInterface
          signOut={signOut}
          isValidBusiness={isValidBusiness}
          isAuthenticated={isAuthenticated}
          isMobileScreen={isMobileScreen}
        />
      </Route>
    </Switch>
  )
}

export default ClientOktaRouteHandler
