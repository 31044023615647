import {ApolloError, useLazyQuery} from '@apollo/client'
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import LoginInterface from 'clientDashboard/LoginInterface'
import StyledButton from 'ui/atoms/StyledButton'
import StyledInput from 'ui/atoms/StyledInput'
import client from 'config/apollo.config'
import {AuthMethod, LocalStorageKeys} from 'constants/constants'
import env from 'constants/env'
import {AuthMethodType} from 'graphql/generatedTypes/graphql'
import {
  GET_AUTH_METHOD,
  GET_OKTA_CONFIG_FROM_ISSUER
} from 'graphql/queries/employee.queries'
import useAccessToken from 'hooks/useAccessToken'
import useEmployee from 'hooks/useEmployee'
import useLocalStorage from 'hooks/useLocalStorage'
import useNotify from 'hooks/useNotify'
import useToteAuth from 'hooks/useToteAuth'
import {useEffect, useState, useRef} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {useTranslation} from 'react-i18next'
import {getURLHost, isValidEmail, isValidURL} from 'utils/common'

interface DataType {
  [key: string]: {
    [key: string]: AuthMethodType
  }
}

function Login() {
  const {t} = useTranslation()
  const notify = useNotify()

  const {updateAuthInfo} = useAuth()
  const {isAuthenticated} = useAccessToken()
  const {
    loginUser,
    verifyOTP,
    resendOTP,
    errorMessage: loginError
  } = useToteAuth()
  const {getEmployee} = useEmployee()
  const [, setToteAccessToken] = useLocalStorage(
    LocalStorageKeys.TOTE_ACCESS_TOKEN,
    ''
  )
  const [checkingAuthValidity, setCheckingAuthValidity] = useState(true)

  const [businessIdInStore] = useLocalStorage(LocalStorageKeys.BUSINESS_ID, '')

  const [businessId, setBusinessId] = useState('')

  const [emailIdInStore, setEmailIdInStore] = useLocalStorage(
    LocalStorageKeys.EMAIL_ID,
    ''
  )
  const [, setOktaConfigInStorage] = useLocalStorage(
    LocalStorageKeys.OKTA_CONFIG,
    {
      oktaIssuer: '',
      oktaClientId: ''
    }
  )

  const [, setAuthMethod] = useLocalStorage(
    LocalStorageKeys.AUTH_METHOD,
    AuthMethod.TOTE
  )

  const [email, setEmail] = useState(emailIdInStore || '')
  const [password, setPassword] = useState('')
  const [oktaIssuer, setOktaIssuer] = useState('')
  const [otp, setOtp] = useState('')

  const [showPasswordField, setShowPasswordField] = useState(false)
  const [showOktaIssuerField, setShowOktaIssuerField] = useState(false)
  const [showOtpField, setShowOtpField] = useState(false)

  const [showBusinessSelection, setShowBusinessSelection] = useState(false)
  const [businessList, setBusinessList] = useState([])

  const [businessAuthData, setBusinessAuthData] = useState<DataType>({})

  const [errorMessage, setErrorMessage] = useState('')

  const isLocalOrDevelopment = env.APP_ENV === 'development'

  const recaptchaRef = useRef<any>(null)

  useEffect(() => {
    if (isAuthenticated && businessIdInStore) {
      window.location.href = window.location.origin + `/${businessIdInStore}`
    } else {
      setCheckingAuthValidity(false)
    }
  }, [])

  useEffect(() => {
    if (!loginError) return
    setErrorMessage(loginError)
  }, [loginError])

  const generateToken = async () => {
    if (recaptchaRef.current) {
      const token = await recaptchaRef.current.executeAsync()
      return token
    }
  }

  const handleAuthResponse = (data: any) => {
    data = data.getAuthMethod || data.getOktaConfigFromIssuer

    if (data?.length === 0 || !data[0].businessId) {
      setShowOktaIssuerField(true)
      return
    }

    if (data?.length > 1 || (data?.length > 0 && !businessId)) {
      setShowBusinessSelection(true)
      setBusinessId(data[0].businessId)

      const newBusinessAuthData = data.reduce((acc: any, authMethod: any) => {
        acc[authMethod.businessId] = authMethod
        return acc
      }, {})

      setBusinessAuthData((prevData) => ({
        ...prevData,
        ...newBusinessAuthData
      }))

      const newBusinessList = data?.map((authMethod: any) => ({
        businessId: authMethod.businessId,
        businessName: authMethod.businessName
      }))

      setBusinessList(newBusinessList)
      return
    }

    setShowBusinessSelection(false)
    data = data[0]
    setBusinessId(data.businessId)

    if (data.authMethod === AuthMethod.TOTE) {
      setShowPasswordField(true)
      setAuthMethod(AuthMethod.TOTE)
    } else if (
      data.authMethod === AuthMethod.OKTA &&
      data.okta?.clientId &&
      data.okta?.issuer
    ) {
      updateAuthInfo({
        authMethod: AuthMethod.OKTA,
        businessId: data.businessId as string,
        okta: {
          oktaIssuer: data.okta.issuer,
          oktaClientId: data.okta.clientId
        }
      })
      setAuthMethod(AuthMethod.OKTA)
      setOktaConfigInStorage({
        oktaIssuer: data.okta.issuer,
        oktaClientId: data.okta.clientId
      })

      setTimeout(
        (okta) => {
          window.location.href =
            window.location.origin +
            `/${data.businessId}?oktaIssuer=${okta.issuer}&oktaClientId=${okta.clientId}`
        },
        1000,
        data.okta
      )
      setEmailIdInStore(email?.toLocaleLowerCase())
    } else {
      setErrorMessage(t('login.invalid-auth-configurations'))
    }
  }

  const [getAuthMethod] = useLazyQuery(GET_AUTH_METHOD, {
    fetchPolicy: 'no-cache',
    onCompleted: handleAuthResponse,
    onError: (error) => {
      if (error.message.includes('User not found')) {
        setShowOktaIssuerField(true)
        setErrorMessage(t('login.invalid-username-or-password'))
      } else {
        setErrorMessage(
          error?.message || t('login.invalid-username-or-password')
        )
      }
    }
  })

  const [getOktaConfigFromIssuer] = useLazyQuery(GET_OKTA_CONFIG_FROM_ISSUER, {
    fetchPolicy: 'no-cache',
    onCompleted: handleAuthResponse,
    onError: (error) => {
      setErrorMessage(t('login.invalid-username-or-password'))
    }
  })

  const validEmailWithCaptcha = (email: string) => {
    return !isValidEmail(email)
  }

  const isSubmitButtonDisabled = () => {
    if (showOtpField) {
      return otp.length !== 6 || isNaN(Number(otp))
    }

    return (
      validEmailWithCaptcha(email) ||
      (showPasswordField && !password.length) ||
      (showOktaIssuerField && !isValidURL(oktaIssuer))
    )
  }

  const handleResendOtp = () => {
    resendOTP({
      variables: {
        email: email.toLocaleLowerCase(),
        businessId
      },
      onCompleted: () => {
        setOtp('')
        notify.show(t('login.otp-sent-successfully'), 'info')
      }
    })
  }

  const handleSubmit = async (e: any) => {
    e?.preventDefault()
    setErrorMessage('')

    if (isSubmitButtonDisabled()) return
    if (showOtpField) {
      verifyOTP({
        variables: {
          email: email.toLocaleLowerCase(),
          otp,
          businessId
        }
      })
      return
    }

    if (showPasswordField) {
      loginUser({
        variables: {
          email: email.toLocaleLowerCase(),
          password,
          businessId
        },
        onCompleted: async (data: any) => {
          const isMFAEnabled = data.loginUserForDashboard.mfaEnabled
          if (!isMFAEnabled) {
            setToteAccessToken(data.loginUserForDashboard.toteAccessToken || '')
            const businessId = data.loginUserForDashboard.businessId
            await client?.clearStore?.()
            await getEmployee()
            window.location.href = window.location.origin + `/${businessId}`
          } else {
            setShowOtpField(true)
            notify.show(
              t('login.please-enter-the-otp-sent-to-your-email'),
              'info'
            )
          }
        },

        onError: (error: ApolloError) => {
          setErrorMessage(t('login.invalid-username-or-password'))
        }
      })
      return
    }

    if (showOktaIssuerField) {
      getOktaConfigFromIssuer({
        variables: {
          issuer: getURLHost(oktaIssuer),
          businessId: businessId || ''
        }
      })
      return
    }

    if (showBusinessSelection && businessId) {
      const data = {
        getAuthMethod: [businessAuthData[businessId]]
      }
      handleAuthResponse(data)
      return
    }

    let generatedToken = ''
    if (!isLocalOrDevelopment) {
      generatedToken = await generateToken()
    }

    getAuthMethod({
      variables: {
        emailId: email.toLocaleLowerCase(),
        businessId: businessId || '',
        token: generatedToken || ''
      }
    })
  }

  const getLoginFields = () => {
    return (
      <>
        <Grid
          item
          container
          width={'100%'}
          style={{
            justifyContent: 'center'
          }}
        >
          <StyledInput
            style={{
              width: '50%'
            }}
            size='medium'
            label={t('login.email')}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            autoComplete='on'
          />
        </Grid>
        {showPasswordField && (
          <Grid
            item
            container
            width={'100%'}
            style={{
              justifyContent: 'center'
            }}
          >
            <StyledInput
              style={{
                width: '50%'
              }}
              size='medium'
              label={t('login.password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type='password'
            />
          </Grid>
        )}
        {showOktaIssuerField && (
          <Grid
            item
            container
            width={'100%'}
            style={{
              justifyContent: 'center'
            }}
          >
            <StyledInput
              style={{
                width: '50%'
              }}
              size='medium'
              label={t('login.okta-subdomain')}
              value={oktaIssuer}
              onChange={(e) => setOktaIssuer(e.target.value)}
              error={!!oktaIssuer && !isValidURL(oktaIssuer)}
              helperText={
                !!oktaIssuer && !isValidURL(oktaIssuer)
                  ? t('login.invalid-issuer')
                  : ''
              }
            />
          </Grid>
        )}
        {showBusinessSelection && (
          <Grid
            item
            container
            width={'100%'}
            style={{
              justifyContent: 'center'
            }}
          >
            <FormControl
              fullWidth
              style={{
                width: '50%',
                alignItems: 'center'
              }}
            >
              <InputLabel id='business-id'>{t('login.business')}</InputLabel>

              <Select
                fullWidth
                size='medium'
                label={t('login.business')}
                value={businessId}
                onChange={(e) => setBusinessId(e.target.value as string)}
              >
                {businessList.map((business: any) => {
                  return (
                    <MenuItem
                      key={business.businessId}
                      value={business.businessId}
                    >
                      {business.businessName}
                    </MenuItem>
                  )
                })}
                <Divider />
                <MenuItem
                  value=''
                  onClick={() => {
                    setShowOktaIssuerField(true)
                    setShowBusinessSelection(false)
                  }}
                >
                  Other
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
      </>
    )
  }

  const getOtpField = () => {
    return (
      <Grid
        item
        container
        width={'100%'}
        style={{
          justifyContent: 'center'
        }}
      >
        <StyledInput
          style={{
            width: '50%'
          }}
          size='medium'
          label='OTP'
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          error={(!!otp && otp.length !== 6) || isNaN(Number(otp))}
          helperText={
            (!!otp && otp.length !== 6) || isNaN(Number(otp))
              ? t('login.invalid-otp')
              : otp.length === 6 && isNaN(Number(otp))
                ? t('login.otp-should-be-a-number')
                : ''
          }
        />
      </Grid>
    )
  }

  const getFields = () => {
    return (
      <Grid
        spacing={2}
        container
        onKeyPress={(e: any) => {
          if (e?.key === 'Enter') {
            handleSubmit(e)
          }
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Grid item>
          <Typography variant='h1'>{t('login.dashboard-login')}</Typography>
        </Grid>
        {showOtpField ? getOtpField() : getLoginFields()}
        <Grid
          item
          width={'50%'}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
        >
          <Typography variant='subtitle2' color={'red'}>
            {errorMessage}
          </Typography>
        </Grid>
        <Grid
          item
          width={'50%'}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
        >
          {!isLocalOrDevelopment && (
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={env.REACT_RECAPTCHA_SITE_KEY}
              size='invisible'
            />
          )}
        </Grid>
        <Grid
          container
          spacing={2}
          item
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
        >
          <Grid item>
            <StyledButton
              type='submit'
              onClick={handleSubmit}
              disabled={isSubmitButtonDisabled()}
            >
              {t('login.submit')}
            </StyledButton>
          </Grid>
          {showOtpField && (
            <Grid item>
              <StyledButton type='submit' onClick={handleResendOtp}>
                {t('login.resend-otp')}
              </StyledButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }

  if (checkingAuthValidity) return <div />

  return <LoginInterface body={getFields()} />
}

export default Login
