import {graphql} from 'graphql/generatedTypes'

export const GET_LOCATION_INVENTORY = graphql(`
  query fetchLocationInventory(
    $locationId: String!
    $description: String!
    $sku: String!
    $unitPrice: String!
    $unitCount: String!
    $pageIndex: Int
    $pageSize: Int
  ) {
    fetchLocationInventory(
      locationId: $locationId
      description: $description
      sku: $sku
      unitPrice: $unitPrice
      unitCount: $unitCount
      pageIndex: $pageIndex
      pageSize: $pageSize
    ) {
      inventories {
        id
        sku
        description
        unit
        unitCount
        taxable
        sendEmail
        taxDetails {
          taxAmount
          taxGroup
          taxRate
        }
        pricingDetails {
          currency
          currencyMultiplier
          discount
          total
          unitPrice
        }
        logoUrl
        upc
        categoryName
        className
        subclassName
        skuType
        ageVerificationRequired
        requiredMinimumAge
        recommendedProducts {
          sku
          description
        }
      }
      totalPageCount
      pageIndex
      pageSize
    }
  }
`)

export const GET_INVENTORY_BY_COMMAND = graphql(`
  query getInventoryByCommand(
    $command: String!
    $locationId: String!
    $genieVersion: String!
    $chatSessionId: String
  ) {
    getInventoryByCommand(
      command: $command
      locationId: $locationId
      genieVersion: $genieVersion
      chatSessionId: $chatSessionId
    ) {
      products {
        skuType
        sku
        unitPrice
        unitDiscount
        unitTaxAmount
        description
        unitSubTotal
        aisleLocation
        logoUrl
        promotions {
          promotionType
          promotionValue
          promotionName
        }
      }
      answer
      resultType
      query
      chatSessionId
      followups
    }
  }
`)
