// @ts-nocheck
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import theme, {space} from 'config/theme'

export function NavItem({children, icon, collapse = false, ...other}: any) {
  return (
    <ListItemButton
      disableRipple
      disableTouchRipple
      {...other}
      sx={{
        borderRadius: '12px',
        color: 'black',
        padding: space.SMALL,
        width: collapse ? 'fit-content' : '100%',
        marginBottom: space.XS,
        '&.Mui-selected': {
          color: 'black',
          backgroundColor: theme.palette.primary.light
        }
      }}
    >
      {icon && (
        <ListItemIcon
          sx={{
            minWidth: space.LARGE
          }}
        >
          {icon}
        </ListItemIcon>
      )}

      {children}
    </ListItemButton>
  )
}

export function NavSubItem({children, icon, ...other}: any) {
  return (
    <ListItem disablePadding>
      {icon && (
        <ListItemIcon
          style={{
            minWidth: '34px'
          }}
        >
          {icon}
        </ListItemIcon>
      )}

      <NavItem
        disableRipple
        disableTouchRipple
        style={{
          marginBottom: '0px',
          height: '40px'
        }}
        {...other}
      >
        {children}
      </NavItem>
    </ListItem>
  )
}

export function NavItemText({children, ...other}: any) {
  return (
    <ListItemText
      {...other}
      sx={{
        '&.MuiListItemText-root': {
          marginTop: '0px',
          marginBottom: '0px'
        }
      }}
      primary={children}
    ></ListItemText>
  )
}
