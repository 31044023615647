import 'react-notifications-component/dist/theme.css'

import {Route, Switch, useLocation} from 'react-router-dom'
import AdminOktaRouteHandler from 'adminPanel/AdminOktaRouteHandler'
import ClientRouteHandler from 'clientDashboard/ClientRouteHandler'
import CustomerRouteHandler from 'consumerAgent/CustomerRouteHandler'

function AdminAppRoutes() {
  const {pathname} = useLocation()

  const isAdminRoute = pathname.startsWith('/admin')
  const isCustomerRoute = pathname.startsWith('/customer')
  return (
    <Switch>
      <Route>
        {isAdminRoute ? (
          <AdminOktaRouteHandler />
        ) : isCustomerRoute ? (
          <CustomerRouteHandler />
        ) : (
          <ClientRouteHandler />
        )}
      </Route>
    </Switch>
  )
}

export default AdminAppRoutes
