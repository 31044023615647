import {graphql} from 'graphql/generatedTypes'

export const GET_EMPLPOYEE = graphql(`
  query getEmployee {
    getEmployee {
      id
      name
      email
      locations {
        id
        address {
          street
          city
          state
          zipCode
          country
        }
        name
        timeZone
        status
        storeNumber
        locationConfig {
          cashManagement {
            tillAmountUpperLimit
            storeFloatAmount
          }
        }
        storeGroups {
          id
          name
          status
          storeGroupCluster {
            id
            name
            hierarchyLevel
          }
        }
      }
      business {
        id
        name
        logo
        brand
        featureFlags
        configurations {
          colors {
            primary
            secondary
          }
        }
        storeGroupClusters {
          id
          name
          hierarchyLevel
        }
      }
      role {
        id
        name
        permissions
        description
      }
      toteSuperAdmin
      assignedStoreGroups {
        id
        name
        status
        storeGroupCluster {
          id
          name
          hierarchyLevel
        }
        parentStoreGroup {
          id
          name
          status
          storeGroupCluster {
            id
            name
            hierarchyLevel
          }
        }
      }
    }
  }
`)

export const GET_ALL_EMPLOYEES = graphql(`
  query getAllEmployees(
    $businessId: String!
    $locationId: String
    $name: String
    $email: String
    $role: String
    $employeeCode: String
    $pageIndex: Int
    $pageSize: Int
    $fetchAll: Boolean
    $storeGroupIds: [String!]
    $locationIds: [String!]
  ) {
    getAllEmployees(
      businessId: $businessId
      locationId: $locationId
      name: $name
      email: $email
      role: $role
      employeeCode: $employeeCode
      pageIndex: $pageIndex
      pageSize: $pageSize
      fetchAll: $fetchAll
      storeGroupIds: $storeGroupIds
      locationIds: $locationIds
    ) {
      employees {
        id
        name
        email
        phoneNumber
        employeeCode
        fullAddress {
          street
          city
          state
          zipCode
          country
        }
        role {
          id
          name
        }
        locations {
          id
          name
        }
        defaultLocation {
          id
          name
        }
        status
        assignedStoreGroups {
          id
          name
          status
          storeGroupCluster {
            id
            name
            hierarchyLevel
          }
          parentStoreGroup {
            id
            name
            status
            storeGroupCluster {
              id
              name
              hierarchyLevel
            }
          }
        }
      }
      totalCount
    }
  }
`)

export const GET_AUTH_METHOD = graphql(`
  query getAuthMethod($emailId: String!, $businessId: String, $token: String) {
    getAuthMethod(emailId: $emailId, businessId: $businessId, token: $token) {
      businessId
      businessName
      authMethod
      okta {
        clientId
        issuer
      }
    }
  }
`)

export const GET_OKTA_CONFIG_FROM_ISSUER = graphql(`
  query getOktaConfigFromIssuer($issuer: String!, $businessId: String) {
    getOktaConfigFromIssuer(issuer: $issuer, businessId: $businessId) {
      businessId
      businessName
      authMethod
      okta {
        clientId
        issuer
      }
    }
  }
`)

export const SEND_PASSWORD_SET_LINK = graphql(`
  mutation sendPasswordSetLink($email: String!, $businessId: String!) {
    sendPasswordSetLink(email: $email, businessId: $businessId)
  }
`)

export const VALIDATE_PASSWORD_SET_TOKEN = graphql(`
  query validatePasswordSetToken($token: String!) {
    validatePasswordSetToken(token: $token)
  }
`)

export const GET_ADMIN_USER = graphql(`
  query getAdminUser {
    getAdminUser {
      id
      name
      email
      phoneNumber
    }
  }
`)
