import {CircularProgress, Grid, Typography} from '@mui/material'

import {space} from 'config/theme'

const Loading = ({text = ''}: {text?: string}) => {
  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: space.LARGE
      }}
    >
      <CircularProgress color='primary' />
      <Typography variant='body1' style={{marginTop: space.XS}}>
        {text}
      </Typography>
    </Grid>
  )
}

export default Loading
