import {useLazyQuery, useSubscription} from '@apollo/client'
import {Card, CardContent, Grid, Typography} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import StyledTable from 'ui/molecules/StyledTable'
import {CashTransactionFailureType} from 'graphql/generatedTypes/graphql'
import {GET_TRANSACTION_FAILURES} from 'graphql/queries/transaction-failure.queries'
import {CASH_EVENT_SUBSCRIPTION} from 'graphql/subscriptions/cash-event-subscriptions'
import {MRT_ColumnDef} from 'material-react-table'
import moment from 'moment'
import React from 'react'
import {useMemo} from 'react'
import {useEffect, useState} from 'react'
import {localDateTimeString} from 'utils/common'

function CashTransactionFailureLogs() {
  const {authInfo} = useAuth()
  const [transactionData, setTransactionData] = useState<
    CashTransactionFailureType[]
  >([])

  const [dateRange, setDateRange] = React.useState([
    moment().local().startOf('day'),
    moment().local().endOf('day')
  ])

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10
  })

  const [columnFilters, setColumnFilters] = React.useState([])

  const [
    getTransactionFailures,
    {
      data: _eventData,
      loading: loadingEvents,
      refetch: refetchTransactionFailures
    }
  ] = useLazyQuery(GET_TRANSACTION_FAILURES, {
    fetchPolicy: 'no-cache'
  })

  const fetchAllFailures = () => {
    getTransactionFailures({
      variables: {
        locationId: authInfo.locationId as string,
        dateRange: dateRange.map((date) => date.format()),
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize
      }
    })
  }

  useEffect(() => {
    fetchAllFailures()
  }, [dateRange, pagination])

  useEffect(() => {
    if (_eventData) {
      setTransactionData(
        _eventData.cashTransactionFailures
          ?.nodes as CashTransactionFailureType[]
      )
    }
  }, [_eventData])

  const {data: _subData} = useSubscription(CASH_EVENT_SUBSCRIPTION, {
    variables: {
      locationId: authInfo.locationId
    }
  })

  useEffect(() => {
    if (_subData) {
      const cashEvent = _subData.webCashEvent?.cashEvent
      if (cashEvent === 'refresh') {
        refetchTransactionFailures()
      }
    }
  }, [_subData])

  useEffect(() => {
    columnFilters.forEach((filter: any) => {
      if (filter?.id === 'createdAt') {
        let startDate = moment(filter?.value[0]).startOf('day')
        let endDate = moment(filter?.value[1]).endOf('day')
        if (!endDate.isValid()) {
          endDate = dateRange[1]
        }
        if (!startDate.isValid()) {
          startDate = dateRange[0]
        }
        setDateRange([startDate, endDate])
      }
    })
  }, [columnFilters])

  const columns = useMemo<MRT_ColumnDef<CashTransactionFailureType>[]>(
    () => [
      {
        accessorKey: 'createdAt',
        header: 'Transaction Date & Time',
        enableColumnFilter: true,
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue() ? localDateTimeString(cell.getValue()) : '-'}
            </Typography>
          )
        },
        muiFilterTextFieldProps: {
          defaultValue: dateRange[0],
          sx: {
            minWidth: '150px'
          }
        },
        filterVariant: 'date-range'
      },
      {
        accessorKey: 'eventType',
        header: 'Event',
        enableColumnFilter: false,
        Cell: ({cell, row}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'eventId',
        header: 'Event Id',
        enableColumnFilter: false,
        Cell: ({cell, row}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      }
    ],
    [dateRange]
  )

  return (
    <>
      <StyledTable
        columns={columns}
        data={transactionData}
        state={{
          showSkeletons: loadingEvents,
          showProgressBars: loadingEvents,
          columnFilters: columnFilters,
          pagination: pagination
        }}
        enableTableFooter={true}
        enableSorting={false}
        enableStickyHeader={true}
        manualFiltering={true}
        manualPagination={true}
        onColumnFiltersChange={setColumnFilters as any}
        onPaginationChange={setPagination}
        rowCount={_eventData?.cashTransactionFailures?.totalPageCount || 0}
        initialState={{
          density: 'compact',
          showColumnFilters: true
        }}
        renderDetailPanel={({row}) => {
          if (loadingEvents) return ''
          return (
            <Grid
              container
              spacing={2}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <Grid item>
                <Card>
                  <CardContent>
                    <Typography variant='subtitle1'>Payload:</Typography>
                    <pre>
                      {row.original.payload
                        ? JSON.stringify(row.original.payload, null, 2)
                        : ''}
                    </pre>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Typography variant='subtitle1'>Error:</Typography>
                    <div>
                      <Typography variant='subtitle2'>
                        {row.original.error}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )
        }}
      />
    </>
  )
}

export default CashTransactionFailureLogs
