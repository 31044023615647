import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Grid, Typography} from '@mui/material'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {Skeleton} from '@mui/material'

interface CashManagementKeyStatsProps {
  label: string
  value: string | number
  percentageChange: string
  isLoading?: boolean
}

const CashManagementKeyStats: React.FC<CashManagementKeyStatsProps> = ({
  label,
  value,
  percentageChange,
  isLoading
}) => {
  if (isLoading) {
    return (
      <Skeleton
        style={{
          padding: '12px',
          flexDirection: 'column',
          display: 'flex',
          border: '1px solid #EFEFEF',
          borderRadius: '8px',
          alignItems: 'flex-start',
          width: '100%',
          justifyContent: 'space-between'
        }}
      />
    )
  }
  return (
    <Grid
      style={{
        padding: '12px',
        flexDirection: 'column',
        display: 'flex',
        border: '1px solid #EFEFEF',
        borderRadius: '8px',
        alignItems: 'flex-start',
        width: '100%',
        justifyContent: 'space-between'
      }}
    >
      <Grid item>
        <Typography
          style={{
            fontSize: '16px',
            fontFamily: 'Popins',
            color: '#8C8C8C'
          }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          style={{
            fontFamily: 'Popins',
            fontSize: '32px',
            fontWeight: 400
          }}
        >
          {value}
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: '2px 5px',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '4px',
          backgroundColor: percentageChange.includes('-')
            ? '#FFE5E5'
            : '#F6FFED',
          borderRadius: '4px',
          width: 'fit-content'
        }}
      >
        <Grid item>
          {percentageChange.includes('-') ? (
            <FontAwesomeIcon
              icon={icon({
                name: 'arrow-trend-down',
                family: 'classic',
                style: 'solid'
              })}
              fontSize={12}
              color={'#ED2626'}
            />
          ) : (
            <FontAwesomeIcon
              icon={icon({
                name: 'arrow-trend-up',
                family: 'classic',
                style: 'solid'
              })}
              fontSize={12}
              color={'#52C41A'}
            />
          )}
        </Grid>
        <Grid item>
          <Typography
            style={{
              fontFamily: 'Popins',
              fontSize: '16px',
              color: percentageChange.includes('-') ? '#ED2626' : '#52C41A'
            }}
          >
            {percentageChange}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CashManagementKeyStats
