import {TextField, TextFieldProps} from '@mui/material'
import {forwardRef, ForwardRefRenderFunction} from 'react'

const StyledInput: ForwardRefRenderFunction<HTMLInputElement, TextFieldProps> =
  function ({...other}, ref) {
    return (
      <>
        <TextField variant='outlined' {...other} ref={ref}></TextField>
      </>
    )
  }

export default forwardRef<HTMLInputElement, TextFieldProps>(StyledInput)
