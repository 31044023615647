import React, {useState} from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const ITEM_HEIGHT = 48

interface ThreeDotsMenuProps {
  exportOptions?: any
}

const ThreeDotsMenu = ({exportOptions}: ThreeDotsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const getOptionMenuItems = () => {
    return exportOptions.map((option: any) => (
      <MenuItem
        key={option.label}
        onClick={() => handleMenuItemClick(option)}
        disabled={!option.data || option.data.length === 0}
      >
        {option.label}
      </MenuItem>
    ))
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (option: any) => {
    if (option.onClick) {
      option.onClick()
    }
    handleClose()
  }

  return (
    <div>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch'
            }
          }
        }}
      >
        {getOptionMenuItems()}
      </Menu>
    </div>
  )
}

export default ThreeDotsMenu
