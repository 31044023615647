import {
  BaseSelectProps,
  FormControl,
  InputLabel,
  MenuItem,
  MenuItemProps,
  Select
} from '@mui/material'
import {forwardRef, ReactNode} from 'react'

import {ReactComponent as ChevronDownIcon} from 'assets/chevron-up.svg'

interface StyledSelectProps extends BaseSelectProps {
  label: string
  flexAlign?: boolean
}

const StyledSelect = forwardRef<HTMLDivElement, StyledSelectProps>(
  (
    {
      children,
      label,
      size = 'small',
      sx = {minWidth: 144, m: 1} as any,
      flexAlign = false,
      ...props
    },
    ref
  ) => {
    return (
      <FormControl variant='outlined' size={size} sx={sx}>
        <InputLabel
          variant='outlined'
          sx={{
            '&.MuiInputLabel-root': {
              color: 'black'
            }
          }}
        >
          {label}
        </InputLabel>
        <Select
          ref={ref}
          autoWidth
          IconComponent={ChevronDownIcon}
          size={size}
          sx={{
            typography: 'body1',
            '&.MuiInputBase-root': {
              borderRadius: '8px',
              '& fieldset': {
                borderColor: 'black'
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
                borderWidth: '1px'
              },
              ' .MuiInputBase-input': {
                ...(flexAlign && {
                  display: 'flex',
                  alignItems: 'center'
                })
              }
            },
            '& .MuiSelect-icon': {
              top: 'auto'
            }
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                borderRadius: '12px',
                border: '1px solid black',
                minWidth: (sx?.minWidth || 144) - 24,
                padding: '4px 12px'
              }
            }
          }}
          {...props}
        >
          {children}
        </Select>
      </FormControl>
    )
  }
)

export default StyledSelect

export interface StyledMenuItemProps extends MenuItemProps {
  children: ReactNode
}

export function StyledMenuItem({children, ...other}: StyledMenuItemProps) {
  return (
    <MenuItem
      sx={{
        typography: 'body1',
        '&.MuiMenuItem-root': {
          padding: '8px 4px'
        }
      }}
      {...other}
    >
      {children}
    </MenuItem>
  )
}
