import type {RootState} from 'clientDashboard/clientDashboard.store'

/* Locations Selectors */
export const selectSaveStoreGroupModalData = (state: RootState) =>
  state.settings.locations?.saveStoreGroupModal || null

export const selectStoreGroups = (state: RootState) =>
  state.settings.locations?.storeGroups || {}

export const selectChildStoreGroups = (state: RootState) =>
  state.settings.locations?.childStoreGroups || {}

export const selectStoreGroupsByIdMap = (state: RootState) =>
  state.settings.locations?.storeGroupsByIdMap || {}

export const selectAncestorsStoreGroupsByStoreGroupIdMap = (state: RootState) =>
  state.settings.locations?.ancestorsStoreGroupsByStoreGroupIdMap || {}
