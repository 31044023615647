import 'react-notifications-component/dist/theme.css'
import {Provider as ReduxStoreProvider} from 'react-redux'
import {store} from 'consumerAgent/consumerAgent.store'

import ConsumerAgentCartMerge from 'consumerAgent/ConsumerAgentCartMerge'
import ConsumerAgentHomePage from 'consumerAgent/ConsumerAgentHomePage'
import ConsumerAgentLogin from 'consumerAgent/ConsumerAgentLogin'
import CustomerLoyaltySignup from 'consumerAgent/CustomerLoyaltySignup'
import {Route, Switch} from 'react-router-dom'
import {ConsumerAgentProvider} from 'consumerAgent/ConsumerAgentContext'
import CustomerSubscriptions from 'consumerAgent/CustomerSubscriptions'

function CustomerRouteHandler() {
  return (
    <ReduxStoreProvider store={store}>
      <Switch>
        <Route
          exact
          path='/customers/signup'
          component={CustomerLoyaltySignup}
        />

        <Route
          exact
          path='/customer/subscriptions'
          component={CustomerSubscriptions}
        />

        <Route path='/customers/location/:locationId'>
          <ConsumerAgentProvider>
            <Switch>
              <Route
                exact
                path='/customers/location/:locationId/consumer-agent'
                component={ConsumerAgentHomePage}
              />

              <Route
                exact
                path='/customers/location/:locationId/login'
                component={ConsumerAgentLogin}
              />

              <Route
                exact
                path='/customers/location/:locationId/cart/:cartId/merge'
                component={ConsumerAgentCartMerge}
              />
            </Switch>
          </ConsumerAgentProvider>
        </Route>
      </Switch>
    </ReduxStoreProvider>
  )
}

export default CustomerRouteHandler
