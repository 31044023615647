import {graphql} from 'graphql/generatedTypes'

export const GET_ALL_ROLES_AND_PERMISSIONS = graphql(`
  query getAllRoles($businessId: String!) {
    getAllRoles(businessId: $businessId) {
      id
      name
      description
      isActive
      permissions
      createdAt
      updatedAt
    }

    getAllPermissions {
      displayName
      name
      description
      category
    }
  }
`)
