import {Grid, Typography} from '@mui/material'
import theme, {colors, space} from 'config/theme'

import {ReactComponent as GenieIcon} from 'assets/sparkles.svg'

type ConsumerAgentMessageCardProps = {
  type: ConsumerAgentMessageType
  children?: React.ReactNode
}
export enum ConsumerAgentMessageType {
  AGENT = 'AGENT',
  CONSUMER = 'CONSUMER'
}

const ConsumerAgentMessageCard = ({
  type,
  children = ''
}: ConsumerAgentMessageCardProps) => {
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        justifyContent: 'center',
        padding: `${space.XS} ${space.SMALL} `
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          padding: space.MEDIUM,
          borderRadius: '16px',
          backgroundColor:
            type === ConsumerAgentMessageType.CONSUMER
              ? colors.primary[20]
              : theme.palette.background.paper
        }}
      >
        {type === ConsumerAgentMessageType.AGENT && (
          <Grid
            item
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <GenieIcon />
            <Typography variant='h6' marginLeft={space.SMALL}>
              Genie AI
            </Typography>
          </Grid>
        )}

        {type === ConsumerAgentMessageType.CONSUMER && (
          <Typography variant='h6'>You</Typography>
        )}

        {children}
      </Grid>
    </Grid>
  )
}

export default ConsumerAgentMessageCard
