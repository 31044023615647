import {useMutation} from '@apollo/client'
import {Grid, Typography} from '@mui/material'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledInput from 'ui/atoms/StyledInput'
import theme from 'config/theme'
import {CASH_DROP} from 'graphql/mutations/cash-management.mutations'
import useNotify from 'hooks/useNotify'
import {t} from 'i18next'
import React from 'react'
import {LocationType} from 'types/location.types'
import {centsToDollar, formatCurrency} from 'utils/common'
import {v4 as uuid4} from 'uuid'
import {
  amountInCents,
  isInvalidInputAmount,
  parseAmount
} from 'utils/currency.utils'

interface FilterSelectProps {
  modelOpen: boolean
  selectedItem: any
  location: LocationType
  tillSafeAmount: number
  onUpdate: () => void
}
const CashDropDrawerContainer = ({
  modelOpen,
  selectedItem,
  location,
  tillSafeAmount,
  onUpdate
}: FilterSelectProps) => {
  const notify = useNotify()
  const [selectedAmount, setSelectedAmount] = React.useState<string>('0')
  const [eventId] = React.useState<string>(uuid4())

  const [performMutation] = useMutation(CASH_DROP)

  const handlePickupClick = async () => {
    try {
      await performMutation({
        variables: {
          amount: amountInCents(selectedAmount),
          location: location.id,
          till: selectedItem.till,
          register: selectedItem.register,
          eventId: eventId
        }
      })
      onUpdate()
      notify.show(t('cash-management.transactions.success'), 'success')
    } catch (error) {
      notify.show(
        (error as any)?.message || 'Something went wrong. Please try again.',
        'error'
      )
    }
  }

  const isInvalidAmount =
    isInvalidInputAmount(selectedAmount) ||
    Number(selectedAmount) > centsToDollar(tillSafeAmount)

  const cashPickupForm = () => {
    return (
      <>
        <Grid container spacing={2} padding={4}>
          <Grid item xs={12} sm={12}>
            <Typography variant='h3' style={{textAlign: 'left'}}>
              Till {selectedItem.till} is low on cash
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} padding={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant='h3'>Total Cash</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant='subtitle1' style={{textAlign: 'end'}}>
              {formatCurrency(centsToDollar(selectedItem.totalCash))}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} padding={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant='h3'>Net Cash</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant='subtitle1'
              color={theme.palette.error.main}
              style={{textAlign: 'end'}}
            >
              {formatCurrency(centsToDollar(Math.abs(selectedItem.netCash)))}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} padding={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant='h3'>Cash Drop Amount</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledInput
              fullWidth
              required
              value={selectedAmount}
              type='number'
              InputProps={{
                startAdornment: t('currency-symbol')
              }}
              onClick={() =>
                setSelectedAmount(
                  centsToDollar(Math.abs(selectedItem.netCash)).toString()
                )
              }
              onChange={(e) => {
                setSelectedAmount(parseAmount(e.target.value))
              }}
              error={isInvalidAmount}
              helperText={isInvalidAmount ? 'Invalid amount' : ''}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <StyledDialog
      open={modelOpen}
      title={'Register ' + selectedItem.register + ' Cash Drop'}
      body={<Grid>{cashPickupForm()}</Grid>}
      successButtonName='Drop Cash'
      cancelButtonName='Dismiss'
      cancelCallback={() => {
        onUpdate()
      }}
      onClose={() => {}}
      disableSuccessButton={Number(selectedAmount) <= 0 || isInvalidAmount}
      successCallback={() => {
        handlePickupClick()
      }}
    />
  )
}

export default CashDropDrawerContainer
