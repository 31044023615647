import {graphql} from 'graphql/generatedTypes'

// Pass business id only if the config is business specific
// there can be configs which are not business specific for ex sendgrid configs
// Pass location id only if the config is location specific
// there can be configs which are not location specific for ex stripe credentials
export const FETCH_SERVICE_CONFIG_BY_TYPE = graphql(`
  query FetchServiceConfigByType(
    $serviceConfigType: String!
    $businessId: String
    $locationId: String
  ) {
    serviceConfig(
      serviceConfigType: $serviceConfigType
      businessId: $businessId
      locationId: $locationId
    ) {
      type
      name
      configs
      updatedAt
    }
  }
`)

export const FETCH_SERVICE_CONFIG_PROVIDERS = graphql(`
  query FetchServiceConfigProviders($category: String!) {
    serviceConfigProviders(category: $category) {
      provider
      metadata {
        shopifyMetadata {
          webhookTopicsToSubscribe
          availableApiVersions
        }
      }
    }
  }
`)

export const FETCH_SERVICE_CONFIGS_BY_CATEGORY = graphql(`
  query FetchServiceConfigsByCategory(
    $category: String!
    $businessId: String
    $locationId: String
  ) {
    serviceConfigsByCategory(
      category: $category
      businessId: $businessId
      locationId: $locationId
    ) {
      type
      name
      configs
      updatedAt
    }
  }
`)
