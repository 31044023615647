import {graphql} from 'graphql/generatedTypes'

export const ADD_CUSTOMER_FOR_CONSUMER_AGENT = graphql(`
  mutation addCustomerForConsumerAgent($customer: CustomerInput!) {
    addCustomerForConsumerAgent(customer: $customer)
  }
`)

export const LOGIN_CUSTOMER = graphql(`
  mutation loginCustomer(
    $email: String!
    $password: String!
    $businessId: String!
    $locationId: String!
  ) {
    loginCustomer(
      email: $email
      password: $password
      businessId: $businessId
      locationId: $locationId
    ) {
      customer {
        id
        email
        firstName
        lastName
      }
      token
    }
  }
`)
