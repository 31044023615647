import {Grid} from '@mui/material'
import {useEffect} from 'react'
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'
import {useMutation} from '@apollo/client'
import {GENERATE_ATLAS_AUTH_TOKEN} from 'graphql/mutations/cash-management.mutations'
import {PlainObject} from '@mongodb-js/charts-embed-dom/dist/declarations/src/types'

// insight chart interface
interface InsightChartsProps {
  chartConfig: {
    chartId: string
    chartBaseUrl: string
    xAxis: {
      label: string
      name: string
      timeType: string
      type: string
    }
    yAxis: {
      label: string
      name: string
      timeType: string
      type: string
    }
    filter: PlainObject
  }
  setChartData: (data: any) => void
}

const InsightCharts = ({chartConfig, setChartData}: InsightChartsProps) => {
  console.log('chartConfig', chartConfig)
  const [generateAtlasAuthToken] = useMutation(GENERATE_ATLAS_AUTH_TOKEN)

  async function login() {
    const data = await generateAtlasAuthToken()
    return data.data.generateAtlasAuthToken.token
  }

  async function renderChart() {
    const sdk = new ChartsEmbedSDK({
      baseUrl: chartConfig.chartBaseUrl,
      getUserToken: async function () {
        return await login()
      }
    })
    const chart = sdk.createChart({
      chartId: chartConfig.chartId,
      showAttribution: false,
      height: '100%',
      width: '100%',
      autoRefresh: true,
      filter: chartConfig.filter,
      maxDataAge: 1
    })
    // render the chart into a container
    await chart.render(document.getElementById('chart-data')!)
    chart.getData().then((data) => {
      setChartData(data)
    })
  }
  useEffect(() => {
    renderChart()
  }, [chartConfig])

  return (
    <Grid
      container
      style={{
        width: '100%'
      }}
    >
      <Grid
        item
        id='chart-data'
        style={{
          height: '500px',
          width: '1200px'
        }}
      ></Grid>
    </Grid>
  )
}

export default InsightCharts
