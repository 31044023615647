import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import _forEach from 'lodash/forEach'
import {Employee} from 'clientDashboard/clientDashboard.types'
import {GetEmployeeQuery, StoreGroupType} from 'graphql/generatedTypes/graphql'
import {NullablePartial} from 'utils/type.utils'
import {saveToLocalStorage} from 'libs/localStorage.helpers'
import _groupBy from 'lodash/groupBy'
import _size from 'lodash/size'
import _map from 'lodash/map'
import {APPLIED_LOCATION_GROUPS} from 'clientDashboard/constants/localStorage.constants'
import {
  getActualGroupOptionsAndGroups,
  withMappedGroups,
  withMappedLocations
} from 'clientDashboard/employee.slice.helpers'
import {StoreGroupClusters} from 'clientDashboard/constants/employee.constants'

type EmployeePayload = GetEmployeeQuery['getEmployee']
type LocationPayload = {
  selectedLocationId: string
  selectedStoreNumber: string
}

export type Location2 = {
  id: string
  name: string
  parentGroup: {
    id: string
  }
  groupLevel?: {
    name: string
    hierarchyLevel: number
  }
}

export type Cluster = {
  name: string
  hierarchyLevel: number
}

export type Group = {
  id: string
  name: string
  // = storeGroupCluster
  groupLevel: {
    name: string
    hierarchyLevel: number
  }
  status?: string
  // = parentStoreGroup
  parentGroup: {
    id: string
  }
}

type GroupWithLabel = Group & {
  label: string
}

export type AppliedGroups = Record<string, GroupWithLabel[]>

type EmployeeSlice = Employee & {
  locations2: Location2[]
  clusters: Cluster[]
  groups: Group[]
  appliedLocationGroups: AppliedGroups
  topBarLocationGroups: Record<string, any>
}

const initialState: NullablePartial<EmployeeSlice> = {}

export const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployeeDetails: (state, action: PayloadAction<EmployeePayload>) => {
      const {
        id,
        email,
        name,
        role,
        toteSuperAdmin,
        locations,
        assignedStoreGroups,
        business
      } = action.payload
      state.employeeId = id
      state.name = name
      state.email = email
      state.isToteSuperAdmin = toteSuperAdmin

      // @ts-ignore
      state.role = role
      // @ts-ignore
      state.role.roleId = role.id

      // @ts-ignore
      state.locations = locations

      _forEach(state.locations, (location) => {
        // @ts-ignore
        location.locationId = location.id
      })

      const locationsHierarchyLevel = _size(business?.storeGroupClusters) + 1

      state.locations2 = withMappedLocations(locations as any)

      let _clusters = [] as Cluster[]
      _forEach(business?.storeGroupClusters, (cluster, index) => {
        _clusters.push({
          name: cluster.name,
          hierarchyLevel: index + 1
        })
      })
      _clusters.push({
        name: StoreGroupClusters.Location,
        hierarchyLevel: locationsHierarchyLevel
      })
      state.clusters = _clusters

      state.groups = withMappedGroups(assignedStoreGroups as StoreGroupType[])
    },

    updateSelectedLocation: (state, action: PayloadAction<LocationPayload>) => {
      const {selectedLocationId, selectedStoreNumber} = action.payload
      state.selectedLocationId = selectedLocationId
      state.selectedStoreNumber = selectedStoreNumber
    },

    setAppliedGroups: (state, action: PayloadAction<AppliedGroups>) => {
      // getActualGroupOptionsAndGroups() call is needed for data consistency
      const {appliedLocationGroups} = getActualGroupOptionsAndGroups({
        clusters: state.clusters || [],
        groups: state.groups || [],
        locations: state.locations2 || [],
        updatedGroups: action.payload || {}
      })

      state.appliedLocationGroups = appliedLocationGroups
      saveToLocalStorage(APPLIED_LOCATION_GROUPS, appliedLocationGroups)
    },

    updateGroupsAndOptionsForTopBar: (
      state,
      action: PayloadAction<AppliedGroups | null>
    ) => {
      const prop = 'topBarLocationGroups'
      if (action.payload === null) {
        state[prop] = null
        return
      }

      if (!state[prop]) {
        state[prop] = {}
      }
      const updatedGroups = Object.assign(
        state[prop].appliedLocationGroups || {},
        action.payload
      )

      const {clusterOptionsMap, appliedLocationGroups} =
        getActualGroupOptionsAndGroups({
          clusters: state.clusters || [],
          groups: state.groups || [],
          locations: state.locations2 || [],
          updatedGroups
        })

      state[prop].clusterOptionsMap = clusterOptionsMap
      state[prop].appliedLocationGroups = appliedLocationGroups
    },

    resetLocationGroupsForTopBar: (state) => {
      const prop = 'topBarLocationGroups'
      if (!state[prop]) {
        state[prop] = {}
      }
      const clusters = state.clusters || []
      const groupOptions = _groupBy(state.groups, 'groupLevel.name')
      const locationOptions = state.locations2 || []

      const optionMap = {} as any
      _forEach(clusters, (cluster) => {
        optionMap[cluster.name] =
          cluster.name === 'Location'
            ? locationOptions
            : groupOptions[cluster.name]
      })

      const actualAppliedGroups = {} as any
      _forEach(Object.keys(optionMap), (key) => {
        actualAppliedGroups[key] = _map(optionMap[key], ({id, name}) => ({
          id,
          name
        }))
      })

      state[prop].clusterOptionsMap = optionMap
      state[prop].appliedLocationGroups = actualAppliedGroups
    },

    applyTopBarLocationGroups: (state) => {
      const appliedLocationGroups =
        state.topBarLocationGroups?.appliedLocationGroups || {}
      state.appliedLocationGroups = appliedLocationGroups
      saveToLocalStorage(APPLIED_LOCATION_GROUPS, appliedLocationGroups)

      state.topBarLocationGroups = null
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    }
  }
})

export const {
  setEmployeeDetails,
  updateSelectedLocation,
  updateGroupsAndOptionsForTopBar,
  setAppliedGroups,
  resetLocationGroupsForTopBar,
  applyTopBarLocationGroups,
  setIsAuthenticated
} = employeeSlice.actions
