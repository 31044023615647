import {Grid, Typography} from '@mui/material'
import {space} from 'config/theme'

function InsufficientPermission() {
  return (
    <Grid
      container
      item
      style={{
        padding: space.SMALL
      }}
    >
      <Grid item>
        <Typography variant='body1'>
          You do not have permission to access this feature
        </Typography>
      </Grid>
    </Grid>
  )
}

export default InsufficientPermission
