import {Collapse, Grid, Typography} from '@mui/material'
import StyledExpandMore from 'ui/molecules/StyledExpandMore'
import {colors, customPadding} from 'config/theme'
import {TransactionType} from 'constants/constants'
import React from 'react'
import {summaryType} from 'types/cashSummary.types'
import {centsToDollar, formatCurrency} from 'utils/common'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

type SummaryProps = {
  summary: summaryType
}
const CashSummary = ({summary}: SummaryProps) => {
  const [expanded, setExpanded] = React.useState(true)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  const summaryData: {
    label: string
    value: string | number
    transactionType: TransactionType
  }[] = [
    {
      label: 'Starting Cash',
      value:
        centsToDollar(summary?.tillFloat) + centsToDollar(summary?.open || 0),
      transactionType: TransactionType.CREDIT
    },
    {
      label: 'Cash Sales',
      value: centsToDollar(summary?.cash) + centsToDollar(summary?.sale),
      transactionType: TransactionType.CREDIT
    },
    {
      label: 'Cash Refunds',
      value: centsToDollar(summary?.refund),
      transactionType: TransactionType.DEBIT
    },
    {
      label: 'Cash Drop',
      value: centsToDollar(summary?.cashDrop),
      transactionType: TransactionType.CREDIT
    },
    {
      label: 'Cash Pickup',
      value: centsToDollar(summary?.cashPickup),
      transactionType: TransactionType.DEBIT
    },
    {
      label: 'Paid In',
      value: centsToDollar(summary?.paidIn),
      transactionType: TransactionType.CREDIT
    },
    {
      label: 'Paid Out',
      value: centsToDollar(summary?.paidOut),
      transactionType: TransactionType.DEBIT
    },
    {
      label: 'Variance',
      value: centsToDollar(summary?.cashAdjustment),
      transactionType: TransactionType.CREDIT
    }
  ]

  return (
    <Grid
      item
      container
      style={{
        display: 'flex',
        padding: customPadding.SMALL,
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        gap: 12
      }}
    >
      <Grid
        item
        container
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 24,
          alignSelf: 'stretch'
        }}
      >
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 6
          }}
        >
          <Typography variant='h6'>Overview</Typography>
        </Grid>

        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 12,
            flex: '1 0 0'
          }}
        >
          <StyledExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'
          >
            <ExpandMoreIcon />
          </StyledExpandMore>
        </Grid>
      </Grid>
      <Grid
        item
        style={{
          display: 'flex',
          gap: 12,
          alignSelf: 'stretch',
          flexDirection: 'column'
        }}
      >
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <Grid container spacing={{xs: 2, md: 3}}>
            {summaryData?.map((item: any, index: number) => {
              return (
                <Grid item key={`summary-${index}`} xs={12} sm={4} md={3}>
                  <Grid
                    item
                    key={`summary-card-${index}`}
                    style={{
                      padding: '12px',
                      borderRadius: '4px',
                      border: '1px solid',
                      borderColor: colors.cardBorder,
                      background: colors.cardLightBlue
                    }}
                  >
                    <Typography
                      variant='body2'
                      style={{color: colors.grayFont}}
                    >
                      {item.label}
                    </Typography>
                    <Typography variant='subtitle1'>
                      {item.transactionType === TransactionType.CREDIT
                        ? formatCurrency(item.value)
                        : '(' + formatCurrency(item.value) + ')'}
                    </Typography>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  )
}

export default CashSummary
