import {
  useAppDispatch,
  useAppSelector
} from 'clientDashboard/clientDashboard.store'
import {selectClusters} from 'clientDashboard/employee.selectors'
import {Cluster} from 'clientDashboard/employee.slice'
import _map from 'lodash/map'
import {Autocomplete, TextField} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import {
  selectAppliedGroupsForEmployeeModal,
  selectClusterOptionsForEmployeeModal
} from 'clientDashboard/settings/employees/employeeManagement.selectors'
import {updateGroupsAndOptionsEmployee} from 'clientDashboard/settings/employees/employeeManagement.slice'

function HierarchySelector() {
  const dispatch = useAppDispatch()
  const clusters = useAppSelector(selectClusters)
  const optionMap = useAppSelector(selectClusterOptionsForEmployeeModal) || {}
  const appliedLocationGroups = useAppSelector(
    selectAppliedGroupsForEmployeeModal
  ) || {}

  const getOptions = (cluster: Cluster) => {
    return _map(optionMap[cluster.name], ({id, name}) => ({id, name}))
  }

  return (
    <div className='grid grid-cols-2 gap-6'>
      {clusters.map((cluster) => {
        const values = appliedLocationGroups[cluster.name] || []
        const id = `${cluster.hierarchyLevel}_${cluster.name}`
        return (
          <Autocomplete
            multiple
            key={id}
            disablePortal
            disableCloseOnSelect
            id={id}
            options={getOptions(cluster)}
            value={values}
            limitTags={1}
            classes={{
              input: '!min-w-0'
            }}
            onChange={(event: any, newValue: any[]) => {
              dispatch(
                updateGroupsAndOptionsEmployee({
                  [cluster.name]: newValue
                })
              )
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, {selected}) => {
              const {key, ...optionProps} = props
              return (
                <li key={key} {...optionProps}>
                  <Checkbox style={{marginRight: 8}} checked={selected} />
                  {option.name}
                </li>
              )
            }}
            isOptionEqualToValue={(option, value) => {
              return value && option?.id === value?.id
            }}
            renderInput={(params) => {
              return <TextField {...params} required maxRows={1} label={cluster.name} />
            }}
          />
        )
      })}
    </div>
  )
}

export default HierarchySelector
