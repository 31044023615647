import {graphql} from 'graphql/generatedTypes'

export const GET_DEVICE_INFO = graphql(`
  query getDeviceInfo($deviceId: String!) {
    getDeviceInfo(deviceId: $deviceId) {
      name
      id
      status
      deviceType
      numberOfErrors
      timeUsed
      metadata
    }
  }
`)

export const GET_DEVICE_ACTIVITIES = graphql(`
  query getDeviceActivities($deviceId: String!) {
    getDeviceActivities(deviceId: $deviceId) {
      id
      time
      user
      action
      description
    }
  }
`)

export const GET_ERROR_NOTIFICATION = graphql(`
  query getErrorNotifications(
    $businessId: String!
    $locationId: String!
    $live: Boolean
  ) {
    getErrorNotifications(
      businessId: $businessId
      locationId: $locationId
      live: $live
    ) {
      id
      activityId
      lastActive
      severity
      deviceName
      registerName
      zoneName
      serialNumber
      issueDescription
      action
      timeNotified
      timeResolved
    }
  }
`)

export const GET_DEVICE_DATA = graphql(`
  query getDeviceData($businessId: String!, $locationId: String!) {
    getDeviceData(businessId: $businessId, locationId: $locationId) {
      id
      lastActive
      deviceName
      severity
      registerName
      zoneName
      serialNumber
      issueDescription
      action
      deviceType
    }
  }
`)

export const GET_BUSINESS = graphql(`
  query getBusiness($businessId: String!) {
    getBusiness(businessId: $businessId) {
      id
      name
      brand
      logo
      authMethod
      featureFlags
    }
  }
`)

export const GET_PERIPHERAL_HUB_NOTIFICATION_CONFIG = graphql(`
  query getPeripheralHubNotificationConfig($employeeId: String!) {
    getPeripheralHubNotificationConfig(employeeId: $employeeId) {
      enabled
      severity
      issue
      device
      alert
      methods {
        email
        text
      }
    }
  }
`)

export const GET_ZONES = graphql(`
  query getZoneData1($businessId: String!, $locationId: String!) {
    fetchZones(businessId: $businessId, locationId: $locationId) {
      id
      name
      description
      registers {
        id
      }
    }

    fetchDevices(businessId: $businessId, locationId: $locationId) {
      id
      status
      deviceName
      metadata
    }

    fetchRegisters(businessId: $businessId, locationId: $locationId) {
      id
      name
      devices {
        id
      }
    }
  }
`)
