import React, {useEffect} from 'react'
import {FormControl, Grid, InputLabel, Select} from '@mui/material'
import {useLazyQuery} from '@apollo/client'
import {GET_ALL_EMPLOYEES} from 'graphql/queries/employee.queries'
import {useAuth} from 'auth/AuthContext'
import {StyledMenuItem} from 'ui/molecules/StyledSelect'

interface InsightsEmployeePickerProps {
  employee: string
  setEmployee: (employee: string) => void
}

const InsightsEmployeePicker: React.FC<InsightsEmployeePickerProps> = ({
  employee,
  setEmployee
}) => {
  const {authInfo} = useAuth()

  const [getEmployees, {data: employeeData}] = useLazyQuery(GET_ALL_EMPLOYEES, {
    fetchPolicy: 'no-cache'
  })
  useEffect(() => {
    getEmployees({
      variables: {
        businessId: authInfo.businessId as string,
        locationId: authInfo.locationId as string,
        fetchAll: true
      }
    })
  }, [])

  return (
    <FormControl fullWidth>
      <InputLabel id='employee'>Employee</InputLabel>
      <Select
        fullWidth
        required
        size='medium'
        label={'Employee'}
        value={employee}
        onChange={(e) => {
          setEmployee(e.target.value as string)
        }}
        inputProps={{'aria-label': 'Without label'}}
      >
        {employeeData?.getAllEmployees?.employees.map(
          (employee: any, index: number) => (
            <StyledMenuItem key={index} value={employee.employeeCode}>
              {employee.name}
            </StyledMenuItem>
          )
        )}
      </Select>
    </FormControl>
  )
}

export default InsightsEmployeePicker
