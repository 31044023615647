// @ts-nocheck
import {Tab} from '@mui/material'
import Tabs, {TabsProps} from '@mui/material/Tabs'
import {colors} from 'config/theme'

interface TabData {
  value: any
  label: string
  disabled?: boolean
  icon?: React.ReactNode
  count?: number
}

interface StyledTabProps extends TabsProps {
  tabs: TabData[]
}

function StyledTab({tabs, ...others}: StyledTabProps) {
  return (
    <Tabs
      centered
      variant='fullWidth'
      TabIndicatorProps={{
        style: {
          backgroundColor: 'black'
        }
      }}
      sx={{
        '& .MuiTab-root': {
          borderBottom: '3px solid',
          borderBottomColor: colors.grayBorder
        }
      }}
      {...others}
    >
      {tabs.map((tab: TabData, index: number) => {
        return (
          <Tab
            disableRipple={true}
            key={index}
            value={tab.value}
            label={tab.label}
            disabled={tab.disabled}
          />
        )
      })}
    </Tabs>
  )
}

export default StyledTab
