import {useMutation} from '@apollo/client'
import {FormControl, Grid, InputLabel, Select} from '@mui/material'
import {StyledMenuItem} from 'ui/molecules/StyledSelect'
import useNotify from 'hooks/useNotify'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {useAuth} from '../../auth/AuthContext'
import {SAFE_EVENT_OPTIONS, SAFE_TYPES} from 'constants/constants'
import {CREATE_SAFE_CASH_TRANSACTION} from 'graphql/mutations/cash-management.mutations'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledInput from 'ui/atoms/StyledInput'
import {v4 as uuid4} from 'uuid'
import {
  amountInCents,
  isInvalidInputAmount,
  parseAmount
} from 'utils/currency.utils'

interface SafeEventProps {
  setModelOpen: any
}

const SafeEventForm: React.FC<SafeEventProps> = ({setModelOpen}) => {
  const {authInfo} = useAuth()
  const notify = useNotify()

  const {t} = useTranslation()

  const [transactionMutation] = useMutation(CREATE_SAFE_CASH_TRANSACTION)

  const [fromSafe, setFromSafe] = React.useState<string>('')
  const [toSafe, setToSafe] = React.useState<string>('')
  const [event, setEvent] = React.useState<string>('')
  const [eventAmount, setEventAmount] = React.useState<string>('0')
  const [description, setDescription] = React.useState<string>('')
  const [eventId] = React.useState<string>(uuid4())

  useEffect(() => {
    if (!description) {
      setDescription(
        SAFE_EVENT_OPTIONS.find((option) => option.value === event)?.label || ''
      )
    }
  }, [event])

  const submitTransaction = async () => {
    try {
      await transactionMutation({
        variables: {
          amount: amountInCents(eventAmount),
          locationId: authInfo.locationId,
          eventType: event,
          description: description,
          fromSafe: fromSafe,
          toSafe: toSafe,
          eventId: eventId
        }
      })
      setModelOpen(false)
      notify.show(t('cash-management.transactions.success'), 'success')
    } catch (error) {
      console.error('Mutation error:', error)
      notify.show(
        (error as any)?.message || 'Something went wrong. Please try again.',
        'error'
      )
    }
  }

  const getCreateTransactionFormBody = () => {
    return (
      <Grid container spacing={4} padding={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel id='from_safe'>From Safe</InputLabel>
            <Select
              fullWidth
              required
              size='medium'
              label={'From Safe'}
              labelId='from_safe'
              value={fromSafe}
              onChange={(e) => {
                setFromSafe(e.target.value as string)
              }}
              inputProps={{'aria-label': 'Without label'}}
            >
              {SAFE_TYPES?.map((safe: any) => (
                <StyledMenuItem key={safe.value} value={safe.value}>
                  {safe.label}
                </StyledMenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel id='to_safe'>To Safe</InputLabel>
            <Select
              fullWidth
              required
              size='medium'
              label={'To Safe'}
              labelId='to_safe'
              value={toSafe}
              onChange={(e) => {
                setToSafe(e.target.value as string)
              }}
              inputProps={{'aria-label': 'Without label'}}
            >
              {SAFE_TYPES?.map(
                (safe: any) =>
                  safe.value !== fromSafe && (
                    <StyledMenuItem key={safe.value} value={safe.value}>
                      {safe.label}
                    </StyledMenuItem>
                  )
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel id='transaction-type'>Transaction Type</InputLabel>
            <Select
              fullWidth
              required
              size='medium'
              label={'Transaction Type'}
              value={event}
              onChange={(e) => {
                setEvent(e.target.value as string)
              }}
              inputProps={{'aria-label': 'Without label'}}
            >
              {SAFE_EVENT_OPTIONS.map((eventOption, index) => (
                <StyledMenuItem key={index} value={eventOption.value}>
                  {eventOption.label}
                </StyledMenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledInput
            fullWidth
            required
            label={'Transaction Amount'}
            value={eventAmount}
            type='number'
            InputProps={{
              startAdornment: t('currency-symbol')
            }}
            onChange={(e) => {
              setEventAmount(parseAmount(e.target.value))
            }}
            error={isInvalidInputAmount(eventAmount)}
            helperText={
              isInvalidInputAmount(eventAmount) ? 'Invalid amount' : ''
            }
          />
        </Grid>

        <Grid item xs={12}>
          <StyledInput
            fullWidth
            label={'Description'}
            value={description}
            type='text'
            onChange={(e) => {
              setDescription(e.target.value as string)
            }}
            multiline
            minRows={3}
            maxRows={4}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <StyledDialog
        open={true}
        title='Create New Safe Transaction'
        body={getCreateTransactionFormBody()}
        onClose={() => {
          setModelOpen(true)
        }}
        successButtonName='Save'
        cancelButtonName='Dismiss'
        cancelCallback={() => {
          setModelOpen(false)
        }}
        disableSuccessButton={
          !toSafe ||
          !fromSafe ||
          !event ||
          isInvalidInputAmount(eventAmount) ||
          Number(eventAmount) === 0
        }
        successCallback={submitTransaction}
      />
    </>
  )
}

export default SafeEventForm
