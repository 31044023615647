// @ts-nocheck
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {IconButton, InputAdornment} from '@mui/material'

import StyledInput from 'ui/atoms/StyledInput'

export default function StyledSearchBar({
  placeholder = 'Search',
  onRequestSearch,
  onCancelSearch,
  value,
  onChange,
  toggleSearch,
  style = {},
  inputProps = {},
  size = 'large',
  ...others
}: any) {
  function handleKeyDownEvent(event: any) {
    event.which === 13 && onRequestSearch && onRequestSearch()
  }

  function handleAction(event: any) {
    if (!value) {
      return
    }
    toggleSearch && toggleSearch()
    onCancelSearch && onCancelSearch()
  }

  function handleOnBlur(event: any) {
    if (!toggleSearch) {
      return
    }

    if (value) {
      return
    }

    toggleSearch()
  }

  return (
    <StyledInput
      placeholder={placeholder}
      onKeyDown={handleKeyDownEvent}
      value={value}
      onChange={(event: any) => {
        onChange(event.target.value)
      }}
      onBlur={handleOnBlur}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              size={size}
              onClick={handleAction}
              sx={{right: '0px', position: 'absolute'}}
            >
              <FontAwesomeIcon
                icon={icon({
                  name: 'search',
                  family: 'classic',
                  style: 'solid'
                })}
              />
            </IconButton>
          </InputAdornment>
        ),
        sx: {paddingRight: '40px'}
      }}
      style={style}
      size={size}
      sx={{
        // change the typography of the input
        '& .MuiInputBase-root': {
          typography: 'subtitle2',
          fontSize: '14px'
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderRadius: '20px',
            border: '1px solid black',
            padding: '8px 16px',
            alignItems: 'center'
          }
        }
      }}
      {...inputProps}
      {...others}
    />
  )
}
