import {useLazyQuery} from '@apollo/client'
import {selectStoreGroupClusters} from 'clientDashboard/business.selectors'
import {
  useAppSelector,
  useAppDispatch
} from 'clientDashboard/clientDashboard.store'
import {
  FETCH_STORE_GROUPS,
  FETCH_STORE_GROUPS_BY_CLUSTER_ID
} from 'graphql/queries/store-groups'
import {useEffect, useState} from 'react'
import {
  setOrphanItems,
  setSaveStoreGroupModalData,
  setStoreGroups
} from 'clientDashboard/settings/locations/locations.slice'
import {Grid} from '@mui/material'
import {selectBusinessId} from 'clientDashboard/employee.selectors'
import StoreGroupAccordion from 'clientDashboard/settings/StoreGrouping/StoreGroupAccordion'
import {GET_LOCATIONS} from 'graphql/queries/location.queries'
import {
  selectAncestorsStoreGroupsByStoreGroupIdMap,
  selectChildStoreGroups,
  selectSaveStoreGroupModalData,
  selectStoreGroupsByIdMap
} from 'clientDashboard/settings/locations/locations.selectors'
import StoreGroupSave from 'clientDashboard/settings/StoreGrouping/StoreGroupSave'
import {StoreGroupClusterType} from 'graphql/generatedTypes/graphql'
import {useHistory} from 'react-router-dom'

const StoreGroupList = () => {
  const dispatch = useAppDispatch()
  // Get the URI after 'location-grouping' and split it, then extract the last element
  let uriParts = window.location.pathname.split('location-grouping')
  let subGroup = decodeURI(
    uriParts[uriParts.length - 1].split('/').pop() as string
  )
  const storeGroupClusters = useAppSelector(selectStoreGroupClusters)
  // check if subGroup equals any item in storeGroupClusters
  const [selectedStoreGroupClusterId, setSelectedStoreGroupClusterId] =
    useState('')
  const [isEditMode, setIsEditMode] = useState(false)
  const [editStoreGroupId, setEditStoreGroupId] = useState('')
  const businessId = useAppSelector(selectBusinessId)
  const [isViewLocationsMode, setIsViewLocationsMode] = useState(false)
  const [viewLocationStoreGroupId, setViewLocationStoreGroupId] = useState('')
  const history = useHistory()
  const ancestorsStoreGroupsByStoreGroupIdMap = useAppSelector(
    selectAncestorsStoreGroupsByStoreGroupIdMap
  )
  const storeGroupByIdMap = useAppSelector(selectStoreGroupsByIdMap)
  const [_fetchStoreGroups, {data: _storeGroupsData}] = useLazyQuery(
    FETCH_STORE_GROUPS,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const childStoreGroups = useAppSelector(selectChildStoreGroups)
  const storeGroupsByIdMap = useAppSelector(selectStoreGroupsByIdMap)

  const fetchStoreGroups = () => {
    _fetchStoreGroups()
  }

  const [_getAllLocations, {data: _allLocations}] = useLazyQuery(
    GET_LOCATIONS,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const getAllLocations = () => {
    _getAllLocations({
      variables: {
        businessId: businessId
      }
    })
  }

  useEffect(() => {
    if (selectedStoreGroupClusterId) {
      fetchStoreGroups()
      getAllLocations()
    }
  }, [selectedStoreGroupClusterId])

  useEffect(() => {
    if (!isEditMode) {
      return
    }
  }, [isEditMode])

  const [getLocations, {data: _locationData}] = useLazyQuery(GET_LOCATIONS, {
    fetchPolicy: 'no-cache'
  })
  const [_fetchStoreGroupsByClusterId, {data: _storeGroupsByClusterId}] =
    useLazyQuery(FETCH_STORE_GROUPS_BY_CLUSTER_ID, {
      fetchPolicy: 'no-cache'
    })
  const fetchNextHierarchyLevelClusterId = (clusterId: string) => {
    const cluster = storeGroupClusters.find(
      (cluster: StoreGroupClusterType) => cluster.id === clusterId
    )
    if (!cluster) {
      return
    }
    const nextHierarchyLevel = cluster.hierarchyLevel + 1
    const nextCluster = storeGroupClusters.find(
      (cluster: StoreGroupClusterType) =>
        cluster.hierarchyLevel === nextHierarchyLevel
    )
    return nextCluster?.id
  }
  useEffect(() => {
    if (!isEditMode) {
      return
    }
    if (!_locationData && !_storeGroupsByClusterId) {
      return
    }

    const storeGroupCluster = storeGroupsByIdMap[editStoreGroupId].cluster
    const isLowestLevel =
      storeGroupCluster?.hierarchyLevel === storeGroupClusters.length
    const orphanItems = isLowestLevel
      ? _locationData?.getAllLocations
      : _storeGroupsByClusterId?.storeGroupsByClusterId
    setTimeout(() => {
      dispatch(setOrphanItems(orphanItems ? orphanItems : []))
    })
  }, [_locationData, _storeGroupsByClusterId])
  const fetchStoreGroupClusterByStoreGroupId = (id: string) => {
    let storeGroup = storeGroupsByIdMap[id]
    return storeGroup.cluster?.id
  }
  const fetchOrphanStoreGroups = () => {
    _fetchStoreGroupsByClusterId({
      variables: {
        storeGroupClusterId: fetchNextHierarchyLevelClusterId(
          fetchStoreGroupClusterByStoreGroupId(
            editStoreGroupId as string
          ) as string
        ) as string,
        status: 'active',
        fetchOrphanedStoreGroups: true
      }
    })
  }

  const fetchOrphanLocations = () => {
    getLocations({
      variables: {
        businessId: businessId,
        storeGroupIds: []
      }
    })
  }

  useEffect(() => {
    if (!_storeGroupsData || !_allLocations) {
      return
    }
    setTimeout(() => {
      dispatch(
        setStoreGroups({
          storeGroupsList: _storeGroupsData.storeGroups,
          locationsList: _allLocations.getAllLocations
        })
      )
    })
  }, [_storeGroupsData, _allLocations])

  useEffect(() => {
    if (_allLocations) {
      setTimeout(() => {
        dispatch(setStoreGroups(_allLocations.getAllLocations))
      })
    }
    if (storeGroupClusters) {
      storeGroupClusters.forEach((storeGroup) => {
        if (storeGroup.name.toLowerCase() === subGroup) {
          setSelectedStoreGroupClusterId(storeGroup.id)
        }
      })
    }
  }, [])

  const fetchStoreGroupClusterById = (id: string) => {
    return storeGroupClusters.find(
      (cluster: StoreGroupClusterType) => cluster.id === id
    )
  }

  useEffect(() => {
    if (!editStoreGroupId) {
      return
    }
    let storeGroup = storeGroupsByIdMap[editStoreGroupId]
    let childGroups = childStoreGroups[storeGroup.id as string]
    setTimeout(() => {
      dispatch(
        setSaveStoreGroupModalData({
          id: storeGroup.id,
          name: storeGroup.name,
          status: storeGroup.status,
          cluster: {
            id: storeGroup?.cluster?.id as string,
            name: storeGroup?.cluster?.name as string,
            hierarchyLevel: storeGroup?.cluster?.hierarchyLevel as number
          },
          groupItems: childGroups
        })
      )
    })
    let clusterGroup = fetchStoreGroupClusterById(
      storeGroup.cluster?.id as string
    )
    const isLowestLevel =
      clusterGroup?.hierarchyLevel === storeGroupClusters.length
    if (isLowestLevel) {
      fetchOrphanLocations()
    } else {
      fetchOrphanStoreGroups()
    }
  }, [editStoreGroupId])

  useEffect(() => {
    if (!isViewLocationsMode && !viewLocationStoreGroupId) {
      return
    }
    let header = storeGroupByIdMap[viewLocationStoreGroupId].name
    ancestorsStoreGroupsByStoreGroupIdMap[viewLocationStoreGroupId].forEach(
      (storeGroup: any) => {
        header = `${storeGroup.name} > ${header}`
      }
    )
    history.push(`/${businessId}/location-settings`, {
      headerDescription: header,
      selectedStoreGroupIds: [viewLocationStoreGroupId]
    })
  }, [isViewLocationsMode, viewLocationStoreGroupId])

  const storeGroupModalData = useAppSelector(selectSaveStoreGroupModalData)

  const showStoreGroupAccordion = () => {
    if (!selectedStoreGroupClusterId) {
      return <></>
    }
    return (
      <Grid>
        <StoreGroupAccordion
          onEditClick={(storeGroupId: string) => {
            setIsEditMode(true)
            setEditStoreGroupId(storeGroupId)
          }}
          onViewLocationsClick={(storeGroupId: string) => {
            setViewLocationStoreGroupId(storeGroupId)
            setIsViewLocationsMode(true)
          }}
          topLevelStoreGroupClusterName={subGroup as string}
        />
      </Grid>
    )
  }

  const showEditStoreGroupModal = () => {
    if (
      !isEditMode ||
      !storeGroupModalData ||
      !storeGroupModalData.orphanItems
    ) {
      return <></>
    }
    return (
      <>
        <StoreGroupSave
          isEditMode={isEditMode}
          onSuccessCallback={() => {
            fetchStoreGroups()
            getAllLocations()
            setIsEditMode(false)
            setEditStoreGroupId('')
          }}
          onCloseOrCancelCallback={() => {
            setIsEditMode(false)
            setEditStoreGroupId('')
          }}
        />
      </>
    )
  }

  return (
    <Grid
      spacing={2}
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%'
      }}
    >
      {showStoreGroupAccordion()}
      {showEditStoreGroupModal()}
    </Grid>
  )
}

export default StoreGroupList
