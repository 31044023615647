import {useLazyQuery} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Box, Grid, MenuItem, Typography} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import {notify} from 'ui/molecules/CustomNotifications'
import ServiceConfigCard from 'clientDashboard/settings/ServiceConfigCard'
import ShopifyServiceConfigDialog from 'clientDashboard/settings/ShopifyServiceConfigDialog'
import StyledButton from 'ui/atoms/StyledButton'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledInput from 'ui/atoms/StyledInput'
import theme, {space} from 'config/theme'
import {OMS_CATEGORY, SHOPIFY} from 'constants/constants'
import {
  ServiceConfigProvidersType,
  ServiceConfigType
} from 'graphql/generatedTypes/graphql'
import {
  FETCH_SERVICE_CONFIG_PROVIDERS,
  FETCH_SERVICE_CONFIGS_BY_CATEGORY
} from 'graphql/queries/service-config.queries'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {titleize} from 'utils/common'

const defaultShopifyConfigs = {
  type: '',
  configs: {
    adminAccessToken: '',
    shopName: '',
    toteShopifyAppSecretKey: '',
    toteShopifyAppApiKey: '',
    apiVersion: '',
    toteOauthClientId: ''
  }
}

function OmsSettings() {
  const {authInfo} = useAuth()
  const {t} = useTranslation()
  const [
    selectServiceConfigProviderDialogOpen,
    setSelectServiceConfigProviderDialogOpen
  ] = useState(false)
  const [configProvider, setConfigProvider] = useState('')
  const [omsServiceConfigs, setOmsServiceConfigs] = useState<
    ServiceConfigType[]
  >([])
  const [loadingOmsServiceConfig, setLoadingOmsServiceConfig] = useState(true)
  const [omsServiceProviders, setOmsServiceProviders] = useState<
    ServiceConfigProvidersType[]
  >([])
  const [serviceConfigInputDialogOpen, setServiceConfigInputDialogOpen] =
    useState(false)
  const [
    omsServiceProvidersNotConfigured,
    setOmsServiceProvidersNotConfigured
  ] = useState<string[]>([])
  const [_fetchServiceConfigProviders, {data: _serviceConfigProvidersData}] =
    useLazyQuery(FETCH_SERVICE_CONFIG_PROVIDERS, {
      fetchPolicy: 'no-cache'
    })
  const fetchServiceConfigProviders = () => {
    _fetchServiceConfigProviders({
      variables: {
        category: OMS_CATEGORY
      }
    })
  }

  useEffect(() => {
    setOmsServiceProviders(
      _serviceConfigProvidersData?.serviceConfigProviders ?? []
    )
  }, [_serviceConfigProvidersData])

  const [_fetchServiceConfigsByCategory, {data: _serviceConfigsData}] =
    useLazyQuery(FETCH_SERVICE_CONFIGS_BY_CATEGORY, {
      fetchPolicy: 'no-cache'
    })

  const fetchServiceConfigsByCategory = (category: string) => {
    _fetchServiceConfigsByCategory({
      variables: {
        category: category,
        businessId: authInfo.businessId as string
      }
    })
  }

  useEffect(() => {
    let _omsServiceConfigs = (_serviceConfigsData?.serviceConfigsByCategory ??
      []) as ServiceConfigType[]
    setOmsServiceConfigs(_omsServiceConfigs)
    let _configuredProviders = _omsServiceConfigs.map(
      (serviceConfig) => serviceConfig.type
    )
    let _notConfiguredProviders = omsServiceProviders.filter(
      (provider) => !_configuredProviders.includes(provider.provider)
    )
    setOmsServiceProvidersNotConfigured(
      _notConfiguredProviders.map((provider) => provider.provider)
    )
    // this is done to reset the config provider when the user saves the configurations
    // and then tries to add a new provider again
    setConfigProvider('')
  }, [_serviceConfigsData])

  useEffect(() => {
    if (!_serviceConfigsData) {
      return
    }
    setLoadingOmsServiceConfig(false)
  }, [omsServiceConfigs])

  useEffect(() => {
    fetchServiceConfigProviders()
    fetchServiceConfigsByCategory(OMS_CATEGORY)
  }, [])

  const loadOmsServiceConfigsDialog = (inputDialogOpen: boolean) => {
    let _serviceConfig = omsServiceConfigs.find(
      (serviceConfig) => serviceConfig.type === configProvider
    )
    if (_serviceConfig) {
      _serviceConfig = JSON.parse(JSON.stringify(_serviceConfig))
    }
    return (
      <>
        <Grid container spacing={2} padding={2}>
          {configProvider === SHOPIFY && (
            <ShopifyServiceConfigDialog
              serviceConfig={_serviceConfig ?? defaultShopifyConfigs}
              toggleServiceConfigInputDialog={setServiceConfigInputDialogOpen}
              onSuccessCallback={() => {
                notify.show(
                  t('oms-settings.config-stored-successfully'),
                  'success'
                )
                fetchServiceConfigsByCategory(OMS_CATEGORY)
              }}
              onCloseOrCancelCallback={() => {
                setConfigProvider('')
              }}
              inputDialogOpen={inputDialogOpen}
              providerMetadata={
                omsServiceProviders.find(
                  (provider) => provider.provider === SHOPIFY
                )?.metadata ?? {}
              }
            />
          )}
        </Grid>
      </>
    )
  }

  const loadSelectOmsProviderDialogBody = () => {
    return (
      <Grid>
        <Grid item>
          <StyledInput
            label={t('oms-settings.oms')}
            value={configProvider}
            onChange={(e) => setConfigProvider(e.target.value.toLowerCase())}
            select
            fullWidth
          >
            {omsServiceProvidersNotConfigured.map((provider) => (
              <MenuItem key={provider} value={provider}>
                {titleize(provider)}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
      </Grid>
    )
  }

  const updateOmsServiceProvidersNotConfigured = () => {
    const configuredProviders = omsServiceConfigs.map(
      (serviceConfig) => serviceConfig.type
    )
    const notConfiguredProviders = omsServiceProviders.filter(
      (provider) => !configuredProviders.includes(provider.provider)
    )
    setOmsServiceProvidersNotConfigured(
      notConfiguredProviders.map((provider) => provider.provider)
    )
  }

  const loadNoConfiguredOmsProviderPage = () => {
    if (loadingOmsServiceConfig || omsServiceConfigs.length > 0) {
      return <></>
    }
    return (
      <Grid
        style={{
          width: '100%',
          height: window.innerHeight - 250,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row'
        }}
      >
        <Grid
          item
          container
          sm={6}
          xs={12}
          rowGap={8}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: theme.palette.info.light,
            borderRadius: '12px',
            padding: space.LARGE
          }}
        >
          <Grid item>
            <Box
              sx={{
                width: '76px',
                height: '76px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.background.default,
                borderRadius: '8px'
              }}
            >
              <FontAwesomeIcon
                icon={icon({
                  name: 'shopping-bag',
                  family: 'classic',
                  style: 'solid'
                })}
                fontSize={'30px'}
                color={theme.palette.primary.main}
              />
            </Box>
          </Grid>
          <Grid item>
            <Typography variant='h1'>
              {t('oms-settings.setup-order-management-system')}
            </Typography>
          </Grid>
          <Grid item>
            <StyledButton
              variant='contained'
              color='primary'
              size='medium'
              onClick={() => {
                setSelectServiceConfigProviderDialogOpen(true)
                updateOmsServiceProvidersNotConfigured()
              }}
            >
              {t('oms-settings.link-oms')}
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const loadServiceConfigSelectorDialog = () => {
    return (
      <StyledDialog
        open={selectServiceConfigProviderDialogOpen}
        title={t('oms-settings.add-oms')}
        body={loadSelectOmsProviderDialogBody()}
        successButtonName={t('next')}
        cancelButtonName={t('cancel')}
        cancelCallback={() => {
          setSelectServiceConfigProviderDialogOpen(false)
          setConfigProvider('')
        }}
        successCallback={() => {
          setSelectServiceConfigProviderDialogOpen(false)
          setServiceConfigInputDialogOpen(true)
        }}
        onClose={() => {
          setSelectServiceConfigProviderDialogOpen(false)
          setConfigProvider('')
        }}
        disableSuccessButton={!configProvider}
      />
    )
  }

  const loadServiceConfigsCardPage = () => {
    return (
      <>
        <Grid
          rowGap={6}
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Grid container justifyContent='space-between'>
            <Grid item xs={6} padding={2}>
              <Typography variant='h2'>
                {t('oms-settings.order-management-system')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{display: 'flex', justifyContent: 'flex-end'}}
            >
              <StyledButton
                onClick={() => setSelectServiceConfigProviderDialogOpen(true)}
              >
                {t('oms-settings.add-oms')}
              </StyledButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {omsServiceConfigs.map((serviceConfig) => (
              <ServiceConfigCard
                key={serviceConfig.type}
                serviceConfig={serviceConfig}
                toggleDialog={() => {
                  setServiceConfigInputDialogOpen(true)
                }}
                setServiceConfigType={(serviceConfigType: string) =>
                  setConfigProvider(serviceConfigType)
                }
                editCTAText={t('oms-settings.edit-credentials')}
              />
            ))}
          </Grid>
        </Grid>
      </>
    )
  }
  return (
    <Grid
      spacing={2}
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%'
      }}
    >
      {loadNoConfiguredOmsProviderPage()}
      {selectServiceConfigProviderDialogOpen &&
        loadServiceConfigSelectorDialog()}
      {loadOmsServiceConfigsDialog(serviceConfigInputDialogOpen)}
      {omsServiceConfigs.length > 0 && loadServiceConfigsCardPage()}
    </Grid>
  )
}

export default OmsSettings
