import {graphql} from 'graphql/generatedTypes'

export const STORE_GROUP_CLUSTER_STATS = graphql(`
  query StoreGroupClusterStats {
    storeGroupClusterStats {
      id
      storeGroupClusterName
      activeCount
      draftCount
    }
  }
`)

export const FETCH_STORE_GROUPS_BY_CLUSTER_ID = graphql(`
  query StoreGroupsByClusterId(
    $storeGroupClusterId: String!
    $status: String
    $fetchOrphanedStoreGroups: Boolean
  ) {
    storeGroupsByClusterId(
      storeGroupClusterId: $storeGroupClusterId
      status: $status
      fetchOrphanedStoreGroups: $fetchOrphanedStoreGroups
    ) {
      id
      name
      status
      storeGroupCluster {
        id
        name
        hierarchyLevel
      }
    }
  }
`)

export const FETCH_CHILD_STORE_GROUPS = graphql(`
  query ChildStoreGroups($storeGroupId: String!) {
    childStoreGroups(storeGroupId: $storeGroupId) {
      id
      name
      status
      storeGroupCluster {
        id
        name
        hierarchyLevel
      }
    }
  }
`)

export const FETCH_STORE_GROUPS = graphql(`
  query StoreGroups {
    storeGroups {
      id
      name
      status
      storeGroupCluster {
        id
        name
        hierarchyLevel
      }
      parentStoreGroup {
        id
        name
        status
        storeGroupCluster {
          id
          name
          hierarchyLevel
        }
      }
    }
  }
`)
