import React, {useCallback, useMemo, useState} from 'react'
import StyledInput from 'ui/atoms/StyledInput'
import {t} from 'i18next'
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete
} from '@mui/material'
import {isValidEmail} from 'utils/common'
import StyledDialog from 'ui/molecules/StyledDialog'
import _ from 'lodash'
import {useMutation} from '@apollo/client'
import {notify} from 'ui/molecules/CustomNotifications'
import {
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE
} from 'graphql/mutations/employee.mutations'
import AddressFields from "ui/organisms/AddressFields";

interface EmployeeConfigurationDialogBodyProps {
  allRoles: any[]
  allLocations: any[]
  allEmployees: any[]
  businessId: any
  isEditMode: boolean
  setIsEditMode?: (isEditMode: boolean) => void
  setShowCreateEmployeeDialog: (show: boolean) => void
  showCreateEmployeeDialog: boolean
  setAllEmployees: (allEmployees: any) => void
  editEmployeeId: any
}
const defaultFormValues = {
  name: '',
  employeeCode: '',
  email: '',
  locations: [],
  role: null,
  mobileNumber: '',
  address: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  },
  defaultLocation: null
}
const EmployeeConfigurationDialog = ({
  allRoles,
  allLocations,
  allEmployees,
  businessId,
  isEditMode,
  setIsEditMode = () => {},
  setShowCreateEmployeeDialog,
  showCreateEmployeeDialog,
  setAllEmployees,
  editEmployeeId
}: EmployeeConfigurationDialogBodyProps) => {
  const [formValues, setFormValues] = useState({...defaultFormValues})
  const clearForm = () => {
    setFormValues({
      ...defaultFormValues
    })
  }

  useMemo(() => {
    if (isEditMode) {
      const employee = allEmployees.find(
        (employee: any) => employee.id === editEmployeeId
      )
      setFormValues({
        name: employee.name,
        employeeCode: employee.employeeCode,
        email: employee.email,
        locations:
          employee.locations?.map((location: any) => {
            return {
              id: location.id,
              lable: location.name
            }
          }) || [],
        role: employee.role?.id || null,
        mobileNumber: employee.phoneNumber,
        address: {
          street: employee.fullAddress?.street || '',
          city: employee.fullAddress?.city || '',
          state: employee.fullAddress?.state || '',
          zipCode: employee.fullAddress?.zipCode || '',
          country: employee.fullAddress?.country || ''
        },
        defaultLocation: employee.defaultLocation?.id
      })
    }
  }, [isEditMode, editEmployeeId, allEmployees])

  const allEmailSet = useMemo(() => {
    return new Set(allEmployees.map((employee: any) => employee.email))
  }, [allEmployees])

  const allEmployeeCodeSet = useMemo(() => {
    return new Set(allEmployees.map((employee: any) => employee.employeeCode))
  }, [allEmployees])

  const handleChange = (path: any, value: any) => {
    setFormValues((prev: any) => {
      return _.set({...prev}, path, value)
    })
  }

  const setAddress = (address: {
    street: string
    city: string
    state: string
    zipCode: string
    country: string
  }) => {
    setFormValues((prev) => {
      return {
        ...prev,
        address
      }
    })
  }

  const [_createEmployee] = useMutation(CREATE_EMPLOYEE, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      // efficiently append new employee to the list
      setAllEmployees((prev: any) => {
        return [...prev, data.createEmployee]
      })
      setShowCreateEmployeeDialog(false)
      clearForm()
      setIsEditMode(false)
    },
    onError: (error) => {
      notify.show(error.message, 'error')
    }
  })
  const createEmployee = useCallback(() => {
    _createEmployee({
      variables: {
        businessId: businessId as string,
        employee: {
          name: formValues.name,
          email: formValues.email.toLocaleLowerCase(),
          employeeCode: formValues.employeeCode,
          phoneNumber: formValues.mobileNumber,
          address: {
            street: formValues.address.street,
            city: formValues.address.city,
            state: formValues.address.state,
            zipCode: formValues.address.zipCode,
            country: formValues.address.country
          },
          role: formValues.role,
          locations:
            formValues.locations.map((location: any) => location.id) || [],
          defaultLocation: formValues.defaultLocation
        }
      }
    })
  }, [_createEmployee, formValues, businessId])

  const [_updateEmployee] = useMutation(UPDATE_EMPLOYEE, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setAllEmployees((prev: any) => {
        return prev.map((employee: any) => {
          if (employee.id === data.updateEmployee.id) {
            return data.updateEmployee
          }
          return employee
        })
      })

      setShowCreateEmployeeDialog(false)
      clearForm()
      setIsEditMode(false)
    },
    onError: (error) => {
      notify.show(error.message, 'error')
    }
  })

  const updateEmployee = useCallback(() => {
    _updateEmployee({
      variables: {
        employeeId: editEmployeeId,
        businessId: businessId as string,
        employee: {
          name: formValues.name,
          email: formValues.email,
          employeeCode: formValues.employeeCode,
          phoneNumber: formValues.mobileNumber,
          address: {
            street: formValues.address.street,
            city: formValues.address.city,
            state: formValues.address.state,
            zipCode: formValues.address.zipCode,
            country: formValues.address.country
          },
          role: formValues.role,
          locations:
            formValues.locations.map((location: any) => location.id) || [],
          defaultLocation: formValues.defaultLocation
        }
      }
    })
  }, [_updateEmployee, editEmployeeId, formValues, businessId])

  const employeeConfigurationDialogBody = () => {
    const renderEmailHelperText = () => {
      if (allEmailSet.has(formValues.email) && !isEditMode) {
        return t('employee-management.employee-with-this-email-already-exists')
      } else if (formValues.email && !isValidEmail(formValues.email)) {
        return t('employee-management.invalid-email')
      }
      return ''
    }
    const renderEmailError = () => {
      if (allEmailSet.has(formValues.email) && !isEditMode) {
        return true
      } else if (formValues.email && !isValidEmail(formValues.email)) {
        return true
      }
      return false
    }
    return (
      <>
        {' '}
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <Typography variant='h4'>
              {t('employee-management.details')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              fullWidth
              required
              label={t('employee-management.name')}
              variant='outlined'
              value={formValues.name}
              onChange={(e: any) => handleChange('name', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              fullWidth
              required
              label={t('employee-management.employee-code')}
              variant='outlined'
              value={formValues.employeeCode}
              onChange={(e: any) => {
                handleChange('employeeCode', e.target.value)
              }}
              error={
                allEmployeeCodeSet.has(formValues.employeeCode) && !isEditMode
              }
              helperText={
                allEmployeeCodeSet.has(formValues.employeeCode) && !isEditMode
                  ? t(
                      'employee-management.employee-with-this-code-already-exists'
                    )
                  : ''
              }
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              fullWidth
              label={t('employee-management.email')}
              variant='outlined'
              value={formValues.email}
              onChange={(e: any) => handleChange('email', e.target.value)}
              error={renderEmailError()}
              helperText={renderEmailHelperText()}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              fullWidth
              label={t('employee-management.mobile-number')}
              variant='outlined'
              value={formValues.mobileNumber}
              onChange={(e: any) =>
                handleChange('mobileNumber', e.target.value)
              }
            />
          </Grid>

          {/* Address Fields */}
          <AddressFields address={formValues.address} setAddress={setAddress} />

          <Grid item xs={12}>
            <Typography variant='h4'>
              {t('employee-management.role')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id='role-for-employee' required>
                {t('employee-management.role')}
              </InputLabel>

              <Select
                size='medium'
                label={t('employee-management.role')}
                labelId='role-for-employee'
                value={formValues.role || ''}
                onChange={(e: any) => handleChange('role', e.target.value)}
              >
                {(allRoles || []).map(
                  ({name, id}: {name: string; id: string}) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography variant='h4'>
              {t('employee-management.locations')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              id='employee-locations'
              options={allLocations}
              freeSolo
              value={formValues.locations}
              getOptionLabel={(option: any) => option.lable}
              onChange={(event, newValue) => {
                handleChange('locations', newValue)
              }}
              renderInput={(params) => (
                <StyledInput
                  required
                  {...params}
                  variant='outlined'
                  label={t('employee-management.locations')}
                  placeholder={t('employee-management.locations')}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id='default-location'>
                {t('employee-management.default-location')}
              </InputLabel>

              <Select
                size='medium'
                label={t('employee-management.default-location')}
                disabled={!formValues.locations.length}
                labelId='default-location'
                value={formValues.defaultLocation || ''}
                onChange={(e: any) =>
                  handleChange('defaultLocation', e.target.value)
                }
              >
                {(formValues.locations || []).map(
                  ({lable, id}: {lable: string; id: string}) => (
                    <MenuItem key={id} value={id}>
                      {lable}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <StyledDialog
      open={showCreateEmployeeDialog}
      onClose={() => {
        setShowCreateEmployeeDialog(false)
        setIsEditMode(false)
        clearForm()
      }}
      title={
        isEditMode
          ? t('employee-management.edit-employee')
          : t('employee-management.create-employee')
      }
      successButtonName={t('employee-management.confirm')}
      cancelButtonName={t('employee-management.cancel')}
      cancelCallback={() => {
        setShowCreateEmployeeDialog(false)
        clearForm()
        setIsEditMode(false)
      }}
      disableSuccessButton={
        !formValues.name ||
        !formValues.employeeCode ||
        !isValidEmail(formValues.email) ||
        !formValues.locations.length ||
        !formValues.role ||
        (allEmployeeCodeSet.has(formValues.employeeCode) && !isEditMode) ||
        (allEmailSet.has(formValues.email) && !isEditMode)
      }
      successCallback={() => {
        isEditMode ? updateEmployee() : createEmployee()
      }}
      body={employeeConfigurationDialogBody()}
    />
  )
}

export default EmployeeConfigurationDialog
