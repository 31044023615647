import {graphql} from 'graphql/generatedTypes'

export const GET_REGISTER_AND_TILL = graphql(`
  query getRegisterTills($businessId: String!, $locationId: String!) {
    fetchRegisterList(businessId: $businessId, locationId: $locationId) {
      id
      name
    }

    fetchTills(businessId: $businessId, locationId: $locationId) {
      id
      name
    }
  }
`)
