import {graphql} from 'graphql/generatedTypes'

export const GENIE_QUERY = graphql(`
  query GenieQueryDashboard(
    $query: String!
    $chatSessionId: String!
    $queryType: String
  ) {
    genieQueryDashboard(
      query: $query
      chatSessionId: $chatSessionId
      queryType: $queryType
    ) {
      messageId
      queryType
      answer
      query
      status
      chatSessionId
      followups
      genieAnalyticsQuery {
        data
        html
      }
    }
  }
`)

export const FETCH_GENIE_MESSAGE = graphql(`
  query FetchGenieMessageDashboard($messageId: String!) {
    fetchGenieMessageDashboard(messageId: $messageId) {
      messageId
      queryType
      answer
      query
      status
      chatSessionId
      followups
      genieAnalyticsQuery {
        data
        html
      }
    }
  }
`)
