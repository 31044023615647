import {useQuery} from '@apollo/client'
import {Typography} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import StyledTable from 'ui/molecules/StyledTable'
import {TransactionType} from 'constants/constants'
import {GET_TRANSACTIONS} from 'graphql/queries/cash-management.queries'
import useNotify from 'hooks/useNotify'
import {MRT_ColumnDef} from 'material-react-table'
import React, {useEffect, useMemo} from 'react'
import {CashTransactionType} from 'types/cashSummary.types'
import {
  centsToDollar,
  downloadJsonAsCsv,
  formatCurrency,
  localDateTimeString
} from 'utils/common'
import ExportInsightDataInsight from 'clientDashboard/cashManagement/cashManagerInsights/ExportInsightDataInsight'
import ChartDataForm from 'clientDashboard/cashManagement/ChartDataForm'
import {t} from 'i18next'
import _ from 'lodash'

interface CashTransactionListingProps {
  dateRange: Date[]
  locationId: string
  eventType?: string
  chartData?: any
  employeeCodes?: string[]
  subcriptionData?: any
}
const CashTransactionListing = ({
  dateRange,
  locationId,
  eventType,
  employeeCodes,
  chartData,
  subcriptionData
}: CashTransactionListingProps) => {
  const notify = useNotify()

  const {authInfo} = useAuth()

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10
  })
  const [chartModalOpen, setChartModalOpen] = React.useState(false)

  const {
    loading: loadingTransaction,
    data: transactionData,
    refetch: refetchTransaction
  } = useQuery(GET_TRANSACTIONS, {
    variables: {
      dateRange: dateRange,
      transactionType: eventType,
      locationId: locationId as string,
      employeeCode: employeeCodes ? _.join(employeeCodes, ',') : '',
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize
    },
    onError: (error) => {
      notify.show(error.message, 'error')
    }
  })

  useEffect(() => {
    if (subcriptionData) {
      const cashEvent = subcriptionData.webCashEvent?.cashEvent
      if (cashEvent === 'refresh') {
        refetchTransaction()
      }
    }
  }, [subcriptionData])

  const columns = useMemo<MRT_ColumnDef<CashTransactionType>[]>(
    () => [
      {
        accessorKey: 'transactionTime',
        header: 'Transaction Date & Time',
        enableColumnFilter: true,
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue() ? localDateTimeString(cell.getValue()) : '-'}
            </Typography>
          )
        }
      },
      {
        accessorKey: 'register',
        header: 'Register',
        enableColumnFilter: true,
        filterVariant: 'multi-select',
        muiFilterTextFieldProps: {
          placeholder: 'All',
          sx: {
            minWidth: '100px'
          }
        },
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },

      {
        accessorKey: 'till',
        header: 'Till',
        enableColumnFilter: true,
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'transactionId',
        header: 'Transaction ID',
        enableColumnFilter: false,

        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'eventId',
        header: 'Event ID',
        enableColumnFilter: false,
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'eventTime',
        header: 'Event Date & Time',
        enableColumnFilter: false,
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue() ? localDateTimeString(cell.getValue()) : '-'}
            </Typography>
          )
        }
      },
      {
        accessorKey: 'employee',
        header: 'Employee',
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue()?.name || ''}
            </Typography>
          )
        }
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        enableColumnFilter: false,
        muiTableBodyCellProps: {
          align: 'right'
        },
        Cell: ({cell, row}: any) => {
          if (row.original.transactionType === TransactionType.DEBIT) {
            return (
              <Typography variant='subtitle2'>
                ({formatCurrency(centsToDollar(cell.getValue()))})
              </Typography>
            )
          }

          return (
            <Typography variant='subtitle2'>
              {formatCurrency(centsToDollar(cell.getValue()))}
            </Typography>
          )
        }
      }
    ],
    []
  )
  const exportTransactionsCsvData = () => {
    const transactions = transactionData?.cashTransactions?.nodes || []
    const csvData = transactions.map((transaction: any) => {
      return {
        'Transaction Date & Time': localDateTimeString(
          transaction.transactionTime
        ),
        Register: transaction.register,
        Till: transaction.till,
        'Transaction ID': transaction.transactionId,
        'Event ID': transaction.eventId,
        'Event Date & Time': localDateTimeString(transaction.eventTime),
        Employee: transaction.employee?.name || '',
        Amount:
          transaction.transactionType === TransactionType.DEBIT
            ? `(${formatCurrency(centsToDollar(transaction.amount))})`
            : formatCurrency(centsToDollar(transaction.amount))
      }
    })
    downloadJsonAsCsv(csvData, 'transactions.csv')
  }

  return (
    <>
      <StyledTable
        columns={columns}
        data={transactionData?.cashTransactions?.nodes || []}
        enableTableFooter={true}
        enableTopToolbar={true}
        enableToolbarInternalActions={false}
        enableSorting={false}
        enableColumnActions={false}
        enableHiding={false}
        enablePagination={true}
        enableStickyHeader={true}
        manualFiltering={false}
        manualPagination={true}
        onPaginationChange={setPagination}
        renderTopToolbarCustomActions={() => {
          return (
            <>
              <ExportInsightDataInsight
                exportOptions={[
                  {
                    id: 'export-csv',
                    label: t('insights.cash-management.export.table-csv-data'),
                    onClick: () => {
                      exportTransactionsCsvData()
                    },
                    type: 'primary',
                    data: transactionData?.cashTransactions?.nodes
                  },
                  {
                    id: 'export-chart-data',
                    label: t('insights.cash-management.export.chart-csv-data'),
                    onClick: () => {
                      setChartModalOpen(true)
                    },
                    type: 'secondary',
                    data: chartData
                  }
                ]}
              />
            </>
          )
        }}
        rowCount={transactionData?.cashTransactions?.totalPageCount || 0}
        state={{
          pagination,
          showSkeletons: loadingTransaction
        }}
        initialState={{
          density: 'compact',
          columnVisibility: {eventId: false, eventTime: false}
        }}
      />
      {chartModalOpen && (
        <ChartDataForm
          modalOpen={chartModalOpen}
          setModalOpen={setChartModalOpen}
          data={chartData}
        />
      )}
    </>
  )
}

export default CashTransactionListing
