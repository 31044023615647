import classes from 'ui/atoms/BouncingLoader/bouncing-loader.styles.module.css'

const BouncingDotsLoader = () => {
  return (
    <div className={classes.bouncingLoader}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default BouncingDotsLoader
