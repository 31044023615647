import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Step,
  StepLabel,
  Stepper,
  styled,
  SwipeableDrawer
} from '@mui/material'
import nfcTapImage from 'assets/nfc-tap.png'

import ConsumerAgentAddressInput from 'consumerAgent/ConsumerAgentAddressInput'
import {ConsumerAgentPaymentMethods} from 'consumerAgent/ConsumerAgentPayments'
import StyledButton from 'ui/atoms/StyledButton'
import StripeCheckout from 'consumerAgent/StripeCheckout'
import theme, {colors, space} from 'config/theme'
import React from 'react'
import QRCode from 'react-qr-code'
import useConsumerAgent, {
  ConsumerAgentCartTransferMethods,
  ConsumerAgentCheckoutSteps,
  defaultPaymentMetadata
} from 'consumerAgent/ConsumerAgentContext'

const Puller = styled('div')(({theme}) => ({
  width: 30,
  height: 6,
  backgroundColor: colors.grayBorder,
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)'
}))

const OrderedList = ({items}: any) => {
  return (
    <List>
      {items.map((item: any, index: any) => (
        <ListItem key={index}>
          <ListItemIcon
            sx={{
              minWidth: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid black',
              borderRadius: '50%',
              backgroundColor: theme.palette.primary.dark,
              color: 'white',
              marginRight: space.MEDIUM
            }}
          >
            {index + 1}
          </ListItemIcon>

          {item}
        </ListItem>
      ))}
    </List>
  )
}

const steps = ['Delivery', 'Payment']
const cashPaymentInstructions = [
  'Head to any cashier',
  'Let them know you want to transfer your cart',
  'Complete your purchase'
]

const ConsumerAgentBottomDrawer = () => {
  const {
    isBottomDrawerOpen,
    setIsBottomDrawerOpen,
    selectedPaymentMethod,
    selectedCheckoutStep,
    setSelectedCheckoutStep,
    cartTransferMethod,
    setCartTransferMethod,
    address,
    cartId,
    setPaymentMetadata,
    paymentMetadata
  } = useConsumerAgent()

  const stripeRef = React.useRef(null)

  const submitPayment = () => {
    if (selectedPaymentMethod === ConsumerAgentPaymentMethods.CARD) {
      if (stripeRef.current) {
        return (stripeRef.current as any)?.submitPaymentForm?.()
      }
    }
  }

  const renderPaymentOptions = () => {
    if (selectedPaymentMethod === ConsumerAgentPaymentMethods.CASH) {
      return (
        <Grid
          item
          style={{
            backgroundColor: theme.palette.background.paper,
            padding: space.SMALL,
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'center',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'stretch',
            borderRadius: '12px',
            border: '1px solid black',
            width: '100%'
          }}
        >
          <Grid
            xs={5}
            item
            gap={1}
            style={{
              alignContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            {cartTransferMethod === ConsumerAgentCartTransferMethods.NFC_TAP ? (
              <img
                src={nfcTapImage}
                alt='cash-payment'
                style={{
                  maxHeight: '130px',
                  height: 'auto',
                  marginTop: space.SMALL,
                  border: '1px solid',
                  borderColor: colors.grayBorder,
                  borderRadius: '12px',
                  boxShadow: '0px 0px 3.308px 0px rgba(0, 0, 0, 0.15)'
                }}
              />
            ) : (
              <QRCode size={130} value={`${cartId}`} />
            )}

            <StyledButton
              onClick={() => {
                setCartTransferMethod(
                  cartTransferMethod ===
                    ConsumerAgentCartTransferMethods.QR_CODE
                    ? ConsumerAgentCartTransferMethods.NFC_TAP
                    : ConsumerAgentCartTransferMethods.QR_CODE
                )
              }}
              size='small'
              startIcon={
                <FontAwesomeIcon
                  icon={icon({
                    name: 'repeat',
                    family: 'classic',
                    style: 'light'
                  })}
                  style={{
                    fontSize: '24px'
                  }}
                />
              }
              style={{
                backgroundColor: theme.palette.primary.dark
              }}
            >
              {cartTransferMethod === ConsumerAgentCartTransferMethods.QR_CODE
                ? 'NFC Tap'
                : 'QR Code'}
            </StyledButton>
          </Grid>

          <Grid item xs={7}>
            <OrderedList items={cashPaymentInstructions} />
          </Grid>
        </Grid>
      )
    }

    if (selectedPaymentMethod === ConsumerAgentPaymentMethods.CARD) {
      return <StripeCheckout ref={stripeRef} />
    }
  }

  return (
    <SwipeableDrawer
      anchor='bottom'
      open={isBottomDrawerOpen}
      onClose={() => {
        setIsBottomDrawerOpen(false)
        setSelectedCheckoutStep(ConsumerAgentCheckoutSteps.DELIVERY)
        setPaymentMetadata(defaultPaymentMetadata)
      }}
      onOpen={() => {
        setIsBottomDrawerOpen(true)
        setSelectedCheckoutStep(ConsumerAgentCheckoutSteps.DELIVERY)
      }}
      ModalProps={{
        keepMounted: true
      }}
      PaperProps={{
        sx: {
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          width: '100%'
        }
      }}
    >
      <Puller />
      <Grid
        style={{
          padding: space.MEDIUM,

          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
        width={'100%'}
        gap={2}
      >
        <Grid
          style={{
            padding: space.MEDIUM,
            alignSelf: 'center'
          }}
          width={'80%'}
        >
          <Stepper activeStep={selectedCheckoutStep}>
            {steps.map((label, index) => {
              const stepProps: {completed?: boolean} = {}
              const labelProps: {
                optional?: React.ReactNode
              } = {}

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Grid>

        {selectedCheckoutStep === ConsumerAgentCheckoutSteps.DELIVERY && (
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Grid item>
              <ConsumerAgentAddressInput />
            </Grid>

            <Grid
              item
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: space.MEDIUM
              }}
            >
              <StyledButton
                disabled={!address}
                onClick={() =>
                  setSelectedCheckoutStep(ConsumerAgentCheckoutSteps.PAYMENT)
                }
                style={{
                  marginTop: space.MEDIUM
                }}
              >
                Confirm
              </StyledButton>
            </Grid>
          </Grid>
        )}

        {selectedCheckoutStep === ConsumerAgentCheckoutSteps.PAYMENT && (
          <Grid
            container
            spacing={2}
            width={'100%'}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <Grid item xs={12}>
              {renderPaymentOptions()}
            </Grid>

            <Grid
              item
              container
              spacing={2}
              width={'100%'}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              {!paymentMetadata.isPaymentSuccessful ? (
                <>
                  <Grid item xs={6}>
                    <StyledButton
                      fullWidth
                      onClick={() =>
                        setSelectedCheckoutStep(
                          ConsumerAgentCheckoutSteps.DELIVERY
                        )
                      }
                      style={{
                        marginTop: space.MEDIUM
                      }}
                    >
                      Back
                    </StyledButton>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton
                      fullWidth
                      onClick={() => {
                        submitPayment()
                      }}
                      style={{
                        marginTop: space.MEDIUM
                      }}
                    >
                      Confirm
                    </StyledButton>
                  </Grid>
                </>
              ) : (
                <Grid item xs={6}>
                  <StyledButton
                    fullWidth
                    onClick={() => {
                      setIsBottomDrawerOpen(false)
                      setSelectedCheckoutStep(
                        ConsumerAgentCheckoutSteps.DELIVERY
                      )
                      setPaymentMetadata(defaultPaymentMetadata)
                    }}
                    style={{
                      marginTop: space.MEDIUM
                    }}
                  >
                    Close
                  </StyledButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </SwipeableDrawer>
  )
}

export default ConsumerAgentBottomDrawer
