import {useMutation, useSubscription} from '@apollo/client'
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import {
  CustomerType,
  SubscriptionItemType
} from 'graphql/generatedTypes/graphql'
import {
  APPROVE_ITEM_SUBSCRIPTION,
  VALIDATE_CUSTOMER_SIGNUP_TOKEN
} from 'graphql/mutations/customer.mutation'
import {CART_SUBSCRIPTION_EVENTS} from 'graphql/subscriptions/customers.subscription'
import useNotify from 'hooks/useNotify'
import {useEffect, useState} from 'react'
import React from 'react'
import {useTranslation} from 'react-i18next'
import StyledButton from 'ui/atoms/StyledButton'
import Loading from 'ui/molecules/Loading'

interface SubscriptionTableProps {
  subscriptionItems: SubscriptionItemType[]
  handleApprove: (sku: string) => void
}

const CustomerSubscriptions = () => {
  const {t} = useTranslation()
  const notify = useNotify()
  const urlParams = new URLSearchParams(window.location.search)
  const tokenParam = urlParams.get('token') || ''
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false)
  const [customer, setCustomer] = useState<CustomerType | null>(null)
  const [cartId, setCartId] = useState<string>('')
  const [subscriptionItems, setSubscriptionItems] = useState<
    SubscriptionItemType[]
  >([])

  useEffect(() => {
    validateTokenMutation({variables: {token: tokenParam, checkUsage: false}})
  }, [])

  const handleTokenValidationError = (error: Error) => {
    notify.show(error.message, 'error')
    setIsTokenValid(false)
  }

  const [validateTokenMutation, {loading: validatingToken, data}] = useMutation(
    VALIDATE_CUSTOMER_SIGNUP_TOKEN,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setIsTokenValid(true)
        setCustomer(
          data.validateCustomerSignupToken.cart?.customer as CustomerType
        )
        setCartId(data.validateCustomerSignupToken.cart?.id as string)
      },
      onError: handleTokenValidationError
    }
  )

  useSubscription(CART_SUBSCRIPTION_EVENTS, {
    variables: {
      token: tokenParam
    },
    shouldResubscribe: true,
    onError: (error) => {
      console.error('Error in customer cart subscription', error)
    },
    onData: (data) => {
      setSubscriptionItems(
        (data?.data?.data?.customerCartSubscriptionEvents?.cart
          ?.subscriptionItems || []) as SubscriptionItemType[]
      )
    }
  })

  const [approveItemSubscription] = useMutation(APPROVE_ITEM_SUBSCRIPTION, {
    onError: (error) => {
      notify.show(error.message, 'error')
    }
  })

  const handleApprove = (sku: string) => {
    approveItemSubscription({
      variables: {
        cartId,
        sku: sku,
        token: tokenParam
      }
    })
  }

  if (!isTokenValid) {
    if (validatingToken) return <Loading />
    return (
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh'
        }}
      >
        <Typography variant='h2'>
          {t('customer-management.loyalty-signup.invalid-token')}
        </Typography>
      </Grid>
    )
  }

  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '10px',
        background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)'
      }}
    >
      <Box
        sx={{
          padding: {xs: '20px', sm: '40px'},
          backgroundColor: '#fff',
          borderRadius: '12px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          maxWidth: '100%',
          width: {xs: '300px', sm: '500px'}
        }}
      >
        <Grid item xs={12}>
          <Typography variant='h2' align='center' gutterBottom>
            Subscriptions
          </Typography>

          <Typography variant='subtitle2' align='center' gutterBottom>
            {data?.validateCustomerSignupToken?.cart?.location?.name}
          </Typography>

          <Typography variant='body1' align='center' paragraph>
            Cart ID: {data?.validateCustomerSignupToken?.cart?.id}
          </Typography>

          <Box mb={2} textAlign='center'>
            <Typography variant='body1'>
              Customer: {customer?.firstName || 'Unknown'}{' '}
              {customer?.lastName || ''}
            </Typography>
            <Typography variant='body1'>Email: {customer?.email}</Typography>
          </Box>

          <SubscriptionTable
            subscriptionItems={subscriptionItems}
            handleApprove={handleApprove}
          />
        </Grid>
      </Box>
    </Grid>
  )
}

const SubscriptionTable: React.FC<SubscriptionTableProps> = ({
  subscriptionItems,
  handleApprove
}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='subscription table' style={{minWidth: 'auto'}}>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>SKU</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Frequency</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptionItems.map((item) => (
            <TableRow key={item.sku}>
              <TableCell>
                {item.status === 'pending' ? (
                  <StyledButton
                    variant='text'
                    color='primary'
                    size='small'
                    onClick={() => handleApprove(item.sku)}
                  >
                    Subscribe
                  </StyledButton>
                ) : (
                  <Typography
                    variant='h4'
                    style={{
                      textTransform: 'capitalize',
                      textAlign: 'center'
                    }}
                    color={'green'}
                  >
                    {item.status}
                  </Typography>
                )}
              </TableCell>
              <TableCell>{item.description}</TableCell>
              <TableCell>{item.sku}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>{item.total}</TableCell>
              <TableCell>{item.frequency}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CustomerSubscriptions
