import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Typography
} from '@mui/material'
import {ReactComponent as SubMeuIcon} from 'assets/submenu-item.svg'
import {ReactComponent as SubMenuLastItemIcon} from 'assets/submenu-last-item.svg'
import {useAuth} from 'auth/AuthContext'
import {
  NavItem,
  NavItemText,
  NavSubItem
} from 'ui/organisms/navigationDrawer/NavigationBlocks'
import theme, {space} from 'config/theme'
import {LocalStorageKeys} from 'constants/constants'
import {Permission} from 'constants/permissions'
import useLocalStorage from 'hooks/useLocalStorage'
import _ from 'lodash'
import {Fragment, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useHistory, useLocation} from 'react-router-dom'
import classes from 'ui/organisms/navigationDrawer/navigation-drawer.styles.module.css'
import Beta from 'ui/atoms/Beta'

export const drawerWidth = 290
export const collapsedWidth = 70

interface NavigationProps {
  isMobileScreen: boolean
}

function Navigation({isMobileScreen}: NavigationProps) {
  const {t} = useTranslation('commons')

  const history = useHistory()

  const {authInfo} = useAuth()

  const [businessName, setBusinessName] = useState(authInfo.businessName)
  const [businessLogo, setBusinessLogo] = useState(authInfo.businessLogo)

  const [businessId] = useLocalStorage(LocalStorageKeys.BUSINESS_ID)

  const [navigationOpenFlags, setNavigationOpenFlags] = useLocalStorage(
    LocalStorageKeys.NAVIGATION_OPEN_MENU_FLAGS,
    [false]
  )
  useEffect(() => {
    if (!authInfo.businessName) return
    setBusinessName(authInfo.businessName)
  }, [authInfo.businessName])

  useEffect(() => {
    if (!authInfo.businessLogo) return
    setBusinessLogo(authInfo.businessLogo)
  }, [authInfo.businessLogo])

  const basePath = (path: string) =>
    `/${businessId || authInfo.businessId}/${path}`

  const navOptions: any = []

  const getGrowthAndSalesMenuItems = () => {
    const growthAndSalesMenuItems = []

    if (
      authInfo.featureFlags?.orders &&
      authInfo.permissions?.includes(Permission.MANAGE_ORDERS)
    ) {
      growthAndSalesMenuItems.push({
        name: t('navigationDrawer.growth-and-sales.transactions'),
        url: basePath('transactions')
      })
    }

    if (
      authInfo.featureFlags?.promotions &&
      authInfo.permissions?.includes(Permission.MANAGE_PROMOTIONS)
    ) {
      growthAndSalesMenuItems.push({
        name: t('navigationDrawer.growth-and-sales.promotions'),
        url: basePath('promotions')
      })
    }

    if (
      authInfo.featureFlags?.promotions &&
      authInfo.permissions?.includes(Permission.MANAGE_RULES)
    ) {
      growthAndSalesMenuItems.push({
        name: t('navigationDrawer.growth-and-sales.rules'),
        url: basePath('rules')
      })
    }

    return growthAndSalesMenuItems
  }

  if (getGrowthAndSalesMenuItems()?.length) {
    navOptions.push({
      name: t('navigationDrawer.growth-and-sales.overview'),
      icon: (
        <FontAwesomeIcon
          icon={icon({
            name: 'chart-line-up',
            family: 'classic',
            style: 'regular'
          })}
          fontSize={20}
          color={theme.palette.primary.main}
          style={{
            borderWidth: '1px'
          }}
        />
      ),

      children: getGrowthAndSalesMenuItems()
    })
  }

  if (
    authInfo.featureFlags?.cashManagement &&
    authInfo.permissions?.includes(Permission.MANAGE_CASH_MANAGEMENT)
  ) {
    const children = []
    children.push({
      name: t('navigationDrawer.cashManagement.transaction'),
      url: basePath('cash-management/transaction')
    })
    if (authInfo?.permissions?.includes(Permission.CREATE_CASH_TRANSACTION)) {
      children.push({
        name: t('navigationDrawer.cashManagement.failure-logs'),
        url: basePath('cash-management/failure-logs')
      })
    }
    navOptions.push({
      name: t('navigationDrawer.cashManagement.overview'),
      url: basePath('cash-management/summary'),
      icon: (
        <FontAwesomeIcon
          icon={icon({
            name: 'money-bill-trend-up',
            family: 'classic',
            style: 'regular'
          })}
          fontSize={20}
          color={theme.palette.primary.main}
          style={{
            borderWidth: '1px'
          }}
        />
      ),
      children: children
    })
  }

  if (
    authInfo.featureFlags?.peripheralHub &&
    authInfo.permissions?.includes(Permission.MANAGE_PERIPHERAL_HUB)
  ) {
    navOptions.push({
      name: t('navigationDrawer.peripheralHub.overview'),
      url: basePath('peripheral-hub/overview'),
      icon: (
        <FontAwesomeIcon
          icon={icon({
            name: 'house-chimney-user',
            family: 'classic',
            style: 'regular'
          })}
          fontSize={20}
          color={theme.palette.primary.main}
          style={{
            borderWidth: '1px'
          }}
        />
      ),
      children: [
        {
          name: t('navigationDrawer.peripheralHub.devices'),
          url: basePath('peripheral-hub/devices')
        },
        {
          name: t('navigationDrawer.peripheralHub.notification'),
          url: basePath('peripheral-hub/notifications')
        }
      ]
    })
  }

  if (
    authInfo.featureFlags?.inventory &&
    authInfo.permissions?.includes(Permission.MANAGE_INVENTORY)
  ) {
    navOptions.push({
      name: t('navigationDrawer.inventory.overview'),
      url: basePath('inventory-management'),
      icon: (
        <FontAwesomeIcon
          icon={icon({
            name: 'bag-shopping',
            family: 'classic',
            style: 'regular'
          })}
          fontSize={20}
          color={theme.palette.primary.main}
          style={{
            borderWidth: '1px'
          }}
        />
      )
    })
  }

  if (
    authInfo.featureFlags?.enableCustomerProfile &&
    authInfo.permissions?.includes(Permission.MANAGE_CUSTOMERS)
  ) {
    navOptions.push({
      name: t('navigationDrawer.customers.overview'),
      url: basePath('customers'),
      icon: (
        <FontAwesomeIcon
          icon={icon({
            name: 'book-user',
            family: 'classic',
            style: 'regular'
          })}
          fontSize={20}
          color={theme.palette.primary.main}
          style={{
            borderWidth: '1px'
          }}
        />
      )
    })
  }

  if (authInfo.featureFlags?.enableAnalyticsAgent) {
    navOptions.push({
      name: (
        <>
          Analytics Agent <Beta />
        </>
      ),
      url: basePath('analytics-agent'),
      icon: (
        <FontAwesomeIcon
          icon={icon({
            name: 'analytics',
            family: 'classic',
            style: 'regular'
          })}
          fontSize={20}
          color={theme.palette.primary.main}
          style={{
            borderWidth: '1px'
          }}
        />
      )
    })
  }

  if (
    authInfo.featureFlags?.enableInsights &&
    authInfo.permissions?.includes(Permission.MANAGE_INSIGHTS)
  ) {
    navOptions.push({
      name: t('navigationDrawer.insights.overview'),
      url: basePath('insights'),
      icon: (
        <FontAwesomeIcon
          icon={icon({
            name: 'chart-line',
            family: 'classic',
            style: 'regular'
          })}
          fontSize={20}
          color={theme.palette.primary.main}
          style={{
            borderWidth: '1px'
          }}
        />
      )
    })
  }

  const {pathname} = useLocation()

  useEffect(() => {
    setCollapse(isMobileScreen)
  }, [isMobileScreen])

  const [openFlags, setOpenFlags] = useState(
    Array(navOptions.length).fill(false)
  )

  useEffect(() => {
    setOpenFlags(navigationOpenFlags || [])
  }, [navOptions.length])

  useEffect(() => {
    if (!openFlags.length) return

    setNavigationOpenFlags(openFlags)
  }, [openFlags])

  let [collapse, setCollapse] = useState(false)

  const handleExpandMenu = (index: number) => {
    let newOpenFlags = [...openFlags]
    newOpenFlags[index] = !newOpenFlags[index]
    setOpenFlags(newOpenFlags)

    if (collapse && navOptions[index]?.children) {
      setCollapse(false)
    }
  }

  return (
    <Drawer
      sx={{
        width: collapse ? collapsedWidth : drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: collapse ? collapsedWidth : drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: 'white',
          transition: 'width 0.7s',
          transitionTimingFunction: 'ease-out'
        },
        transition: 'width 0.7s',
        transitionTimingFunction: 'ease-out'
      }}
      variant='permanent'
      anchor='left'
    >
      <List className={classes.listSection}>
        <Grid>
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: space.MEDIUM,
              marginTop: space.XS,
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <Avatar
              style={{marginRight: space.SMALL}}
              src={businessLogo as string}
            />
            {!collapse && (
              <Typography variant='h2' style={{marginLeft: '10px'}}>
                {businessName}
              </Typography>
            )}
          </Grid>

          {navOptions?.map((navOption: any, index: number) => {
            if (navOption.children) {
              return (
                <div
                  key={index}
                  className={classes.navigationItemWrapper}
                  style={{
                    flexDirection: collapse ? 'inherit' : 'column'
                  }}
                >
                  <Divider
                    style={{
                      marginBottom: space.XXS
                    }}
                  />
                  <NavItem
                    id={`${_.camelCase(navOption.name)}MenuItem`}
                    key={index}
                    icon={navOption.icon}
                    collapse={collapse}
                    selected={navOption.children.some((child: any) =>
                      pathname.startsWith(child.url)
                    )}
                    to={
                      navOption.url || navOption.children[0]?.url || undefined
                    }
                    component={
                      navOption.url || navOption.children[0]?.url
                        ? Link
                        : undefined
                    }
                    onClick={() => {
                      !openFlags[index] && handleExpandMenu(index)
                    }}
                  >
                    <NavItemText
                      className={
                        collapse
                          ? classes.collapsedNavItemText
                          : classes.navItemText
                      }
                    >
                      <Typography className={classes.mainNavItemText}>
                        {navOption.name}
                      </Typography>
                    </NavItemText>

                    <IconButton
                      onClick={(e) => {
                        e.preventDefault()
                        handleExpandMenu(index)
                      }}
                      size='small'
                    >
                      {openFlags[index] && !collapse ? (
                        <FontAwesomeIcon
                          icon={icon({
                            name: 'chevron-up',
                            family: 'classic',
                            style: 'solid'
                          })}
                          color={theme.palette.text.secondary}
                          className={
                            collapse ? classes.collapseIcon : classes.expandIcon
                          }
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={icon({
                            name: 'chevron-down',
                            family: 'classic',
                            style: 'solid'
                          })}
                          color={theme.palette.text.secondary}
                          className={
                            collapse ? classes.collapseIcon : classes.expandIcon
                          }
                        />
                      )}
                    </IconButton>
                  </NavItem>
                  {!collapse && (
                    <Collapse
                      in={openFlags[index]}
                      timeout='auto'
                      unmountOnExit
                    >
                      <List component='div' disablePadding>
                        {navOption.children.map(
                          (child: any, childIndex: any) => {
                            return (
                              <NavSubItem
                                id={`${_.camelCase(child.name)}SubMenuItem`}
                                key={childIndex}
                                component={Link}
                                to={child.url}
                                icon={
                                  childIndex ===
                                  navOption.children.length - 1 ? (
                                    <SubMenuLastItemIcon />
                                  ) : (
                                    <SubMeuIcon />
                                  )
                                }
                                selected={pathname.startsWith(child.url)}
                                onClick={() => {
                                  setCollapse(isMobileScreen)
                                }}
                              >
                                <NavItemText>
                                  <Typography
                                    className={classes.childNavItemText}
                                  >
                                    {child.name}
                                  </Typography>
                                </NavItemText>
                              </NavSubItem>
                            )
                          }
                        )}
                      </List>
                    </Collapse>
                  )}
                </div>
              )
            }
            return (
              <Fragment key={index}>
                <Divider
                  style={{
                    marginBottom: space.XXS
                  }}
                />
                <div key={index} className={classes.navigationItemWrapper}>
                  <NavItem
                    id={`${_.camelCase(navOption.name)}MenuItem`}
                    component={Link}
                    to={navOption.url}
                    collapse={collapse}
                    icon={navOption.icon}
                    selected={pathname.startsWith(navOption.url)}
                  >
                    <NavItemText
                      className={
                        collapse
                          ? classes.collapsedNavItemText
                          : classes.navItemText
                      }
                    >
                      <Typography className={classes.mainNavItemText}>
                        {navOption.name}
                      </Typography>
                    </NavItemText>
                  </NavItem>
                </div>
              </Fragment>
            )
          })}
        </Grid>

        <Grid
          container
          item
          style={{
            display: 'flex',
            flexDirection: collapse ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Grid
              item
              container
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                history.push(basePath('settings'))
              }}
            >
              <FontAwesomeIcon
                icon={icon({
                  name: 'gear',
                  family: 'classic',
                  style: 'solid'
                })}
                fontSize={20}
                color='black'
              />
              {!collapse && (
                <Typography
                  variant='h3'
                  style={{
                    marginLeft: '5px'
                  }}
                >
                  Settings
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </List>
    </Drawer>
  )
}

export default Navigation
