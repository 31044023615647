import {Box} from '@mui/material'
import {LoginCallback, Security} from '@okta/okta-react'
import env from 'constants/env'
import useAdminOktaAuth from 'hooks/useAdminOktaAuth'
import React from 'react'
import {Route, Switch, useLocation} from 'react-router-dom'

import AdminNavigationDrawer from 'ui/organisms/navigationDrawer/AdminNavigationDrawer'
import Loading from 'ui/molecules/Loading'
import LoginRequired from 'adminPanel/LoginRequired'
import {drawerWidth} from 'ui/organisms/navigationDrawer/NavigationDrawer'
import NotFound from 'ui/organisms/NotFound'
import {space} from 'config/theme'
import AdminClientRoutes from './AdminRoutes'

function AdminOktaRouteHandler() {
  const {pathname} = useLocation()
  const isLoginCallback = pathname === '/admin/login/callback'

  const adminOktaConfig = {
    oktaIssuer: env.REACT_APP_ADMIN_OKTA_ISSUER,
    oktaClientId: env.REACT_APP_ADMIN_OKTA_CLIENT_ID
  }

  const {oktaConfig, signOut, onAuthRequired, restoreOriginalUri} =
    useAdminOktaAuth()

  if (isLoginCallback && !window.location.search) {
    return <Loading />
  }

  if (!oktaConfig) {
    if (adminOktaConfig?.oktaClientId) {
      return <Loading />
    }
    return <LoginRequired />
  }

  return (
    <Security
      oktaAuth={oktaConfig}
      restoreOriginalUri={restoreOriginalUri}
      onAuthRequired={onAuthRequired}
    >
      <Box sx={{display: 'flex', height: '100%'}}>
        {!isLoginCallback && <AdminNavigationDrawer signOut={signOut} />}
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: 'white',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: space.MEDIUM,
            width: `calc(95% - ${drawerWidth}px)`
          }}
        >
          <Box
            sx={{
              backgroundColor: 'white'
            }}
          >
            <Switch>
              <Route path={`/admin/login/callback`} component={LoginCallback} />
              <Route path={'/admin'}>
                <AdminClientRoutes signOut={signOut} />
              </Route>
              <Route path={'*'} component={NotFound} />
            </Switch>
          </Box>
        </Box>
      </Box>
    </Security>
  )
}

export default AdminOktaRouteHandler
