import {Chip, ChipProps} from '@mui/material'
import React from 'react'
import theme from 'config/theme'

type StyledChipProps = ChipProps

const StyledChip: React.FC<StyledChipProps> = (props) => {
  return (
    <Chip
      sx={{
        color: theme.palette.text.primary,
        '&.MuiChip-root': {
          backgroundColor: theme.palette.primary.light,

          '&.MuiChip-colorPrimary': {
            backgroundColor: theme.palette.primary.light
          },
          '&.MuiChip-colorSecondary': {
            backgroundColor: theme.palette.primary.light
          },
          '&.MuiChip-colorError': {
            backgroundColor: theme.palette.error.light
          },
          '&.MuiChip-colorWarning': {
            backgroundColor: theme.palette.warning.light
          },
          '&.MuiChip-colorInfo': {
            backgroundColor: theme.palette.info.light
          },
          '&.MuiChip-colorSuccess': {
            backgroundColor: theme.palette.success.light
          }
        }
      }}
      {...props}
    />
  )
}

export default StyledChip
