import {gql} from '@apollo/client'

// To fetch till level data
export const GET_TILL_TRANSACTIONS = gql`
  query TILL_TRANSACTIONS(
    $register: String
    $till: String
    $date: String!
    $locationId: String!
  ) {
    tillTransactions(
      till: $till
      register: $register
      date: $date
      locationId: $locationId
    ) {
      nodes {
        id
        date
        openTime
        closeTime
        netCash
        status
        till
        totalCash
        register
        employeeCode
        employee {
          name
        }
        defaultStartingFloat
        tillAmountUpperLimit
      }
      registerTotalCash
      registerNetCash
      activeTill
    }
  }
`

export const GET_TRANSACTION_SUMMARY = gql`
  query TRANSACTION_SUMMARY(
    $register: String
    $till: String
    $date: String!
    $locationId: String!
  ) {
    tillTransactionsSummary(
      till: $till
      register: $register
      date: $date
      locationId: $locationId
    ) {
      cash
      sale
      cashAdjustment
      cashPickup
      paidIn
      paidOut
      tillFloat
      open
      tillClose
      reconcile
      transfer
      refund
      cashDrop
      startingDate
      endingDate
      registerTotalCash
      safeTotalCash
      storeTotalCash
      reserveTotalCash
    }
  }
`

export const GET_TRANSACTIONS = gql`
  query TRANSACTIONS(
    $register: String
    $till: String
    $dateRange: [String!]!
    $locationId: String!
    $employeeCode: String
    $transactionType: String
    $pageIndex: Int
    $pageSize: Int
  ) {
    cashTransactions(
      till: $till
      register: $register
      dateRange: $dateRange
      locationId: $locationId
      employeeCode: $employeeCode
      transactionType: $transactionType
      pageIndex: $pageIndex
      pageSize: $pageSize
    ) {
      totalPageCount
      nodes {
        amount
        closeTime
        eventTime
        dateCreated
        description
        employeeCode
        eventType
        id
        openTime
        register
        storeNumber
        till
        transactionType
        transactionId
        transferTo
        transferToTill
        transactionTime
        eventId
        safeType
        isSafeTransaction
        source
        employee {
          name
        }
      }
    }
  }
`

export const GET_REGISTER_STATUS = gql`
  query GET_STATUS($locationId: String!, $businessId: String!) {
    getTillStatus(locationId: $locationId, businessId: $businessId)
  }
`

export const GET_BANK_DEPOSITS = gql`
  query BANK_DEPOSITS(
    $locationId: String!
    $dateRange: [String!]!
    $pageIndex: Int
    $pageSize: Int
  ) {
    getBankDeposits(
      locationId: $locationId
      dateRange: $dateRange
      pageIndex: $pageIndex
      pageSize: $pageSize
    ) {
      totalPageCount
      nodes {
        id
        amount
        createdAt
        updatedAt
        depositNumber
        depositReceipt
        employee {
          name
        }
        status
      }
    }
  }
`

export const AGGREGRATE_CASH_TRANSACTIONS = gql`
  query AGGREGRATE_CASH_TRANSACTIONS(
    $storeNumbers: [String!]
    $locationIds: [String!]
    $transactionTimeRange: [String!]
    $eventType: [String!]
  ) {
    aggregrateCashTransactions(
      storeNumbers: $storeNumbers
      locationIds: $locationIds
      transactionTimeRange: $transactionTimeRange
      eventType: $eventType
    ) {
      cashSalesStats {
        cashSales
        percentageChange
      }
      averageTransactionValueStats {
        averageTransactionValue
        percentageChange
      }
      cashVarianceStats {
        cashVariance
        percentageChange
      }
    }
  }
`

export const STATS_FOR_VARIANCE_REPORT = gql`
  query VARIANCE_REPORT(
    $storeNumbers: [String!]
    $locationIds: [String!]
    $transactionTimeRange: [String!]
    $employeeCodes: [String!]
    $eventType: [String!]
  ) {
    aggregrateCashTransactions(
      storeNumbers: $storeNumbers
      locationIds: $locationIds
      transactionTimeRange: $transactionTimeRange
      employeeCodes: $employeeCodes
      eventType: $eventType
    ) {
      totalExpectedCashStats {
        totalExpectedCash
        percentageChange
      }
      totalActualCashStats {
        totalActualCash
        percentageChange
      }
      totalVarianceStats {
        totalVariance
        percentageChange
      }
      averageVariancePercentageStats {
        averageVariancePercentage
        percentageChange
      }
    }
  }
`
