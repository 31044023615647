import {createSelector} from '@reduxjs/toolkit'

import type {RootState} from 'clientDashboard/clientDashboard.store'
import _find from 'lodash/find'
import {AppliedGroups} from 'clientDashboard/employee.slice'

export const selectPermissions = createSelector(
  (state: RootState) => state.employee.role?.permissions,
  (permissions) => permissions || []
)

export const selectBusinessId = (state: RootState) =>
  state.business.businessId || ''

export const selectLocationId = (state: RootState) =>
  state.employee.selectedLocationId || ''

export const selectBusinessAndLocation = createSelector(
  selectBusinessId,
  selectLocationId,
  (businessId, locationId) => ({
    businessId,
    locationId
  })
)

export const selectSelectedLocationId = (state: RootState) =>
  state.employee.selectedLocationId || ''

export const selectIsAuthenticated = (state: RootState) =>
  state.employee?.isAuthenticated || false

export const selectCurrentStore = (state: RootState) => {
  return _find(state.employee.locations, {
    storeNumber: state.employee.selectedStoreNumber
  })
}

export const selectClusters = createSelector(
  (state: RootState) => state.employee?.clusters,
  (clusters) => clusters || []
)

export const selectAppliedLocationGroups = createSelector(
  (state: RootState) => state.employee?.appliedLocationGroups,
  (groups) => {
    return groups || ({} as AppliedGroups)
  }
)

export const selectSelectedLocationGroupIds = createSelector(
  [selectAppliedLocationGroups, selectClusters],
  (appliedLocationGroups, clusters) => {
    let selectedLocationIds = [] as string[]
    if (appliedLocationGroups['Location']) {
      selectedLocationIds = appliedLocationGroups['Location'].map(
        (storeGroup) => storeGroup.id
      )
    }
    if (clusters.length < 2) {
      return {
        locationIds:
          selectedLocationIds.length === 0 ? null : selectedLocationIds,
        storeGroupIds: null
      }
    }
    let lowestLevelClusterName = clusters[clusters.length - 2].name
    let selectedStoreGroupIds: string[] = []
    if (appliedLocationGroups[lowestLevelClusterName]) {
      selectedStoreGroupIds = appliedLocationGroups[lowestLevelClusterName].map(
        (storeGroup) => storeGroup.id
      )
    }
    if (selectedLocationIds.length > 0) {
      return {
        locationIds: selectedLocationIds,
        storeGroupIds: null
      }
    }
    return {
      locationIds: null,
      storeGroupIds:
        selectedStoreGroupIds.length === 0 ? null : selectedStoreGroupIds
    }
  }
)
