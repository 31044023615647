import _groupBy from 'lodash/groupBy'
import _forEach from 'lodash/forEach'
import _filter from 'lodash/filter'
import _intersectionBy from 'lodash/intersectionBy'
import {Cluster, Group, Location2} from 'clientDashboard/employee.slice'
import {StoreGroupClusters} from 'clientDashboard/constants/employee.constants'
import {LocationType, StoreGroupType} from 'graphql/generatedTypes/graphql'
import _map from 'lodash/map'
import _size from 'lodash/size'
import _last from 'lodash/last'

type AppliedGroupsWithOptions = {
  clusters: Cluster[]
  groups: Group[]
  locations: Location2[]
  updatedGroups: Record<string, Group[]>
}

export const getActualGroupOptionsAndGroups = ({
  clusters,
  groups,
  locations,
  updatedGroups
}: AppliedGroupsWithOptions) => {
  const groupOptions = _groupBy(groups, 'groupLevel.name')
  const locationOptions = locations || []

  const appliedSet = new Set()
  _forEach(updatedGroups, (value, key) => {
    _forEach(value, ({id}) => {
      appliedSet.add(`${key}+++${id}`)
    })
  })

  const optionMap = {} as any
  _forEach(clusters, (cluster, index) => {
    const allOptionsForCluster =
      cluster.name === StoreGroupClusters.Location
        ? locationOptions
        : groupOptions[cluster.name]
    if (index === 0) {
      optionMap[cluster.name] = allOptionsForCluster
      return
    }

    const parentCluster = clusters[index - 1]
    optionMap[cluster.name] = _filter(allOptionsForCluster, (option) => {
      const isParentApplied = appliedSet.has(
        `${parentCluster.name}+++${option.parentGroup.id}`
      )
      if (!isParentApplied) {
        appliedSet.delete(`${cluster.name}+++${option.id}`)
      }

      return isParentApplied
    })
  })

  const actualAppliedGroups = {} as any
  _forEach(Object.keys(updatedGroups), (key) => {
    actualAppliedGroups[key] = _intersectionBy(
      updatedGroups[key],
      optionMap[key],
      'id'
    )
  })

  return {
    clusterOptionsMap: optionMap,
    appliedLocationGroups: actualAppliedGroups
  }
}

export const withMappedGroups = (storeGroups: StoreGroupType[]): Group[] =>
  _map(storeGroups, (group) => ({
    id: group.id,
    name: group.name,
    groupLevel: {
      name: group.storeGroupCluster.name || '',
      hierarchyLevel: group.storeGroupCluster.hierarchyLevel
    },
    status: group.status,
    parentGroup: {
      id: group.parentStoreGroup?.id || ''
    }
  }))

export const withMappedLocations = (locations: LocationType[]): Location2[] =>
  _map(locations, (location) => {
    const hierarchyLevel = _size(location.storeGroups) + 1
    return {
      id: location.id,
      name: location.name,
      groupLevel: {
        name: StoreGroupClusters.Location,
        hierarchyLevel
      },
      status: location.status,
      parentGroup: {
        id: _last(location.storeGroups)?.id || ''
      }
    }
  })
