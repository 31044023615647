import {useMutation} from '@apollo/client'
import {Grid} from '@mui/material'
import {selectStoreGroupClusters} from 'clientDashboard/business.selectors'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {StoreGroupClusterType} from 'graphql/generatedTypes/graphql'
import {RENAME_STORE_GROUP_CLUSTER} from 'graphql/mutations/store-groups'
import useNotify from 'hooks/useNotify'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import StyledInput from 'ui/atoms/StyledInput'
import StyledDialog from 'ui/molecules/StyledDialog'

type RenameStoreGroupClusterProps = {
  onSuccessCallback: () => void
  onCloseOrCancelCallback: () => void
  storeGroupCluster: StoreGroupClusterType | undefined
}

const StoreGroupClusterRenameDialog = ({
  onSuccessCallback,
  onCloseOrCancelCallback,
  storeGroupCluster
}: RenameStoreGroupClusterProps) => {
  const [newClusterName, setNewClusterName] = useState('')
  const [inputDialogOpen, setInputDialogOpen] = useState(true)
  const storeGroupClusters = useAppSelector(selectStoreGroupClusters)
  const [errorText, setErrorText] = useState('')
  const notify = useNotify()
  const {t} = useTranslation()
  const [_renameStoreGroupCluster, {loading: loadingRenameStoreGroupCluster}] =
    useMutation(RENAME_STORE_GROUP_CLUSTER, {
      onCompleted: () => {
        onSuccessCallback()
        setInputDialogOpen(false)
      },
      onError: (error: any) => {
        onCloseOrCancelCallback()
        setInputDialogOpen(false)
        console.error('Error renaming store group cluster', error)
        notify.show(t('store-groups.rename-cluster.error-message'), 'error')
      }
    })

  const isUniqueName = (name: string) => {
    return !storeGroupClusters.some((cluster) => cluster.name === name)
  }

  const isValidNewClusterName = (name: string) => {
    if (!isUniqueName(name)) {
      setErrorText('Name already exists')
      return false
    }
    return true
  }

  const renameStoreGroupCluster = () => {
    _renameStoreGroupCluster({
      variables: {
        storeGroupClusterId: storeGroupCluster?.id as string,
        newClusterName: newClusterName
      }
    })
  }

  const loadRenameStoreGroupClusterDialogBody = () => {
    return (
      <Grid container spacing={4} padding={2}>
        <Grid item>
          <StyledInput
            fullWidth
            label='Current Group Name'
            value={storeGroupCluster?.name as string}
            disabled={true}
          />
        </Grid>
        <Grid item>
          <StyledInput
            fullWidth
            label='New Group Name'
            value={newClusterName}
            onChange={(e) => {
              setErrorText('')
              setNewClusterName(e.target.value)
              isValidNewClusterName(e.target.value)
            }}
            error={errorText !== ''}
            helperText={errorText === '' ? ' ' : errorText}
          />
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid>
      <StyledDialog
        open={inputDialogOpen}
        title='Update Group Name'
        onClose={() => {
          setInputDialogOpen(false)
          onCloseOrCancelCallback()
        }}
        body={loadRenameStoreGroupClusterDialogBody()}
        successButtonName='Update'
        cancelButtonName='Cancel'
        cancelCallback={() => {
          setInputDialogOpen(false)
          onCloseOrCancelCallback()
        }}
        successCallback={() => {
          renameStoreGroupCluster()
        }}
        disableSuccessButton={
          loadingRenameStoreGroupCluster || newClusterName === ''
        }
      ></StyledDialog>
    </Grid>
  )
}

export default StoreGroupClusterRenameDialog
