import {graphql} from 'graphql/generatedTypes'

export const RENAME_STORE_GROUP_CLUSTER = graphql(`
  mutation RenameStoreGroupCluster(
    $storeGroupClusterId: String!
    $newClusterName: String!
  ) {
    renameStoreGroupCluster(
      storeGroupClusterId: $storeGroupClusterId
      newClusterName: $newClusterName
    ) {
      name
      hierarchyLevel
    }
  }
`)

export const CREATE_STORE_GROUP = graphql(`
  mutation CreateStoreGroup(
    $name: String!
    $status: String
    $storeGroupClusterId: String!
    $childStoreGroupIds: [String!]!
    $childLocationIds: [String!]!
  ) {
    createStoreGroup(
      input: {
        name: $name
        status: $status
        storeGroupClusterId: $storeGroupClusterId
        childStoreGroupIds: $childStoreGroupIds
        childLocationIds: $childLocationIds
      }
    ) {
      id
      name
      status
      storeGroupCluster {
        id
        name
        hierarchyLevel
      }
    }
  }
`)

export const UPDATE_STORE_GROUP = graphql(`
  mutation UpdateStoreGroup(
    $id: String!
    $name: String!
    $status: String
    $childStoreGroupIds: [String!]!
    $childLocationIds: [String!]!
  ) {
    updateStoreGroup(
      input: {
        id: $id
        name: $name
        status: $status
        childStoreGroupIds: $childStoreGroupIds
        childLocationIds: $childLocationIds
      }
    ) {
      id
      name
      status
      storeGroupCluster {
        id
        name
        hierarchyLevel
      }
    }
  }
`)
