import type {RootState} from 'clientDashboard/clientDashboard.store'

export const selectErrorNotifications = (state: RootState) =>
  state.peripheralHub.errorNotifications.data || []

export const selectPaginationForErrorNotifications = (state: RootState) =>
  state.peripheralHub.errorNotifications.pagination || []

export const selectZones = (state: RootState) => state.peripheralHub.zones || []
export const selectRegisters = (state: RootState) => state.peripheralHub.registers || []
export const selectDevices = (state: RootState) => state.peripheralHub.devices || []

export const selectIsZoneEditMode = (state: RootState) => state.peripheralHub.isZoneEditMode || false

export const selectEditZoneModalData = (state: RootState) => state.peripheralHub.editZoneModal || null
export const selectHasEditZoneModalData = (state: RootState) => !!state.peripheralHub.editZoneModal
