import {useLazyQuery, useMutation} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import AddressFields from 'ui/organisms/AddressFields'
import StyledButton from 'ui/atoms/StyledButton'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledInput from 'ui/atoms/StyledInput'
import StyledTable from 'ui/molecules/StyledTable'
import {Permission} from 'constants/permissions'
import {CustomerType, PreferenceType} from 'graphql/generatedTypes/graphql'
import {
  ADD_CUSTOMER,
  UPDATE_CUSTOMER
} from 'graphql/mutations/customer.mutation'
import {FETCH_CUSTOMERS} from 'graphql/queries/customers.queries'
import useNotify from 'hooks/useNotify'
import usePermission from 'hooks/usePermission'
import _ from 'lodash'
import {MRT_ColumnDef} from 'material-react-table'
import {useMemo} from 'react'
import {useEffect, useState} from 'react'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {convertToLocalTimeString, stringAvatar} from 'utils/common'

const preference = {
  title: '',
  value: [''],
  key: ''
}

const defaultFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  gender: '',
  address: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  },
  preferences: [preference]
}

function Customers() {
  const notify = useNotify()

  const {t} = useTranslation('commons')
  const [data, setData] = useState<CustomerType[]>([])
  const {authInfo} = useAuth()
  const history = useHistory()

  const [modelOpen, setModelOpen] = React.useState(false)
  const [formValues, setFormValues] = useState({...defaultFormValues})
  const [isEditMode, setIsEditMode] = useState(false)
  const [customerID, setCustomerID] = useState('')

  const hasCreateCustomerAccess = usePermission(
    Permission.CREATE_AND_UPDATE_CUSTOMER
  )

  const clearForm = () => {
    setFormValues({
      ...defaultFormValues
    })
  }

  const [getCustomers, {data: customersData, loading: loadingCustomers}] =
    useLazyQuery(FETCH_CUSTOMERS, {
      fetchPolicy: 'no-cache'
    })

  const [_addCustomer] = useMutation(ADD_CUSTOMER, {
    onCompleted: () => {
      fetchAllCustomers()
      clearForm()
      setModelOpen(false)
      setIsEditMode(false)
    },
    onError: (error) => {
      notify.show(error.message, 'error')
    }
  })

  const [_updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: () => {
      fetchAllCustomers()
      clearForm()
      setModelOpen(false)
      setIsEditMode(false)
    },
    onError: (error) => {
      notify.show(error.message, 'error')
    }
  })

  const addCustomer = () => {
    _addCustomer({
      variables: {
        customer: {
          ...formValues,
          business: authInfo.businessId as string
        }
      }
    })
  }

  const updateCustomer = () => {
    _updateCustomer({
      variables: {
        customerId: customerID,
        customer: {
          ...formValues,
          business: authInfo.businessId as string
        }
      }
    })
  }

  const fetchAllCustomers = () => {
    getCustomers({
      variables: {
        businessId: authInfo.businessId as string,
        locationId: authInfo.locationId as string
      }
    })
  }

  useEffect(() => {
    fetchAllCustomers()
  }, [])

  useEffect(() => {
    if (customersData) {
      setData(customersData.listCustomers as CustomerType[])
    }
  }, [customersData])

  const columns = useMemo<MRT_ColumnDef<CustomerType>[]>(
    () => [
      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        id: 'name',
        header: 'Name',
        size: 250,
        Cell: ({renderedCellValue, row}) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem'
            }}
          >
            {row.original.avatar ? (
              <Avatar alt='avatar' src={row.original.avatar} />
            ) : (
              <Avatar
                {...stringAvatar(
                  `${row.original.firstName} ${row.original.lastName}`
                )}
              />
            )}
            <span>{renderedCellValue}</span>
          </Box>
        )
      },
      {
        accessorKey: 'email',
        header: 'Email',
        Cell: ({cell, row}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'phone',
        header: 'Phone',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        },
        enableSorting: true
      },

      {
        accessorKey: 'barcode',
        header: 'Customer ID',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'dateModified',
        header: 'Sign Up Date',
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue() ? convertToLocalTimeString(cell.getValue()) : ''}
            </Typography>
          )
        }
      }
    ],
    []
  )

  const setAddress = (address: {
    street: string
    city: string
    state: string
    zipCode: string
    country: string
  }) => {
    setFormValues((prev) => {
      return {
        ...prev,
        address
      }
    })
  }

  const customerConfigurationDialog = () => {
    return (
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <Typography variant='h4'>Details</Typography>
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='First Name *'
            variant='outlined'
            value={formValues.firstName}
            onChange={(e: any) => handleChange('firstName', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='Last Name *'
            variant='outlined'
            value={formValues.lastName}
            onChange={(e: any) => handleChange('lastName', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='Email *'
            variant='outlined'
            value={formValues.email}
            onChange={(e: any) => handleChange('email', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='Phone *'
            variant='outlined'
            value={formValues.phone}
            onChange={(e: any) => handleChange('phone', e.target.value)}
          />
        </Grid>

        {/* Address Fields */}
        <AddressFields address={formValues.address} setAddress={setAddress} />

        {/* Preferences */}
        <Grid item xs={12}>
          <Typography variant='h4'>Preferences</Typography>
        </Grid>
        <Grid
          container
          item
          gap={4}
          style={{display: 'flex', flexDirection: 'column'}}
        >
          {formValues.preferences.map((preference: any, index) => (
            <Grid
              container
              item
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
              spacing={2}
              key={index}
            >
              <Grid item xs={5} sm={5}>
                <StyledInput
                  fullWidth
                  label='Title'
                  variant='outlined'
                  value={preference?.title}
                  helperText={t('customers.preference.hint.title')}
                  onChange={(e: any) =>
                    handlePChange(index, 'title', e.target.value)
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={5} sm={5}>
                <StyledInput
                  fullWidth
                  label='Value'
                  variant='outlined'
                  value={preference?.value.join(',')}
                  helperText={t('customers.preference.hint.value')}
                  onChange={(e: any) =>
                    handlePChange(
                      index,
                      'value',
                      e.target.value.split(',').map((v: string) => v.trim())
                    )
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={2}>
                <IconButton
                  onClick={(e: any) => {
                    handleRemoveField(index)
                  }}
                >
                  <Tooltip title='Delete'>
                    <FontAwesomeIcon
                      icon={icon({
                        name: 'trash-alt',
                        family: 'classic',
                        style: 'solid'
                      })}
                      fontSize={20}
                      style={{
                        borderWidth: '1px'
                      }}
                    />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={6}>
          <StyledButton
            variant='contained'
            color='primary'
            onClick={addPreference}
          >
            Add Preference
          </StyledButton>
        </Grid>
      </Grid>
    )
  }

  const addPreference = () => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      preferences: [...prevFormValues.preferences, preference]
    }))
  }

  const handleChange = (path: any, value: any) => {
    console.log(path, value)
    setFormValues((prev) => {
      return _.set({...prev}, path, value)
    })
  }

  const handlePChange = (index: any, path: string, value: any) => {
    let customerPreference = formValues.preferences[index]
    customerPreference = {
      ...customerPreference,
      [path]: value
    } as typeof preference
    formValues.preferences[index] = customerPreference
    setFormValues({...formValues})
    console.log(customerPreference)
  }

  const handleRemoveField = (index: any) => {
    let customerPreferences = formValues.preferences
    customerPreferences.splice(index, 1)
    formValues.preferences = customerPreferences
    setFormValues({...formValues})
  }

  return (
    <>
      <StyledTable
        columns={columns}
        data={data}
        enableRowActions
        state={{
          showSkeletons: loadingCustomers,
          showProgressBars: loadingCustomers
        }}
        initialState={{
          columnPinning: {
            left: ['mrt-row-expand'],
            right: ['mrt-row-actions']
          },
          columnVisibility: {
            dateModified: false
          }
        }}
        renderTopToolbarCustomActions={() => {
          return (
            <Grid
              style={{
                order: 1
              }}
            >
              <StyledButton
                fullWidth={false}
                id='successButton'
                disabled={!hasCreateCustomerAccess}
                size='medium'
                onClick={() => setModelOpen(true)}
                startIcon={
                  <FontAwesomeIcon
                    icon={icon({
                      name: 'plus'
                    })}
                  />
                }
              >
                Create Customer
              </StyledButton>
            </Grid>
          )
        }}
        renderRowActionMenuItems={({row, closeMenu}) => [
          <MenuItem
            key={0}
            onClick={() => {
              closeMenu()
              history.push(
                `/${authInfo.businessId}/customers/${row.original.id}`
              )
            }}
            sx={{m: 0}}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                icon={icon({
                  name: 'user-circle'
                })}
                size='2x'
              />
            </ListItemIcon>
            View Profile
          </MenuItem>,
          <MenuItem
            disabled={!hasCreateCustomerAccess}
            key={0}
            onClick={() => {
              closeMenu()
              setCustomerID(row.original.id)
              setModelOpen(true)
              setIsEditMode(true)
              setFormValues({
                address: {
                  city: (row.original.address?.city as string) || '',
                  state: (row.original.address?.state as string) || '',
                  street: (row.original.address?.street as string) || '',
                  zipCode: (row.original.address?.zipCode as string) || '',
                  country: (row.original.address?.country as string) || ''
                },
                firstName: row.original?.firstName as string,
                lastName: row.original?.lastName as string,
                email: row.original?.email as string,
                phone: row.original?.phone as string,
                gender: row.original?.gender as string,
                preferences: row.original?.preferences
                  ?.filter(
                    (customerPre): customerPre is PreferenceType =>
                      customerPre !== null
                  )
                  .map((customerPre: PreferenceType) => {
                    return {
                      title: customerPre.title as string,
                      value: customerPre.value as string[],
                      key: customerPre.key as string
                    }
                  })
              })
            }}
            sx={{m: 0}}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                icon={icon({
                  name: 'edit'
                })}
                size='2x'
              />
            </ListItemIcon>
            Edit
          </MenuItem>
        ]}
      />

      <StyledDialog
        open={modelOpen}
        onClose={() => {
          setModelOpen(false)
          clearForm()
          setIsEditMode(false)
        }}
        title={isEditMode ? 'Edit Customer' : 'Add Customer'}
        successButtonName={isEditMode ? 'Update' : 'Add'}
        cancelButtonName='Cancel'
        cancelCallback={() => {
          clearForm()
          setModelOpen(false)
          setIsEditMode(false)
        }}
        disableSuccessButton={
          !formValues.firstName ||
          !formValues.lastName ||
          !formValues.email ||
          !formValues.phone
        }
        successCallback={() => {
          isEditMode ? updateCustomer() : addCustomer()
        }}
        body={customerConfigurationDialog()}
      />
    </>
  )
}

export default Customers
