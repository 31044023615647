import {combineSlices, configureStore} from '@reduxjs/toolkit'
import {useDispatch, useSelector} from 'react-redux'
import {cashManagementSummarySlice} from 'clientDashboard/cashManagement/cashManagement.slice'
import {employeeSlice} from 'clientDashboard/employee.slice'
import {businessSlice} from 'clientDashboard/business.slice'
import {peripheralHubSlice} from 'clientDashboard/peripheralHub/peripheralHub.slice'
import {settingsSlice} from 'clientDashboard/settings/settings.slice'

const rootReducer = combineSlices(
  businessSlice,
  employeeSlice,
  cashManagementSummarySlice,
  peripheralHubSlice,
  settingsSlice
)

export const store = configureStore({
  reducer: rootReducer,
  devTools: {
    name: 'Client Dashboard'
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()
