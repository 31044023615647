import {graphql} from 'graphql/generatedTypes'

export const LIST_SECRETS = graphql(`
  query listSecrets {
    listSecrets {
      secretName
      secretValue
    }
  }
`)
