import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  split
} from '@apollo/client'
import {onError} from '@apollo/client/link/error'
import {WebSocketLink} from '@apollo/client/link/ws'
import {getMainDefinition} from '@apollo/client/utilities'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import env from 'constants/env'
import {OperationDefinitionNode} from 'graphql'

import {EventTypes, LocalStorageKeys} from 'constants/constants'
import {authEventEmitter} from 'auth/events'
import {parseJson} from 'utils/common'
import {DefaultOptions} from '@apollo/client/core/ApolloClient'

const httpLink = createUploadLink({
  uri: env.REACT_APP_SERVER_HTTP_URL || 'http://localhost:8000/api/v1/graphql/'
})

const wsLink = new WebSocketLink({
  uri: env.REACT_APP_SERVER_WS_URL || `ws://localhost:8000/api/v1/graphql/`,
  options: {
    reconnect: true,
    connectionParams: {
      Authorization: `Bearer ${parseJson(
        localStorage.getItem(LocalStorageKeys.TOTE_ACCESS_TOKEN)
      )}`,
      GuestAuthorization: `Bearer ${parseJson(
        localStorage.getItem(LocalStorageKeys.GUEST_ACCESS_TOKEN)
      )}`
    }
  }
})

// Middleware for setting the Authorization header
const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the token from local storage or any other storage mechanism
  // const token = parseJson(
  //   localStorage.getItem(LocalStorageKeys.TOTE_ACCESS_TOKEN)
  // )

  const guest_token = parseJson(
    localStorage.getItem(LocalStorageKeys.GUEST_ACCESS_TOKEN)
  )
  const pathName = window.location.pathname
  const tokenKeyName = pathName.startsWith('/admin')
    ? LocalStorageKeys.TOTE_ADMIN_ACCESS_TOKEN
    : LocalStorageKeys.TOTE_ACCESS_TOKEN
  const token = parseJson(localStorage.getItem(tokenKeyName))
  operation.setContext(({headers = {}}) => {
    const requestHeaders = {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
        GuestAuthorization: guest_token ? `Bearer ${guest_token}` : ''
      }
    }

    return requestHeaders
  })

  return forward(operation)
})

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response?.errors?.[0]?.extensions?.code === 'AUTHENTICATION_ERROR') {
      authEventEmitter.emit(EventTypes.SIGN_OUT)
      client?.clearStore?.()
    }
    return response
  })
})

const errorLink = onError(
  ({graphQLErrors, networkError, operation, forward}: any) => {
    if (graphQLErrors) {
      graphQLErrors.map(({message, locations, path}: any) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`)
    }
  }
)

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache'
  },
  query: {
    fetchPolicy: 'no-cache'
  },
  mutate: {
    fetchPolicy: 'no-cache'
  }
} as DefaultOptions

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    authLink,
    afterwareLink,
    errorLink,
    split(
      ({query}) => {
        const definition = getMainDefinition(query) as OperationDefinitionNode
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      httpLink
    )
  ]),
  defaultOptions
})

export default client
