import {graphql} from 'graphql/generatedTypes'

export const CREATE_EMPLOYEE = graphql(`
  mutation createEmployee($businessId: String!, $employee: EmployeeInput!) {
    createEmployee(businessId: $businessId, employee: $employee) {
      id
      name
      email
      phoneNumber
      employeeCode
      fullAddress {
        street
        city
        state
        zipCode
        country
      }
      role {
        id
        name
      }
      locations {
        id
        name
      }
      defaultLocation {
        id
        name
      }
      status
    }
  }
`)

export const UPDATE_EMPLOYEE = graphql(`
  mutation updateEmployee(
    $employeeId: String!
    $businessId: String!
    $employee: EmployeeInput!
  ) {
    updateEmployee(
      employee: $employee
      businessId: $businessId
      employeeId: $employeeId
    ) {
      id
      name
      email
      phoneNumber
      employeeCode
      fullAddress {
        street
        city
        state
        zipCode
        country
      }
      role {
        id
        name
      }
      locations {
        id
        name
      }
      defaultLocation {
        id
        name
      }
      status
    }
  }
`)

export const SEND_OTP_FOR_PASSWORD_RESET = graphql(`
  mutation sendOtpForPasswordReset($token: String!) {
    sendOtpForPasswordReset(token: $token)
  }
`)

export const RESET_PASSWORD_WITH_OTP = graphql(`
  mutation resetPasswordWithOtp(
    $token: String!
    $otp: String!
    $password: String!
    $pin: String!
  ) {
    resetPasswordWithOtp(
      token: $token
      otp: $otp
      password: $password
      pin: $pin
    )
  }
`)

export const RESEND_OTP_FOR_PASSWORD_RESET = graphql(`
  mutation resendOtpForPasswordReset($token: String!) {
    resendOtpForPasswordReset(token: $token)
  }
`)
