import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const NotFound = () => {
  return (
    <div style={{textAlign: 'center', marginTop: '50px'}}>
      <>
        <FontAwesomeIcon
          icon={icon({
            name: 'exclamation-triangle',
            family: 'classic',
            style: 'solid'
          })}
          style={{
            fontSize: '100px'
          }}
        />

        <h1>404</h1>
        <h2>Page Not Found!</h2>
        <p>
          The page you are looking for does not exist or another error occurred.
        </p>
      </>
    </div>
  )
}

export default NotFound
