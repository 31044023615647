import {graphql} from 'graphql/generatedTypes'

export const CREATE_PAYMENT_INTENT = graphql(`
  mutation createPaymentIntent($amount: Int!, $businessId: String!) {
    createPaymentIntent(amount: $amount, businessId: $businessId)
  }
`)

export const PLACE_ORDER = graphql(`
  mutation placeOrderConsumerAgent(
    $cartId: String!
    $currency: String!
    $amount: Int!
    $paymentMethod: String!
    $paymentGateway: String
    $paymentGatewayReferenceId: String
    $nonce: String!
    $businessId: String!
    $locationId: String!
  ) {
    placeOrderConsumerAgent(
      input: {
        cartId: $cartId
        currency: $currency
        amount: $amount
        paymentMethod: $paymentMethod
        paymentGateway: $paymentGateway
        paymentGatewayReferenceId: $paymentGatewayReferenceId
        nonce: $nonce
      }
      requestContext: {businessId: $businessId, locationId: $locationId}
    ) {
      amountPaid
      amountRefunded
      currency
      id
      paymentStatus
      refundStatus
      totalAmount
      payments {
        id
        orderId
        status
        amount
        paymentMethod
        paymentGatewayReferenceId
        currency
        paymentMethodDetails {
          type
          card {
            last4
            brand
            fingerprint
          }
        }
      }
    }
  }
`)

export const ORDER_PAYMENT_STATUS = graphql(`
  mutation orderPaymentStatusConsumerAgent(
    $orderId: String!
    $paymentId: String!
    $businessId: String!
    $locationId: String!
  ) {
    orderPaymentStatusConsumerAgent(
      orderId: $orderId
      paymentId: $paymentId
      requestContext: {businessId: $businessId, locationId: $locationId}
    ) {
      id
      paymentStatus
      refundStatus
      totalAmount
      currency
      amountPaid
      amountRefunded
    }
  }
`)
