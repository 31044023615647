import {Grid, Typography} from '@mui/material'
import StyledCheckBox from 'ui/atoms/StyledCheckbox'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledSelect, {StyledMenuItem} from 'ui/molecules/StyledSelect'
import {space} from 'config/theme'
import usePeripheralHubNotificationConfig from 'hooks/usePeripheralHubNotifications'
import {t} from 'i18next'
import {useEffect, useState} from 'react'

const severityOptions = [
  {
    value: 'all',
    label: t(
      'peripheral-hub.notifications.severity-filter-options.critical-and-warning'
    )
  },
  {
    value: 'critical',
    label: t(
      'peripheral-hub.notifications.severity-filter-options.only-critical'
    )
  },
  {
    value: 'warning',
    label: t(
      'peripheral-hub.notifications.severity-filter-options.only-warning'
    )
  }
]

const issueOptions = [
  {value: 'all', label: 'All Issues'},
  {value: 'issue1', label: 'Issue 1'},
  {value: 'issue2', label: 'Issue 2'}
]

const alertTimingOptions = [{value: 'instant', label: 'Instant'}]

type EditPeripheralHubNotificationsDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  devices: any[]
}

const EditPeripheralHubNotificationsDialog = ({
  open,
  setOpen,
  devices
}: EditPeripheralHubNotificationsDialogProps) => {
  const {
    peripheralHubNotificationConfig,
    updatePeripheralHubNotificationConfig
  } = usePeripheralHubNotificationConfig()

  const [selectedSeverity, setSelectedSeverity] = useState('all')
  const [selectedIssue, setSelectedIssue] = useState('all')
  const [selectedDevice, setSelectedDevice] = useState<string>('')
  const [selectedAlertTiming, setSelectedAlertTiming] = useState('instant')

  const [notificationChannels, setNotificationChannels] = useState({
    email: false,
    text: false
  })

  useEffect(() => {
    setSelectedSeverity(peripheralHubNotificationConfig.severity)
    setSelectedIssue(peripheralHubNotificationConfig.issue)
    setSelectedDevice(peripheralHubNotificationConfig.device || '')
    setSelectedAlertTiming(peripheralHubNotificationConfig.alert)
    setNotificationChannels({
      email: peripheralHubNotificationConfig.methods.email,
      text: peripheralHubNotificationConfig.methods.text
    })
  }, [peripheralHubNotificationConfig])

  const editNotificationsBody = () => {
    return (
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Grid
          container
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Grid item sm={6}>
            <Typography variant='subtitle1' marginLeft={space.LARGE}>
              Severity
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <StyledSelect
              size='medium'
              sx={{minWidth: 200, m: 1}}
              label=''
              value={selectedSeverity}
              onChange={(e) => {
                setSelectedSeverity(e.target.value as string)
              }}
            >
              {severityOptions.map((option, index) => {
                return (
                  <StyledMenuItem key={index} value={option.value}>
                    {option.label}
                  </StyledMenuItem>
                )
              })}
            </StyledSelect>
          </Grid>
        </Grid>

        <Grid
          container
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Grid item sm={6}>
            <Typography variant='subtitle1' marginLeft={space.LARGE}>
              Issue
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <StyledSelect
              size='medium'
              sx={{minWidth: 200, m: 1}}
              label=''
              value={selectedIssue}
              onChange={(e) => {
                setSelectedIssue(e.target.value as string)
              }}
            >
              {issueOptions.map((option, index) => {
                return (
                  <StyledMenuItem key={index} value={option.value}>
                    {option.label}
                  </StyledMenuItem>
                )
              })}
            </StyledSelect>
          </Grid>
        </Grid>

        <Grid
          container
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Grid item sm={6}>
            <Typography variant='subtitle1' marginLeft={space.LARGE}>
              Devices
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <StyledSelect
              size='medium'
              sx={{minWidth: 200, m: 1}}
              label='Device'
              value={selectedDevice}
              onChange={(e) => {
                setSelectedDevice(e.target.value as string)
              }}
            >
              {devices.map((option, index) => {
                return (
                  <StyledMenuItem key={index} value={option.value}>
                    {option.label}
                  </StyledMenuItem>
                )
              })}
            </StyledSelect>
          </Grid>
        </Grid>

        <Grid
          container
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Grid item sm={6}>
            <Typography variant='subtitle1' marginLeft={space.LARGE}>
              Alert
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <StyledSelect
              size='medium'
              sx={{minWidth: 200, m: 1}}
              label=''
              value={selectedAlertTiming}
              onChange={(e) => {
                setSelectedAlertTiming(e.target.value as string)
              }}
            >
              {alertTimingOptions.map((option, index) => {
                return (
                  <StyledMenuItem key={index} value={option.value}>
                    {option.label}
                  </StyledMenuItem>
                )
              })}
            </StyledSelect>
          </Grid>
        </Grid>

        <Grid
          container
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Grid item sm={6}>
            <Typography variant='subtitle1' marginLeft={space.LARGE}>
              Notification Methods
            </Typography>
          </Grid>

          <Grid
            container
            item
            sm={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            {
              // change camel case to normal text
              Object.keys(notificationChannels).map((channel) => {
                return (
                  <Grid item key={channel}>
                    <StyledCheckBox
                      checked={
                        notificationChannels[
                          channel as keyof typeof notificationChannels
                        ]
                      }
                      onChange={(e) => {
                        setNotificationChannels({
                          ...notificationChannels,
                          [channel]: e.target.checked
                        })
                      }}
                    />

                    {
                      // change camel case to normal text
                      channel
                        .replace(/([A-Z])/g, ' $1')
                        .replace(/^./, function (str) {
                          return str.toUpperCase()
                        })
                    }
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <StyledDialog
      open={open}
      onClose={() => setOpen(false)}
      title='Edit Notifications'
      body={editNotificationsBody()}
      successButtonName='Confirm'
      cancelButtonName='Cancel'
      successCallback={() => {
        updatePeripheralHubNotificationConfig({
          ...peripheralHubNotificationConfig,
          severity: selectedSeverity,
          issue: selectedIssue,
          device: selectedDevice as string,
          alert: selectedAlertTiming,
          methods: {
            email: notificationChannels.email,
            text: notificationChannels.text
          }
        })
        setOpen(false)
      }}
      cancelCallback={() => {
        setOpen(false)
      }}
      closeCallback={() => {
        setOpen(false)
      }}
    ></StyledDialog>
  )
}

export default EditPeripheralHubNotificationsDialog
