import React from 'react'
import {IconButton, IconButtonProps, styled} from '@mui/material'

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore: React.FC<ExpandMoreProps> = (props) => {
  const {expand, ...other} = props
  return <IconButton {...other} />
}

const StyledExpandMore = styled(ExpandMore)(({theme, expand}) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

export default StyledExpandMore
