import {useEffect, useState} from 'react'
import {StoreGroupClusterType} from 'graphql/generatedTypes/graphql'
import {Grid} from '@mui/material'
import {useLazyQuery} from '@apollo/client'
import useEmployee from 'hooks/useEmployee'
import {GET_LOCATIONS} from 'graphql/queries/location.queries'
import {selectStoreGroupClusters} from 'clientDashboard/business.selectors'
import {
  useAppDispatch,
  useAppSelector
} from 'clientDashboard/clientDashboard.store'
import {setStoreGroupClusterStats} from 'clientDashboard/settings/StoreGrouping/storeGroups.slice'
import {
  FETCH_STORE_GROUPS_BY_CLUSTER_ID,
  STORE_GROUP_CLUSTER_STATS
} from 'graphql/queries/store-groups'
import StoreGroupClusterRenameDialog from 'clientDashboard/settings/StoreGrouping/StoreGroupClusterRenameDialog'
import {selectBusinessId} from 'clientDashboard/employee.selectors'
import {
  setOrphanItems,
  setSaveStoreGroupModalData
} from 'clientDashboard/settings/locations/locations.slice'
import StoreGroupSave from 'clientDashboard/settings/StoreGrouping/StoreGroupSave'
import StoreGroupClusterCard from 'clientDashboard/settings/StoreGrouping/StoreGroupClusterCard'
import {selectStoreGroupClusterCardList} from 'clientDashboard/settings/StoreGrouping/storeGroups.selectors'
import {selectSaveStoreGroupModalData} from 'clientDashboard/settings/locations/locations.selectors'

function StoreGrouping() {
  const {getEmployee} = useEmployee()
  const dispatch = useAppDispatch()
  const [showAddNewStoreGroupModal, setShowAddNewStoreSegmentModal] =
    useState(false)
  const [
    showRenameStoreGroupClusterModal,
    setShowRenameStoreGroupClusterModal
  ] = useState(false)
  const [groupClusterIdToBeRenamed, setgroupClusterIdToBeRenamed] = useState('')
  const [
    storeGroupClusterIdToCreateGroup,
    setStoreGroupClusterIdToCreateGroup
  ] = useState('')
  const storeGroupClusters = useAppSelector(selectStoreGroupClusters)
  const storeGroupClusterCardList = useAppSelector(
    selectStoreGroupClusterCardList
  )
  const businessId = useAppSelector(selectBusinessId)

  const storeGroupModalData = useAppSelector(selectSaveStoreGroupModalData)

  const onAddNewStoreSegmentSuccessCallback = () => {
    setShowAddNewStoreSegmentModal(false)
    setStoreGroupClusterIdToCreateGroup('')
    _fetchStoreGroupClusterStats()
  }
  const fetchStoreGroupClusterById = (id: string) => {
    return storeGroupClusters.find(
      (cluster: StoreGroupClusterType) => cluster.id === id
    )
  }
  const [_fetchStoreGroupsByClusterId, {data: _storeGroupsByClusterId}] =
    useLazyQuery(FETCH_STORE_GROUPS_BY_CLUSTER_ID, {
      fetchPolicy: 'no-cache'
    })

  const [_getLocations, {data: _locationData}] = useLazyQuery(GET_LOCATIONS, {
    fetchPolicy: 'no-cache'
  })

  const fetchNextHierarchyLevelClusterId = (clusterId: string) => {
    const cluster = storeGroupClusters.find(
      (cluster: StoreGroupClusterType) => cluster.id === clusterId
    )
    if (!cluster) {
      return
    }
    const nextHierarchyLevel = cluster.hierarchyLevel + 1
    const nextCluster = storeGroupClusters.find(
      (cluster: StoreGroupClusterType) =>
        cluster.hierarchyLevel === nextHierarchyLevel
    )
    return nextCluster?.id
  }

  const fetchOrphanStoreGroups = () => {
    _fetchStoreGroupsByClusterId({
      variables: {
        storeGroupClusterId: fetchNextHierarchyLevelClusterId(
          storeGroupClusterIdToCreateGroup
        ) as string,
        status: 'active',
        fetchOrphanedStoreGroups: true
      }
    })
  }

  const fetchOrphanLocations = () => {
    _getLocations({
      variables: {
        businessId: businessId,
        storeGroupIds: []
      }
    })
  }

  useEffect(() => {
    if (!storeGroupClusterIdToCreateGroup) {
      return
    }
    if (!_locationData && !_storeGroupsByClusterId) {
      return
    }

    const storeGroupCluster = storeGroupClusters.find(
      (cluster: StoreGroupClusterType) =>
        cluster.id === storeGroupClusterIdToCreateGroup
    )
    const isLowestLevel =
      storeGroupCluster?.hierarchyLevel === storeGroupClusters.length
    const orphanItems = isLowestLevel
      ? _locationData?.getAllLocations
      : _storeGroupsByClusterId?.storeGroupsByClusterId
    setTimeout(() => {
      dispatch(setOrphanItems(orphanItems))
    })
  }, [_locationData, _storeGroupsByClusterId])

  useEffect(() => {
    if (!storeGroupClusterIdToCreateGroup) {
      return
    }

    let clusterGroup = fetchStoreGroupClusterById(
      storeGroupClusterIdToCreateGroup
    ) as StoreGroupClusterType

    setTimeout(() => {
      dispatch(
        setSaveStoreGroupModalData({
          name: '',
          status: 'draft',
          cluster: {
            id: clusterGroup.id,
            name: clusterGroup.name,
            hierarchyLevel: clusterGroup?.hierarchyLevel
          }
        })
      )
    })
    const isLowestLevel =
      clusterGroup?.hierarchyLevel === storeGroupClusters.length
    if (isLowestLevel) {
      fetchOrphanLocations()
    } else {
      fetchOrphanStoreGroups()
    }
  }, [storeGroupClusterIdToCreateGroup])

  const [_fetchStoreGroupClusterStats, {data: _storeGroupClusterStats}] =
    useLazyQuery(STORE_GROUP_CLUSTER_STATS, {
      fetchPolicy: 'no-cache'
    })

  useEffect(() => {
    if (!_storeGroupClusterStats) {
      return
    }
    setTimeout(() => {
      dispatch(
        setStoreGroupClusterStats(
          _storeGroupClusterStats.storeGroupClusterStats
        )
      )
    })
  }, [_storeGroupClusterStats])

  const onRenameStoreGroupClusterSuccessCallback = () => {
    setShowRenameStoreGroupClusterModal(false)
    getEmployee()
    _fetchStoreGroupClusterStats()
  }

  useEffect(() => {
    _fetchStoreGroupClusterStats()
  }, [])

  const createNewStoreGroupDialog = () => {
    if (
      !showAddNewStoreGroupModal ||
      !storeGroupClusterIdToCreateGroup ||
      !storeGroupModalData ||
      !storeGroupModalData.orphanItems
    ) {
      return <> </>
    }

    return (
      <Grid container spacing={4} padding={2}>
        <StoreGroupSave
          isEditMode={false}
          onSuccessCallback={onAddNewStoreSegmentSuccessCallback}
          onCloseOrCancelCallback={() => {
            setShowAddNewStoreSegmentModal(false)
            setStoreGroupClusterIdToCreateGroup('')
          }}
        />
      </Grid>
    )
  }

  const renameStoreGroupClusterDialog = () => {
    if (!showRenameStoreGroupClusterModal) {
      return <> </>
    }
    return (
      <Grid container spacing={4} padding={2}>
        <StoreGroupClusterRenameDialog
          onSuccessCallback={onRenameStoreGroupClusterSuccessCallback}
          onCloseOrCancelCallback={() => {
            setShowRenameStoreGroupClusterModal(false)
          }}
          storeGroupCluster={fetchStoreGroupClusterById(
            groupClusterIdToBeRenamed
          )}
        />
      </Grid>
    )
  }

  const showStoreGroupClusterCards = () => {
    // loop through the storeGroupClusterCardList and render the cards
    return (
      <Grid container spacing={4}>
        {storeGroupClusterCardList.map((card, index) => {
          return (
            <Grid item xs={12} key={index}>
              <StoreGroupClusterCard
                card={card}
                setShowAddNewStoreGroupModal={(_storeGroupClusterId) => {
                  setShowAddNewStoreSegmentModal(true)
                  setStoreGroupClusterIdToCreateGroup(_storeGroupClusterId)
                }}
                setShowRenameStoreGroupClusterModal={(
                  _groupClusterIdToBeRenamed
                ) => {
                  setShowRenameStoreGroupClusterModal(true)
                  setgroupClusterIdToBeRenamed(_groupClusterIdToBeRenamed)
                }}
              />
            </Grid>
          )
        })}
      </Grid>
    )
  }

  return (
    <Grid
      spacing={2}
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%'
      }}
    >
      {createNewStoreGroupDialog()}
      {renameStoreGroupClusterDialog()}
      {showStoreGroupClusterCards()}
    </Grid>
  )
}

export default StoreGrouping
