import {
  RootState,
  useAppDispatch,
  useAppSelector
} from 'clientDashboard/clientDashboard.store'
import {
  selectClusters,
} from 'clientDashboard/employee.selectors'
import {
  applyTopBarLocationGroups,
  Cluster,
  resetLocationGroupsForTopBar, updateGroupsAndOptionsForTopBar,
} from 'clientDashboard/employee.slice'
import _map from 'lodash/map'
import {Autocomplete, TextField} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Button from 'ui/atoms/StyledButton'

type HierarchySelectorProps = {
  onClickApply: Function
}

function HierarchySelector({
  onClickApply
}: HierarchySelectorProps) {
  const dispatch = useAppDispatch()
  const clusters = useAppSelector(selectClusters)
  const optionMap = useAppSelector((state: RootState) =>
    state.employee?.topBarLocationGroups?.clusterOptionsMap) || {}

  const appliedLocationGroups = useAppSelector((state: RootState) =>
    state.employee?.topBarLocationGroups?.appliedLocationGroups) || {}

  const getOptions = (cluster: Cluster) => {
    return _map(optionMap[cluster.name], ({id, name}) => ({id, name}))
  }

  return (
    <div className='p-6'>
      <div className='grid grid-cols-2 gap-6'>
        {clusters.map((cluster, index) => {
          const values = appliedLocationGroups[cluster.name] || []
          const id = `${cluster.hierarchyLevel}_${cluster.name}`
          const isLastAndSingle = (index === clusters.length - 1) && !(index % 2)
          return (
            <Autocomplete
              multiple
              key={id}
              disablePortal
              disableCloseOnSelect
              id={id}
              options={getOptions(cluster)}
              value={values}
              limitTags={isLastAndSingle ? 2 : 1}
              classes={{
                input: '!min-w-0',
              }}
              onChange={(event: any, newValue: any[]) => {
                dispatch(
                  updateGroupsAndOptionsForTopBar({
                    [cluster.name]: newValue
                  })
                )
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, {selected}) => {
                const {key, ...optionProps} = props
                return (
                  <li key={key} {...optionProps}>
                    <Checkbox style={{marginRight: 8}} checked={selected} />
                    {option.name}
                  </li>
                )
              }}
              isOptionEqualToValue={(option, value) => {
                return value && option?.id === value?.id
              }}
              sx={{
                ...(isLastAndSingle ? { gridColumn: 'span 2' } : { width: 280 })
            }}
              renderInput={(params) => {
                return (
                  <TextField {...params} maxRows={1} label={cluster.name} />
                )
              }}
            />
          )
        })}
      </div>

      <div className='flex justify-between items-center space-x-2 pt-6'>
        <Button
          variant={'text'}
          onClick={() => {
            dispatch(resetLocationGroupsForTopBar())
          }}
        >
          Select All
        </Button>
        <Button variant={'contained'} onClick={() => {
          dispatch(applyTopBarLocationGroups())
          onClickApply()
        }}>
          Apply
        </Button>
      </div>
    </div>
  )
}

export default HierarchySelector
