import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Box, Grid, IconButton, Typography} from '@mui/material'
import React from 'react'
import theme, {space} from 'config/theme'
import {UNASSIGNED} from 'constants/constants'
import {ZoneChipType, ZoneType} from 'types/peripheral-hub.types'
import {getHealthIcon} from 'utils/common'
import {
  useAppDispatch,
  useAppSelector
} from 'clientDashboard/clientDashboard.store'
import {
  selectDevices,
  selectIsZoneEditMode,
  selectRegisters
} from 'clientDashboard/peripheralHub/peripheralHub.selectors'
import {updateEditZoneModalData} from 'clientDashboard/peripheralHub/peripheralHub.slice'
import {useHistory} from 'react-router-dom'
import {selectBusinessAndLocation} from 'clientDashboard/employee.selectors'
import {getFormattedZoneData} from 'clientDashboard/peripheralHub/peripheralHubOverview/zoneDevices/zoneDevices.helpers'

type ZoneDeviceProps = {
  zone: ZoneType
}

const ZoneDevices: React.FC<ZoneDeviceProps> = ({zone}) => {
  const dispatch = useAppDispatch()

  const registers = useAppSelector(selectRegisters)
  const devices = useAppSelector(selectDevices)
  const isEditModeEnabled = useAppSelector(selectIsZoneEditMode)

  const formattedZoneData = getFormattedZoneData(zone, registers, devices)

  const history = useHistory()
  const {businessId} = useAppSelector(selectBusinessAndLocation)

  return (
    <Grid>
      <Grid
        container
        spacing={1}
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '12px 8px',
          alignItems: 'stretch',
          gap: '10px',
          backgroundColor: theme.palette.background.paper,
          borderRadius: '12px'
        }}
      >
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: space.XXS
          }}
        >
          <Typography variant='h2'>{zone.name}</Typography>
          {isEditModeEnabled && UNASSIGNED !== zone.id && (
            <IconButton
              style={{
                marginLeft: space.SMALL
              }}
              size='small'
              onClick={() => {
                dispatch(updateEditZoneModalData(zone))
              }}
            >
              <FontAwesomeIcon
                icon={icon({
                  name: 'pencil',
                  family: 'classic',
                  style: 'solid'
                })}
                color={theme.palette.text.secondary}
              />
            </IconButton>
          )}
        </Grid>

        {!Boolean(zone.registers?.length) && (
          <Grid
            style={{
              padding: space.XXS
            }}
          >
            <Typography variant='body1'>
              No registers/devices are present in this zone.
            </Typography>
          </Grid>
        )}

        {Boolean(zone.registers?.length) && (
          <Grid
            item
            container
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'stretch',

              gap: '10px'
            }}
          >
            {formattedZoneData.map((box: ZoneChipType, index) => (
              <Grid
                item
                lg={2.5}
                md={4}
                xs={12}
                key={index}
                style={{display: 'flex'}}
              >
                <Box
                  border={1}
                  style={{
                    display: 'flex',
                    padding: '16px',
                    justifyContent: 'space-between',
                    flex: '1 0 0',
                    borderRadius: '12px',
                    borderColor: 'rgba(0, 0, 0, 0.20)',
                    flexDirection: 'column'
                  }}
                >
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Grid
                      container
                      style={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Grid
                        style={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        {box.registers.map((ele, index) => {
                          return (
                            <Grid
                              key={index}
                              style={{
                                display: 'flex',
                                flexDirection: 'row'
                              }}
                            >
                              <Typography variant='h3'>{ele.name}</Typography>
                            </Grid>
                          )
                        })}
                      </Grid>

                      <Grid
                        container
                        style={{
                          justifyContent: 'space-between',
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        {box.devices.map((ele, index) => (
                          <Grid
                            key={index}
                            item
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              marginRight: space.XS
                            }}
                          >
                            {getHealthIcon(ele.status)}

                            <Typography
                              variant='body1'
                              onClick={() => {
                                history.push(
                                  `/${businessId}/peripheral-hub/activity-log/${ele.id}`
                                )
                              }}
                              style={{
                                color: 'black',
                                textDecoration: 'none',
                                cursor: 'pointer'
                              }}
                            >
                              {ele.name}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default ZoneDevices
