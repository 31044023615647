import {graphql} from 'graphql/generatedTypes'

export const CREATE_OAUTH_APPLICATIONS = graphql(`
  mutation generateOauthApplications($locationId: String!, $appName: String!) {
    generateOauthApplications(locationId: $locationId, appName: $appName) {
      appName
      clientId
      clientSecret
      status
    }
  }
`)
