import {useLazyQuery, useSubscription} from '@apollo/client'
import {Grid} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import CashManagementKeyStats from 'clientDashboard/cashManagement/cashManagerInsights/CashManagementKeyStats'
import CashManagementStatisticsHeader from 'clientDashboard/cashManagement/cashManagerInsights/CashManagementStatisticsHeader'
import CashTransactionListing from 'clientDashboard/cashManagement/CashManagerTransactionListing'
import DurationPicker from 'clientDashboard/cashManagement/DurationPicker'
import InsightCharts from 'clientDashboard/cashManagement/InsightCharts'
import InsightsEmployeePicker from 'clientDashboard/cashManagement/InsightsEmployeePicker'
import InsightsLocationPicker from 'clientDashboard/cashManagement/InsightsLocationPicker'
import {
  CASH_ADJUSTMENT_EVENT_TYPE,
  INSIGHTS_CHART_CASH_MANAGEMENT_CATEGORY_VARIANCE,
  INSIGHTS_TYPE_CASH_MANAGEMENT,
  RECONCILED_EVENT_TYPE
} from 'constants/constants'
import {STATS_FOR_VARIANCE_REPORT} from 'graphql/queries/cash-management.queries'
import {GET_INSIGHT_CHARTS} from 'graphql/queries/insights.queries'
import {CASH_EVENT_SUBSCRIPTION} from 'graphql/subscriptions/cash-event-subscriptions'
import {t} from 'i18next'
import {useEffect, useState} from 'react'
import {convertToDollars, formatCurrency} from 'utils/common'
import {
  generateRangeFromDuration,
  getDurationTypeByDurationId
} from 'utils/date.utils'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectCurrentStore} from 'clientDashboard/employee.selectors'

function CashManagementVarianceInsights() {
  const currentStore = useAppSelector(selectCurrentStore) as any
  const [baseStore, setBaseStore] = useState<any>(currentStore)
  const [duration, setDuration] = useState('today')

  const [employee, setEmployee] = useState<string>('')
  const [chartData, setChartData] = useState(null as any)
  const [insightChart, setInsightsChart] = useState(null as any)
  const [totalExpectedCashStats, setTotalExpectedCashStats] = useState({
    totalExpectedCash: '',
    percentageChange: ''
  })
  const [totalActualCashStats, setTotalActualCashStats] = useState({
    totalActualCash: '',
    percentageChange: ''
  })
  const [totalVarianceStats, setTotalVarianceStats] = useState({
    totalVariance: '',
    percentageChange: ''
  })
  const [averageVariancePercentageStats, setAverageVariancePercentageStats] =
    useState({
      averageVariancePercentage: '',
      percentageChange: ''
    })

  const [getBusinessInsights] = useLazyQuery(GET_INSIGHT_CHARTS, {
    onCompleted: (data) => {
      let chartConfig = data.getInsightCharts?.charts?.filter(
        (chart: any) => chart.enableChart
      )
      if (chartConfig && chartConfig.length > 0) {
        setInsightsChart(chartConfig[0])
      }
    },
    onError: (error) => {
      console.log('Error getting business insights', error)
    }
  })

  useEffect(() => {
    let dateRange = generateRangeFromDuration(duration)
    let storeNumbers = []
    let employeeCodes = []
    let location_ids = []
    if (baseStore.storeNumber) {
      storeNumbers.push(baseStore.storeNumber)
      location_ids.push(baseStore.id)
    }
    if (employee) {
      employeeCodes.push(employee)
    }
    aggregrateCashTransactions({
      variables: {
        locationIds: [baseStore.id],
        transactionTimeRange: dateRange,
        eventType: [CASH_ADJUSTMENT_EVENT_TYPE, RECONCILED_EVENT_TYPE],
        ...(employeeCodes.length > 0 && {employeeCodes: employeeCodes})
      }
    })
    getBusinessInsights({
      variables: {
        chartCategory: INSIGHTS_CHART_CASH_MANAGEMENT_CATEGORY_VARIANCE,
        insightType: INSIGHTS_TYPE_CASH_MANAGEMENT,
        durationType: getDurationTypeByDurationId(duration),
        storeNumbers: storeNumbers.join(','),
        locationIds: location_ids.join(','),
        duration: dateRange.join(','),
        employeeCodes: employeeCodes.join(',')
      }
    })
  }, [baseStore.storeNumber, duration, employee])

  const [
    aggregrateCashTransactions,
    {loading: aggregrateCashTransactionsLoading}
  ] = useLazyQuery(STATS_FOR_VARIANCE_REPORT, {
    onCompleted: (data) => {
      setTotalExpectedCashStats({
        totalExpectedCash: formatCurrency(
          convertToDollars(
            data?.aggregrateCashTransactions?.totalExpectedCashStats
              ?.totalExpectedCash
          )
        ),
        percentageChange:
          data?.aggregrateCashTransactions?.totalExpectedCashStats
            ?.percentageChange
      })
      setTotalActualCashStats({
        totalActualCash: formatCurrency(
          convertToDollars(
            data?.aggregrateCashTransactions?.totalActualCashStats
              ?.totalActualCash
          )
        ),
        percentageChange:
          data?.aggregrateCashTransactions?.totalActualCashStats
            ?.percentageChange
      })
      setTotalVarianceStats({
        totalVariance: formatCurrency(
          convertToDollars(
            data?.aggregrateCashTransactions?.totalVarianceStats?.totalVariance
          )
        ),
        percentageChange:
          data?.aggregrateCashTransactions?.totalVarianceStats?.percentageChange
      })
      setAverageVariancePercentageStats({
        averageVariancePercentage: data?.aggregrateCashTransactions
          ?.averageVariancePercentageStats?.averageVariancePercentage as string,
        percentageChange:
          data?.aggregrateCashTransactions?.averageVariancePercentageStats
            ?.percentageChange
      })
    },
    onError: (error) => {
      console.log('Error getting aggregrateCashTransactions', error)
    }
  })

  const {data} = useSubscription(CASH_EVENT_SUBSCRIPTION, {
    variables: {
      locationId: currentStore?.id as string
    }
  })

  useEffect(() => {
    if (data) {
      const cashEvent = data.webCashEvent?.cashEvent
      let dateRange = generateRangeFromDuration(duration)
      if (cashEvent === 'refresh') {
        aggregrateCashTransactions({
          variables: {
            locationIds: [baseStore.id],
            transactionTimeRange: dateRange,
            eventType: [CASH_ADJUSTMENT_EVENT_TYPE, RECONCILED_EVENT_TYPE]
          }
        })
      }
    }
  }, [data])

  return (
    <Grid
      key='main-grid'
      container
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
        alignSelf: 'stretch',
        alignItems: 'flex-start',
        gap: '24px',
        flex: '1 0 0'
      }}
    >
      <Grid
        key='filter-grid'
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '12px',
          flex: '1 0 0'
        }}
      >
        <Grid
          item
          style={{
            width: '200px'
          }}
        >
          <InsightsLocationPicker
            label={'Location'}
            store={baseStore}
            setStore={setBaseStore}
          />
        </Grid>
        <Grid
          item
          style={{
            width: '200px'
          }}
        >
          <InsightsEmployeePicker
            employee={employee}
            setEmployee={setEmployee}
          />
        </Grid>
        <Grid
          item
          style={{
            width: '200px'
          }}
        >
          <DurationPicker duration={duration} setDuration={setDuration} />
        </Grid>
      </Grid>
      <Grid
        key={'chart-and-stats-grid'}
        item
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          gap: '24px'
        }}
      >
        <Grid
          key={'chart-grid'}
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #EFEFEF',
            borderRadius: '12px'
          }}
          xs={9}
        >
          <CashManagementStatisticsHeader
            title={t('insights.cash-management.variance-report.header-title')}
            subtitle={t(
              'insights.cash-management.variance-report.header-subtitle'
            )}
          />
          {insightChart && (
            <InsightCharts
              chartConfig={insightChart}
              setChartData={setChartData}
            />
          )}
        </Grid>
        <Grid
          key={'stats-grid'}
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '24px'
          }}
          xs={3}
        >
          <CashManagementKeyStats
            label={t(
              'insights.cash-management.variance-report.stats.total-expected-cash'
            )}
            value={totalExpectedCashStats?.totalExpectedCash}
            percentageChange={totalExpectedCashStats?.percentageChange}
            isLoading={aggregrateCashTransactionsLoading}
          />
          <CashManagementKeyStats
            label={t(
              'insights.cash-management.variance-report.stats.total-actual-cash'
            )}
            value={totalActualCashStats?.totalActualCash}
            percentageChange={totalActualCashStats?.percentageChange}
            isLoading={aggregrateCashTransactionsLoading}
          />
          <CashManagementKeyStats
            label={t(
              'insights.cash-management.variance-report.stats.total-variance'
            )}
            value={totalVarianceStats?.totalVariance}
            percentageChange={totalVarianceStats?.percentageChange}
            isLoading={aggregrateCashTransactionsLoading}
          />
          <CashManagementKeyStats
            label={t(
              'insights.cash-management.variance-report.stats.average-variance-percentage'
            )}
            value={averageVariancePercentageStats?.averageVariancePercentage}
            percentageChange={averageVariancePercentageStats?.percentageChange}
            isLoading={aggregrateCashTransactionsLoading}
          />
        </Grid>
      </Grid>
      <Grid
        item
        key={'data-table'}
        style={{
          width: '100%'
        }}
      >
        <CashTransactionListing
          locationId={baseStore.id}
          dateRange={generateRangeFromDuration(duration)}
          eventType={CASH_ADJUSTMENT_EVENT_TYPE}
          chartData={chartData}
          employeeCodes={employee === '' ? ['all'] : [employee]}
          subcriptionData={data}
        />
      </Grid>
    </Grid>
  )
}

export default CashManagementVarianceInsights
