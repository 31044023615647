import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Grid, IconButton, InputAdornment} from '@mui/material'
import StyledInput from 'ui/atoms/StyledInput'
import {colors, space} from 'config/theme'
import {forwardRef, useCallback, useEffect, useImperativeHandle} from 'react'
import SpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition'

type ConsumerAgentQueryInputProps = {
  text: string
  setText: (text: string) => void
  submitText: () => void
}

export interface ConsumerAgentQueryInputRef {
  focusOnInput: () => void
}

const ConsumerAgentQueryInput = forwardRef<
  ConsumerAgentQueryInputRef,
  ConsumerAgentQueryInputProps
>((props, ref) => {
  const {text, setText, submitText} = props
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition()

  const handleListening = useCallback(() => {
    if (listening) {
      SpeechRecognition.stopListening()
    } else {
      SpeechRecognition.startListening()
    }
  }, [listening])

  useImperativeHandle(ref, () => ({
    focusOnInput() {
      const input = document.getElementById('query-input')
      input?.focus()
    }
  }))

  useEffect(() => {
    if (!listening && transcript) {
      setText(transcript)
      setTimeout(() => resetTranscript(), 500) // Adjust the timeout as needed
    }
  }, [listening, transcript, resetTranscript])

  return (
    <Grid sx={{display: 'flex', alignItems: 'center', p: 2}}>
      <StyledInput
        id='query-input'
        variant='outlined'
        placeholder={'Ask me anything...'}
        value={text}
        onChange={(e) => {
          setText(e.target.value)
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && text) {
            submitText()
          }
        }}
        style={{
          marginTop: space.XS,
          borderRadius: '4px',
          width: '100%',
          borderColor: colors.grayBorder,
          border: '1px solid'
        }}
        InputProps={{
          style: {
            fontSize: '16px'
          },

          startAdornment: (
            <InputAdornment position='start'>
              <FontAwesomeIcon
                icon={icon({
                  name: 'search',
                  family: 'classic',
                  style: 'light'
                })}
                style={{
                  color: 'black',
                  fontSize: '20px'
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <>
                {browserSupportsSpeechRecognition &&
                  !text &&
                  (listening ? (
                    <IconButton onClick={handleListening}>
                      <FontAwesomeIcon
                        icon={icon({
                          name: 'circle-stop',
                          family: 'classic',
                          style: 'solid'
                        })}
                        style={{
                          fontSize: '20px'
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton onClick={handleListening}>
                      <FontAwesomeIcon
                        icon={icon({
                          name: 'microphone',
                          family: 'classic',
                          style: 'solid'
                        })}
                        style={{
                          fontSize: '20px'
                        }}
                      />
                    </IconButton>
                  ))}

                {text && (
                  <IconButton onClick={submitText}>
                    <FontAwesomeIcon
                      icon={icon({
                        name: 'circle-arrow-up',
                        family: 'classic',
                        style: 'solid'
                      })}
                      style={{
                        fontSize: '30px',
                        color: 'black'
                      }}
                    />
                  </IconButton>
                )}
              </>
            </InputAdornment>
          )
        }}
      />
    </Grid>
  )
})

export default ConsumerAgentQueryInput
