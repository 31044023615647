import {RootState} from 'clientDashboard/clientDashboard.store'
import {createSelector} from "@reduxjs/toolkit";
import _map from "lodash/map";
import {StoreGroupClusters} from "clientDashboard/constants/employee.constants";
import _flatten from "lodash/flatten";

export const selectAppliedGroupsForEmployeeModal = (state: RootState) =>
  state.settings?.employeeManagement?.editEmployeeModalData
    ?.appliedLocationGroups

export const selectAssignedGroupAndLocationIds = createSelector(
  (state: RootState) => state.settings?.employeeManagement?.editEmployeeModalData?.appliedLocationGroups,
  (applied) => {
    const groupMap = {...applied}

    const locationIds = _map(groupMap[StoreGroupClusters.Location], 'id')
    groupMap[StoreGroupClusters.Location] = []

    const groups = _flatten(Object.values(groupMap))
    const groupIds = _map(groups, 'id')

    return ({
      locationIds,
      groupIds
    })
  }
)


export const selectClusterOptionsForEmployeeModal = (state: RootState) =>
  state.settings?.employeeManagement?.editEmployeeModalData?.clusterOptionsMap
