import {SecureRoute} from '@okta/okta-react'
import {AuthMethod, LocalStorageKeys} from 'constants/constants'
import useAccessToken from 'hooks/useAccessToken'
import useLocalStorage from 'hooks/useLocalStorage'
import React from 'react'
import {Route, RouteProps} from 'react-router-dom'

const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const [authMethod] = useLocalStorage(
    LocalStorageKeys.AUTH_METHOD,
    AuthMethod.TOTE
  )

  // check if the user is authenticated
  const {isAuthenticated} = useAccessToken()

  if (!isAuthenticated && authMethod === AuthMethod.TOTE) {
    window.location.href = window.location.origin + `/login`
  }

  if (authMethod === AuthMethod.OKTA) {
    return (
      <SecureRoute
        exact={props.exact}
        path={props.path}
        component={props.component}
      />
    )
  }

  if (authMethod === AuthMethod.TOTE && isAuthenticated) {
    return <Route {...props} />
  }

  return <div />
}

export default ProtectedRoute
