import {useLazyQuery, useMutation} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Box, Grid, IconButton, Tooltip, Typography} from '@mui/material'
import StyledButton from 'ui/atoms/StyledButton'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledInput from 'ui/atoms/StyledInput'
import StyledTable from 'ui/molecules/StyledTable'
import {SecretType} from 'graphql/generatedTypes/graphql'
import {ADD_SECRET, DELETE_SECRET} from 'graphql/mutations/secrets.mutations'
import {LIST_SECRETS} from 'graphql/queries/secrets.queries'
import useNotify from 'hooks/useNotify'
import _ from 'lodash'
import {MRT_ColumnDef} from 'material-react-table'
import {useMemo} from 'react'
import {useEffect, useState} from 'react'
import theme, {colors} from 'config/theme'

const defaultFormValues = {
  secretName: '',
  secretValue: ''
}

function SecretManager() {
  const notify = useNotify()

  const [secretNamesList, setSecretNamesList] = useState<SecretType[]>([])
  const [formValues, setFormValues] = useState({...defaultFormValues})
  const [isEditMode, setIsEditMode] = useState(false)
  const [secretNameForDelete, setSecretNameForDelete] = useState('')
  const [showAddSecretDialog, setShowAddSecretDialog] = useState(false)
  const [showDeleteSecretDialog, setShowDeleteSecretDialog] = useState(false)
  const clearForm = () => {
    setFormValues({
      ...defaultFormValues
    })
  }

  const [
    getSecretNamesList,
    {data: _secretNames, loading: loadingSecretNames}
  ] = useLazyQuery(LIST_SECRETS, {
    fetchPolicy: 'no-cache'
  })

  const [_addSecret, {loading: loadingAddSecret}] = useMutation(ADD_SECRET, {
    onCompleted: () => {
      getSecretNamesList()
      clearForm()
      setShowAddSecretDialog(false)
    },
    onError: (error) => {
      console.log('Error saving secret', error)
      notify.show('Failed to save Secret', 'error')
      clearForm()
    }
  })

  const addSecret = () => {
    _addSecret({
      variables: {
        secretName: formValues.secretName,
        secretValue: formValues.secretValue
      }
    })
  }

  const [_deleteSecret, {loading: loadingDeleteSecret}] = useMutation(
    DELETE_SECRET,
    {
      onCompleted: () => {
        getSecretNamesList()
        clearForm()
        setShowDeleteSecretDialog(false)
      },
      onError: (error) => {
        console.log('Error deleting secret', error)
        notify.show('Failed to delete Secret', 'error')
      }
    }
  )

  const deleteSecret = () => {
    _deleteSecret({
      variables: {
        secretName: secretNameForDelete
      }
    })
  }

  const fetchSecretNamesList = () => {
    getSecretNamesList({
      variables: {}
    })
  }

  useEffect(() => {
    fetchSecretNamesList()
  }, [])

  useEffect(() => {
    setShowAddSecretDialog(loadingAddSecret)
    if (isEditMode && !loadingAddSecret) {
      setIsEditMode(false)
    }
  }, [loadingAddSecret])

  useEffect(() => {
    setShowDeleteSecretDialog(loadingDeleteSecret)
  }, [loadingDeleteSecret])

  useEffect(() => {
    if (_secretNames) {
      setSecretNamesList(_secretNames.listSecrets as SecretType[])
    }
  }, [_secretNames])

  const columns = useMemo<MRT_ColumnDef<SecretType>[]>(
    () => [
      {
        accessorKey: 'secretName',
        header: 'Secret Name',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      }
    ],
    []
  )

  const secretDialogBody = () => {
    const handleChange = (path: any, value: any) => {
      setFormValues((prev) => {
        const updatedValues = {...prev}
        _.set(updatedValues, path, value)
        return updatedValues
      })
    }

    return (
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <Typography variant='h4'>Details</Typography>
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='Secret Name'
            variant='outlined'
            value={formValues.secretName}
            onChange={(e: any) => handleChange('secretName', e.target.value)}
            disabled={isEditMode}
            autoComplete='off'
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='Secret Value'
            variant='outlined'
            value={formValues.secretValue}
            onChange={(e: any) => handleChange('secretValue', e.target.value)}
            autoComplete='off'
          />
        </Grid>
      </Grid>
    )
  }

  const deleteSecretDialogBody = () => {
    return (
      <Grid container spacing={2} padding={2}>
        <Typography variant='subtitle2'>
          {`Are you sure you want to delete the secret: `}
          <strong>
            <em>{secretNameForDelete}</em>
          </strong>
          {`?`}
        </Typography>
      </Grid>
    )
  }

  return (
    <>
      <StyledTable
        columns={columns}
        data={secretNamesList}
        enableTableFooter={false}
        enableRowActions={true}
        state={{
          showSkeletons: loadingSecretNames,
          showProgressBars: loadingSecretNames
        }}
        muiTableFooterCellProps={{
          align: 'center',
          sx: {
            backgroundColor: theme.palette.background.paper,
            border: '1px solid',
            borderColor: colors.grayBorder,
            borderRadius: '6px',
            fontSize: '14px'
          }
        }}
        initialState={{
          columnPinning: {
            left: ['mrt-row-expand'],
            right: ['mrt-row-actions']
          }
        }}
        renderTopToolbarCustomActions={() => {
          return (
            <Grid
              style={{
                order: 1
              }}
            >
              <StyledButton
                onClick={() => {
                  setShowAddSecretDialog(true)
                }}
                disabled={loadingAddSecret}
              >
                Add Secret
              </StyledButton>
            </Grid>
          )
        }}
        renderDetailPanel={({row}) => {
          if (loadingSecretNames) return ''
          return (
            <Box>
              <Typography variant='h3'>Secret Details</Typography>
              <Typography variant='subtitle2'>
                SecretName: {row.original.secretName}
              </Typography>
            </Box>
          )
        }}
        renderRowActions={({row}) => (
          <Box>
            <Tooltip title='Edit Secret Value'>
              <IconButton
                onClick={() => {
                  setShowAddSecretDialog(true)
                  setIsEditMode(true)

                  setFormValues({
                    secretName: row.original.secretName ?? '',
                    secretValue: row.original.secretValue ?? ''
                  })
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'edit',
                    family: 'classic',
                    style: 'light'
                  })}
                  fontSize={20}
                  color='black'
                  style={{
                    borderWidth: '1px'
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete Secret'>
              <IconButton
                onClick={() => {
                  setSecretNameForDelete(row.original.secretName)
                  setShowDeleteSecretDialog(true)
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'trash-alt',
                    family: 'classic',
                    style: 'regular'
                  })}
                  fontSize={20}
                  style={{
                    borderWidth: '1px'
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />

      <Grid item>
        <StyledDialog
          open={showAddSecretDialog}
          onClose={() => {
            setShowAddSecretDialog(false)
            clearForm()
            setIsEditMode(false)
          }}
          title={isEditMode ? 'Edit Secret Value' : 'Add Secret'}
          successButtonName={isEditMode ? 'Update' : 'Add'}
          cancelButtonName='Cancel'
          cancelCallback={() => {
            setShowAddSecretDialog(false)
            clearForm()
            setIsEditMode(false)
          }}
          disableSuccessButton={
            !formValues.secretName ||
            !formValues.secretValue ||
            (secretNamesList.some(
              (secret) => secret.secretName === formValues.secretName
            ) &&
              !isEditMode) ||
            loadingAddSecret
          }
          successCallback={() => {
            addSecret()
          }}
          body={secretDialogBody()}
        />
      </Grid>
      <Grid item>
        <StyledDialog
          open={showDeleteSecretDialog}
          onClose={() => {
            setShowDeleteSecretDialog(false)
          }}
          title='Delete Secret'
          successButtonName='Delete'
          cancelButtonName='Cancel'
          cancelCallback={() => {
            setShowDeleteSecretDialog(false)
            clearForm()
          }}
          successCallback={() => {
            deleteSecret()
          }}
          disableSuccessButton={loadingDeleteSecret}
          body={deleteSecretDialogBody()}
        />
      </Grid>
    </>
  )
}

export default SecretManager
