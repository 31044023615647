import {useState} from 'react'

type LocalStorageType<T> = [T | null, (value: T) => void, () => void]

function useLocalStorage<T>(
  key: string,
  initialValue?: T
): LocalStorageType<T> {
  const [storedValue, setStoredValue] = useState<T | null>(() => {
    try {
      const item = localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue ?? null
    } catch (error) {
      return initialValue ?? null
    }
  })

  const setValue = (value: T) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      console.error(error)
    }
  }

  const clearValue = () => {
    try {
      localStorage.removeItem(key)
      setStoredValue(null)
    } catch (error) {
      console.error(error)
    }
  }

  return [storedValue, setValue, clearValue]
}

export default useLocalStorage
