import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Grid} from '@mui/material'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import StyledButton from 'ui/atoms/StyledButton'
import theme, {colors, space} from 'config/theme'
import {
  MaterialReactTable,
  MRT_RowData,
  MRT_ShowHideColumnsMenu as MRTShowHideColumnsMenu,
  MRT_TableOptions,
  useMaterialReactTable
} from 'material-react-table'
import {useState} from 'react'

function StyledTable<TData extends MRT_RowData>({
  columns,
  data,
  ...others
}: MRT_TableOptions<TData>) {
  const [anchorElForShowHideColumns, setAnchorElForShowHideColumns] =
    useState<HTMLElement | null>(null)

  const handleClickedShowHideColumns = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElForShowHideColumns(event.currentTarget)
  }

  const tableInstance = useMaterialReactTable({
    columns,
    data,

    muiSelectCheckboxProps: {
      size: 'small'
    },
    muiSelectAllCheckboxProps: {
      size: 'small'
    },

    muiTableHeadCellProps: {
      align: 'left',
      sx: {
        backgroundColor: theme.palette.background.paper,
        borderBottom: '0.5px solid',
        borderLeft: '0.25px solid',
        borderRight: '0.25px solid',
        borderColor: colors.grayBorder,
        paddingTop: '22px',
        paddingBottom: '22px',
        fontSize: '16px',
        fontWeight: 500,
        textTransform: 'capitalize'
      }
    },
    muiTableContainerProps: {
      sx: {
        borderTop: '1px solid',
        borderColor: colors.grayBorder
      }
    },
    muiTableBodyCellProps: {
      align: 'left',
      sx: {
        border: '1px solid',
        borderColor: colors.grayBorder,
        fontSize: '14px',
        fontWeight: 400
      }
    },

    muiTopToolbarProps: {
      justifyContent: 'flex-end',
      marginBottom: space.XS,
      marginRight: `-${space.XS}`
    },

    renderToolbarInternalActions() {
      return (
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={2}
          style={{
            alignContent: 'center',
            alignItems: 'center'
          }}
        >
          <Grid item>
            <StyledButton
              variant='outlined'
              onClick={() => {
                tableInstance.setShowColumnFilters(
                  (showColumnFilters: boolean) => !showColumnFilters
                )
              }}
              style={{
                color: 'black',
                borderColor: colors.grayBorder
              }}
              startIcon={
                <FontAwesomeIcon
                  icon={icon({
                    name: 'bars-filter',
                    family: 'classic',
                    style: 'regular'
                  })}
                  color={theme.palette.text.secondary}
                  fontSize={20}
                />
              }
            >
              Filter
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              variant='outlined'
              onClick={handleClickedShowHideColumns}
              style={{
                color: 'black',
                borderColor: colors.grayBorder
              }}
              startIcon={
                <FontAwesomeIcon
                  icon={icon({
                    name: 'columns-3',
                    family: 'classic',
                    style: 'solid'
                  })}
                  color={theme.palette.text.secondary}
                  fontSize={20}
                />
              }
            >
              Columns
            </StyledButton>
          </Grid>

          {anchorElForShowHideColumns && (
            <MRTShowHideColumnsMenu
              anchorEl={anchorElForShowHideColumns}
              setAnchorEl={setAnchorElForShowHideColumns}
              table={tableInstance}
            />
          )}
        </Grid>
      )
    },

    enableGlobalFilter: false,
    autoResetPageIndex: false,
    enableFullScreenToggle: false,
    enablePagination: true,
    enableDensityToggle: false,
    paginationDisplayMode: 'pages',
    enableColumnActions: false,
    ...others
  })

  return (
    <Grid item style={{overflowX: 'auto', maxWidth: '100%'}}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MaterialReactTable table={tableInstance} />
      </LocalizationProvider>
    </Grid>
  )
}

export default StyledTable
