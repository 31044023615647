import {gql} from '@apollo/client'

export const GET_RULES = gql`
  query allRules($businessId: String!) {
    allRules(businessId: $businessId) {
      businessId
      ruleName
      ruleType
      ruleValue
      applyTo
      excludeFrom
      applyToProductGroup
      excludeFromProductGroup
    }
  }
`

export const GET_RULES_TYPES = gql`
  query getRuleTypes {
    getRuleTypes
  }
`

export const GET_PROMOTIONS_TYPES = gql`
  query getPromotionTypes {
    getPromotionTypes
  }
`

export const GET_PRODUCT_SKU_LIST = gql`
  query getProductSkuList {
    getProductSkuList
  }
`

export const GET_PROMOTION_TYPES = gql`
  query getPromotionTypes {
    getPromotionTypes
  }
`

export const GET_PROMOTION_SCHEMAS_TYPE = gql`
  query getPromotionSchemaTypes {
    getPromotionSchemaTypes
  }
`

export const GET_PROMOTIONS = gql`
  query getAllPromotions($businessId: String!) {
    getAllPromotions(businessId: $businessId) {
      id
      businessId
      active
      canBeCombined
      description
      endDate
      promotionName
      promotionType
      startDate
      promotionValue
      priority
      promotionSchema
      subscriptionType
      rules {
        ruleName
        ruleType
        ruleValue
      }
    }
  }
`

export const GET_TOTALS_FOR_PROMOTIONS = gql`
  query GetTotalsForPromotions(
    $sku: String!
    $quantity: Int!
    $promotionNames: [String!]!
    $businessId: String!
  ) {
    getTotalsForPromotions(
      sku: $sku
      quantity: $quantity
      promotionNames: $promotionNames
      businessId: $businessId
    ) {
      totalAmount
      promotionAmount
    }
  }
`

export const GET_INVENTORY = gql(`
  query getInventoryBySkuAndDiscount($sku: String!) {
    getInventoryBySkuAndDiscount(sku: $sku) {
      sku
      unitPrice
      unitDiscount
      unitTaxAmount
      description
      unitSubTotal
      aisleLocation
      logoUrl
    }
  }
`)
export const GET_CART_GUEST = gql`
  query cartGuest($cartId: String!) {
    cartGuest(cartId: $cartId) {
      id
      items {
        sku
        quantity
        unitPrice
        unitDiscount
        description
        logoUrl
        unitSubTotal
        unitDiscount
        unitTaxAmount
        totalTaxAmount
        totalDiscount
        total
      }
      totalDiscount
      subTotal
      totalAmount
      totalTax
    }
  }
`
