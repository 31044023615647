import dayjs from 'dayjs'

export {}

export function generateRangeFromDuration(durationId: string) {
  const now = dayjs()
  let start, end

  switch (durationId) {
    case 'today':
      start = now.startOf('day')
      end = now.endOf('day')
      break
    case 'yesterday':
      start = now.subtract(1, 'day').startOf('day')
      end = now.subtract(1, 'day').endOf('day')
      break
    case 'last7days':
      start = now.subtract(7, 'day').startOf('day')
      end = now.endOf('day')
      break
    case 'last30days':
      start = now.subtract(30, 'day').startOf('day')
      end = now.endOf('day')
      break
    case 'last90days':
      start = now.subtract(90, 'day').startOf('day')
      end = now.endOf('day')
      break
    case 'thismonth':
      start = now.startOf('month')
      end = now.endOf('month')
      break
    case 'lastmonth':
      start = now.subtract(1, 'month').startOf('month')
      end = now.subtract(1, 'month').endOf('month')
      break
    case 'last3months':
      start = now.subtract(3, 'month').startOf('month')
      end = now.endOf('month')
      break
    case 'last6months':
      start = now.subtract(6, 'month').startOf('month')
      end = now.endOf('month')
      break
    default:
      throw new Error('Invalid duration')
  }
  return [start.toDate(), end.toDate()]
}

export const getChartByDurationCategory = (
  duration: string,
  insightsConfig: any[]
) => {
  let durationCategory = ''
  durationCategory = ['today', 'yesterday'].includes(duration)
    ? 'hour'
    : ['last3months', 'last6months', 'thismonth', 'lastmonth'].includes(
          duration
        )
      ? 'date-of-month'
      : 'week'
  const chart = insightsConfig.find(
    (chart: any) => chart.xAxis.timeType === durationCategory
  )
  return chart
}

export const generateTransactionTimeFilterFromRange = (range: any) => {
  const start = dayjs(range[0])
  const end = dayjs(range[1])
  return {$gte: start.toDate(), $lt: end.toDate()}
}

export const getDurationTypeByDurationId = (durationId: string) => {
  switch (durationId) {
    case 'today':
    case 'yesterday':
      return 'hourly'
    case 'last7days':
    case 'last30days':
    case 'last90days':
    case 'thismonth':
    case 'lastmonth':
    case 'last3months':
    case 'last6months':
      return 'daily'
    default:
      return 'weekly'
  }
}
