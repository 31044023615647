import {Button, ButtonProps} from '@mui/material'
import {ForwardRefRenderFunction} from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import {colors} from "config/theme";

type StyledButton = ButtonProps & {showLoader?: boolean}

const StyledButton: ForwardRefRenderFunction<HTMLInputElement, StyledButton> =
  function StyledButton({
    variant = 'contained',
    size = 'small',
    children,
    showLoader = false,
    ...others
  }: StyledButton) {
    return (
      <Button
        disableRipple={variant.startsWith('text')}
        variant={variant}
        size={size}
        sx={{
          '&.MuiButton-root': {
            boxShadow: 'none',
            borderRadius: '8px',
            padding: '8px 16px 8px 16px',
            borderWidth: '1px',
            fontSize: '16px'
          }
        }}
        {...others}
      >
        {showLoader ? (
          <div className={'flex justify-center items-center'}>
            <CircularProgress
              size={20}
              style={{color: colors.grayFont}}
              className={'mr-2'}
            />
            {children}
          </div>
        ) : (
          children
        )}
      </Button>
    )
  }
export default StyledButton
