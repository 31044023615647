import {Grid} from '@mui/material'
import BusinessColors from 'clientDashboard/settings/BusinessColors'
import TemplateEditor from 'clientDashboard/settings/TemplateEditor'

function BusinessSettings() {
  return (
    <Grid
      container
      gap={4}
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Grid item>
        <BusinessColors />
      </Grid>

      <Grid item>
        <TemplateEditor />
      </Grid>
    </Grid>
  )
}

export default BusinessSettings
