import {Grid} from '@mui/material'
import EmployeeManager from 'clientDashboard/settings/EmployeeManager'
import PermissionWrapper from 'clientDashboard/settings/PermissionWrapper'
import {Permission} from 'constants/permissions'

function EmployeesSettings() {
  return (
    <Grid>
      <Grid item>
        <PermissionWrapper permissions={[Permission.ASSIGN_ROLE]}>
          <EmployeeManager />
        </PermissionWrapper>
      </Grid>
    </Grid>
  )
}

export default EmployeesSettings
