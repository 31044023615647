import StyledDialog from 'ui/molecules/StyledDialog'
import {t} from 'i18next'
import {Grid} from '@mui/material'
import StyledTab from 'ui/molecules/StyledTab'
import {ZONE_EDIT_TABS} from 'constants/constants'
import React from 'react'
import ZoneInfoTab from 'clientDashboard/peripheralHub/peripheralHubOverview/AddOrEditZoneModal/ZoneInfoTab'
import ZoneDevicesTab from 'clientDashboard/peripheralHub/peripheralHubOverview/AddOrEditZoneModal/ZoneDevicesTab'
import {
  useAppDispatch,
  useAppSelector
} from 'clientDashboard/clientDashboard.store'
import {
  closeEditZoneModal,
  setDeviceOverviewData,
  updateEditZoneModalData
} from 'clientDashboard/peripheralHub/peripheralHub.slice'
import {
  selectEditZoneModalData,
  selectZones
} from 'clientDashboard/peripheralHub/peripheralHub.selectors'
import {ZoneType} from 'clientDashboard/peripheralHub/types/peripheral-hub.types'
import {selectBusinessAndLocation} from 'clientDashboard/employee.selectors'
import {useMutation} from '@apollo/client'
import {
  ADD_ZONE_DATA,
  UPDATE_ZONE_DATA
} from 'graphql/mutations/peripheral-hub.mutations'
import {getUpdatedZonesData} from 'clientDashboard/peripheralHub/peripheralHubOverview/AddOrEditZoneModal/addOrEditZoneModal.helpers'

const AddOrEditZoneModal = ({onUpdateComplete}: any) => {
  const dispatch = useAppDispatch()

  // zoneData -> data for all zones
  // editZoneData -> data for zone being edited
  const zoneData = useAppSelector(selectZones)
  let editZoneData = useAppSelector(selectEditZoneModalData) as ZoneType

  const {businessId, locationId} = useAppSelector(selectBusinessAndLocation)

  const onCompleted = () => {
    dispatch(closeEditZoneModal())
    onUpdateComplete()
  }
  const [addZoneDataApi, {loading: addApiLoading}] = useMutation(
    ADD_ZONE_DATA,
    {onCompleted}
  )

  const [editZoneDataApi, {loading: editApiLoading}] = useMutation(
    UPDATE_ZONE_DATA,
    {onCompleted}
  )

  const [selectedTab, setSelectedTab] = React.useState(ZONE_EDIT_TABS.ZONE_INFO)

  // @ts-ignore
  const selectedItems = editZoneData.selectedItems

  const confirmEditZone = () => {
    const [allZones, currentZone] = getUpdatedZonesData(
      zoneData,
      editZoneData,
      selectedItems
    )

    // @ts-ignore
    editZoneData = currentZone
    dispatch(updateEditZoneModalData(editZoneData))
    dispatch(setDeviceOverviewData({zones: allZones}))
    saveZoneData()
  }

  const saveZoneData = () => {
    const {id, name, description, devices, registers} = editZoneData
    const variables = {
      businessId: businessId,
      locationId: locationId,
      name,
      description,
      devices,
      registers,
      ...(id && {zoneId: id})
    }

    const updateApi = id ? editZoneDataApi : addZoneDataApi
    // @ts-ignore
    updateApi({variables})
  }

  const showLoader = addApiLoading || editApiLoading

  return (
    <StyledDialog
      open
      title={t('peripheral-hub.zone-manager.edit-zone')}
      body={
        <Grid>
          <StyledTab
            value={selectedTab}
            tabs={[
              {
                label: t('peripheral-hub.zone-manager.tab.zone-info'),
                value: ZONE_EDIT_TABS.ZONE_INFO
              },
              {
                label: t('peripheral-hub.zone-manager.tab.zone-devices'),
                value: ZONE_EDIT_TABS.ZONE_DEVICES
              }
            ]}
            onChange={(event: React.ChangeEvent<{}>, value: string) =>
              setSelectedTab(value)
            }
          />
          {selectedTab === ZONE_EDIT_TABS.ZONE_INFO && <ZoneInfoTab />}
          {selectedTab === ZONE_EDIT_TABS.ZONE_DEVICES && <ZoneDevicesTab />}
        </Grid>
      }
      successButtonName={t('peripheral-hub.zone-manager.confirm-button')}
      cancelButtonName={t('peripheral-hub.zone-manager.cancel-button')}
      successCallback={() => {
        confirmEditZone()
      }}
      cancelCallback={() => {
        dispatch(closeEditZoneModal())
      }}
      closeCallback={() => {
        dispatch(closeEditZoneModal())
      }}
      disableSuccessButton={showLoader}
      showLoader={showLoader}
    />
  )
}

export default AddOrEditZoneModal
