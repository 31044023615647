import {Grid, Typography, useMediaQuery} from '@mui/material'
import {colors, space} from 'config/theme'
import React from 'react'
import classes from 'auth/login.styles.module.css'

type LoginInterfaceProps = {
  body: React.ReactNode
}

const GridItem = ({children, xs, style}: any) => (
  <Grid item xs={xs} style={style}>
    {children}
  </Grid>
)

const LoginInterface = ({body}: LoginInterfaceProps) => {
  const isMobileScreen = useMediaQuery('(max-width:600px)')
  const flexDirection = isMobileScreen ? 'column' : 'row'
  const xsValue = isMobileScreen ? 12 : 6

  return (
    <Grid
      rowSpacing={isMobileScreen ? 4 : 0}
      columnSpacing={isMobileScreen ? 0 : 4}
      container
      style={{
        display: 'flex',
        flexDirection,
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: isMobileScreen ? undefined : '100%'
      }}
    >
      <GridItem
        item
        container
        xs={xsValue}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: colors.loginInterfaceColor,
          alignItems: 'center',
          width: '100%',
          height: isMobileScreen ? undefined : '100%'
        }}
      >
        <Grid item>
          <Typography
            className={classes.logoFont}
            style={{
              fontSize: '96px'
            }}
          >
            TOTE
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className={classes.logoHelperText}
            style={{
              fontSize: '16px',
              marginTop: `-${space.LARGE}`,
              marginBottom: `${space.LARGE}`
            }}
          >
            Unify. Innovate. Transcend.
          </Typography>
        </Grid>
      </GridItem>

      <GridItem xs={xsValue} style={{width: '100%'}}>
        {body}
      </GridItem>
    </Grid>
  )
}

export default LoginInterface
