import {Grid} from '@mui/material'
import {useEffect} from 'react'
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'
import {useMutation} from '@apollo/client'
import {GENERATE_ATLAS_AUTH_TOKEN} from 'graphql/mutations/cash-management.mutations'
import {PlainObject} from '@mongodb-js/charts-embed-dom/dist/declarations/src/types'

// insight chart interface
interface AtlasDashboardProps {
  data: {
    dashboardId: string
    dashboardBaseUrl: string
    dashboardTitle: string
    dashboardSubtitle: string
    category: string
    type: string
    charts: {
      chartId: string
      filter: PlainObject
    }[]
  }
}

const AtlasDashboard = ({data}: AtlasDashboardProps) => {
  const [generateAtlasAuthToken] = useMutation(GENERATE_ATLAS_AUTH_TOKEN)

  async function login() {
    const data = await generateAtlasAuthToken()
    return data.data.generateAtlasAuthToken.token
  }

  async function renderDashboard() {
    const sdk = new ChartsEmbedSDK({
      baseUrl: data.dashboardBaseUrl,
      getUserToken: async function () {
        return await login()
      }
    })
    const dashboard = sdk.createDashboard({
      dashboardId: data.dashboardId,
      showAttribution: false,
      charts: data.charts,
      autoRefresh: true,
      maxDataAge: 1,
      widthMode: 'scale',
      heightMode: 'scale'
    })

    // render the chart into a container
    await dashboard.render(document.getElementById(data.dashboardId)!)
  }
  useEffect(() => {
    renderDashboard()
  }, [data])

  return <Grid item id={data.dashboardId} height={650} width={1300}></Grid>
}

export default AtlasDashboard
