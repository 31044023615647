import {graphql} from 'graphql/generatedTypes'

export const TRANSACTION_EVENT_SUBSCRIPTION = graphql(`
  subscription transactionEventSubscription(
    $locationId: String!
    $businessId: String!
  ) {
    webCashEvent(locationId: $locationId, businessId: $businessId) {
      cashEvent
    }
  }
`)

export const CART_SUBSCRIPTION_EVENTS = graphql(`
  subscription customerCartSubscriptionEvents($token: String!) {
    customerCartSubscriptionEvents(token: $token) {
      flow
      cart {
        subscriptionItems {
          frequency
          status
          description
          sku
          total
          quantity
        }
        id
        location {
          id
          name
          business {
            id
            name
          }
        }
      }
    }
  }
`)
