import {matchSorter} from 'match-sorter'
import {
  useAppDispatch,
  useAppSelector
} from 'clientDashboard/clientDashboard.store'
import {selectSaveStoreGroupModalData} from 'clientDashboard/settings/locations/locations.selectors'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledInput from 'ui/atoms/StyledInput'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch
} from '@mui/material'
import {useEffect, useMemo, useState} from 'react'
import {
  addAllToGroup,
  addToGroup,
  changeSaveStoreGroupModalField,
  closeSaveStoreGroupModal,
  SaveStoreGroup,
  removeAllFromGroup,
  removeFromGroup,
  undoAddAllToGroup
} from 'clientDashboard/settings/locations/locations.slice'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {selectStoreGroupClusters} from 'clientDashboard/business.selectors'
import {useMutation} from '@apollo/client'
import {
  CREATE_STORE_GROUP,
  UPDATE_STORE_GROUP
} from 'graphql/mutations/store-groups'
import pluralize from 'pluralize'
import _every from 'lodash/every'
import _includes from 'lodash/includes'
import useNotify from 'hooks/useNotify'

const GroupStatus = ({status}: any) => {
  const dispatch = useAppDispatch()

  return (
    <FormControl className='w-64'>
      <InputLabel>Status</InputLabel>
      <Select
        value={status || 'draft'}
        label='Status'
        onChange={(e) => {
          dispatch(
            changeSaveStoreGroupModalField({
              key: 'status',
              value: e.target.value
            })
          )
        }}
      >
        <MenuItem value={'active'}>Active</MenuItem>
        <MenuItem value={'draft'}>Draft</MenuItem>
      </Select>
    </FormControl>
  )
}

const OrphanItem = ({id, isChecked, name}: any) => {
  const dispatch = useAppDispatch()

  return (
    <div key={id} className={'py-0 px-3 bg-white'}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked || false}
            onChange={(e) => {
              if (e.target.checked) {
                dispatch(addToGroup({id, name}))
              } else {
                dispatch(removeFromGroup({id, name}))
              }
            }}
          />
        }
        label={name}
      />
    </div>
  )
}

const GroupItem = ({id, isChecked, name}: any) => {
  const dispatch = useAppDispatch()
  return (
    <div key={id} className={'flex justify-between p-3 mt-3 mx-3 bg-white'}>
      <span>{name}</span>
      <IconButton
        onClick={() => {
          dispatch(removeFromGroup({id, name}))
        }}
        style={{
          color: 'white'
        }}
      >
        <FontAwesomeIcon
          icon={icon({
            name: 'xmark',
            family: 'classic',
            style: 'light'
          })}
          style={{
            color: 'black',
            fontSize: '24px'
          }}
        />
      </IconButton>
    </div>
  )
}

const ModalBody = () => {
  const dispatch = useAppDispatch()
  const editGroup = useAppSelector(
    selectSaveStoreGroupModalData
  ) as SaveStoreGroup
  const clusters = useAppSelector(selectStoreGroupClusters)
  const [searchText, setSearchText] = useState('')

  const {name, status, groupItems, orphanItems, cluster} = editGroup || {}

  const filteredOrphanItems = useMemo(() => {
    if (!searchText) {
      return orphanItems || []
    }
    return matchSorter(orphanItems, searchText, {keys: ['name']})
  }, [searchText, orphanItems])
  const checkIfGroupContainsAllOrphans = (
    groupItems: any[],
    orphanItems: any[]
  ) => {
    return _every(orphanItems, (orphan) => _includes(groupItems, orphan))
  }
  const childClusterName = clusters[cluster.hierarchyLevel]?.name || 'Locations'
  return (
    <>
      <div className='flex '>
        <StyledInput
          fullWidth
          label='Name'
          className='mr-6'
          value={name}
          onChange={(e) => {
            dispatch(
              changeSaveStoreGroupModalField({
                key: 'name',
                value: e.target.value
              })
            )
          }}
        />

        <GroupStatus status={status} />
      </div>

      <div className='h-[440px] mt-4 flex space-x-6'>
        {/*  Left  */}
        <div className='flex flex-col flex-1'>
          <div className='flex justify-between items-center'>
            <div className='text-lg font-medium'>
              {`Available ${pluralize(childClusterName)}`}
            </div>
            <FormControlLabel
              labelPlacement={'start'}
              control={
                <Switch
                  onChange={(e) => {
                    if (e.target.checked) {
                      dispatch(addAllToGroup())
                    } else {
                      dispatch(undoAddAllToGroup())
                    }
                  }}
                />
              }
              checked={checkIfGroupContainsAllOrphans(groupItems, orphanItems)}
              label='Select All'
            />
          </div>

          <StyledInput
            fullWidth
            label='Search'
            className='mt-4'
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <div className='flex flex-col flex-1 mt-4 rounded border border-solid border-[#b9b9b9] overflow-scroll'>
            {filteredOrphanItems.map((props) => (
              <OrphanItem key={props.id} {...props} />
            ))}
          </div>
        </div>

        {/*  Right  */}
        <div className='flex flex-col flex-1'>
          <div className='flex justify-between items-center'>
            <div className='text-lg font-medium'>
              {`Selected ${pluralize(childClusterName)}`}
            </div>
            <Button
              onClick={() => {
                dispatch(removeAllFromGroup())
              }}
            >
              Remove All
            </Button>
          </div>

          <div
            className={
              'flex flex-col flex-1 mt-4 rounded overflow-scroll bg-[#F8F8F8]'
            }
          >
            {groupItems.map((props) => (
              <GroupItem key={props.id} {...props} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

type StoreGroupSaveProps = {
  isEditMode: boolean
  onSuccessCallback: () => void
  onCloseOrCancelCallback: () => void
}

const StoreGroupSave = ({
  isEditMode,
  onSuccessCallback,
  onCloseOrCancelCallback
}: StoreGroupSaveProps) => {
  const dispatch = useAppDispatch()
  const editGroup = useAppSelector(
    selectSaveStoreGroupModalData
  ) as SaveStoreGroup

  const {name, cluster} = editGroup || {}

  const storeGroupClusters = useAppSelector(selectStoreGroupClusters)
  const notify = useNotify()
  const [isLowestLevel, setIsLowestLevel] = useState(false)

  const [_updateStoreGroup, {loading: loadingUpdateGroup}] = useMutation(
    UPDATE_STORE_GROUP,
    {
      onCompleted: (data) => {
        console.log(data)
      },
      onError: (error) => {
        notify.show(error.message, 'error')
        console.error(error)
      }
    }
  )

  useEffect(() => {
    if (!cluster) {
      return
    }
    setIsLowestLevel(
      storeGroupClusters[cluster.hierarchyLevel - 1]?.hierarchyLevel ===
        storeGroupClusters.length
    )
  }, [cluster])

  const updateStoreGroup = async () => {
    return _updateStoreGroup({
      variables: {
        id: editGroup.id as string,
        name: editGroup.name,
        status: editGroup.status,
        childStoreGroupIds: isLowestLevel
          ? []
          : editGroup.groupItems.map((item) => item.id),
        childLocationIds: isLowestLevel
          ? editGroup.groupItems.map((item) => item.id)
          : []
      }
    })
  }

  const [_createStoreGroup, {loading: loadingCreateGroup}] = useMutation(
    CREATE_STORE_GROUP,
    {
      onCompleted: (data) => {
        console.log(data)
      },
      onError: (error) => {
        notify.show(error.message, 'error')
        console.error(error)
      }
    }
  )

  const createStoreGroup = async () => {
    return _createStoreGroup({
      variables: {
        name: editGroup.name,
        status: editGroup.status,
        storeGroupClusterId: editGroup.cluster.id,
        childStoreGroupIds: isLowestLevel
          ? []
          : editGroup.groupItems.map((item) => item.id),
        childLocationIds: isLowestLevel
          ? editGroup.groupItems.map((item) => item.id)
          : []
      }
    })
  }

  return (
    <StyledDialog
      open
      title={`Create New ${cluster.name || 'Store Group'}`}
      body={<ModalBody />}
      maxWidth={'md'}
      successButtonName={'Save'}
      cancelButtonName={'Cancel'}
      footerClassNames={'mr-2 space-x-4'}
      successCallback={async () => {
        isEditMode ? await updateStoreGroup() : await createStoreGroup()
        dispatch(closeSaveStoreGroupModal())
        onSuccessCallback()
      }}
      disableSuccessButton={!name || loadingUpdateGroup || loadingCreateGroup}
      cancelCallback={() => {
        dispatch(closeSaveStoreGroupModal())
        onCloseOrCancelCallback()
      }}
      closeCallback={() => {
        dispatch(closeSaveStoreGroupModal())
        onCloseOrCancelCallback()
      }}
    />
  )
}
export default StoreGroupSave
