import {Alert, Snackbar} from '@mui/material'
import React, {createContext, ReactNode, useCallback, useState} from 'react'

import theme, {space} from 'config/theme'

interface NotificationContextType {
  show: (
    message: string,
    type: 'success' | 'error' | 'warning' | 'info',
    duration?: number
  ) => void
}

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
)

const NotificationProvider = ({children}: {children: ReactNode}) => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [type, setType] = useState<'success' | 'error' | 'warning' | 'info'>(
    'success'
  )
  const [duration, setDuration] = useState(3000)

  const show = useCallback(
    (
      msg: string,
      alertType: 'success' | 'error' | 'warning' | 'info',
      durationOverride = 3000
    ) => {
      setMessage(msg)
      setType(alertType)
      setDuration(durationOverride)
      setOpen(true)
    },
    []
  )

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <NotificationContext.Provider value={{show}}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={duration}
        onClose={handleClose as any}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        style={{
          marginTop: space.LARGE
        }}
        message={message}
      >
        <Alert
          severity={type}
          variant='filled'
          sx={{
            ' &.MuiAlert-colorError': {
              backgroundColor: theme.palette.error.light,
              color: theme.palette.error.dark
            },
            ' &.MuiAlert-colorWarning': {
              backgroundColor: theme.palette.warning.light,
              color: theme.palette.warning.dark
            },
            ' &.MuiAlert-colorInfo': {
              backgroundColor: theme.palette.info.light,
              color: theme.palette.info.dark
            },
            ' &.MuiAlert-colorSuccess': {
              backgroundColor: theme.palette.success.light,
              color: theme.palette.success.dark
            }
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  )
}

export {NotificationProvider, NotificationContext}
