import {useLazyQuery, useMutation} from '@apollo/client'
import {UPDATE_PERIPHERAL_HUB_NOTIFICATION} from 'graphql/mutations/peripheral-hub.mutations'
import {useEffect, useState} from 'react'

import {useAuth} from 'auth/AuthContext'
import {GET_PERIPHERAL_HUB_NOTIFICATION_CONFIG} from 'graphql/queries/peripheral-hub.queries'

type UsePeripheralHubNotificationConfigType = {
  peripheralHubNotificationConfig: PeripheralHubNotificationConfig
  updatePeripheralHubNotificationConfig: (
    config: PeripheralHubNotificationConfig
  ) => void
}

type PeripheralHubNotificationConfig = {
  enabled: boolean
  severity: string
  issue: string
  device: string | null
  alert: string
  methods: {
    email: boolean
    text: boolean
  }
}

function getDefaultValue() {
  return {
    enabled: false,
    severity: '',
    issue: '',
    device: null,
    alert: '',
    methods: {
      email: false,
      text: false
    }
  }
}

const usePeripheralHubNotificationConfig =
  (): UsePeripheralHubNotificationConfigType => {
    const {authInfo} = useAuth()

    const [
      peripheralHubNotificationConfig,
      setPeripheralHubNotificationConfig
    ] = useState<PeripheralHubNotificationConfig>(getDefaultValue())

    const [_getPeripheralHubNotificationConfig] = useLazyQuery(
      GET_PERIPHERAL_HUB_NOTIFICATION_CONFIG,
      {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
          setPeripheralHubNotificationConfig({
            alert: data.getPeripheralHubNotificationConfig.alert || '',
            device: data.getPeripheralHubNotificationConfig.device || null,
            enabled: data.getPeripheralHubNotificationConfig.enabled as boolean,
            issue: data.getPeripheralHubNotificationConfig.issue || '',

            methods: {
              email: data.getPeripheralHubNotificationConfig.methods
                .email as boolean,
              text: data.getPeripheralHubNotificationConfig.methods
                .text as boolean
            },
            severity: data.getPeripheralHubNotificationConfig.severity || ''
          })
        }
      }
    )

    const getPeripheralHubNotificationConfig = () => {
      _getPeripheralHubNotificationConfig({
        variables: {
          employeeId: authInfo.employeeId as string
        }
      })
    }

    useEffect(() => {
      getPeripheralHubNotificationConfig()
    }, [])

    const [_updatePeripheralHubNotificationConfig] = useMutation(
      UPDATE_PERIPHERAL_HUB_NOTIFICATION,
      {
        onCompleted: (data) => {
          getPeripheralHubNotificationConfig()
        }
      }
    )

    const updatePeripheralHubNotificationConfig = (
      config: PeripheralHubNotificationConfig
    ) => {
      _updatePeripheralHubNotificationConfig({
        variables: {
          notificationConfiguration: {
            methods: {
              email: config.methods.email,
              text: config.methods.text
            },
            enabled: config.enabled,
            severity: config.severity,
            issue: config.issue,
            device: config.device || null,
            alert: config.alert
          }
        }
      })
    }

    return {
      peripheralHubNotificationConfig,
      updatePeripheralHubNotificationConfig
    }
  }

export default usePeripheralHubNotificationConfig
