import {useLazyQuery} from '@apollo/client'
import {Grid} from '@mui/material'
import StyledTable from 'ui/molecules/StyledTable'
import {REFETCH_INTERVAL} from 'constants/constants'
import {MRT_ColumnDef, MRT_PaginationState} from 'material-react-table'
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import ActivityLogHeader from 'clientDashboard/peripheralHub/ActivityLogHeader'
import {
  GET_DEVICE_ACTIVITIES,
  GET_DEVICE_INFO
} from 'graphql/queries/peripheral-hub.queries'

type LogData = {
  date: string
  time: string
  user: string
  action: string
  description: string
}

const PeripheralHubActivityLog = () => {
  const {t} = useTranslation()
  const {id}: any = useParams()
  const [deviceInfo, setDeviceInfo] = React.useState<any>(null)

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10
  })

  const [data, setData] = useState<any>([])

  const [
    getDeviceActivities,
    {loading: loadingDeviceActivities, data: deviceActivities}
  ] = useLazyQuery(GET_DEVICE_ACTIVITIES, {fetchPolicy: 'no-cache'})

  const [getDeviceInfo, {data: deviceInfoData, error}] = useLazyQuery(
    GET_DEVICE_INFO,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const fetchDeviceData = () => {
    getDeviceInfo({
      variables: {
        deviceId: id
      }
    })
    getDeviceActivities({
      variables: {
        deviceId: id
      }
    })
  }

  useEffect(() => {
    fetchDeviceData()
    const interval = setInterval(fetchDeviceData, REFETCH_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!deviceInfoData?.getDeviceInfo) return
    setDeviceInfo(deviceInfoData.getDeviceInfo)
  }, [deviceInfoData])

  useEffect(() => {
    if (!deviceActivities?.getDeviceActivities?.length) return
    setData(deviceActivities.getDeviceActivities || [])
  }, [deviceActivities])

  const columns = useMemo<MRT_ColumnDef<LogData>[]>(
    () => [
      {
        accessorFn: (row) => new Date(row.time),
        accessorKey: 'time',
        header: t('peripheral-hub.device-activity-log.table-columns.time'),
        muiFilterTextFieldProps: {
          sx: {
            minWidth: '250px'
          }
        },
        Cell: ({cell}) => {
          return cell.getValue<Date>()?.toLocaleString()
        }
      },
      {
        accessorKey: 'user',
        header: t('peripheral-hub.device-activity-log.table-columns.user')
      },
      {
        accessorKey: 'action',
        header: t('peripheral-hub.device-activity-log.table-columns.action')
      },
      {
        accessorKey: 'description',
        header: t(
          'peripheral-hub.device-activity-log.table-columns.description'
        )
      }
    ],
    []
  )

  if (error) return <div>Error! {error.message}</div>
  if (!deviceInfo)
    return <div>{t('peripheral-hub.device-activity-log.error-message')}</div>

  return (
    <Grid
      container
      spacing={4}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Grid item>
        <ActivityLogHeader {...deviceInfo} />
      </Grid>

      <StyledTable
        columns={columns}
        data={data}
        onPaginationChange={setPagination}
        state={{
          pagination,
          showProgressBars: loadingDeviceActivities
        }}
      />
    </Grid>
  )
}

export default PeripheralHubActivityLog
