import {useLazyQuery, useMutation} from '@apollo/client'
import {Grid, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import HtmlIframe from 'clientDashboard/analyticsAgent/HtmlIframe'
import {GENERATE_GENIE_CHAT_SESSION_ID} from 'graphql/mutations/genie.mutation'
import {FETCH_GENIE_MESSAGE, GENIE_QUERY} from 'graphql/queries/genie.queries'
import {notify} from 'ui/molecules/CustomNotifications'
import StyledButton from 'ui/atoms/StyledButton'
import StyledInput from 'ui/atoms/StyledInput'
import StyledTable from 'ui/molecules/StyledTable'
import Loading from 'ui/molecules/Loading'
import {GenieQueryType} from 'graphql/generatedTypes/graphql'

const AnalyticsAgent = () => {
  const [chatSessionId, setChatSessionId] = useState('')
  const [userInput, setUserInput] = useState('')
  const [genieMessageId, setGenieMessageId] = useState('')
  const [tableColumns, setTableColumns] = useState<string[]>([])
  const [pollResult, setPollResult] = useState(false)
  const [genieMessage, setGenieMessage] = useState<GenieQueryType | undefined>()

  const interval = React.useRef<any>()
  const timeoutInterval = React.useRef<any>()

  const [generateChatSessionId] = useMutation(GENERATE_GENIE_CHAT_SESSION_ID, {
    onCompleted: (data) => {
      setChatSessionId(data.generateGenieChatSessionId)
    }
  })

  useEffect(() => {
    generateChatSessionId()

    // stop polling on component unmount
    return () => {
      clearInterval(interval.current)
    }
  }, [])

  const [_genieQuery] = useLazyQuery(GENIE_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setGenieMessageId(data.genieQueryDashboard.messageId)
      setPollResult(true)
    }
  })

  const genieQuery = () => {
    _genieQuery({
      variables: {
        query: userInput,
        chatSessionId: chatSessionId
      }
    })
  }

  const [_fetchGenieMessage] = useLazyQuery(FETCH_GENIE_MESSAGE, {
    fetchPolicy: 'no-cache'
  })

  const fetchGenieMessage = async (messageId: string) => {
    return await _fetchGenieMessage({
      variables: {
        messageId: messageId
      }
    })
  }

  useEffect(() => {
    if (!pollResult) return

    interval.current = setInterval(async () => {
      if (!genieMessageId) return
      try {
        const res = await fetchGenieMessage(genieMessageId)
        if (!res.data?.fetchGenieMessageDashboard) return
        if (res.data?.fetchGenieMessageDashboard.status !== 'processed') return

        console.log('Genie message:', res.data.fetchGenieMessageDashboard)

        setPollResult(false)
        setGenieMessageId('')

        setGenieMessage(res.data.fetchGenieMessageDashboard)
        const columnData =
          res.data.fetchGenieMessageDashboard?.genieAnalyticsQuery?.data[0]
        const columns = Object.keys(columnData)
        setTableColumns(columns)

        // clear the interval
        clearInterval(interval.current)
        clearInterval(timeoutInterval.current)
      } catch (error) {
        notify.show('Error processing query. Please try again.', 'error')
        // clear the interval
        clearInterval(interval.current)
        clearInterval(timeoutInterval.current)
      }
    }, 2000)

    // Cleanup function to clear the interval
    timeoutInterval.current = setTimeout(() => {
      clearInterval(interval.current)
      setPollResult(false)
      notify.show('Error processing query. Please try again.', 'error')
    }, 40000)
  }, [genieMessageId])

  const columns = tableColumns.map((column) => {
    return {
      accessorKey: column,
      header: column,
      Cell: ({cell}: any) => {
        return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
      }
    }
  })

  return (
    <Grid
      container
      xs={12}
      padding={4}
      spacing={4}
      style={{alignContent: 'center', alignItems: 'center'}}
    >
      <Grid item xs={9}>
        <StyledInput
          label='Enter your query'
          fullWidth
          onChange={(e: any) => setUserInput(e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledButton
          size='large'
          onClick={() => {
            setPollResult(true)
            setTableColumns([])
            genieQuery()
          }}
        >
          Submit
        </StyledButton>
      </Grid>
      {!pollResult &&
        genieMessage &&
        genieMessage.genieAnalyticsQuery?.html !== '' && (
          <Grid item xs={12}>
            <HtmlIframe
              htmlString={
                genieMessage.genieAnalyticsQuery?.html || '<div></div>'
              }
            />
          </Grid>
        )}
      {!pollResult && genieMessage && columns.length > 0 && (
        <Grid item xs={12}>
          <StyledTable
            columns={columns}
            data={genieMessage?.genieAnalyticsQuery?.data || []}
          />
        </Grid>
      )}
      {pollResult && (
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            alignContent: 'center'
          }}
        >
          <Loading />
        </Grid>
      )}
    </Grid>
  )
}

export default AnalyticsAgent
