import {useLazyQuery, useMutation} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Grid, Typography} from '@mui/material'
import LoginInterface from 'clientDashboard/LoginInterface'
import StyledButton from 'ui/atoms/StyledButton'
import StyledInput from 'ui/atoms/StyledInput'
import {
  RESEND_OTP_FOR_PASSWORD_RESET,
  RESET_PASSWORD_WITH_OTP,
  SEND_OTP_FOR_PASSWORD_RESET
} from 'graphql/mutations/employee.mutations'
import {VALIDATE_PASSWORD_SET_TOKEN} from 'graphql/queries/employee.queries'
import useNotify from 'hooks/useNotify'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import PasswordChecklist from 'react-password-checklist'

const TTL_FOR_RESEND_OTP = 30

const enum step {
  PASSWORD = 1,
  PIN = 2,
  OTP = 3
}

function PasswordSettings() {
  const notify = useNotify()
  const {t} = useTranslation()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [pin, setPin] = useState('')
  const [confirmPin, setConfirmPin] = useState('')
  const [otp, setOtp] = useState('')
  const [currentStep, setCurrentStep] = useState(step.PASSWORD)

  const [isPasswordStrong, setIsPasswordStrong] = useState(false)
  const [isPinStrong, setIsPinStrong] = useState(false)

  const [isTokenValid, setIsTokenValid] = useState(false)
  const [token, setToken] = useState('')

  const [countdownForResendOtp, setCountdownForResendOtp] =
    useState(TTL_FOR_RESEND_OTP)
  const [isResendOtpDisabled, setIsResendOtpDisabled] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (isResendOtpDisabled) {
      if (countdownForResendOtp > 0) {
        const timerId = setTimeout(
          () => setCountdownForResendOtp(countdownForResendOtp - 1),
          1000
        )
        return () => clearTimeout(timerId)
      } else {
        setIsResendOtpDisabled(false)
      }
    }
  }, [isResendOtpDisabled, countdownForResendOtp])

  const [
    _validatePasswordSetToken,
    {loading: validatePasswordSetTokenLoading}
  ] = useLazyQuery(VALIDATE_PASSWORD_SET_TOKEN, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setIsTokenValid(true)
    },
    onError: (error) => {
      notify.show(error.message, 'error')
      setTimeout(() => {
        window.location.href = window.location.origin + '/login'
      }, 2000)
    }
  })

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token') || ''
    setToken(token)
    _validatePasswordSetToken({
      variables: {
        token: token
      }
    })
  }, [])

  const [_sendOtpForPasswordReset] = useMutation(SEND_OTP_FOR_PASSWORD_RESET, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      notify.show(t('password-set-page.otp-sent-successfully'), 'success')
    },
    onError: (error) => {
      setErrorMessage(error.message)
    }
  })

  const [_resendOtpForPasswordReset] = useMutation(
    RESEND_OTP_FOR_PASSWORD_RESET,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        notify.show(t('password-set-page.otp-sent-successfully'), 'success')
      },
      onError: (error) => {
        setErrorMessage(error.message)
      }
    }
  )

  const [_resetPasswordWithOtp] = useMutation(RESET_PASSWORD_WITH_OTP, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      notify.show(t('password-set-page.password-reset-successfully'), 'success')
      setTimeout(() => {
        window.location.href = window.location.origin + '/login'
      }, 2000)
    },
    onError: (error) => {
      setErrorMessage(error.message)
    }
  })

  const getLoginFields = () => {
    return (
      <>
        {currentStep === step.PASSWORD && (
          <>
            <Grid item container width={'100%'} justifyContent={'flex-start'}>
              <StyledInput
                fullWidth
                size='medium'
                type='password'
                label={t('password-set-page.password')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item container width={'100%'} justifyContent={'flex-start'}>
              <StyledInput
                fullWidth
                label={t('password-set-page.confirm-password')}
                size='medium'
                type='password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
          </>
        )}

        {currentStep === step.PIN && (
          <>
            <Grid item container width={'100%'} justifyContent={'flex-start'}>
              <StyledInput
                fullWidth
                label={t('password-set-page.pin')}
                size='medium'
                type='password'
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
            </Grid>
            <Grid item container width={'100%'} justifyContent={'flex-start'}>
              <StyledInput
                fullWidth
                size='medium'
                label={t('password-set-page.confirm-pin')}
                type='password'
                value={confirmPin}
                onChange={(e) => setConfirmPin(e.target.value)}
              />
            </Grid>
          </>
        )}
      </>
    )
  }

  const passwordStrength = () => {
    if (currentStep !== step.PASSWORD || (!password && !confirmPassword)) {
      return ''
    }
    return (
      <PasswordChecklist
        rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
        minLength={8}
        value={password}
        valueAgain={confirmPassword}
        onChange={(isValid) => {
          setIsPasswordStrong(isValid)
        }}
      />
    )
  }

  const pinStrength = () => {
    if (currentStep !== step.PIN || (!pin && !confirmPin)) {
      return ''
    }
    return (
      <PasswordChecklist
        rules={['minLength', 'maxLength', 'number', 'match']}
        messages={{
          minLength: t('password-set-page.pin-has-at-least-6-digits'),
          number: t('password-set-page.pin-should-contain-only-numbers'),
          match: t('password-set-page.pin-match'),
          maxLength: t('password-set-page.pin-has-at-most-6-digits')
        }}
        minLength={6}
        maxLength={6}
        value={pin}
        valueAgain={confirmPin}
        onChange={(isValid) => {
          setIsPinStrong(isValid)
        }}
      />
    )
  }

  const getOtpField = () => {
    return (
      <Grid item container width={'100%'} justifyContent={'flex-start'}>
        <StyledInput
          fullWidth
          label={t('password-set-page.otp')}
          size='medium'
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          error={(!!otp && otp.length !== 6) || isNaN(Number(otp))}
          helperText={
            (!!otp && otp.length !== 6) || isNaN(Number(otp))
              ? t('password-set-page.invalid-otp')
              : otp.length === 6 && isNaN(Number(otp))
                ? t('password-set-page.otp-should-be-a-number')
                : ''
          }
        />
      </Grid>
    )
  }

  const handleSubmit = () => {
    switch (currentStep) {
      case step.PASSWORD:
        setCurrentStep(step.PIN)
        break
      case step.PIN:
        setCurrentStep(step.OTP)
        setIsResendOtpDisabled(true)
        _sendOtpForPasswordReset({
          variables: {
            token: token
          }
        })
        break
      case step.OTP:
        _resetPasswordWithOtp({
          variables: {
            token: token,
            otp: otp,
            password: password,
            pin: pin
          }
        })
        break
      default:
        break
    }
  }

  const isSubmitButtonDisabled = () => {
    switch (currentStep) {
      case step.PASSWORD:
        return (
          !password ||
          !confirmPassword ||
          password !== confirmPassword ||
          !isPasswordStrong
        )

      case step.PIN:
        if (pin) {
          return (
            !pin ||
            !confirmPin ||
            pin !== confirmPin ||
            !isPinStrong ||
            isNaN(Number(pin))
          )
        } else {
          return !password
        }
      case step.OTP:
        return !otp || otp.length !== 6 || isNaN(Number(otp))

      default:
        break
    }
  }

  const handleSkip = () => {
    switch (currentStep) {
      case step.PASSWORD:
        setCurrentStep(step.PIN)
        setPassword('')
        setConfirmPassword('')
        break
      case step.PIN:
        setPin('')
        setConfirmPin('')
        setCurrentStep(step.OTP)
        setIsResendOtpDisabled(true)
        _sendOtpForPasswordReset({
          variables: {
            token: token
          }
        })
        break
      default:
        break
    }
  }

  const isSkipButtonDisabled = () => {
    switch (currentStep) {
      case step.PASSWORD:
        return false
      case step.PIN:
        return !password
      default:
        break
    }
  }

  const showMainHeading = () => {
    switch (currentStep) {
      case step.PASSWORD:
        return t('password-set-page.set-password')
      case step.PIN:
        return t('password-set-page.set-6-digit-pin')
      case step.OTP:
        return t('password-set-page.enter-verification-code')
      default:
        break
    }
  }

  const showSubHeading = () => {
    switch (currentStep) {
      case step.PASSWORD:
        return t('password-set-page.to-login-on-the-tote-dashboard')
      case step.PIN:
        return t('password-set-page.to-login-on-the-mpos')
      case step.OTP:
        return t('password-set-page.sent-to-your-email-address')
      default:
        break
    }
  }

  const handleResendOtp = () => {
    _resendOtpForPasswordReset({
      variables: {
        token: token
      }
    })

    setCountdownForResendOtp(TTL_FOR_RESEND_OTP)
    setIsResendOtpDisabled(true)
  }

  if (validatePasswordSetTokenLoading || !isTokenValid) {
    return <div></div>
  }

  const getFields = () => {
    return (
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
        xs={12}
      >
        <Grid
          spacing={2}
          container
          onKeyPress={(e: any) => {
            if (e?.key === 'Enter') {
              // handleSubmit(e)
            }
          }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
          }}
          xs={8}
          md={6}
        >
          <Grid item>
            <Typography variant='h1'>{showMainHeading()}</Typography>
            <Typography color={'text.secondary'} variant='subtitle1'>
              {showSubHeading()}
            </Typography>
          </Grid>

          {currentStep === step.OTP ? getOtpField() : getLoginFields()}

          <Grid
            item
            width={'100%'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start'
            }}
          >
            <Typography variant='subtitle2' color={'red'} minHeight={'24px'}>
              {errorMessage}
            </Typography>
          </Grid>

          <Grid
            container
            spacing={2}
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            {currentStep === step.PIN && (
              <Grid item>
                <StyledButton
                  startIcon={
                    <FontAwesomeIcon
                      icon={icon({
                        name: 'arrow-left',
                        family: 'classic',
                        style: 'regular'
                      })}
                      fontSize={20}
                      style={{
                        borderWidth: '1px'
                      }}
                    />
                  }
                  variant='outlined'
                  type='submit'
                  onClick={() => setCurrentStep(step.PASSWORD)}
                >
                  {t('password-set-page.back')}
                </StyledButton>
              </Grid>
            )}

            {currentStep !== step.OTP && (
              <Grid item>
                <StyledButton
                  variant='outlined'
                  fullWidth
                  type='submit'
                  onClick={handleSkip}
                  disabled={isSkipButtonDisabled()}
                >
                  {t('password-set-page.skip')}
                </StyledButton>
              </Grid>
            )}

            <Grid item>
              <StyledButton
                fullWidth
                type='submit'
                onClick={handleSubmit}
                disabled={isSubmitButtonDisabled()}
              >
                {t('password-set-page.submit')}
              </StyledButton>
            </Grid>

            {currentStep === step.OTP && (
              <Grid item>
                <StyledButton
                  fullWidth
                  type='submit'
                  onClick={handleResendOtp}
                  disabled={isResendOtpDisabled}
                >
                  {isResendOtpDisabled
                    ? `${t('password-set-page.resend-otp')} in (${countdownForResendOtp})`
                    : t('password-set-page.resend-otp')}
                </StyledButton>
              </Grid>
            )}
          </Grid>

          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            {passwordStrength()}
            {pinStrength()}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return <LoginInterface body={getFields()} />
}

export default PasswordSettings
