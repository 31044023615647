import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Divider
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import theme, {space} from 'config/theme'
import {StoreGroup} from 'clientDashboard/settings/locations/locations.slice'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {
  selectChildStoreGroups,
  selectStoreGroups,
  selectStoreGroupsByIdMap
} from 'clientDashboard/settings/locations/locations.selectors'
import _toLower from 'lodash/toLower'
import StyledButton from 'ui/atoms/StyledButton'
import {selectStoreGroupClusters} from 'clientDashboard/business.selectors'
import pluralize from 'pluralize'
import usePermission from 'hooks/usePermission'
import {Permission} from 'constants/permissions'

type StoreGroupAccordionProps = {
  onEditClick: (storeGroupId: string) => void
  onViewLocationsClick: (storeGroupId: string) => void
  topLevelStoreGroupClusterName: string
}

const StoreGroupAccordion = ({
  onEditClick,
  onViewLocationsClick,
  topLevelStoreGroupClusterName
}: StoreGroupAccordionProps) => {
  const storeGroups = useAppSelector(selectStoreGroups)
  const childStoreGroups = useAppSelector(selectChildStoreGroups)
  const storeGroupsByIdMap = useAppSelector(selectStoreGroupsByIdMap)
  const storeGroupClusters = useAppSelector(selectStoreGroupClusters)
  const isLowestHierarchyLevel = (clusterId: string) => {
    return storeGroupClusters[storeGroupClusters.length - 1].id === clusterId
  }
  const hasUpdateStoreGroupPermission = usePermission(
    Permission.UPDATE_LOCATION_GROUP
  )
  const fetchNextHierarchyClusterName = (clusterId: string) => {
    const clusterIndex = storeGroupClusters.findIndex(
      (cluster) => cluster.id === clusterId
    )
    if (clusterIndex === -1) {
      return ''
    }
    if (clusterIndex === storeGroupClusters.length - 1) {
      return 'Locations'
    }
    return storeGroupClusters[clusterIndex + 1].name
  }

  const storeGroupAccordion = (
    storeGroup: StoreGroup,
    showLocationIcon: boolean
  ) => {
    if (!storeGroup) {
      return <></>
    }
    let childCounts = 0
    if (childStoreGroups[storeGroup.id as string]) {
      childCounts = childStoreGroups[storeGroup.id as string].length
    }
    let childClusterName = fetchNextHierarchyClusterName(
      storeGroup.cluster?.id as string
    )
    let _childStoreGroups = childStoreGroups[storeGroup.id as string]
    return (
      <Accordion
        key={storeGroup.id}
        sx={{
          backgroundColor: theme.palette.background.default,
          border: 'none',
          boxShadow: 'none'
        }}
      >
        <AccordionSummary
          expandIcon={
            !isLowestHierarchyLevel(storeGroup.cluster?.id as string) ? (
              <ExpandMoreIcon
                sx={{
                  pointerEvents: 'auto'
                }}
              />
            ) : null
          }
          sx={{
            pointerEvents: 'none',
            flexDirection: 'row-reverse'
          }}
        >
          <Grid
            container
            style={{
              padding: space.SMALL,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Grid
              item
              gap={4}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              {showLocationIcon && (
                <Grid item>
                  <FontAwesomeIcon
                    icon={icon({
                      name: 'location-dot',
                      family: 'classic',
                      style: 'solid'
                    })}
                    fontSize={'20px'}
                    color={theme.palette.primary.main}
                  />
                </Grid>
              )}

              <Grid item>
                <Typography variant='h2'>{storeGroup.name}</Typography>
              </Grid>
              {storeGroup.status === 'draft' && (
                <Grid item>{<sub>Draft</sub>}</Grid>
              )}

              <Grid item>
                <Typography
                  variant='subtitle1'
                  color={theme.palette.text.secondary}
                >
                  {childCounts}{' '}
                  {pluralize(_toLower(childClusterName), childCounts)}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              gap={4}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              {hasUpdateStoreGroupPermission && (
                <Grid item>
                  <StyledButton
                    onClick={(event) => {
                      event.stopPropagation()
                      onEditClick(storeGroup.id as string)
                    }}
                    style={{
                      pointerEvents: 'auto'
                    }}
                  >
                    Edit {storeGroup.cluster?.name}
                  </StyledButton>
                </Grid>
              )}

              <Grid item>
                {isLowestHierarchyLevel(storeGroup.cluster?.id as string) && (
                  <StyledButton
                    onClick={(event) => {
                      event.stopPropagation()
                      onViewLocationsClick(storeGroup.id as string)
                    }}
                    style={{
                      pointerEvents: 'auto'
                    }}
                  >
                    View Locations
                  </StyledButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>

        {!isLowestHierarchyLevel(storeGroup.cluster?.id as string) && (
          <AccordionDetails
            sx={{
              marginLeft: space.MEDIUM
            }}
          >
            <Divider />
            {_childStoreGroups &&
              _childStoreGroups.map((childGroup: StoreGroup) => {
                return (
                  <AccordionDetails key={childGroup.id}>
                    {storeGroupAccordion(
                      storeGroupsByIdMap[childGroup.id as string],
                      false
                    )}
                  </AccordionDetails>
                )
              })}
          </AccordionDetails>
        )}
      </Accordion>
    )
  }
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        backgroundColor: theme.palette.background.default,
        flexDirection: 'column'
      }}
    >
      {storeGroups[topLevelStoreGroupClusterName] &&
        storeGroups[topLevelStoreGroupClusterName].map(
          (storeGroup: StoreGroup) => (
            <Grid item xs={12} key={storeGroup.id}>
              {storeGroupAccordion(storeGroup, true)}
            </Grid>
          )
        )}
    </Grid>
  )
}

export default StoreGroupAccordion
