import InsufficientPermission from 'clientDashboard/settings/InsufficientPermission'
import usePermission from 'hooks/usePermission'
import React, {ReactNode} from 'react'

type PermissionWrapperProps = {
  permissions: string[]
  children: Element | ReactNode | any
}

const PermissionWrapper: React.FC<PermissionWrapperProps> = ({
  permissions,
  children
}) => {
  const hasPermission = usePermission(permissions)

  return hasPermission ? children : <InsufficientPermission />
}

export default PermissionWrapper
