import {useMutation} from '@apollo/client'
import {Grid, Link, Typography} from '@mui/material'
import useNotify from 'hooks/useNotify'
import StyledDialog from 'ui/molecules/StyledDialog'
import {BULK_CASH_TRANSACTION} from 'graphql/mutations/cash-management.mutations'
import {t} from 'i18next'
import {TillStatus, TransactionType} from 'constants/constants'
import StyledCheckBox from 'ui/atoms/StyledCheckbox'
import React from 'react'
import StyledInput from 'ui/atoms/StyledInput'
import theme, {space} from 'config/theme'
import moment from 'moment'
import Loading from 'ui/molecules/Loading'
import {
  amountInCents,
  isInvalidInputAmount,
  parseAmount
} from 'utils/currency.utils'
import {centsToDollar} from 'utils/common'

interface FilterSelectProps {
  registerList: any
  locationId: string
  onUpdate: (value: boolean) => void
  isClose?: boolean
  setReviewDrawerOpen: (value: boolean) => void
  refetchData: () => void
}

const CashBulkActionForm = ({
  registerList,
  locationId,
  isClose,
  onUpdate,
  setReviewDrawerOpen,
  refetchData
}: FilterSelectProps) => {
  const notify = useNotify()

  // @ts-ignore
  const [performMutation] = useMutation(BULK_CASH_TRANSACTION)

  const [loader, setLoader] = React.useState(false)

  const [formEntries, setFormEntries] = React.useState(
    registerList
      .filter(
        (entry: any) =>
          entry.status === (isClose ? TillStatus.OPEN : TillStatus.CLOSED)
      )
      .map((entry: any) => ({
        checked: false,
        id: entry.id,
        register: entry.register,
        till: entry.till,
        employee: entry.employee,
        amount: centsToDollar(
          isClose ? entry.totalCash : entry.defaultStartingFloat
        ),
        locationId: locationId,
        transactionType: isClose
          ? TransactionType.DEBIT
          : TransactionType.CREDIT,
        eventType: isClose ? 'close' : TillStatus.OPEN,
        description: isClose ? 'close' : TillStatus.OPEN
      }))
  )

  const handleInputChange = (id: string, field: string, value: any) => {
    setFormEntries((prevEntries: any[]) =>
      prevEntries.map((entry) =>
        entry.id === id ? {...entry, [field]: value} : entry
      )
    )
  }

  const handleSubmit = async (isCloseWithReconcile: boolean) => {
    setLoader(true)
    try {
      const variables = {
        transactions: formEntries
          .filter((entry: any) => entry.checked)
          .map((entry: any) => ({
            id: entry.id,
            register: entry.register,
            till: entry.till,
            amount: amountInCents(entry.amount),
            locationId: entry.locationId,
            transactionType: entry.transactionType,
            eventType: entry.eventType,
            description: entry.description
          }))
      }

      await performMutation({variables})
      refetchData()
      notify.show(t('cash-management.transactions.success'), 'success')

      setLoader(false)
      onUpdate(false)
      if (isCloseWithReconcile) {
        setReviewDrawerOpen(true)
      }
    } catch (error) {
      setLoader(false)
      notify.show(
        (error as any)?.message || 'Something went wrong. Please try again.',
        'error'
      )
    }
  }

  const toggleSelectAll = (e: any) => {
    e.preventDefault()
    const allSelected = formEntries.every((entry: any) => entry.checked)
    setFormEntries((prevEntries: any[]) =>
      prevEntries.map((entry) => ({...entry, checked: !allSelected}))
    )
  }

  const isSubmitDisabled =
    formEntries.every((entry: any) => !entry.checked) ||
    formEntries.some((entry: any) => isInvalidInputAmount(entry.amount))
  const selectedCount = formEntries.filter((entry: any) => entry.checked).length

  const selectAll = () => {
    return (
      <Grid
        item
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: space.XXXS,
          flex: '1 0 0'
        }}
      >
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            alignSelf: 'stretch'
          }}
        >
          <Link href='#' onClick={toggleSelectAll} sx={{cursor: 'pointer'}}>
            {formEntries.every((entry: any) => entry.checked)
              ? 'Unselect All'
              : 'Select All'}
          </Link>
          <Typography
            variant='body2'
            style={{color: theme.palette.text.secondary}}
          >
            {selectedCount} / {formEntries.length} Selected
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const openRegistersView = () => {
    return (
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: space.SMALL,
          alignSelf: 'stretch'
        }}
      >
        {formEntries.length === 0 ? (
          noRegistersView()
        ) : (
          <>
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                alignSelf: 'stretch'
              }}
            >
              {selectAll()}
            </Grid>
            {/* main body */}
            <Grid
              item
              container
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: space.MEDIUM,
                alignSelf: 'stretch'
              }}
            >
              {formEntries.map((entry: any, index: number) => (
                <Grid
                  item
                  key={entry.id}
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    borderRadius: space.XXS,
                    border: '1px solid',
                    borderColor: theme.palette.divider,
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    hyphens: 'auto'
                  }}
                >
                  {/*  card body */}
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      padding: `20px ${space.LARGE}`,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexShrink: 0
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: space.LARGE
                      }}
                    >
                      <Grid xs={1} item>
                        <StyledCheckBox
                          checked={Boolean(entry.checked)}
                          onChange={(e) =>
                            handleInputChange(
                              entry.id,
                              'checked',
                              e.target.checked
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        xs={11}
                        item
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Typography variant='h3'>{entry.register}</Typography>

                        <Typography
                          variant='subtitle2'
                          style={{color: theme.palette.text.secondary}}
                        >
                          {entry.till}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: `0px ${space.SMALL}`,
                        flexShrink: 0,
                        alignSelf: 'stretch',
                        borderRadius: space.XXS
                      }}
                    >
                      <StyledInput
                        fullWidth
                        label={'Starting Float'}
                        value={entry.amount}
                        type='number'
                        InputProps={{
                          startAdornment: t('currency-symbol')
                        }}
                        onChange={(e) =>
                          handleInputChange(
                            entry.id,
                            'amount',
                            parseAmount(e.target.value)
                          )
                        }
                        error={isInvalidInputAmount(entry.amount)}
                        helperText={
                          isInvalidInputAmount(entry.amount)
                            ? 'Invalid amount'
                            : ''
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    )
  }

  const closeRegistersView = () => {
    return (
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: space.SMALL,
          alignSelf: 'stretch'
        }}
      >
        {formEntries.length === 0 ? (
          noRegistersView()
        ) : (
          <>
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                alignSelf: 'stretch'
              }}
            >
              {selectAll()}
            </Grid>
            {/* main body */}
            <Grid
              item
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: space.MEDIUM,
                alignSelf: 'stretch'
              }}
            >
              {formEntries.map((entry: any, index: number) => (
                <Grid
                  item
                  key={entry.id}
                  style={{
                    display: 'flex',
                    alignSelf: 'stretch',
                    borderRadius: space.XXS,
                    border: '1px solid',
                    borderColor: theme.palette.divider,
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    hyphens: 'auto'
                  }}
                >
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      padding: `20px ${space.LARGE}`,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flex: '1 0 0'
                    }}
                  >
                    <Grid xs={1} item>
                      <StyledCheckBox
                        checked={Boolean(entry.checked)}
                        onChange={(e) =>
                          handleInputChange(
                            entry.id,
                            'checked',
                            e.target.checked
                          )
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      xs={11}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flex: '1, 0, 0'
                      }}
                    >
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Grid
                          item
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: space.SMALL
                          }}
                        >
                          <Typography variant='h3'>{entry.register}</Typography>
                        </Grid>

                        <Grid
                          item
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: space.SMALL
                          }}
                        >
                          <Typography
                            variant='subtitle2'
                            style={{color: theme.palette.text.secondary}}
                          >
                            {entry.till}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Grid
                          item
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: space.SMALL
                          }}
                        >
                          <Typography variant='h3'>Employee</Typography>
                        </Grid>

                        <Grid
                          item
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: space.SMALL
                          }}
                        >
                          <Typography
                            variant='subtitle2'
                            style={{color: theme.palette.text.secondary}}
                          >
                            {entry.employee?.name}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={2}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Grid
                          item
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: space.SMALL
                          }}
                        >
                          <Typography variant='h3'>Date</Typography>
                        </Grid>

                        <Grid
                          item
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: space.SMALL
                          }}
                        >
                          <Typography
                            variant='subtitle2'
                            style={{color: theme.palette.text.secondary}}
                          >
                            {moment().local().format('MM/DD/YY')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    )
  }

  const noRegistersView = () => {
    return (
      <Grid item>
        <Typography variant='h3'>
          There are currently no available registers.
        </Typography>
      </Grid>
    )
  }

  return (
    <StyledDialog
      open={true}
      title={isClose ? 'Close Registers' : 'Open Registers'}
      body={
        loader ? (
          <Loading />
        ) : isClose ? (
          closeRegistersView()
        ) : (
          openRegistersView()
        )
      }
      successButtonName={isClose ? 'Close' : 'Open'}
      otherSuccessButtonName={isClose ? 'Close & Reconcile' : ''}
      otherSuccessCallback={() => {
        handleSubmit(true)
      }}
      cancelButtonName='Cancel'
      cancelCallback={() => {
        onUpdate(false)
      }}
      disableSuccessButton={isSubmitDisabled || loader}
      onClose={() => {}}
      successCallback={() => {
        handleSubmit(false)
      }}
    />
  )
}

export default CashBulkActionForm
