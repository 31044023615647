import {Typography} from '@mui/material'
import {convertToLocalTimeString} from 'utils/common'
import {DeviceStatus} from 'constants/constants'
import StyledChip from 'ui/atoms/StyledChip'
import React from 'react'
import {MRT_ColumnDef} from 'material-react-table'
import {ErrorNotificationType} from 'graphql/generatedTypes/graphql'

export const getColumns = ({
  history,
  businessId
}: any): MRT_ColumnDef<ErrorNotificationType>[] => [
  {
    accessorKey: 'severity',
    header: 'Severity',
    muiFilterTextFieldProps: {
      sx: {
        minWidth: '100px'
      }
    },
    filterVariant: 'select',
    filterSelectOptions: [
      {
        label: 'Critical',
        value: 'Critical'
      },
      {
        label: 'Warning',
        value: 'Warning'
      }
    ]
  },
  {
    accessorKey: 'serialNumber',
    header: 'Serial Number'
  },
  {
    accessorKey: 'deviceName',
    header: 'Device Name',
    Cell: ({cell, row}: any) => (
      <Typography
        style={{
          color: 'black',
          textDecoration: 'underline',
          cursor: 'pointer'
        }}
        onClick={() => {
          history.push(
            `/${businessId}/peripheral-hub/activity-log/${row.original.id}`
          )
        }}
      >
        {cell.getValue()?.toString()}
      </Typography>
    )
  },
  {
    accessorKey: 'registerName',
    header: 'Register Name'
  },
  {
    accessorKey: 'lastActive',
    header: 'Last Active',

    Cell: ({cell}: any) => {
      return cell.getValue() ? convertToLocalTimeString(cell.getValue()) : '-'
    }
  },
  {
    accessorKey: 'issueDescription',
    header: 'Issue Description',

    Cell: ({cell, row}: any) =>
      row.original.severity === DeviceStatus.CRITICAL ? (
        <StyledChip label={cell.getValue()} color='error' />
      ) : (
        <StyledChip label={cell.getValue()} color='warning' />
      )
  },
  {
    accessorKey: 'action',
    header: 'Action'
  }
]
