import {createSlice} from '@reduxjs/toolkit'
import {NullablePartial} from 'utils/type.utils'
import _set from 'lodash/set'
import _toLower from 'lodash/toLower'
import pluralize from 'pluralize'

export type StoreGroupClusterStats = {
  id?: string
  storeGroupClusterName: string
  activeCount: number
  draftCount: number
}

export type StoreGroupClusterCardList = {
  storeGroupCluster: any
  subtext: string
  showRenameIcon: boolean
  showAddIcon: boolean
  redirectionUri: string
  showRedirectionIcon: boolean
}

type StoreGroups = {
  storeGroupClusterStats: StoreGroupClusterStats[]
  storeGroupClusterCardList: any[]
}

const initialState: NullablePartial<StoreGroups> = {}

export const storeGroupsSlice = createSlice({
  name: 'settings/storeGroups',
  initialState,
  reducers: {
    setStoreGroupClusterStats: (state, action) => {
      const {key, value} = action.payload
      _set(state, ['storeGroupClusterStats', key], value)

      const storeGroupClusterStats = action.payload
      // loop through storeSegmentsStats and create the card list
      let cardList = [] as StoreGroupClusterCardList[]
      if (!storeGroupClusterStats) {
        return
      }

      storeGroupClusterStats.forEach((stats: any, idx: number) => {
        let count = stats.activeCount + stats.draftCount
        let card = {
          storeGroupCluster: {},
          subtext: `${count === 0 ? 'No' : count} ${pluralize(_toLower(stats.storeGroupClusterName), count)} added`,
          showRenameIcon: true,
          showAddIcon: true,
          redirectionUri: `location-grouping/${_toLower(stats.storeGroupClusterName)}`,
          showRedirectionIcon: true
        }
        let storeGroupCluster = null
        // if stats.id is not None, then it is a category
        // fetch the category name from storeSegmentCategories
        if (stats.id) {
          let category = stats.storeGroupClusterName
          if (category) {
            storeGroupCluster = {
              name: category,
              id: stats.id,
              hierarchyLevel: idx + 1
            }
          }
        }
        if (!storeGroupCluster) {
          storeGroupCluster = {
            name: stats.storeGroupClusterName,
            id: stats.storeGroupClusterName,
            hierarchyLevel:
              stats.storeGroupClusterName === 'Business'
                ? 0
                : storeGroupClusterStats.length + 1
          }
        }
        card.storeGroupCluster = storeGroupCluster
        cardList.push(card)
      })

      // make rename to false for first and last card
      if (cardList.length > 0) {
        _set(cardList[0], 'showRenameIcon', false)
        _set(cardList[0], 'showAddIcon', false)
        _set(cardList[0], 'subtext', 'All locations')
        _set(cardList[0], 'showRedirectionIcon', false)
        _set(cardList[cardList.length - 1], 'showRenameIcon', false)
        _set(cardList[cardList.length - 1], 'showAddIcon', false)
        _set(
          cardList[cardList.length - 1],
          'redirectionUri',
          'location-settings'
        )
      }

      state.storeGroupClusterCardList = cardList
    }
  }
})

export const {setStoreGroupClusterStats} = storeGroupsSlice.actions
