const envs = (window as any).env || {}
const env = {
  REACT_APP_SERVER_HTTP_URL:
    process.env.REACT_APP_SERVER_HTTP_URL || envs.REACT_APP_SERVER_HTTP_URL,
  REACT_APP_SERVER_WS_URL:
    process.env.REACT_APP_SERVER_WS_URL || envs.REACT_APP_SERVER_WS_URL,
  REACT_APP_ADMIN_OKTA_CLIENT_ID:
    process.env.REACT_APP_ADMIN_OKTA_CLIENT_ID ||
    envs.REACT_APP_ADMIN_OKTA_CLIENT_ID,
  REACT_APP_ADMIN_OKTA_ISSUER:
    process.env.REACT_APP_ADMIN_OKTA_ISSUER || envs.REACT_APP_ADMIN_OKTA_ISSUER,
  REACT_RECAPTCHA_SITE_KEY:
    process.env.REACT_RECAPTCHA_SITE_KEY || envs.REACT_RECAPTCHA_SITE_KEY,
  APP_ENV: process.env.APP_ENV || envs.APP_ENV,
  REACT_APP_GOOGLE_PLACES_API_KEY:
    process.env.REACT_APP_GOOGLE_PLACES_API_KEY ||
    envs.REACT_APP_GOOGLE_PLACES_API_KEY,
  REACT_APP_NEW_RELIC_BROWSER_LICENSE_KEY:
    process.env.REACT_APP_NEW_RELIC_BROWSER_LICENSE_KEY ||
    envs.REACT_APP_NEW_RELIC_BROWSER_LICENSE_KEY,
  REACT_APP_NEW_RELIC_APPLICATION_ID:
    process.env.REACT_APP_NEW_RELIC_APPLICATION_ID ||
    envs.REACT_APP_NEW_RELIC_APPLICATION_ID,
  REACT_APP_NEW_RELIC_ACCOUNT_ID:
    process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID ||
    envs.REACT_APP_NEW_RELIC_ACCOUNT_ID,
  REACT_APP_NEW_RELIC_TRUST_KEY:
    process.env.REACT_APP_NEW_RELIC_TRUST_KEY ||
    envs.REACT_APP_NEW_RELIC_TRUST_KEY,
  REACT_APP_NEW_RELIC_AGENT_ID:
    process.env.REACT_APP_NEW_RELIC_AGENT_ID ||
    envs.REACT_APP_NEW_RELIC_AGENT_ID,
  REACT_APP_HOST_URL: process.env.REACT_APP_HOST_URL || envs.REACT_APP_HOST_URL,
  REACT_APP_MONGO_ATLAS_CHARTS_BASE_URL:
    process.env.REACT_APP_MONGO_ATLAS_CHARTS_BASE_URL ||
    envs.REACT_APP_MONGO_ATLAS_CHARTS_BASE_URL,
  REACT_APP_MONGO_ATLAS_CHART_ID:
    process.env.REACT_APP_MONGO_ATLAS_CHART_ID ||
    envs.REACT_APP_MONGO_ATLAS_CHART_ID
}

export default env
