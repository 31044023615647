import {
  useAppDispatch,
  useAppSelector
} from 'clientDashboard/clientDashboard.store'
import {useLazyQuery} from '@apollo/client'
import {GET_ZONES} from 'graphql/queries/peripheral-hub.queries'
import {setDeviceOverviewData} from 'clientDashboard/peripheralHub/peripheralHub.slice'
import {selectBusinessAndLocation} from 'clientDashboard/employee.selectors'
import {REFETCH_INTERVAL, UNASSIGNED} from 'constants/constants'
import {t} from 'i18next'
import _sortBy from 'lodash/sortBy'
import {GetZoneData1Query} from "graphql/generatedTypes/graphql";

const useZoneData = () => {
  const dispatch = useAppDispatch()
  const {businessId, locationId} = useAppSelector(selectBusinessAndLocation)

  const [_fetchZoneData, {loading, error}] = useLazyQuery(GET_ZONES, {
    pollInterval: REFETCH_INTERVAL
  })

  const fetchZoneData = (onCompleted?: Function) => {
    _fetchZoneData({
      variables: {
        businessId,
        locationId
      },
      onCompleted: (data) => {
        const sortedData = transformData(data)
        dispatch(setDeviceOverviewData(sortedData.getZoneData))

        if (typeof onCompleted === 'function') {
          onCompleted(data)
        }
      }
    })
  }

  return {fetchZoneData, loading, error}
}

function transformData(input: GetZoneData1Query) {
  if (!input) {
    return
  }
  const output: any = {getZoneData: {devices: {}, registers: {}, zones: []}}
  const allRegisterIds = new Set(input.fetchRegisters.map((reg: any) => reg.id))
  const assignedRegisterIds = new Set()

  // Process devices
  input.fetchDevices.forEach((device: any) => {
    output.getZoneData.devices[device.id] = {
      device_name: device.deviceName,
      status: device.status,
      metadata: device.metadata
    }
  })

  // Process registers
  input.fetchRegisters.forEach((register: any) => {
    output.getZoneData.registers[register.id] = {
      register_name: register.name,
      devices: register.devices.map((device: any) => device.id)
    }
  })

  // Process zones and track assigned registers
  input.fetchZones.forEach((zone: any) => {
    const registers = (zone.registers || [])?.map((reg: any) => reg.id)
    registers.forEach((id: any) => assignedRegisterIds.add(id))

    output.getZoneData.zones.push({
      id: zone.id,
      name: zone.name,
      description: zone.description,
      registers: registers,
      devices: []
    })
  })

  // Add unassigned registers to a new "Unassigned" zone
  const unassignedRegisters = Array.from(allRegisterIds).filter(
    (id) => !assignedRegisterIds.has(id)
  )

  output.getZoneData.zones = _sortBy(output.getZoneData.zones, 'name')

  output.getZoneData.zones.push({
    id: UNASSIGNED,
    name: t('unassigned'),
    description: '',
    registers: unassignedRegisters || [],
    devices: []
  })

  return output
}

export default useZoneData
