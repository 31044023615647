import {gql} from '@apollo/client'
import {graphql} from 'graphql/generatedTypes'

export const CASH_PICKUP = gql`
  mutation cashPickup(
    $location: String!
    $amount: Float!
    $till: String!
    $register: String!
    $eventId: String!
  ) {
    addTransactionEvent(
      locationId: $location
      register: $register
      till: $till
      amount: $amount
      transactionType: "debit"
      eventType: "cash_pickup"
      description: "Cash Pickup"
      eventId: $eventId
    ) {
      cashEvent
    }
  }
`

export const CASH_DROP = gql`
  mutation cashDrop(
    $location: String!
    $amount: Float!
    $till: String!
    $register: String!
    $eventId: String!
  ) {
    addTransactionEvent(
      locationId: $location
      register: $register
      till: $till
      amount: $amount
      transactionType: "credit"
      eventType: "cash_drop"
      description: "Cash Drop"
      eventId: $eventId
    ) {
      cashEvent
    }
  }
`

export const CREATE_CASH_TRANSACTION = gql`
  mutation createTransaction(
    $location: String!
    $amount: Float!
    $employeeCode: String
    $till: String!
    $register: String!
    $businessDate: String
    $eventType: String!
    $description: String!
    $transactionType: String
    $transactionId: String
    $eventId: String!
  ) {
    addTransactionEvent(
      locationId: $location
      register: $register
      till: $till
      employeeCode: $employeeCode
      amount: $amount
      businessDate: $businessDate
      transactionType: $transactionType
      eventType: $eventType
      description: $description
      transactionId: $transactionId
      eventId: $eventId
    ) {
      cashEvent
    }
  }
`

export const CREATE_SAFE_CASH_TRANSACTION = gql`
  mutation createSafeTransaction(
    $locationId: String!
    $fromSafe: String!
    $toSafe: String!
    $amount: Float!
    $eventType: String!
    $description: String!
    $eventId: String!
  ) {
    addSafeCashTransactionEvent(
      locationId: $locationId
      fromSafe: $fromSafe
      toSafe: $toSafe
      amount: $amount
      eventType: $eventType
      description: $description
      eventId: $eventId
    ) {
      cashEvent
    }
  }
`

export const CASH_TILL_CLOSE = gql`
  mutation cashTillClose(
    $location: String!
    $amount: Float!
    $cashVariance: Float!
    $till: String!
    $register: String!
    $businessDate: String
    $reasonCode: String
    $denominationBreakdown: [DenominationInput!]
    $reasonDescription: String
    $otherDenominationCurrency: Int
    $eventId: String!
  ) {
    addTransactionEvent(
      locationId: $location
      register: $register
      till: $till
      amount: $amount
      businessDate: $businessDate
      cashVariance: $cashVariance
      reasonCode: $reasonCode
      transactionType: "debit"
      eventType: "reconcile"
      description: "Reconcile Till"
      denominationBreakdown: $denominationBreakdown
      otherDenominationCurrency: $otherDenominationCurrency
      reasonDescription: $reasonDescription
      eventId: $eventId
    ) {
      cashEvent
    }
  }
`

export const CREATE_CASH_DEPOSIT = gql`
  mutation cashDeposit($location: String!, $amount: Int!) {
    createBankDeposit(locationId: $location, amount: $amount) {
      amount
      createdAt
      updatedAt
      status
      id
    }
  }
`

export const SAFE_RECONCILE = gql`
  mutation safeReconcile(
    $location: String!
    $amount: Int!
    $safeType: String!
    $reasonCode: String!
    $denominationBreakdown: [DenominationInput!]
    $reasonDescription: String
    $otherDenominationCurrency: Int
  ) {
    createStoreTransaction(
      locationId: $location
      amount: $amount
      safeType: $safeType
      reasonCode: $reasonCode
      denominationBreakdown: $denominationBreakdown
      reasonDescription: $reasonDescription
      otherDenominationCurrency: $otherDenominationCurrency
    ) {
      amount
      dateCreated
      dateModified
      id
    }
  }
`

export const UPDATE_CASH_DEPOSIT = gql`
  mutation cashDepositUpdate(
    $id: String!
    $location: String!
    $depositNumber: String!
    $depositReceipt: Upload!
  ) {
    updateBankDeposit(
      id: $id
      locationId: $location
      depositNumber: $depositNumber
      depositReceipt: $depositReceipt
    ) {
      amount
      createdAt
      updatedAt
      status
      depositReceipt
      id
    }
  }
`
export const DOWNLOAD_FILE_MUTATION = gql`
  mutation DownloadFile($locationId: String!, $id: String!) {
    downloadDepositReceipt(locationId: $locationId, id: $id) {
      fileContent
      fileName
    }
  }
`

export const GENERATE_ATLAS_AUTH_TOKEN = gql`
  mutation generateAtlasAuthToken {
    generateAtlasAuthToken {
      success
      token
    }
  }
`

export const BULK_CASH_TRANSACTION = graphql(`
  mutation bulkCashTransaction($transactions: [CashTransactionInputType!]!) {
    bulkTransactionEvent(transactions: $transactions) {
      cashEvent
    }
  }
`)
