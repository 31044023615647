import {graphql} from 'graphql/generatedTypes'

export const GET_TRANSACTION_FAILURES = graphql(`
  query cashTransactionFailures(
    $locationId: String!
    $dateRange: [String!]!
    $pageIndex: Int
    $pageSize: Int
  ) {
    cashTransactionFailures(
      locationId: $locationId
      dateRange: $dateRange
      pageIndex: $pageIndex
      pageSize: $pageSize
    ) {
      nodes {
        createdAt
        error
        eventId
        eventType
        id
        payload
      }
      totalPageCount
    }
  }
`)
