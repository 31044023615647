import {gql} from '@apollo/client'

export const ADD_SECRET = gql`
  mutation AddSecret($secretName: String!, $secretValue: String!) {
    addSecret(secretName: $secretName, secretValue: $secretValue)
  }
`

export const DELETE_SECRET = gql`
  mutation DeleteSecret($secretName: String!) {
    deleteSecret(secretName: $secretName)
  }
`
