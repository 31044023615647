import {graphql} from 'graphql/generatedTypes'

export const ADD_LOCATION = graphql(`
  mutation addLocation($location: LocationInput!) {
    addLocation(location: $location) {
      id
      name
      brand
      address {
        street
        city
        state
        zipCode
        country
      }
      phoneNumbers {
        contactPhone
        supportPhone
      }
      description
      storeNumber
      operatingHours {
        day
        fromHour
        toHour
      }
      timeZone
      locationConfig {
        stripe {
          displayName
          stripeTerminalLocationId
        }
      }
      status
      notificationEmails
    }
  }
`)

export const UPDATE_LOCATION = graphql(`
  mutation updateLocation($locationId: String!, $location: LocationInput!) {
    updateLocation(location: $location, locationId: $locationId) {
      id
      name
      brand
      address {
        street
        city
        state
        zipCode
        country
      }
      phoneNumbers {
        contactPhone
        supportPhone
      }
      description
      storeNumber
      operatingHours {
        day
        fromHour
        toHour
      }
      timeZone
      locationConfig {
        stripe {
          displayName
          stripeTerminalLocationId
        }
      }
      status
      notificationEmails
    }
  }
`)

export const ADD_UPDATE_LOCATION_CONFIG = graphql(`
  mutation locationConfig($input: LocationConfigInput!) {
    locationConfig(input: $input)
  }
`)

export const DEACTIVATE_LOCATION = graphql(`
  mutation deactivateLocation($locationId: String!) {
    deactivateLocation(locationId: $locationId) {
      id
      name
      brand
      address {
        street
        city
        state
        zipCode
        country
      }
      phoneNumbers {
        contactPhone
        supportPhone
      }
      description
      storeNumber
      operatingHours {
        day
        fromHour
        toHour
      }
      timeZone
      locationConfig {
        stripe {
          displayName
          stripeTerminalLocationId
        }
      }
      status
    }
  }
`)

export const ACTIVATE_LOCATION = graphql(`
  mutation activateLocation($locationId: String!) {
    activateLocation(locationId: $locationId) {
      id
      name
      brand
      address {
        street
        city
        state
        zipCode
        country
      }
      phoneNumbers {
        contactPhone
        supportPhone
      }
      description
      storeNumber
      operatingHours {
        day
        fromHour
        toHour
      }
      timeZone
      locationConfig {
        stripe {
          displayName
          stripeTerminalLocationId
        }
      }
      status
    }
  }
`)
