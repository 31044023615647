import {useEffect} from 'react'
import {useRouteMatch} from 'react-router-dom'

import {LocalStorageKeys} from 'constants/constants'
import {isValidObjectId} from 'utils/common'
import useLocalStorage from './useLocalStorage'

export type BusinessType = {
  businessId: string
  isValidBusiness: boolean
}

const useBusiness = (): BusinessType => {
  const match = useRouteMatch<{businessId: string}>('/:businessId')
  const [, setBusinessIdInStore] = useLocalStorage(
    LocalStorageKeys.BUSINESS_ID,
    ''
  )
  let businessId = match?.params?.businessId || ''
  // Check if the URL is /login/callback
  if (!businessId) {
    const params = new URLSearchParams(window.location.search)
    businessId = JSON.parse(params.get('state') || '{}')?.businessId || ''
  }

  const isValidBusiness = !!businessId && isValidObjectId(businessId)

  useEffect(() => {
    if (isValidBusiness) {
      setBusinessIdInStore(businessId)
    }
  }, [isValidBusiness])

  return {businessId, isValidBusiness}
}

export default useBusiness
