import {ApolloError, useMutation} from '@apollo/client'
import OktaAuth, {toRelativeUrl} from '@okta/okta-auth-js'
import {VERIFY_ADMIN_OKTA_TOKEN_AND_GENERATE_SESSION_TOKEN} from 'graphql/mutations/login.mutation'
import useAdminEmployee from 'hooks/useAdminEmployee'
import {jwtDecode} from 'jwt-decode'
import {useEffect, useState} from 'react'

import {useAuth} from 'auth/AuthContext'
import client from 'config/apollo.config'
import config from 'config/okta.config'
import {LocalStorageKeys} from 'constants/constants'
import env from 'constants/env'
import useLocalStorage from './useLocalStorage'

export type OktaConfigType = {
  oktaConfig: any
  signOut: (oktaAuth: OktaAuth) => Promise<void>
  onAuthRequired: (oktaAuth: OktaAuth) => Promise<void>
  restoreOriginalUri: (oktaAuth: OktaAuth, originalUri: string) => Promise<void>
}

const useAdminOktaAuth = (): OktaConfigType => {
  const {authInfo, updateAuthInfo} = useAuth()
  const [oktaConfig, setOktaConfig] = useState<any>(null)
  const [
    toteAdminAccessToken,
    setToteAdminAccessToken,
    clearToteAdminAccessToken
  ] = useLocalStorage(LocalStorageKeys.TOTE_ADMIN_ACCESS_TOKEN, '')

  const {getEmployee} = useAdminEmployee()

  const [verifyAdminOktaTokenAndGenerateSessionToken] = useMutation(
    VERIFY_ADMIN_OKTA_TOKEN_AND_GENERATE_SESSION_TOKEN,
    {
      fetchPolicy: 'no-cache'
    }
  )
  // Extract the okta issuer and clientId from environment variables and set the oktaConfig in local storage
  useEffect(() => {
    const oktaIssuer = env.REACT_APP_ADMIN_OKTA_ISSUER
    const oktaClientId = env.REACT_APP_ADMIN_OKTA_CLIENT_ID
    if (!oktaIssuer || !oktaClientId) return
    updateAuthInfo({okta: {oktaIssuer, oktaClientId}})
    // If the user is a tote super admin, update the authInfo
    if (authInfo.isToteSuperAdmin === null && toteAdminAccessToken) {
      try {
        const decodedToken: any = jwtDecode(toteAdminAccessToken)
        updateAuthInfo({
          isToteSuperAdmin: Boolean(decodedToken?.is_tote_super_admin)
        })
      } catch (error) {
        console.error('Error while decoding token: ', error)
        signOut(oktaConfig)
      }
    }
  }, [])

  useEffect(() => {
    const {oktaIssuer, oktaClientId} = authInfo.okta || {}
    if (!oktaIssuer || !oktaClientId || oktaConfig) return

    setOktaConfig(
      new OktaAuth({
        ...config.oidc,
        redirectUri: `${window.location.origin}/admin/login/callback`,
        issuer: oktaIssuer,
        clientId: oktaClientId
      })
    )
  }, [authInfo.okta?.oktaIssuer])

  // Function to sign out of Okta
  const signOut = async (oktaAuth: OktaAuth) => {
    if (!oktaAuth?.authStateManager?.getAuthState?.()?.isAuthenticated) return

    await oktaAuth?.signOut({
      postLogoutRedirectUri: `${window.location.origin}/admin`
    })
    clearToteAdminAccessToken()
  }

  // Function to restore original URI
  const restoreOriginalUri = async (
    oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    if (!oktaAuth?.authStateManager?.getAuthState?.()?.isAuthenticated) return
    const tokens = await oktaAuth.tokenManager.getTokens()
    // verifyAdminOktaTokenAndGenerateSessionToken is a function that verifies the Okta token and generates a session token
    // and sets the tote_access_token in local storage
    // and then redirects to the original URI
    await verifyAdminOktaTokenAndGenerateSessionToken({
      fetchPolicy: 'no-cache',
      variables: {
        accessToken: tokens.accessToken?.accessToken as string,
        idToken: tokens.idToken?.idToken as string,
        oktaClientId: oktaAuth.options.clientId as string,
        oktaIssuer: oktaAuth.options.issuer as string
      },
      onCompleted: async (data: any) => {
        if (data.verifyAdminOktaTokenAndGenerateSessionToken) {
          setToteAdminAccessToken(
            data.verifyAdminOktaTokenAndGenerateSessionToken
          )
          await client?.clearStore?.()
          await getEmployee()
        }
        window.location.href = toRelativeUrl(
          originalUri || `/admin`,
          window.location.origin
        )
      },
      onError: async (error: ApolloError) => {
        // print the error
        console.error(
          'Error while verifying Okta token and generating session token: ',
          error
        )
        signOut(oktaAuth)
      }
    })
  }
  // Function to handle authentication required
  const onAuthRequired = async (oktaAuth: OktaAuth) => {
    clearToteAdminAccessToken()
    await client?.clearStore?.()

    await oktaAuth.signInWithRedirect({
      state: JSON.stringify({
        okta: authInfo.okta
      })
    })
  }
  return {oktaConfig, signOut, onAuthRequired, restoreOriginalUri}
}
export default useAdminOktaAuth
