import {AuthMethod, LocalStorageKeys} from 'constants/constants'
import useLocalStorage from 'hooks/useLocalStorage'
import {jwtDecode, JwtPayload} from 'jwt-decode'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from "clientDashboard/clientDashboard.store";
import {setIsAuthenticated} from "clientDashboard/employee.slice";
import {selectIsAuthenticated} from "clientDashboard/employee.selectors";

const useAccessToken = () => {
  const dispatch = useAppDispatch()
  const isAuthenticatedState = useAppSelector(selectIsAuthenticated)

  const location = useLocation()
  const [checkingTokenInQuery, setCheckingTokenInQuery] = useState(true)
  const [toteAccessToken, setToteAccessToken] = useLocalStorage(
    LocalStorageKeys.TOTE_ACCESS_TOKEN,
    ''
  )
  const [, setAuthMethod] = useLocalStorage(
    LocalStorageKeys.AUTH_METHOD,
    AuthMethod.TOTE
  )

  let isAuthenticated = !!toteAccessToken

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const accessToken = params.get('access_token')
    if (accessToken) {
      setToteAccessToken(accessToken)
      setAuthMethod(AuthMethod.TOTE)
    }
    setCheckingTokenInQuery(false)
  }, [location])

  if (toteAccessToken) {
    try {
      const decodedToken: JwtPayload = jwtDecode(toteAccessToken)
      isAuthenticated &&=
        Number(decodedToken.exp) > Math.round(Date.now() / 1000)
    } catch (error) {
      isAuthenticated = false
      console.error('Error while decoding token: ', error)
    }
  }

  if (isAuthenticatedState !== isAuthenticated) {
    dispatch(setIsAuthenticated(isAuthenticated))
  }
  return {isAuthenticated, loading: checkingTokenInQuery}
}

export default useAccessToken
