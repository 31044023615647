import 'react-notifications-component/dist/theme.css'

import {OktaAuth} from '@okta/okta-auth-js'
import {SecureRoute, useOktaAuth} from '@okta/okta-react'
import {Provider as ReduxStoreProvider} from "react-redux";
import { store } from 'adminPanel/adminPanel.store'
import Secrets from 'adminPanel/Secrets'
import useAdminAccessToken from 'hooks/useAdminAccessToken'
import useAdminEmployee from 'hooks/useAdminEmployee'
import {useEffect} from 'react'
import React from 'react'
import {Route, Switch, useRouteMatch} from 'react-router-dom'

import {useAuth} from 'auth/AuthContext'
import NotFound from 'ui/organisms/NotFound'
import {EventTypes, LocalStorageKeys} from 'constants/constants'
import BusinessManager from 'adminPanel/BusinessManager'
import Home from 'ui/molecules/Home'
import {authEventEmitter} from 'auth/events'
import useLocalStorage from 'hooks/useLocalStorage'

type AdminRoutesProps = {
  signOut: (oktaAuth: OktaAuth) => Promise<void>
}

const AdminRoutes = ({signOut}: AdminRoutesProps) => {
  let {url}: any = useRouteMatch()
  const {authInfo} = useAuth()
  const {authState, oktaAuth} = useOktaAuth()
  const {isAuthenticated} = useAdminAccessToken()
  const [toteAdminAccessToken] = useLocalStorage(
    LocalStorageKeys.TOTE_ADMIN_ACCESS_TOKEN
  )

  const {getEmployee, loadingEmployee} = useAdminEmployee()

  useEffect(() => {
    if (!isAuthenticated) {
      signOut(oktaAuth)
    }
    if (!authState?.isAuthenticated) return
    if (toteAdminAccessToken) {
      getEmployee()
    }
  }, [authState?.isAuthenticated])

  // Event listener for EventTypes.SIGN_OUT
  useEffect(() => {
    authEventEmitter.on(EventTypes.SIGN_OUT, () => signOut(oktaAuth))
    return () => {
      authEventEmitter.off(EventTypes.SIGN_OUT, () => signOut(oktaAuth))
    }
  }, [authState?.isAuthenticated])
  if (loadingEmployee) return <div></div>
  return (
    <ReduxStoreProvider store={store} >
      <Switch>
        <SecureRoute path={`${url}/`} exact component={Home} />
        {authState?.isAuthenticated && authInfo?.isToteSuperAdmin && (
          <SecureRoute
            exact
            path={`${url}/business-manager`}
            component={BusinessManager}
          />
        )}
        <SecureRoute path={`${url}/`} exact component={Home} />
        {authState?.isAuthenticated && authInfo?.isToteSuperAdmin && (
          <SecureRoute exact path={`${url}/secrets`} component={Secrets} />
        )}
        <Route path={'*'} component={NotFound} />
      </Switch>
    </ReduxStoreProvider>
  )
}

export default AdminRoutes
