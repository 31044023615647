import {Grid} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import cx from 'clsx'
import StyledButton from 'ui/atoms/StyledButton'
import theme from 'config/theme'

function StyledDialog({
  open,
  title,
  description = '',
  body,
  successButtonName,
  cancelButtonName,
  successCallback,
  cancelCallback,
  closeCallback,
  otherSuccessButtonName,
  otherSuccessCallback,
  disableSuccessButton,
  showLoader = false,
  maxWidth = 'sm',
  isAutoFocused,
  footerClassNames = '',
  ...others
}: any) {
  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      onClose={() => {
        if (closeCallback) {
          closeCallback()
          return
        }
        if (cancelCallback) {
          cancelCallback()
          return
        }
      }}
      sx={{
        '& .MuiPaper-root': {
          background: 'white',
          borderRadius: '12px',
          maxHeight: '720px',
          boxShadow:
            '0px 0px 4px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.2)'
        },
        '& .MuiDialog-container': {
          backgroundColor: 'rgba(67, 67, 67, 0.70)',
          backdropFilter: 'blur(4px)'
        }
      }}
      fullWidth
      {...others}
    >
      {title && (
        <Grid
          container
          direction='row'
          alignContent='space-between'
          justifyContent='flex-start'
          style={{
            boxShadow: 'inset 0px -1px 0px white',
            backgroundColor: theme.palette.primary.light
          }}
        >
          <Grid
            item
            style={{
              padding: '24px 24px'
            }}
          >
            <DialogTitle
              sx={{
                '&.MuiDialogTitle-root': {
                  typography: 'h3',
                  fontSize: '18px',
                  padding: '0px'
                }
              }}
            >
              {title}
            </DialogTitle>

            {description && (
              <DialogTitle
                sx={{
                  '&.MuiDialogTitle-root': {
                    typography: 'subtitle1',
                    fontSize: '14px',
                    padding: '0px',
                    color: 'text.secondary'
                  }
                }}
              >
                {description}
              </DialogTitle>
            )}
          </Grid>
        </Grid>
      )}

      <DialogContent
        sx={{
          '&.MuiDialogContent-root': {
            padding: '20px',
            typography: 'body1',
            fontSize: '16px'
          }
        }}
      >
        {body}
      </DialogContent>

      {(successCallback || cancelCallback || otherSuccessCallback) && (
        <DialogActions
          sx={{
            '&.MuiDialogActions-root': {
              height: '72px',
              boxShadow: 'inset 0px 1px 0px white',
              justifyContent: 'flex-end'
            }
          }}
          className={cx(footerClassNames)}
        >
          {cancelCallback && (
            <StyledButton
              variant='outlined'
              size='medium'
              onClick={cancelCallback}
              style={{
                minWidth: '100px'
              }}
            >
              {cancelButtonName ? cancelButtonName : 'Cancel'}
            </StyledButton>
          )}

          {successCallback && successButtonName && (
            <StyledButton
              id='successButton'
              size='medium'
              disabled={disableSuccessButton}
              onClick={successCallback}
              showLoader={showLoader}
              style={{
                minWidth: '100px'
              }}
            >
              {successButtonName}
            </StyledButton>
          )}
          {otherSuccessCallback && otherSuccessButtonName && (
            <StyledButton
              id='successButton'
              size='medium'
              disabled={disableSuccessButton}
              onClick={otherSuccessCallback}
              style={{
                minWidth: '100px'
              }}
            >
              {otherSuccessButtonName}
            </StyledButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default StyledDialog
