import {useMutation} from '@apollo/client'
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  RadioGroup,
  Select,
  Typography
} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledInput from 'ui/atoms/StyledInput'
import StyledRadio from 'ui/atoms/StyledRadio'
import {StyledMenuItem} from 'ui/molecules/StyledSelect'
import theme from 'config/theme'
import {
  DEFAULT_DENOMINATIONS,
  RECONCILE_TYPE,
  SAFE_TYPES,
  SAFE_TYPES_ENUM,
  STORE_SAFE_REASON_CODE
} from 'constants/constants'
import {DenominationInput} from 'graphql/generatedTypes/graphql'
import {SAFE_RECONCILE} from 'graphql/mutations/cash-management.mutations'
import useNotify from 'hooks/useNotify'
import {t} from 'i18next'
import cloneDeep from 'lodash/cloneDeep'
import React from 'react'
import {centsToDollar, formatCurrency} from 'utils/common'
import {
  amountInCents,
  isInvalidInputAmount,
  parseAmount
} from 'utils/currency.utils'
import Big from 'big.js'

interface SafeReconcileProps {
  setModelOpen: any
  safeTotalAmount: number
  reserveTotalCash?: number
}

const StoreSafeReconcileForm: React.FC<SafeReconcileProps> = ({
  setModelOpen,
  safeTotalAmount = 0,
  reserveTotalCash = 0
}) => {
  const {authInfo} = useAuth()
  const notify = useNotify()
  const [reconcileSafe] = useMutation(SAFE_RECONCILE)

  const [denominations, setDenominations] = React.useState<DenominationInput[]>(
    cloneDeep(DEFAULT_DENOMINATIONS)
  )

  const [actualSafeAmount, setActualSafeAmount] = React.useState<string>('0')
  const [selectedOption, setSelectedOption] = React.useState('total_amount')

  const [varianceCash, setVarianceCash] = React.useState<number>(0.0)
  const [reasonCode, setReasonCode] = React.useState<string>(
    STORE_SAFE_REASON_CODE[0].value
  )
  const [reason, setReason] = React.useState<string>()

  const [safeType, setSafeType] = React.useState<string>()
  const [safeTypeOpen, setSafeTypeOpen] = React.useState(false)
  const [otherDenominationCurrency, setOtherDenominationCurrency] =
    React.useState<string>('0')

  const submitSafeReconcile = () => {
    reconcileSafe({
      variables: {
        location: authInfo.locationId,
        amount: varianceCash,
        reasonCode: reasonCode,
        safeType: safeType,
        denominationBreakdown: denominations,
        reasonDescription: reason,
        otherDenominationCurrency: amountInCents(otherDenominationCurrency)
      },
      onCompleted: () => {
        notify.show(t('cash-management.safe-reconcile.success'), 'success')
        setModelOpen(false)
      },
      onError: (error) => {
        notify.show(
          (error as any)?.message || t('something-went-wrong'),
          'error'
        )
      }
    })
  }

  const updateActualCash = (value: string) => {
    setActualSafeAmount(value)
    const totalAmount =
      safeType === SAFE_TYPES_ENUM.TILL_SAFE
        ? safeTotalAmount
        : reserveTotalCash
    if (value) {
      const variance = totalAmount - amountInCents(value)
      setVarianceCash(variance)
    } else {
      setVarianceCash(0)
    }
  }

  const handleRadioChange = (value: string) => {
    setSelectedOption(value)
    resetFields()
  }

  const handleDenominationChange = (index: number, value: string) => {
    const updatedDenominations = [...denominations]
    if (!isNaN(Number(value))) {
      let count = new Big(Number(value))
      updatedDenominations[index]['count'] = Number(
        count.abs().round(0, Big.roundDown)
      )
      setDenominations(updatedDenominations)
      calculateDenominationAmount(otherDenominationCurrency)
    }
  }

  const updateOtherDenominationCurrency = (value: string) => {
    setOtherDenominationCurrency(value)
    calculateDenominationAmount(value)
  }

  const calculateDenominationAmount = (value: string) => {
    const updatedDenominations = [...denominations]
    let total = 0
    updatedDenominations.forEach((denomination) => {
      total += (denomination?.denomination || 0) * (denomination?.count || 0)
    })
    total = centsToDollar(total) + Number(value || '0')
    updateActualCash(parseAmount(total.toString()))
  }

  const handleSafeTypeChange = (value: string) => {
    setSafeType(value)
    resetFields()
    if (!value) {
      setSafeTypeOpen(false)
    }
  }

  const resetFields = () => {
    setActualSafeAmount('0')
    setVarianceCash(0)
    setDenominations(cloneDeep(DEFAULT_DENOMINATIONS))
    setOtherDenominationCurrency('0')
  }

  const isSubmitDisabled =
    !safeType ||
    (safeTypeOpen && (!varianceCash || varianceCash === 0)) ||
    (selectedOption === RECONCILE_TYPE.individual_amount.value &&
      denominations.every((entry: any) => entry.count <= 0) &&
      (!otherDenominationCurrency || Number(otherDenominationCurrency) <= 0))

  const safeReconcileForm = () => {
    return (
      <>
        <Grid container spacing={4} padding={2}>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel id='safe-type'>Safe Type</InputLabel>
              <Select
                fullWidth
                size='medium'
                label={'Safe Type'}
                value={safeType || ''}
                onChange={(e) => {
                  handleSafeTypeChange(e.target.value as string)
                }}
                inputProps={{'aria-label': 'Without label'}}
              >
                <StyledMenuItem key='none' value=''>
                  <em>None</em>
                </StyledMenuItem>
                {SAFE_TYPES.map((safeOption, index) => (
                  <StyledMenuItem key={index} value={safeOption.value}>
                    {safeOption.label}
                  </StyledMenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {safeTypeOpen && (
            <>
              <Grid item xs={12} sm={12}>
                <Typography variant='h3'>Log Cash</Typography>
                <Typography
                  variant='subtitle2'
                  style={{
                    color: theme.palette.text.secondary,
                    lineHeight: '140%'
                  }}
                >
                  Input cash total OR log individually
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth required>
                  <RadioGroup
                    value={selectedOption}
                    onChange={(event) => handleRadioChange(event.target.value)}
                    sx={{
                      gap: 2
                    }}
                  >
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        value={RECONCILE_TYPE.total_amount.value}
                        control={<StyledRadio />}
                        componentsProps={{
                          typography: {variant: 'h3'}
                        }}
                        label={RECONCILE_TYPE.total_amount.label}
                      />
                    </Grid>

                    {selectedOption === RECONCILE_TYPE.total_amount.value && (
                      <>
                        <Grid item xs={12} sm={12}>
                          <StyledInput
                            fullWidth
                            value={actualSafeAmount}
                            type='number'
                            InputProps={{
                              startAdornment: t('currency-symbol')
                            }}
                            onChange={(e) => {
                              updateActualCash(parseAmount(e.target.value))
                            }}
                            error={isInvalidInputAmount(actualSafeAmount)}
                            helperText={
                              isInvalidInputAmount(actualSafeAmount)
                                ? 'Invalid amount'
                                : ''
                            }
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        value={RECONCILE_TYPE.individual_amount.value}
                        control={<StyledRadio />}
                        componentsProps={{
                          typography: {variant: 'h3'}
                        }}
                        label={RECONCILE_TYPE.individual_amount.label}
                      />
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
              {selectedOption === RECONCILE_TYPE.individual_amount.value && (
                <>
                  {denominations.map((denomination, index) => {
                    return (
                      <Grid item xs={12} sm={6} key={index}>
                        <StyledInput
                          fullWidth
                          id={`denomination_${index}`}
                          label={
                            t('currency-symbol') +
                            centsToDollar(denomination?.denomination || 0)
                          }
                          type='text'
                          value={denomination?.count || 0}
                          onChange={(e: any) =>
                            handleDenominationChange(index, e.target.value)
                          }
                          inputProps={{
                            pattern: '^[0-9]*$'
                          }}
                        />
                      </Grid>
                    )
                  })}

                  <Grid item xs={12} sm={12}>
                    <StyledInput
                      fullWidth
                      label={'Other Currency'}
                      value={otherDenominationCurrency}
                      type='number'
                      InputProps={{
                        startAdornment: t('currency-symbol')
                      }}
                      onChange={(e) => {
                        updateOtherDenominationCurrency(
                          parseAmount(e.target.value)
                        )
                      }}
                      error={isInvalidInputAmount(otherDenominationCurrency)}
                      helperText={
                        isInvalidInputAmount(otherDenominationCurrency)
                          ? 'Invalid amount'
                          : ''
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <StyledInput
                      fullWidth
                      label={'Total Amount'}
                      value={actualSafeAmount || 0}
                      disabled={
                        selectedOption ===
                        RECONCILE_TYPE.individual_amount.value
                      }
                      type='number'
                      InputProps={{
                        startAdornment: t('currency-symbol')
                      }}
                      onChange={(e) => {
                        updateActualCash(parseAmount(e.target.value))
                      }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={12}>
                <Grid
                  style={{
                    display: 'flex',
                    padding: '24px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '24px',
                    alignSelf: 'stretch',
                    borderRadius: '8px',
                    background: theme.palette.background.paper
                  }}
                >
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      gap: '12px',
                      flex: '1 0 0',
                      borderRight: '1px solid',
                      borderColor: theme.palette.divider
                    }}
                  >
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        gap: '10px',
                        alignSelf: 'stretch'
                      }}
                    >
                      <Typography variant='body2'>Current Cash</Typography>
                    </Grid>
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '10px',
                        alignSelf: 'stretch'
                      }}
                    >
                      <Typography variant='h2'>
                        {formatCurrency(
                          centsToDollar(
                            safeType === SAFE_TYPES_ENUM.TILL_SAFE
                              ? safeTotalAmount
                              : reserveTotalCash
                          )
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      gap: '12px',
                      flex: '1 0 0',
                      borderRight: '1px solid',
                      borderColor: theme.palette.divider
                    }}
                  >
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        gap: '10px',
                        alignSelf: 'stretch'
                      }}
                    >
                      <Typography variant='body2'>Logged Cash</Typography>
                    </Grid>
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '10px',
                        alignSelf: 'stretch'
                      }}
                    >
                      <Typography variant='h2'>
                        {formatCurrency(actualSafeAmount || 0)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      gap: '12px',
                      flex: '1 0 0'
                    }}
                  >
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        gap: '10px',
                        alignSelf: 'stretch'
                      }}
                    >
                      <Typography variant='body2'>Variance</Typography>
                    </Grid>
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '10px',
                        alignSelf: 'stretch',
                        color: 'red'
                      }}
                    >
                      <Typography variant='h2'>
                        {formatCurrency(centsToDollar(Math.abs(varianceCash)))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel id='reason-code'>Reason Code</InputLabel>
                  <Select
                    fullWidth
                    size='medium'
                    label={'Reason Code'}
                    value={reasonCode}
                    onChange={(e) => {
                      setReasonCode(e.target.value as string)
                    }}
                    inputProps={{'aria-label': 'Without label'}}
                    error={Math.abs(varianceCash) > 0 && !reasonCode}
                  >
                    {STORE_SAFE_REASON_CODE.map((reasonCodeOption, index) => (
                      <StyledMenuItem
                        key={index}
                        value={reasonCodeOption.value}
                      >
                        {reasonCodeOption.label}
                      </StyledMenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <StyledInput
                    fullWidth
                    size='medium'
                    label={'Reason Description'}
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value as string)
                    }}
                    inputProps={{'aria-label': 'Without label'}}
                  ></StyledInput>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </>
    )
  }

  return (
    <>
      <StyledDialog
        open
        title='Reconcile Safe'
        body={<Grid>{safeReconcileForm()}</Grid>}
        successButtonName={safeTypeOpen ? 'Reconcile' : 'Confirm'}
        cancelButtonName='Dismiss'
        cancelCallback={() => {
          setModelOpen(false)
        }}
        onClose={() => {}}
        disableSuccessButton={isSubmitDisabled}
        successCallback={() => {
          safeTypeOpen ? submitSafeReconcile() : setSafeTypeOpen(true)
        }}
      />
    </>
  )
}

export default StoreSafeReconcileForm
