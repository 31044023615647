import {Grid} from '@mui/material'
import StyledButton from 'ui/atoms/StyledButton'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import React from 'react'
import ThreeDotsMenu from 'clientDashboard/cashManagement/cashManagerInsights/ThreeDotsMenu'

interface Insight {
  exportOptions: any
}

const ExportInsightDataInsight = ({exportOptions}: Insight) => {
  const primaryExportOptions = React.useMemo(() => {
    return exportOptions.filter((option: any) => option.type === 'primary')
  }, [exportOptions])

  const secondaryExportOptions = React.useMemo(() => {
    return exportOptions.filter((option: any) => option.type === 'secondary')
  }, [exportOptions])

  return (
    <Grid
      container
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 12
      }}
    >
      <Grid
        item
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 12
        }}
      >
        {primaryExportOptions.map((option: any) => (
          <Grid key={option.id}>
            <StyledButton
              key={option.id}
              onClick={() => option.onClick()}
              variant='contained'
              color='primary'
              size='small'
              startIcon={<FileDownloadIcon />}
              disabled={!option.data || option.data.length === 0}
            >
              {option.label}
            </StyledButton>
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <ThreeDotsMenu exportOptions={secondaryExportOptions} />
      </Grid>
    </Grid>
  )
}
export default ExportInsightDataInsight
