import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Moment} from "moment";
import {ColumnFilter} from '@tanstack/table-core'

interface CashManagementState {
  columnFilters: ColumnFilter[],
}


const initialState: CashManagementState = {
  columnFilters: []
}


export const cashManagementSummarySlice = createSlice({
  name: 'cashManagement',
  initialState,
  reducers: {
    applyColumnFilters: (state, action: PayloadAction<ColumnFilter[]>) => {
      state.columnFilters = action.payload;
    },
    applyDateChange: (state, action: PayloadAction<Moment | null>) => {
      const dateFilter = state.columnFilters.find(filter => filter.id === 'date');
      if (dateFilter) {
        dateFilter.value = action.payload;
      } else {
        state.columnFilters.push({
          id: 'date',
          value: action.payload,
        })
      }
    },
  }

})

export const { applyColumnFilters, applyDateChange } = cashManagementSummarySlice.actions

export default cashManagementSummarySlice.reducer
