import {useLazyQuery, useQuery, useSubscription} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Grid, IconButton, Typography} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import CashEventForm from 'clientDashboard/cashManagement/CashEventForm'
import SafeEventForm from 'clientDashboard/cashManagement/SafeEventForm'
import StyledButton from 'ui/atoms/StyledButton'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledTable from 'ui/molecules/StyledTable'
import theme from 'config/theme'
import {EVENT_OPTIONS, TransactionType} from 'constants/constants'
import {Permission} from 'constants/permissions'
import {GET_TRANSACTIONS} from 'graphql/queries/cash-management.queries'
import {GET_REGISTER_AND_TILL} from 'graphql/queries/cash-register-till.queries'
import {GET_ALL_EMPLOYEES} from 'graphql/queries/employee.queries'
import {CASH_EVENT_SUBSCRIPTION} from 'graphql/subscriptions/cash-event-subscriptions'
import useNotify from 'hooks/useNotify'
import usePermission from 'hooks/usePermission'
import {t} from 'i18next'
import {MRT_ColumnDef} from 'material-react-table'
import moment from 'moment'
import React, {useEffect, useMemo} from 'react'
import {CashTransactionType} from 'types/cashSummary.types'
import {
  centsToDollar,
  formatCurrency,
  localDateTimeString,
  underscoreToReadable
} from 'utils/common'

type tillTransactionType = {
  name: string
  description: string
}

const transactionTypeData: tillTransactionType[] = EVENT_OPTIONS.map(
  (eventOption) => ({
    name: eventOption.label,
    description: t(`till-transaction-type.${eventOption.value}.description`)
  })
)

const CashTransactions = () => {
  const notify = useNotify()

  const {authInfo} = useAuth()

  const [transactionInfo, setTransactionInfo] = React.useState(false)

  const hasCreateTransactionAccess = usePermission(
    Permission.CREATE_CASH_TRANSACTION
  )
  const hasCreateSafeTransactionAccess = usePermission(
    Permission.CREATE_SAFE_CASH_TRANSACTION
  )

  const [dateRange, setDateRange] = React.useState([
    moment().local().startOf('day'),
    moment().local().endOf('day')
  ])
  const [selectedRegister, setSelectedRegister] = React.useState<string>('all')
  const [selectedTill, setSelectedTill] = React.useState<string>('all')
  const [selectedEmployee, setSelectedEmployee] = React.useState<string>('all')
  const [selectedEvent, setSelectedEvent] = React.useState<string>('all')

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10
  })

  const [columnFilters, setColumnFilters] = React.useState([])

  const [modelOpen, setModelOpen] = React.useState(false)
  const [safeModalOpen, setSafeModalOpen] = React.useState(false)

  const [getEmployees, {data: employeeData}] = useLazyQuery(GET_ALL_EMPLOYEES, {
    fetchPolicy: 'no-cache'
  })

  const [getRegisterTill, {data: registerTillData}] = useLazyQuery(
    GET_REGISTER_AND_TILL,
    {fetchPolicy: 'no-cache'}
  )

  useEffect(() => {
    getEmployees({
      variables: {
        businessId: authInfo.businessId as string,
        locationId: authInfo.locationId as string,
        fetchAll: true
      }
    })
  }, [])

  useEffect(() => {
    getRegisterTill({
      variables: {
        businessId: authInfo.businessId as string,
        locationId: authInfo.locationId as string
      }
    })
  }, [])

  useEffect(() => {
    refetchTransaction()
  }, [modelOpen, safeModalOpen])

  const {
    loading: loadingTransaction,
    data: transactionData,
    refetch: refetchTransaction
  } = useQuery(GET_TRANSACTIONS, {
    variables: {
      register: selectedRegister,
      till: selectedTill,
      employeeCode: selectedEmployee,
      dateRange: dateRange,
      transactionType: selectedEvent,
      locationId: authInfo.locationId as string,
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize
    },
    onError: (error) => {
      notify.show(error.message, 'error')
    }
  })

  const {data} = useSubscription(CASH_EVENT_SUBSCRIPTION, {
    variables: {
      locationId: authInfo.locationId as string
    }
  })

  useEffect(() => {
    if (data) {
      const cashEvent = data.webCashEvent?.cashEvent
      if (cashEvent === 'refresh') {
        refetchTransaction()
      }
    }
  }, [data])

  const columns = useMemo<MRT_ColumnDef<CashTransactionType>[]>(
    () => [
      {
        accessorKey: 'transactionTime',
        header: 'Transaction Date & Time',
        enableColumnFilter: true,
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue() ? localDateTimeString(cell.getValue()) : '-'}
            </Typography>
          )
        },
        muiFilterTextFieldProps: {
          defaultValue: dateRange[0],
          sx: {
            minWidth: '150px'
          }
        },
        filterVariant: 'date-range'
      },
      {
        accessorKey: 'register',
        header: 'Register',
        enableColumnFilter: true,
        filterVariant: 'multi-select',
        muiFilterTextFieldProps: {
          placeholder: 'All',
          sx: {
            minWidth: '100px'
          }
        },
        filterSelectOptions: registerTillData?.fetchRegisterList?.map(
          (register: any) => ({
            label: register.name,
            value: register.name
          })
        ),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },

      {
        accessorKey: 'till',
        header: 'Till',
        enableColumnFilter: true,
        muiFilterTextFieldProps: {
          placeholder: 'All',
          sx: {
            minWidth: '100px'
          }
        },
        filterVariant: 'multi-select',
        filterSelectOptions: registerTillData?.fetchTills?.map((till: any) => ({
          label: till.name,
          value: till.name
        })),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'eventType',
        header: 'Transaction Type',
        enableColumnFilter: true,
        Header: ({column}) => (
          <>
            Transaction Type
            {
              <IconButton size='small' onClick={() => setTransactionInfo(true)}>
                <FontAwesomeIcon
                  icon={icon({
                    name: 'info-circle',
                    family: 'classic',
                    style: 'solid'
                  })}
                  color={theme.palette.text.secondary}
                />
              </IconButton>
            }
          </>
        ),
        muiFilterTextFieldProps: {
          placeholder: 'All',
          sx: {
            minWidth: '100px'
          }
        },
        filterVariant: 'multi-select',
        filterSelectOptions: EVENT_OPTIONS.map((option: any) => ({
          label: option.label,
          value: option.value
        })),

        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {underscoreToReadable(cell.getValue())}
            </Typography>
          )
        }
      },
      {
        accessorKey: 'transactionId',
        header: 'Transaction ID',
        enableColumnFilter: false,

        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'eventId',
        header: 'Event ID',
        enableColumnFilter: false,
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'eventTime',
        header: 'Event Date & Time',
        enableColumnFilter: false,
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue() ? localDateTimeString(cell.getValue()) : '-'}
            </Typography>
          )
        }
      },
      {
        accessorKey: 'safeType',
        header: 'Transaction On',
        enableColumnFilter: false,
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue()
                ? underscoreToReadable(cell.getValue())
                : 'Register - Till'}
            </Typography>
          )
        }
      },
      {
        accessorKey: 'employee',
        header: 'Employee',
        enableColumnFilter: true,
        muiFilterTextFieldProps: {
          placeholder: 'All',
          sx: {
            minWidth: '100px'
          }
        },
        filterVariant: 'multi-select',
        filterSelectOptions: employeeData?.getAllEmployees?.employees.map(
          (emp: any) => ({
            label: emp.name,
            value: emp.employeeCode
          })
        ),

        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue()?.name || ''}
            </Typography>
          )
        }
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        enableColumnFilter: false,
        muiTableBodyCellProps: {
          align: 'right'
        },
        Cell: ({cell, row}: any) => {
          if (row.original.transactionType === TransactionType.DEBIT) {
            return (
              <Typography variant='subtitle2'>
                ({formatCurrency(centsToDollar(cell.getValue()))})
              </Typography>
            )
          }

          return (
            <Typography variant='subtitle2'>
              {formatCurrency(centsToDollar(cell.getValue()))}
            </Typography>
          )
        }
      },
      {
        accessorKey: 'source',
        header: 'Source',
        enableColumnFilter: false,
        Cell: ({cell, row}: any) => {
          return (
            <Typography variant='subtitle2'>
              {underscoreToReadable(cell.getValue() || '_')}
            </Typography>
          )
        }
      }
    ],
    [dateRange, employeeData?.getAllEmployees, registerTillData]
  )

  useEffect(() => {
    columnFilters.forEach((filter: any) => {
      if (filter?.id === 'transactionTime') {
        let startDate = moment(filter?.value[0]).startOf('day')
        let endDate = moment(filter?.value[1]).endOf('day')
        if (!endDate.isValid()) {
          endDate = dateRange[1]
        }
        if (!startDate.isValid()) {
          startDate = dateRange[0]
        }
        setDateRange([startDate, endDate])
      }
      if (filter?.id === 'register') {
        setSelectedRegister(filter?.value?.join(','))
      }

      if (filter?.id === 'till') {
        setSelectedTill(filter?.value?.join(','))
      }

      if (filter?.id === 'eventType') {
        setSelectedEvent(filter?.value?.join(','))
      }

      if (filter?.id === 'employee') {
        setSelectedEmployee(filter?.value?.join(','))
      }
    })

    if (!columnFilters.some((filter: any) => filter?.id === 'register')) {
      setSelectedRegister('all')
    }

    if (!columnFilters.some((filter: any) => filter?.id === 'till')) {
      setSelectedTill('all')
    }

    if (!columnFilters.some((filter: any) => filter?.id === 'employee')) {
      setSelectedEmployee('all')
    }

    if (!columnFilters.some((filter: any) => filter?.id === 'eventType')) {
      setSelectedEvent('all')
    }
  }, [columnFilters, employeeData])

  const transactionColumns = useMemo<MRT_ColumnDef<tillTransactionType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Transaction Type',
        size: 100
      },
      {
        accessorKey: 'description',
        header: 'description',
        size: 250
      }
    ],
    []
  )

  const statusInfoContainer = () => {
    return (
      <>
        <StyledDialog
          open={transactionInfo}
          title='Transaction Type Information'
          body={
            <Grid>
              <StyledTable
                columns={transactionColumns}
                data={(transactionTypeData || []) as any}
                enableColumnActions={false}
                enableColumnFilters={false}
                enablePagination={false}
                enableSorting={false}
                enableHiding={false}
                muiTableContainerProps={() =>
                  ({sx: {maxHeight: '400px', overflowY: 'auto'}}) as any
                }
                renderToolbarInternalActions={() => null}
              />
            </Grid>
          }
          cancelButtonName='Dismiss'
          cancelCallback={() => {
            setTransactionInfo(false)
          }}
          onClose={() => {}}
        />
      </>
    )
  }

  return (
    <>
      {statusInfoContainer()}
      <StyledTable
        columns={columns}
        data={transactionData?.cashTransactions?.nodes || []}
        enableTableFooter={true}
        enableSorting={false}
        enableStickyHeader={true}
        manualFiltering={true}
        manualPagination={true}
        onColumnFiltersChange={setColumnFilters as any}
        onPaginationChange={setPagination}
        rowCount={transactionData?.cashTransactions?.totalPageCount || 0}
        state={{
          columnFilters,
          pagination,
          showSkeletons: loadingTransaction
        }}
        renderTopToolbarCustomActions={() => {
          return (
            <Grid
              style={{
                order: 1,
                display: 'flex',
                flexDirection: 'row',
                gap: 12
              }}
            >
              <StyledButton
                fullWidth={false}
                id='successButton'
                disabled={!hasCreateTransactionAccess}
                size='medium'
                onClick={() => setModelOpen(true)}
                startIcon={
                  <FontAwesomeIcon
                    icon={icon({
                      name: 'plus'
                    })}
                  />
                }
              >
                Create Transaction
              </StyledButton>
              <StyledButton
                fullWidth={false}
                id='successButton'
                disabled={!hasCreateSafeTransactionAccess}
                size='medium'
                onClick={() => setSafeModalOpen(true)}
                startIcon={
                  <FontAwesomeIcon
                    icon={icon({
                      name: 'plus'
                    })}
                  />
                }
              >
                {t('cash-management.transactions.create-safe-transaction')}
              </StyledButton>
            </Grid>
          )
        }}
        initialState={{
          density: 'compact',
          showColumnFilters: true,
          columnVisibility: {
            eventId: false,
            eventTime: false,
            safeType: false,
            source: false
          }
        }}
      />
      {modelOpen && (
        <CashEventForm modelOpen={modelOpen} setModelOpen={setModelOpen} />
      )}
      {safeModalOpen && <SafeEventForm setModelOpen={setSafeModalOpen} />}
    </>
  )
}

export default CashTransactions
