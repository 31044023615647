import {useMutation} from '@apollo/client'
import {
  Alert,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  RadioGroup,
  Select,
  Typography
} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledInput from 'ui/atoms/StyledInput'
import {StyledMenuItem} from 'ui/molecules/StyledSelect'
import StyledRadio from 'ui/atoms/StyledRadio'
import theme from 'config/theme'
import {
  DEFAULT_DENOMINATIONS,
  RECONCILE_TYPE,
  TILL_REASON_CODE
} from 'constants/constants'
import {DenominationInput} from 'graphql/generatedTypes/graphql'
import {CASH_TILL_CLOSE} from 'graphql/mutations/cash-management.mutations'
import useNotify from 'hooks/useNotify'
import {t} from 'i18next'
import React, {useEffect, useState} from 'react'
import {centsToDollar} from 'utils/common'
import cloneDeep from 'lodash/cloneDeep'
import {debounce} from 'lodash'
import {v4 as uuid4} from 'uuid'
import {
  amountInCents,
  isInvalidInputAmount,
  parseAmount
} from 'utils/currency.utils'
import Big from 'big.js'

interface FilterSelectProps {
  selectedItem: any
  setReviewDrawerOpen: any
  registerList: any
}

const CashReviewDrawerContainer = ({
  selectedItem,
  setReviewDrawerOpen,
  registerList
}: FilterSelectProps) => {
  const notify = useNotify()
  const {authInfo} = useAuth()

  const [denominations, setDenominations] = useState<DenominationInput[]>(
    cloneDeep(DEFAULT_DENOMINATIONS)
  )

  const initialIndex =
    Object.keys(selectedItem || {}).length !== 0
      ? registerList.findIndex(
          (item: any) =>
            item?.register === selectedItem.register &&
            item?.till === selectedItem.till
        )
      : 0

  const [currentIndex, setCurrentIndex] = useState(initialIndex)

  const itemListCount = registerList.length

  const [selectedOption, setSelectedOption] = useState('total_amount')
  const [reason, setReason] = useState<string>()

  const [varianceAmount, setVarianceAmount] = React.useState<number>(0.0)
  const [reasonCode, setReasonCode] = React.useState<string>('')

  const [errorMessage, setErrorMessage] = React.useState<string>('')

  const [actualAmount, setActualAmount] = React.useState<string>('0')
  const [otherDenominationCurrency, setOtherDenominationCurrency] =
    React.useState<string>('0')

  const [eventId, setEventId] = React.useState<string>(uuid4())

  const [performMutation] = useMutation(CASH_TILL_CLOSE)

  const handleNext = () => {
    setCurrentIndex(
      (prevIndex: number) => (prevIndex + 1) % registerList.length
    )
    setActualAmount('0')
    setVarianceAmount(0)
    setReasonCode('')
    setEventId(uuid4())
    setDenominations([...DEFAULT_DENOMINATIONS])
    setOtherDenominationCurrency('0')
  }

  const selectedTill = registerList[currentIndex]

  const handleRadioChange = (value: string) => {
    setSelectedOption(value)
    setActualAmount('0')
    setVarianceAmount(0)
    setReasonCode('')
    setDenominations(cloneDeep(DEFAULT_DENOMINATIONS))
    setOtherDenominationCurrency('0')
  }

  const handlePickupClick = async () => {
    try {
      await performMutation({
        variables: {
          amount: amountInCents(actualAmount),
          cashVariance: varianceAmount,
          reasonCode: reasonCode,
          location: authInfo.locationId,
          till: selectedTill.till,
          register: selectedTill.register,
          denominationBreakdown: denominations,
          reasonDescription: reason,
          otherDenominationCurrency: amountInCents(otherDenominationCurrency),
          eventId: eventId
        }
      })
      if (currentIndex === itemListCount - 1) {
        setReviewDrawerOpen()
      } else {
        handleNext()
      }
      notify.show(t('cash-management.transactions.success'), 'success')
    } catch (error) {
      notify.show(
        (error as any)?.message || 'Something went wrong. Please try again.',
        'error'
      )
    }
  }

  const updateActualAmount = (value: string) => {
    setActualAmount(value)
    if (value) {
      const variance = (selectedTill.totalCash || 0) - amountInCents(value)
      setVarianceAmount(variance)
    }
  }

  const handleDenominationChange = (index: number, value: string) => {
    const updatedDenominations = [...denominations]
    if (!isNaN(Number(value))) {
      let count = new Big(Number(value))
      updatedDenominations[index]['count'] = Number(
        count.abs().round(0, Big.roundDown)
      )
      setDenominations(updatedDenominations)
      calculateDenominationAmount(otherDenominationCurrency)
    }
  }

  const calculateDenominationAmount = (value: string) => {
    const updatedDenominations = [...denominations]
    let total = 0
    updatedDenominations.forEach((denomination) => {
      total += (denomination?.denomination || 0) * (denomination?.count || 0)
    })
    total = centsToDollar(total) + Number(value || '0')
    updateActualAmount(parseAmount(total.toString()))
  }

  const calculateErrorMessage = () => {
    const variance = Math.abs(varianceAmount)
    if (variance > 0 && !reasonCode) {
      setErrorMessage('There is a discrepancy in the till total')
    } else {
      setErrorMessage('')
    }
  }

  const debouncedCalculateErrorMessage = debounce(calculateErrorMessage, 1500)

  useEffect(() => {
    if (actualAmount != null) {
      debouncedCalculateErrorMessage()
    }
  }, [actualAmount, varianceAmount, reasonCode])

  const handleCancel = () => {
    setActualAmount('0')
    setVarianceAmount(0)
    setReasonCode('')
    setDenominations([...DEFAULT_DENOMINATIONS])
    setReviewDrawerOpen()
  }

  const updateOtherDenominationCurrency = (value: string) => {
    setOtherDenominationCurrency(value)
    calculateDenominationAmount(value)
  }

  const isSubmitDisabled =
    (Math.abs(varianceAmount) > 0 && !reasonCode) ||
    isInvalidInputAmount(actualAmount) ||
    (selectedOption === RECONCILE_TYPE.individual_amount.value &&
      denominations.every((entry: any) => entry.count <= 0) &&
      (!otherDenominationCurrency || Number(otherDenominationCurrency) <= 0))

  const varianceAlert = () => {
    return (
      errorMessage !== '' &&
      Math.abs(varianceAmount) > 0 && (
        <Grid item xs={12} sm={12}>
          <Alert
            severity='error'
            variant='outlined'
            sx={{
              ' &.MuiAlert-colorError': {
                backgroundColor: theme.palette.error.light,
                color: theme.palette.error.dark,
                boxShadow: 'none',
                borderRadius: '0px'
              }
            }}
          >
            <Typography variant='h3'>
              There is a discrepancy in the till total
            </Typography>
            <Typography variant='body2'>
              Please recount or confirm with reason code.
            </Typography>
          </Alert>
        </Grid>
      )
    )
  }

  const cashReviewSummary = () => {
    return (
      <>
        <Grid container spacing={4} padding={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant='h3'>
              {'Register ' +
                selectedTill?.register +
                ', ' +
                'Till ' +
                selectedTill?.till}
            </Typography>
            <Typography
              variant='subtitle2'
              style={{
                color: theme.palette.text.secondary,
                lineHeight: '140%'
              }}
            >
              Input cash total OR log individually
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 12
            }}
          >
            <Typography
              variant='body2'
              style={{color: theme.palette.text.secondary}}
            >
              {currentIndex + 1} / {itemListCount}
            </Typography>
            {currentIndex !== itemListCount - 1 && (
              <Link href='#' onClick={handleNext} sx={{cursor: 'pointer'}}>
                Skip to next
              </Link>
            )}
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl fullWidth required>
              <RadioGroup
                value={selectedOption}
                onChange={(event) => handleRadioChange(event.target.value)}
                sx={{
                  gap: 2
                }}
              >
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    value={RECONCILE_TYPE.total_amount.value}
                    control={<StyledRadio />}
                    componentsProps={{
                      typography: {variant: 'h3'}
                    }}
                    label={RECONCILE_TYPE.total_amount.label}
                  />
                </Grid>

                {selectedOption === RECONCILE_TYPE.total_amount.value && (
                  <>
                    {varianceAlert()}
                    <Grid item xs={12} sm={12}>
                      <StyledInput
                        fullWidth
                        value={actualAmount}
                        type='number'
                        InputProps={{
                          startAdornment: t('currency-symbol')
                        }}
                        onChange={(e) => {
                          updateActualAmount(parseAmount(e.target.value))
                        }}
                        error={isInvalidInputAmount(actualAmount)}
                        helperText={
                          isInvalidInputAmount(actualAmount)
                            ? 'Invalid amount'
                            : ''
                        }
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    value={RECONCILE_TYPE.individual_amount.value}
                    control={<StyledRadio />}
                    componentsProps={{
                      typography: {variant: 'h3'}
                    }}
                    label={RECONCILE_TYPE.individual_amount.label}
                  />
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          {selectedOption === RECONCILE_TYPE.individual_amount.value && (
            <>
              {denominations.map((denomination, index) => {
                return (
                  <Grid item xs={12} sm={6} key={index}>
                    <StyledInput
                      fullWidth
                      id={`denomination_${index}`}
                      label={
                        t('currency-symbol') +
                        centsToDollar(denomination?.denomination || 0)
                      }
                      type='text'
                      value={denomination?.count || 0}
                      onChange={(e: any) =>
                        handleDenominationChange(index, e.target.value)
                      }
                      inputProps={{
                        pattern: '^[0-9]*$'
                      }}
                    />
                  </Grid>
                )
              })}

              <Grid item xs={12} sm={12}>
                <StyledInput
                  fullWidth
                  label={'Other Currency'}
                  value={otherDenominationCurrency}
                  type='number'
                  InputProps={{
                    startAdornment: t('currency-symbol')
                  }}
                  onChange={(e) => {
                    updateOtherDenominationCurrency(parseAmount(e.target.value))
                  }}
                  error={isInvalidInputAmount(otherDenominationCurrency)}
                  helperText={
                    isInvalidInputAmount(otherDenominationCurrency)
                      ? 'Invalid amount'
                      : ''
                  }
                />
              </Grid>
              {varianceAlert()}
              <Grid item xs={12} sm={12}>
                <StyledInput
                  fullWidth
                  label={'Total Amount'}
                  value={actualAmount || 0}
                  disabled={
                    selectedOption === RECONCILE_TYPE.individual_amount.value
                  }
                  type='number'
                  InputProps={{
                    startAdornment: t('currency-symbol')
                  }}
                  onChange={(e) => {
                    updateActualAmount(parseAmount(e.target.value))
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel id='reason-code'>Reason Code</InputLabel>
              <Select
                fullWidth
                size='medium'
                label={'Reason Code'}
                value={reasonCode}
                onChange={(e) => {
                  setReasonCode(e.target.value as string)
                }}
                inputProps={{'aria-label': 'Without label'}}
                error={Math.abs(varianceAmount) > 0 && !reasonCode}
              >
                <StyledMenuItem key='none' value=''>
                  <em>None</em>
                </StyledMenuItem>
                {TILL_REASON_CODE.map((reasonCodeOption, index) => (
                  <StyledMenuItem key={index} value={reasonCodeOption.value}>
                    {reasonCodeOption.label}
                  </StyledMenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <StyledInput
                fullWidth
                size='medium'
                label={'Reason Description'}
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value as string)
                }}
                inputProps={{'aria-label': 'Without label'}}
              ></StyledInput>
            </FormControl>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <StyledDialog
        key={selectedTill?.id}
        open={!!selectedTill}
        title={'Reconcile Register'}
        body={<Grid>{cashReviewSummary()}</Grid>}
        successButtonName='Confirm'
        cancelButtonName='Cancel'
        cancelCallback={() => handleCancel()}
        onClose={() => {}}
        disableSuccessButton={isSubmitDisabled}
        successCallback={() => {
          handlePickupClick()
        }}
      />
    </>
  )
}

export default CashReviewDrawerContainer
