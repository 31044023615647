import {LoginCallback} from '@okta/okta-react'

import Loading from 'ui/molecules/Loading'

const CustomLoginCallback = () => {
  // do something with state query param here
  return <LoginCallback loadingElement={<Loading />} />
}

export default CustomLoginCallback
