import {graphql} from 'graphql/generatedTypes'

export const ADD_INVENTORY = graphql(`
  mutation createInventory($inventory: InventoryInput!, $locationId: String!) {
    createInventory(inventory: $inventory, locationId: $locationId) {
      id
      logoUrl
      description
      pricingDetails {
        currency
        currencyMultiplier
        discount
        total
        unitPrice
      }
      sku
      taxDetails {
        taxAmount
        taxGroup
        taxRate
      }
      unit
      unitCount
      upc
      businessId
      locationId
      taxable
      skuType
      className
      subclassName
      categoryName
    }
  }
`)

export const UPDATE_INVENTORY = graphql(`
  mutation updateInventory($inventory: InventoryInput!, $locationId: String!) {
    updateInventory(inventory: $inventory, locationId: $locationId) {
      id
      logoUrl
      description
      pricingDetails {
        currency
        currencyMultiplier
        discount
        total
        unitPrice
      }
      sku
      taxDetails {
        taxAmount
        taxGroup
        taxRate
      }
      unit
      unitCount
      upc
      businessId
      locationId
      taxable
      skuType
      className
      subclassName
      categoryName
    }
  }
`)

export const DELETE_INVENTORY = graphql(`
  mutation deleteInventory($id: String!) {
    deleteInventory(id: $id)
  }
`)
