import gql from 'graphql-tag'
import {graphql} from 'graphql/generatedTypes'

export const GET_INSIGHT = graphql(`
  query getInsight(
    $insightTypes: String!
    $durationType: String!
    $storeNumbers: String
    $locationIds: String
    $employeeCodes: String
    $eventTypes: String
    $duration: String
  ) {
    getInsights(
      insightTypes: $insightTypes
      durationType: $durationType
      storeNumbers: $storeNumbers
      locationIds: $locationIds
      employeeCodes: $employeeCodes
      eventTypes: $eventTypes
      duration: $duration
    ) {
      type
      dashboardId
      dashboardTitle
      dashboardSubtitle
      dashboardBaseUrl
      dashboardDurationType
      enableDashboard
      charts {
        chartId
        chartBaseUrl
        chartName
        category
        xAxis {
          name
          type
          timeType
          label
        }
        yAxis {
          name
          type
          timeType
          label
        }
        filter
        enableChart
      }
    }
  }
`)

export const GET_INSIGHT_CHARTS = gql`
  query getInsightCharts(
    $chartCategory: String!
    $insightType: String!
    $durationType: String!
    $storeNumbers: String
    $locationIds: String
    $employeeCodes: String
    $eventTypes: String
    $duration: String
  ) {
    getInsightCharts(
      chartCategory: $chartCategory
      insightType: $insightType
      durationType: $durationType
      storeNumbers: $storeNumbers
      locationIds: $locationIds
      employeeCodes: $employeeCodes
      eventTypes: $eventTypes
      duration: $duration
    ) {
      charts {
        category
        chartId
        chartBaseUrl
        chartName
        enableChart
        xAxis {
          label
          name
          timeType
          type
        }
        yAxis {
          label
          name
          timeType
          type
        }
        filter
      }
      type
      category
      durationType
    }
  }
`
