import ErrorNotificationTable from 'clientDashboard/peripheralHub/peripheralHubOverview/errorNotificationTable/ErrorNotificationTable'
import useZoneData from 'clientDashboard/peripheralHub/api/useZoneData'
import React, {useEffect} from 'react'
import {Grid, Typography} from '@mui/material'
import {space} from 'config/theme'
import {t} from 'i18next'
import ZoneDevicesContainer from 'clientDashboard/peripheralHub/peripheralHubOverview/zoneDevices/ZoneDevicesContainer'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectHasEditZoneModalData} from 'clientDashboard/peripheralHub/peripheralHub.selectors'
import AddOrEditZoneModal from 'clientDashboard/peripheralHub/peripheralHubOverview/AddOrEditZoneModal/AddOrEditZoneModal'

const PeripheralHubOverview = () => {
  const {fetchZoneData} = useZoneData()

  const hasEditZoneModalData = useAppSelector(selectHasEditZoneModalData)

  useEffect(() => {
    fetchZoneData()
  }, [])

  return (
    <Grid
      container
      spacing={4}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Grid>
        <Typography
          variant='h1'
          style={{
            padding: space.MEDIUM
          }}
        >
          {t('peripheral-hub.action-needed')}
        </Typography>
      </Grid>

      <ErrorNotificationTable />

      <ZoneDevicesContainer />

      {hasEditZoneModalData && (
        <AddOrEditZoneModal
          onUpdateComplete={() => {
            fetchZoneData()
          }}
        />
      )}
    </Grid>
  )
}

export default PeripheralHubOverview
