import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  Badge,
  Chip,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography
} from '@mui/material'
import {colors, space} from 'config/theme'
import useNotify from 'hooks/useNotify'
import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import useConsumerAgent from 'consumerAgent/ConsumerAgentContext'

type ConsumerAgentHeaderProps = {
  openCartPage?: () => void
  handleHomeButton?: () => void
  isCartPageOpen: boolean
  showOnlyBusinessName?: boolean
}

const ConsumerAgentHeader = ({
  openCartPage,
  handleHomeButton,
  isCartPageOpen,
  showOnlyBusinessName = false
}: ConsumerAgentHeaderProps) => {
  const history = useHistory()
  const notify = useNotify()
  const {
    cartItems,
    setCartItems: setCart,
    setChatMessages,
    businessName,
    locationName,
    locationId,
    isAuthenticated,
    customer,
    signOut,
    resetCartId,
    generateChatSessionId
  } = useConsumerAgent()

  const [optionsAnchorEl, setOptionsAnchorEl] = useState<null | HTMLElement>(
    null
  )

  const optionsMenuOpen = Boolean(optionsAnchorEl)

  const handleOptionMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setOptionsAnchorEl(event.currentTarget)
  }

  const [animate, setAnimate] = useState(false)
  useEffect(() => {
    if (cartItems?.length > 0) {
      setAnimate(true)
    }
    setTimeout(() => {
      setAnimate(false)
    }, 1000)
  }, [cartItems?.length])

  return (
    <>
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: 'black',
          padding: space.MEDIUM
        }}
      >
        <Grid
          item
          xs={8}
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
          gap={2}
        >
          {isCartPageOpen && (
            <IconButton
              onClick={handleHomeButton}
              style={{
                color: 'white'
              }}
            >
              <FontAwesomeIcon
                icon={icon({
                  name: 'chevron-left',
                  family: 'classic',
                  style: 'light'
                })}
                style={{
                  color: 'black',
                  fontSize: '24px'
                }}
              />
            </IconButton>
          )}
          <Grid
            item
            style={{
              maxWidth: '80%'
            }}
          >
            <Typography
              variant='subtitle1'
              fontSize={'18px'}
              style={{
                textTransform: 'uppercase',
                fontFamily: 'Palanquin',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {businessName}
            </Typography>
            {/* <Typography
              variant='subtitle2'
              fontSize={'14px'}
              style={{
                textTransform: 'uppercase',
                fontFamily: 'Palanquin',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {locationName}
            </Typography> */}
          </Grid>
        </Grid>

        {!showOnlyBusinessName && (
          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <Chip
              onClick={() => {
                if (isAuthenticated) return
                history.push(`/customers/location/${locationId}/login`)
              }}
              label={
                isAuthenticated
                  ? (customer?.firstName || '') +
                    ' ' +
                    (customer?.lastName || '')
                  : 'Login'
              }
              icon={
                <FontAwesomeIcon
                  icon={icon({
                    name: 'user',
                    family: 'classic',
                    style: 'solid'
                  })}
                  style={{
                    color: 'black',
                    fontSize: '14px'
                  }}
                />
              }
              size='medium'
              style={{
                backgroundColor: colors.primary[20],
                color: 'black',
                fontSize: '14px',
                paddingLeft: space.XS
              }}
            />

            <IconButton onClick={handleOptionMenuClick}>
              <FontAwesomeIcon
                icon={icon({
                  name: 'gear',
                  family: 'classic',
                  style: 'light'
                })}
                style={{
                  color: 'black',
                  fontSize: '24px'
                }}
              />
            </IconButton>

            {!!cartItems?.length && (
              <IconButton
                onClick={openCartPage}
                sx={{
                  animation: animate ? 'shake 1s ease-out' : 'none',
                  '@keyframes shake': {
                    '0%': {transform: 'translateX(0px) rotate(0deg)'},
                    '25%': {transform: 'translateX(-10px) rotate(-10deg)'},
                    '50%': {transform: 'translateX(10px) rotate(10deg)'},
                    '75%': {transform: 'translateX(-10px) rotate(-10deg)'},
                    '100%': {transform: 'translateX(0px) rotate(0deg)'}
                  }
                }}
              >
                <Badge
                  badgeContent={cartItems?.length || 0}
                  sx={{
                    '& .MuiBadge-badge': {
                      border: '1px solid black',
                      backgroundColor: 'white',
                      color: 'black',
                      fontSize: '16px',
                      height: '24px',
                      width: '24px',
                      borderRadius: '50%'
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={icon({
                      name: 'cart-shopping',
                      family: 'classic',
                      style: 'light'
                    })}
                    style={{
                      color: 'black',
                      fontSize: '28px'
                    }}
                  />
                </Badge>
              </IconButton>
            )}
          </Grid>
        )}
      </Grid>

      <Menu
        anchorEl={optionsAnchorEl}
        id='options-menu'
        open={optionsMenuOpen}
        onClose={() => {
          setOptionsAnchorEl(null)
        }}
        onClick={() => {
          setOptionsAnchorEl(null)
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              minWidth: 150,
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              marginTop: space.XS,
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: space.SMALL,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)'
              }
            }
          }
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <Grid>
          <Grid padding={space.SMALL}>
            <Typography variant='h3'>{'Location:'}</Typography>
            <Typography variant='h4' color={'text.secondary'}>
              {locationName}
            </Typography>
          </Grid>
          <Divider />

          <MenuItem
            onClick={() => {
              setChatMessages([])
              generateChatSessionId()
            }}
            style={{
              marginTop: space.XS,
              cursor: 'pointer'
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                icon={icon({
                  name: 'trash',
                  family: 'classic',
                  style: 'regular'
                })}
                style={{
                  fontSize: '24px',
                  color: 'black'
                }}
              />
            </ListItemIcon>
            <Typography variant='h3'>Clear Chat</Typography>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setCart([])
              resetCartId()
            }}
            style={{
              marginTop: space.XS,
              cursor: 'pointer'
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                icon={icon({
                  name: 'cart-circle-xmark',
                  family: 'classic',
                  style: 'regular'
                })}
                style={{
                  fontSize: '24px',
                  color: 'black'
                }}
              />
            </ListItemIcon>
            <Typography variant='h3'>Reset Cart</Typography>
          </MenuItem>

          {isAuthenticated && (
            <MenuItem
              onClick={() => {
                signOut()
                notify.show('Logged out successfully', 'success')
              }}
              style={{
                marginTop: space.XS,
                cursor: 'pointer'
              }}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={icon({
                    name: 'right-from-bracket',
                    family: 'classic',
                    style: 'regular'
                  })}
                  style={{
                    fontSize: '24px',
                    color: 'black'
                  }}
                />
              </ListItemIcon>
              <Typography variant='h3'>Logout </Typography>
            </MenuItem>
          )}
        </Grid>
      </Menu>
    </>
  )
}

export default ConsumerAgentHeader
