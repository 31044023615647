import {combineReducers} from '@reduxjs/toolkit'
import {locationsSlice} from 'clientDashboard/settings/locations/locations.slice'
import {storeGroupsSlice} from 'clientDashboard/settings/StoreGrouping/storeGroups.slice'
import {employeeManagementSlice} from "clientDashboard/settings/employees/employeeManagement.slice";

const settingsReducer = combineReducers({
  locations: locationsSlice.reducer,
  storeGroups: storeGroupsSlice.reducer,
  employeeManagement: employeeManagementSlice.reducer,
})

export const settingsSlice = {
  reducer: settingsReducer,
  reducerPath: 'settings'
}
