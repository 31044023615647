import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Grid, IconButton, Typography} from '@mui/material'

import {colors, space} from 'config/theme'

interface ItemQuantityCounterButtonProps {
  quantity: number
  changeQuantity: (quantity: number) => void
}

const ItemQuantityCounterButton = ({
  quantity,
  changeQuantity
}: ItemQuantityCounterButtonProps) => {
  return (
    <Grid
      container
      style={{
        border: '1px solid',
        borderColor: colors.grayBorder,
        padding: space.XS,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <Grid item>
        <IconButton
          onClick={() => changeQuantity(Math.max(0, quantity - 1))}
          style={{color: 'grey', fontSize: '1rem', paddingLeft: 0}}
        >
          <FontAwesomeIcon
            icon={icon({
              name: 'minus',
              family: 'classic',
              style: 'regular'
            })}
            style={{
              fontSize: '12px'
            }}
          />
        </IconButton>
      </Grid>

      <Grid
        item
        style={{
          alignContent: 'center'
        }}
      >
        <Typography variant='body1'>{quantity}</Typography>
      </Grid>
      <Grid item>
        <IconButton
          key='four'
          onClick={() => changeQuantity(quantity + 1)}
          style={{color: 'grey', fontSize: '1rem', paddingRight: 0}}
        >
          <FontAwesomeIcon
            icon={icon({
              name: 'plus',
              family: 'classic',
              style: 'regular'
            })}
            style={{
              fontSize: '12px'
            }}
          />
        </IconButton>
      </Grid>
    </Grid>
  )
}
export default ItemQuantityCounterButton
