import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Business} from 'clientDashboard/clientDashboard.types'
import {GetEmployeeQuery} from 'graphql/generatedTypes/graphql'
import {NullablePartial} from 'utils/type.utils'

type BusinessPayload = GetEmployeeQuery['getEmployee']['business']

const initialState: NullablePartial<Business> = {
  featureFlags: {}
}

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setBusinessDetails: (state, action: PayloadAction<BusinessPayload>) => {
      const {
        id,
        name,
        brand,
        logo,
        configurations,
        featureFlags,
        storeGroupClusters
      } = action.payload || {}
      state.businessId = id
      state.name = name
      state.brand = brand
      state.logo = logo
      state.configurations = configurations
      state.featureFlags = featureFlags
      state.storeGroupClusters = storeGroupClusters
    }
  }
})

export const {setBusinessDetails} = businessSlice.actions
