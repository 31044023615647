const Beta = () => {
  return (
    <sup
      style={{
        //font size
        fontSize: '14px',
        //color
        color: 'red'
      }}
    >
      Beta
    </sup>
  )
}

export default Beta
