import {Grid, Typography} from '@mui/material'
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import useNotify from 'hooks/useNotify'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef
} from 'react'
import useConsumerAgent from 'consumerAgent/ConsumerAgentContext'
import Loading from 'ui/molecules/Loading'

const StripeCheckout = forwardRef((_, ref) => {
  const {
    paymentIntentClientSecret,
    createPaymentIntent,
    businessId,
    stripePublishableKey,
    cartData,
    paymentMetadata
  } = useConsumerAgent()

  const checkoutFormRef = useRef(null)

  useImperativeHandle(ref, () => ({
    submitPaymentForm: () => {
      if (checkoutFormRef.current) {
        return (checkoutFormRef.current as any)?.submitPaymentForm?.()
      }
    }
  }))

  const stripePromise = useMemo(
    () => loadStripe(stripePublishableKey),
    [stripePublishableKey]
  )

  useEffect(() => {
    if (!paymentIntentClientSecret && cartData?.totalAmount) {
      createPaymentIntent(cartData?.totalAmount || 0, businessId)
    }
  }, [paymentIntentClientSecret])

  if (paymentMetadata.isPaymentSuccessful) {
    return (
      <>
        <Typography variant='body1'>
          Your payment has been successfully processed.
        </Typography>
        <Typography variant='body1'>
          OrderId: {paymentMetadata.orderId}
        </Typography>
        <Typography variant='body1'>
          PaymentId: {paymentMetadata.paymentId}
        </Typography>
        <Typography variant='body1'>
          paymentGatewayReferenceId: {paymentMetadata.paymentGatewayReferenceId}
        </Typography>
      </>
    )
  }

  return (
    <>
      {paymentIntentClientSecret && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: paymentIntentClientSecret,
            appearance: {
              theme: 'stripe'
            }
          }}
        >
          <CheckoutForm ref={checkoutFormRef} />
        </Elements>
      )}
    </>
  )
})

const CheckoutForm = forwardRef((_, ref) => {
  const stripe = useStripe()
  const elements = useElements()
  const notify = useNotify()

  const {
    setPaymentGatewayReferenceId,
    placeOrder,
    paymentInProgress,
    setPaymentInProgress,
    cartData,
    isPaymentSuccessful,
    setPaymentMetadata,
    paymentMetadata
  } = useConsumerAgent()

  useImperativeHandle(ref, () => ({
    submitPaymentForm
  }))

  const submitPaymentForm = async () => {
    if (!stripe || !elements) return

    if (!cartData?.totalAmount) {
      notify.show("Cart amount can't be zero", 'error')
      return
    }

    const {error, paymentIntent} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href
      },
      redirect: 'if_required'
    })

    if (
      error &&
      (error.type === 'card_error' || error.type === 'validation_error')
    ) {
      notify.show(error.message as string, 'error')
    } else if (paymentIntent && paymentIntent.status === 'requires_capture') {
      const _paymentGatewayReferenceId = paymentIntent.id
      setPaymentGatewayReferenceId(_paymentGatewayReferenceId)
      setPaymentMetadata({
        ...paymentMetadata,
        paymentGatewayReferenceId: _paymentGatewayReferenceId
      })

      placeOrder(_paymentGatewayReferenceId)
      setPaymentInProgress(true)
    } else {
      notify.show('An unexpected error occurred.', 'error')
      console.error('An unexpected error occurred.', error)
    }
  }

  return (
    <Grid
      item
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center'
      }}
      spacing={4}
    >
      {paymentInProgress && <Loading />}
      {!paymentInProgress && (
        <Grid item>
          <PaymentElement
            id='payment-element'
            options={{
              layout: 'tabs',

              defaultValues: {
                billingDetails: {
                  address: {
                    country: 'auto'
                  }
                }
              }
            }}
          />
        </Grid>
      )}
    </Grid>
  )
})

export default StripeCheckout
