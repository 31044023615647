import {useAuth} from 'auth/AuthContext'

function usePermission(requiredPermissions: string[] | string): boolean {
  const {authInfo} = useAuth()

  const permissionsArray = Array.isArray(requiredPermissions)
    ? requiredPermissions
    : [requiredPermissions]

  const hasPermission = permissionsArray.some((permission) =>
    (authInfo?.permissions || [])?.includes(permission)
  )

  return hasPermission
}

export default usePermission
