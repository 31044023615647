import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Box, Card, Grid, Typography} from '@mui/material'
import StyledButton from 'ui/atoms/StyledButton'
import theme, {colors, space} from 'config/theme'
import {ServiceConfigType} from 'graphql/generatedTypes/graphql'
import moment from 'moment'
import {convertToLocalTimeString, titleize} from 'utils/common'

type ServiceConfigCardProps = {
  toggleDialog: () => void
  serviceConfig: ServiceConfigType
  editCTAText: string
  setServiceConfigType: (serviceConfigType: string) => void
}

const ServiceConfigCard = ({
  toggleDialog,
  serviceConfig,
  editCTAText,
  setServiceConfigType
}: ServiceConfigCardProps) => {
  const handleClick = () => {
    setServiceConfigType(serviceConfig.type)
    toggleDialog()
  }

  return (
    <Grid>
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: space.LARGE,
          border: '1px solid',
          borderColor: colors.grayBorder,
          height: 'auto',
          boxShadow: 'none',
          borderRadius: '8px',
          backgroundColor: theme.palette.background.default
        }}
      >
        <Grid container justifyContent='space-between'>
          <Grid
            item
            gap={6}
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Grid item>
              <Box
                sx={{
                  width: '30px',
                  height: '76px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: theme.palette.background.default,
                  borderRadius: '8px'
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'circle-check',
                    family: 'classic',
                    style: 'solid'
                  })}
                  fontSize={'30px'}
                  color={theme.palette.primary.main}
                />
              </Box>
            </Grid>
            <Grid
              item
              container
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <Grid item>
                <Typography variant='h1'>
                  {titleize(serviceConfig.type)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant='subtitle1'
                  color={theme.palette.text.secondary}
                >
                  Last updated:{' '}
                  {moment(
                    convertToLocalTimeString(serviceConfig?.updatedAt)
                  ).fromNow()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            color={theme.palette.background.default}
            alignContent='center'
          >
            <StyledButton
              onClick={handleClick}
              variant='outlined'
              color='primary'
              size='small'
            >
              {editCTAText}
            </StyledButton>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}

export default ServiceConfigCard
