import {gql} from '@apollo/client'

export const ADD_RULES = gql`
  mutation CREATE_RULE(
    $businessId: String!
    $ruleName: String!
    $ruleType: String!
    $ruleValue: String!
    $applyTo: [String!]
    $excludeFrom: [String!]
    $applyToProductGroup: [String!]
    $excludeFromProductGroup: [String!]
  ) {
    createRule(
      businessId: $businessId
      ruleName: $ruleName
      ruleType: $ruleType
      ruleValue: $ruleValue
      applyTo: $applyTo
      excludeFrom: $excludeFrom
      applyToProductGroup: $applyToProductGroup
      excludeFromProductGroup: $excludeFromProductGroup
    ) {
      businessId
      ruleName
      ruleType
      ruleValue
      applyTo
      excludeFrom
      applyToProductGroup
      excludeFromProductGroup
    }
  }
`

export const ADD_PROMOTION = gql`
  mutation createPromotion(
    $businessId: String!
    $active: Boolean!
    $canBeCombined: Boolean!
    $endDate: DateTime!
    $priority: Int!
    $promotionName: String!
    $promotionType: String!
    $rules: [String!]!
    $startDate: DateTime!
    $promotionValue: Int!
    $description: String!
    $promotionSchema: String!
    $subscriptionType: Boolean!
  ) {
    createPromotion(
      businessId: $businessId
      active: $active
      canBeCombined: $canBeCombined
      endDate: $endDate
      priority: $priority
      promotionName: $promotionName
      promotionType: $promotionType
      rules: $rules
      startDate: $startDate
      promotionValue: $promotionValue
      description: $description
      promotionSchema: $promotionSchema
      subscriptionType: $subscriptionType
    ) {
      businessId
      active
      canBeCombined
      endDate
      priority
      promotionName
      promotionType
      startDate
      promotionValue
      description
      promotionSchema
      subscriptionType
      rules {
        ruleName
        ruleType
        ruleValue
      }
    }
  }
`

export const ADD_PROMOTION_TO_SKU = gql`
  mutation addPromotionToSku(
    $promotionName: String!
    $sku: String!
    $businessId: String!
  ) {
    addPromotionToSku(
      promotionName: $promotionName
      sku: $sku
      businessId: $businessId
    ) {
      success
      message
    }
  }
`
