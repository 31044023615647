import {Big} from 'big.js'

export function parseAmount(value: string) {
  value = (value + '').replace(/[^\d.-]/g, '')
  if (value && value.includes('.')) {
    value = value.substring(0, value.indexOf('.') + 3)
  }
  return value
}

export function amountInCents(value: string) {
  return value
    ? new Big(value).times(100).round(0, Big.roundDown).toNumber()
    : 0
}

export function isInvalidInputAmount(amount: string) {
  return !!!amount || Number(amount) < 0
}
