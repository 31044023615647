import {graphql} from 'graphql/generatedTypes'

export const ADD_ZONE_DATA = graphql(`
  mutation AddZoneData(
    $businessId: String!
    $locationId: String!
    $name: String!
    $description: String!
    $devices: [String!]!
    $registers: [String!]!
  ) {
    addZoneData(
      businessId: $businessId
      locationId: $locationId
      name: $name
      description: $description
      devices: $devices
      registers: $registers
    )
  }
`)

export const UPDATE_ZONE_DATA = graphql(`
  mutation UpdateZone(
    $businessId: String!
    $locationId: String!
    $zoneId: String!
    $name: String!
    $description: String!
    $devices: [String!]!
    $registers: [String!]!
  ) {
    updateZone(
      businessId: $businessId
      locationId: $locationId
      zoneId: $zoneId
      name: $name
      description: $description
      devices: $devices
      registers: $registers
    )
  }
`)

export const RESOLVE_ERROR_NOTIFICATION = graphql(`
  mutation ResolveErrorNotification($activityId: String!) {
    resolveErrorNotification(activityId: $activityId)
  }
`)

export const DELETE_ZONE = graphql(`
  mutation DeleteZone($zoneId: String!) {
    deleteZone(zoneId: $zoneId)
  }
`)

export const UPDATE_PERIPHERAL_HUB_NOTIFICATION = graphql(`
  mutation updatePeripheralHubNotificationConfig(
    $notificationConfiguration: PeripheralHubNotificationsInput!
  ) {
    updatePeripheralHubNotificationConfig(
      notificationConfiguration: $notificationConfiguration
    )
  }
`)
