import {
  useAppDispatch,
  useAppSelector
} from 'clientDashboard/clientDashboard.store'
import {
  selectIsZoneEditMode,
  selectZones
} from 'clientDashboard/peripheralHub/peripheralHub.selectors'
import ZoneDevices from 'clientDashboard/peripheralHub/peripheralHubOverview/zoneDevices/ZoneDevices'
import {Grid, IconButton, Typography} from '@mui/material'
import theme, {space} from 'config/theme'
import {t} from 'i18next'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import React from 'react'
import usePermission from 'clientDashboard/usePermission'
import {Permission} from 'constants/permissions'
import {
  toggleZoneEditMode,
  updateEditZoneModalData
} from 'clientDashboard/peripheralHub/peripheralHub.slice'

const IconPencil = icon({
  name: 'pencil',
  family: 'classic',
  style: 'solid'
})

const IconPencilSlash = icon({
  name: 'pencil-slash',
  family: 'classic',
  style: 'solid'
})

const ZoneDevicesContainer = () => {
  const hasManageZoneAccess = usePermission(Permission.MANAGE_ZONES)

  const dispatch = useAppDispatch()
  const zones = useAppSelector(selectZones)
  const isEditModeEnabled = useAppSelector(selectIsZoneEditMode)

  const showActions = () => {
    if (!hasManageZoneAccess) return ''
    return (
      <Grid
        item
        container
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start'
        }}
      >
        <Grid container item xs={10}>
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Typography variant='h1' style={{marginRight: space.LARGE}}>
              {t('peripheral-hub.device-overview')}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          xs={1}
          item
          style={{
            marginLeft: 'auto',
            marginTop: space.XS,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <IconButton
            size='small'
            onClick={() => dispatch(toggleZoneEditMode())}
          >
            <FontAwesomeIcon
              icon={isEditModeEnabled ? IconPencilSlash : IconPencil}
              color={theme.palette.text.secondary}
            />
          </IconButton>

          <IconButton
            style={{
              marginLeft: space.XS
            }}
            size='small'
            onClick={() => {
              dispatch(
                updateEditZoneModalData({
                  name: '',
                  description: '',
                  registers: [],
                  devices: []
                })
              )
            }}
          >
            <FontAwesomeIcon
              icon={icon({
                name: 'plus',
                family: 'classic',
                style: 'solid'
              })}
              color={theme.palette.text.secondary}
            />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {showActions()}
      {zones.map((zone) => (
        <Grid item key={`${zone.name}_${zone.id}`}>
          <ZoneDevices key={`${zone.name}_${zone.id}`} zone={zone} />
        </Grid>
      ))}
    </>
  )
}

export default ZoneDevicesContainer
