import {gql} from '@apollo/client'
import {graphql} from 'graphql/generatedTypes'

export const CREATE_CART = gql(`
  mutation createCart($locationId: String!, $employeeId: String!, $customerId: String!) {
    createCart(locationId: $locationId, employeeId: $employeeId, customerId: $customerId) {
      id
    }
  }
`)

export const CREATE_CART_FOR_CONSUMER_AGENT = gql(`
  mutation createCartGuest($locationId: String!) {
    createCartGuest(locationId: $locationId) {
      id
    }
  }
`)

export const UPDATE_CART_GUEST = gql(`
  mutation updateCartItems(
    $cartId: String!, 
    $items: [ItemInput!]!, 
    $subscriptionItems: [SubscriptionItemInput!]!,
    $returnedItems: [ReturnItemInput!]!,
    $customerId: String,
    $promotionId: String,
    $deliveryAddress: AddressInput
  ) {
      updateCartItems(
        cartId: $cartId, 
        items: $items, 
        subscriptionItems: $subscriptionItems, 
        returnedItems: $returnedItems, 
        promotionId: $promotionId, 
        deliveryAddress: $deliveryAddress, 
        customerId: $customerId
      ) {
        id
        items {
          sku
          quantity
          unitPrice
          unitDiscount
          description
          logoUrl
          unitSubTotal
          unitDiscount
          unitTaxAmount
          totalTaxAmount
          totalDiscount
          total
        }
        totalDiscount
        subTotal
        totalAmount
        totalTax
      }
  }
`)

export const COPY_NFC_CART_TO_MPOS = graphql(`
  mutation copyNfcCartToMpos(
    $mposCartId: String!
    $nfcCartId: String!
    $locationId: String!
  ) {
    copyNfcCartToMpos(
      mposCartId: $mposCartId
      nfcCartId: $nfcCartId
      locationId: $locationId
    )
  }
`)

export const VERIFY_CART_FOR_CONSUMER_AGENT = gql(`
  mutation verifyCart($cartId: ID!, $locationId: ID!) {
    verifyCart(cartId: $cartId, locationId: $locationId) 
  }
`)
