import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {NullablePartial} from 'utils/type.utils'
import _flatten from 'lodash/flatten'
import {
  AppliedGroups,
  Cluster,
  Group,
  Location2
} from 'clientDashboard/employee.slice'
import {
  getActualGroupOptionsAndGroups,
  withMappedGroups,
  withMappedLocations
} from 'clientDashboard/employee.slice.helpers'

type SliceState = {
  clusters: Cluster[]
  groups: Group[]
  locations: Location2[]
  editEmployeeModalData?: Record<string, any>
}

const initialState: NullablePartial<SliceState> = {}

export const employeeManagementSlice = createSlice({
  name: 'settings/employeeManagement',
  initialState,
  reducers: {
    setAllGroupsAndClusters: (state, action: PayloadAction<any>) => {
      const { clusters, storeGroups } = action.payload || {}
      const groups = _flatten(storeGroups)
      state.groups = withMappedGroups(groups as any)
      state.clusters = clusters as Cluster[]
    },

    setFullLocationList: (state, action: PayloadAction<any>) => {
      state.locations = withMappedLocations(action.payload)
    },

    updateGroupsAndOptionsEmployee: (state, action: PayloadAction<AppliedGroups | null>) => {
      const prop = 'editEmployeeModalData'
      if (action.payload === null) {
        state[prop] = null
        return
      }

      if (!state[prop]) {
        state[prop] = {}
      }
      const updatedGroups = Object.assign(
        state[prop].appliedLocationGroups || {},
        action.payload
      )

      const { clusterOptionsMap, appliedLocationGroups } = getActualGroupOptionsAndGroups({
        clusters: state.clusters || [],
        groups: state.groups || [],
        locations: state.locations || [],
        updatedGroups,
      })

      state[prop].clusterOptionsMap = clusterOptionsMap
      state[prop].appliedLocationGroups = appliedLocationGroups
    },
  }
})

export const {
  setAllGroupsAndClusters,
  setFullLocationList,
  updateGroupsAndOptionsEmployee,
} = employeeManagementSlice.actions
