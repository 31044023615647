import {useMutation} from '@apollo/client'
import {Grid, Typography} from '@mui/material'
import ConsumerAgentHeader from 'consumerAgent/ConsumerAgentHeader'
import StyledButton from 'ui/atoms/StyledButton'
import StyledInput from 'ui/atoms/StyledInput'
import theme, {space} from 'config/theme'
import {
  ADD_CUSTOMER_FOR_CONSUMER_AGENT,
  LOGIN_CUSTOMER
} from 'graphql/mutations/consumer-agent.mutations'
import useNotify from 'hooks/useNotify'
import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import useConsumerAgent from 'consumerAgent/ConsumerAgentContext'

const ConsumerAgentLogin = () => {
  const {
    businessName,
    businessId,
    locationId,
    setCustomer,
    setSessionToken,
    isAuthenticated,
    setIsAuthenticated
  } = useConsumerAgent()
  const notify = useNotify()
  const history = useHistory()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const [isRegistering, setIsRegistering] = useState(false)

  const clearFields = () => {
    setEmail('')
    setPassword('')
    setFirstName('')
    setLastName('')
  }

  useEffect(() => {
    if (!isAuthenticated) return
    history.push(`/customers/location/${locationId}/consumer-agent`)
  }, [isAuthenticated])

  const [_addCustomerForConsumerAgent] = useMutation(
    ADD_CUSTOMER_FOR_CONSUMER_AGENT,
    {
      onCompleted: (data) => {
        notify.show('Successfully registered', 'success')
        clearFields()
        setTimeout(() => {
          setIsRegistering(false)
        }, 1000)
      },
      onError: (error) => {
        notify.show(error.message, 'error')
      }
    }
  )
  const addCustomerForConsumerAgent = () => {
    _addCustomerForConsumerAgent({
      variables: {
        customer: {
          email: email.toLowerCase(),
          password,
          firstName,
          lastName,
          business: businessId
        }
      }
    })
  }

  const [_loginCustomer] = useMutation(LOGIN_CUSTOMER, {
    onCompleted: (data) => {
      notify.show('Successfully logged in', 'success')
      setSessionToken(data.loginCustomer.token)
      setIsAuthenticated(true)
      clearFields()
      setCustomer(data.loginCustomer.customer)
      setTimeout(() => {
        history.push(`/customers/location/${locationId}/consumer-agent`)
      }, 1000)
    },

    onError: (error) => {
      notify.show(error.message, 'error')
    }
  })

  const loginCustomer = () => {
    _loginCustomer({
      variables: {
        email: email.toLowerCase(),
        password,
        businessId,
        locationId
      }
    })
  }

  const isRegisterButtonDisabled = () => {
    return !email || !password || !firstName || !lastName
  }

  const isLoginButtonDisabled = () => {
    return !email || !password
  }

  const showLoginFields = () => {
    return (
      <>
        <Grid item>
          <Typography variant='h2' fontSize={'18px'}>
            Login to your {businessName} Account
          </Typography>
        </Grid>
        <Grid item>
          <StyledInput
            InputProps={{
              style: {
                fontSize: '16px'
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: '16px'
              }
            }}
            size='medium'
            placeholder='Enter email address'
            label={'Email'}
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>

        <Grid item>
          <StyledInput
            fullWidth
            size='medium'
            placeholder='Enter password'
            InputProps={{
              style: {
                fontSize: '16px'
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: '16px'
              }
            }}
            type='password'
            label={'Password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>

        <Grid item>
          <StyledButton
            fullWidth
            size='large'
            sx={{
              bgcolor: 'primary.dark',
              '&:hover': {bgcolor: 'primary.dark'}
            }}
            onClick={loginCustomer}
            disabled={isLoginButtonDisabled()}
          >
            Confirm
          </StyledButton>
        </Grid>
        <Grid item>
          <Typography
            onClick={() => {
              setIsRegistering(true)
              clearFields()
            }}
            variant='body2'
            style={{
              color: theme.palette.primary.main
            }}
          >
            Or create a new account
          </Typography>
        </Grid>
      </>
    )
  }

  const showRegisterFields = () => {
    return (
      <>
        <Grid item>
          <Typography variant='h2' fontSize={'18px'}>
            Register for a {businessName} Account
          </Typography>
        </Grid>

        <Grid item>
          <StyledInput
            InputProps={{
              style: {
                fontSize: '16px'
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: '16px'
              }
            }}
            required
            size='medium'
            placeholder='Enter first name'
            label={'First Name'}
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Grid>

        <Grid item>
          <StyledInput
            InputProps={{
              style: {
                fontSize: '16px'
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: '16px'
              }
            }}
            required
            size='medium'
            placeholder='Enter last name'
            label={'Last Name'}
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Grid>

        <Grid item>
          <StyledInput
            InputProps={{
              style: {
                fontSize: '16px'
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: '16px'
              }
            }}
            required
            size='medium'
            placeholder='Enter email address'
            label={'Email'}
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>

        <Grid item>
          <StyledInput
            fullWidth
            required
            size='medium'
            placeholder='Enter password'
            InputProps={{
              style: {
                fontSize: '16px'
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: '16px'
              }
            }}
            type='password'
            label={'Password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>

        <Grid item>
          <StyledButton
            fullWidth
            size='large'
            sx={{
              bgcolor: 'primary.dark',
              '&:hover': {bgcolor: 'primary.dark'}
            }}
            onClick={() => {
              addCustomerForConsumerAgent()
            }}
            disabled={isRegisterButtonDisabled()}
          >
            Register
          </StyledButton>
        </Grid>
        <Grid item>
          <Typography
            onClick={() => {
              setIsRegistering(false)
              clearFields()
            }}
            variant='body2'
            style={{
              color: theme.palette.primary.main
            }}
          >
            Or login to an existing account
          </Typography>
        </Grid>
      </>
    )
  }

  return (
    <Grid
      container
      style={{
        height: '100dvh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.default
      }}
    >
      <ConsumerAgentHeader isCartPageOpen={false} showOnlyBusinessName={true} />
      <Grid
        container
        padding={space.SMALL}
        spacing={4}
        style={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'center'
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            isRegistering ? addCustomerForConsumerAgent() : loginCustomer()
          }
        }}
      >
        {!isRegistering && showLoginFields()}
        {isRegistering && showRegisterFields()}
      </Grid>
    </Grid>
  )
}

export default ConsumerAgentLogin
