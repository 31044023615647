import {Grid, Typography} from '@mui/material'
import PermissionWrapper from 'clientDashboard/settings/PermissionWrapper'
import RoleManager from 'clientDashboard/settings/RoleManager'
import {Permission} from 'constants/permissions'

function RolesAndPermissions() {
  return (
    <Grid>
      <Grid item>
        <Typography variant='h2'>Manage Roles</Typography>
      </Grid>
      <Grid item>
        <PermissionWrapper permissions={[Permission.MANAGE_ROLES]}>
          <RoleManager />
        </PermissionWrapper>
      </Grid>
    </Grid>
  )
}

export default RolesAndPermissions
