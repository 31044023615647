import {useContext} from 'react'
import {NotificationContext} from 'app/NotificationContext'

const useNotify = () => {
  const context = useContext(NotificationContext)
  if (context === undefined) {
    throw new Error('useNotify must be used within a NotificationProvider')
  }
  return context
}

export default useNotify
