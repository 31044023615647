const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false
const BASENAME = process.env.PUBLIC_URL || ''
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`
const USE_INTERACTION_CODE = process.env.USE_INTERACTION_CODE === 'true'

const config = {
  oidc: {
    // clientId: <fetchin from server>,
    // issuer: <fetchin from server>,
    redirectUri: REDIRECT_URI,
    scopes: ['address', 'openid', 'profile', 'email', 'phone'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    useInteractionCodeFlow: true,
    useClassicEngine: false
  },

  widget: {
    // baseUrl: ISSUER.replace('/oauth2/default', ''),
    redirectUri: `${window.location.origin}/login/callback`,
    useInteractionCodeFlow: USE_INTERACTION_CODE
  }
}

export default config
