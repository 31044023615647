import 'react-notifications-component/dist/theme.css'

import {useMediaQuery} from '@mui/material'
import useAccessToken from 'hooks/useAccessToken'
import useToteAuth from 'hooks/useToteAuth'
import {useHistory, useLocation} from 'react-router-dom'
import ClientInterface from 'clientDashboard/ClientInterface'

import useBusiness from 'hooks/useBusiness'

function ClientToteRouteHandler() {
  const isMobileScreen = useMediaQuery('(max-width:800px)')
  const {pathname} = useLocation()
  const history = useHistory()
  const {signOut} = useToteAuth()
  const {isValidBusiness} = useBusiness()
  const {isAuthenticated} = useAccessToken()

  const isLoginRoute = pathname === '/login'
  if (!isValidBusiness && !isLoginRoute) {
    history.replace('/login')
  }

  return (
    <ClientInterface
      signOut={signOut}
      isValidBusiness={isValidBusiness}
      isAuthenticated={isAuthenticated}
      isMobileScreen={isMobileScreen}
    />
  )
}

export default ClientToteRouteHandler
