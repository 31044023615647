import {useLazyQuery, useMutation} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography
} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import MultipleImages from 'clientDashboard/inventoryManagement/ImageGrid'
import StyledButton from 'ui/atoms/StyledButton'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledInput from 'ui/atoms/StyledInput'
import StyledTable from 'ui/molecules/StyledTable'
import theme, {colors} from 'config/theme'
import {Permission} from 'constants/permissions'
import {InventoryDetailsType} from 'graphql/generatedTypes/graphql'
import {
  ADD_INVENTORY,
  DELETE_INVENTORY,
  UPDATE_INVENTORY
} from 'graphql/mutations/inventory-management.mutations'
import {GET_LOCATION_INVENTORY} from 'graphql/queries/inventory.queries'
import useNotify from 'hooks/useNotify'
import {t} from 'i18next'
import _ from 'lodash'
import {MRT_ColumnDef} from 'material-react-table'
import React from 'react'
import {useMemo} from 'react'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  checkForNegative,
  checkLength,
  convertToCents,
  convertToDollars,
  formatCurrency,
  isValidURL
} from 'utils/common'

const defaultFormValues = {
  sku: '',
  skuType: '',
  categoryName: '',
  className: '',
  subclassName: '',
  taxDetails: {
    taxGroup: '',
    taxRate: '0.0'
  },
  taxable: 'no',
  pricingDetails: {
    unitPrice: '0',
    currency: 'USD',
    currencyMultiplier: '100',
    discount: '0'
  },
  logoUrl: '',
  description: '',
  unit: 'Per item',
  unitCount: '0',
  upc: '',
  recommendedProducts: [] as string[],
  sendEmail: false,
  ageVerificationRequired: false,
  requiredMinimumAge: '18'
}

interface Image {
  id: string
  url: string
  title: string
}
const currencyList = [{label: 'USD', value: 'USD'}]
const unitTypeList = [
  {label: t('inventory.unit-type.per-item'), value: 'Per item'},
  {label: t('inventory.unit-type.per-pound'), value: 'Per pound'}
]

function InventoryManager() {
  const notify = useNotify()

  const {t} = useTranslation('commons')
  const {authInfo} = useAuth()
  const [showAddInventoryDialog, setShowAddInventoryDialog] = useState(false)
  const [inventoryData, setInventoryData] = useState<InventoryDetailsType[]>([])

  const [formValues, setFormValues] = useState({...defaultFormValues})

  const [isEditMode, setIsEditMode] = useState(false)

  const [isDeleteMode, setIsDeleteMode] = useState(false)
  const [inventoryIdForDelete, setInventoryIdForDelete] = useState('')
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10
  })
  const [columnFilters, setColumnFilters] = React.useState([])

  const clearForm = () => {
    setFormValues({
      ...defaultFormValues
    })
  }

  const isValidImageLogoUrl = (url: string) => {
    if (!url) return true
    return isValidURL(url)
  }

  const validateForm = (formValues: any) => {
    let is_validated = true
    if (
      !formValues.sku ||
      !formValues.description ||
      !formValues.unit ||
      !formValues.unitCount ||
      !formValues.pricingDetails.unitPrice ||
      !formValues.pricingDetails.currency ||
      !formValues.logoUrl ||
      checkForNegative(formValues.taxDetails.taxRate) ||
      checkForNegative(formValues.pricingDetails.unitPrice) ||
      checkForNegative(formValues.pricingDetails.discount) ||
      checkForNegative(formValues.unitCount) ||
      checkLength(formValues.description, 256) ||
      (formValues.ageVerificationRequired && !formValues.requiredMinimumAge)
    ) {
      is_validated = false
    }
    return !is_validated
  }

  const [unitPriceFilter, setUnitPriceFilter] = useState('')
  const [unitCountFilter, setUnitCountFilter] = useState('')
  const [description, setDescription] = useState('')
  const [sku, setSku] = useState('')

  useEffect(() => {
    console.log('columnFilters', columnFilters)
    if (columnFilters.length === 0) {
      setUnitPriceFilter('')
      setUnitCountFilter('')
      setDescription('')
      setSku('')
      return
    }
    columnFilters.forEach((filter: any) => {
      if (filter?.id === 'pricingDetails.unitPrice') {
        setUnitPriceFilter(convertToCents(filter?.value as string))
      }
      if (filter?.id === 'unitCount') {
        setUnitCountFilter(filter?.value)
      }
      if (filter?.id === 'description') {
        setDescription(filter?.value)
      }
      if (filter?.id === 'sku') {
        setSku(filter?.value)
      }
    })
  }, [columnFilters])

  const [
    getLocationInventory,
    {data: _inventoryData, loading: loadingInventories}
  ] = useLazyQuery(GET_LOCATION_INVENTORY, {
    fetchPolicy: 'no-cache',
    variables: {
      locationId: authInfo.locationId as string,
      description: description,
      sku: sku,
      unitPrice: unitPriceFilter,
      unitCount: unitCountFilter,
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize
    }
  })

  const [_addInventory] = useMutation(ADD_INVENTORY, {
    onCompleted: () => {
      fetchAllInventories()
      clearForm()
      notify.show(t('inventory.added-successfully'), 'success')
    },
    onError: (error) => {
      console.log('error', error)
      notify.show(error.message, 'error')
    }
  })

  const [_updateInventory] = useMutation(UPDATE_INVENTORY, {
    onCompleted: () => {
      fetchAllInventories()
      clearForm()
    },
    onError: (error) => {
      console.log('error', error)
      notify.show(error.message, 'error')
    }
  })

  const addInventory = () => {
    console.log('formValues', formValues)
    _addInventory({
      variables: {
        locationId: authInfo.locationId as string,
        inventory: {
          ...{
            ...formValues,
            pricingDetails: {
              ...formValues.pricingDetails,
              unitPrice: convertToCents(formValues.pricingDetails.unitPrice),
              discount: convertToCents(formValues.pricingDetails.discount)
            }
          }
        }
      }
    })
  }

  const updateInventory = () => {
    _updateInventory({
      variables: {
        locationId: authInfo.locationId as string,
        inventory: {
          ...{
            ...formValues,
            pricingDetails: {
              ...formValues.pricingDetails,
              unitPrice: convertToCents(formValues.pricingDetails.unitPrice),
              discount: convertToCents(formValues.pricingDetails.discount)
            }
          }
        }
      }
    })
  }

  const fetchAllInventories = () => {
    getLocationInventory()
  }

  const getImages = (row: InventoryDetailsType) => {
    const images: Image[] = []
    if (row) {
      images.push({
        id: row.sku as string,
        url: row.logoUrl as string,
        title: ''
      })
    }
    return images
  }

  const [_deleteInventory, {loading: loadingDeleteInventory}] = useMutation(
    DELETE_INVENTORY,
    {
      onCompleted: () => {
        fetchAllInventories()
        setIsDeleteMode(false)
        notify.show(t('inventory.delete-inventory-success'), 'success')
      },
      onError: (error) => {
        console.log('error', error)
        notify.show(t('inventory.delete-inventory-failure'), 'error')
        setIsDeleteMode(false)
      }
    }
  )

  const deleteInventory = (_inventoryId: string) => {
    _deleteInventory({
      variables: {
        id: _inventoryId
      }
    })
  }

  useEffect(() => {
    fetchAllInventories()
  }, [])

  useEffect(() => {
    if (_inventoryData) {
      setInventoryData(
        _inventoryData.fetchLocationInventory
          ?.inventories as InventoryDetailsType[]
      )
    }
  }, [_inventoryData])

  useEffect(() => {
    if (isDeleteMode) {
      return
    }
    setInventoryIdForDelete('')
    // Adding set timeout to clear form values after dialog is closed
    // This is to avoid flickering of form values when dialog is in process of closing
    // this is a temporary fix, need to find a better solution where we can tinker the closing animation of dialog
    setTimeout(() => {
      clearForm()
    }, 100)
  }, [isDeleteMode])

  const sendEmailTooltip = () => {
    return (
      <Tooltip
        title='Send Email to customer when the item is purchased/subscribed'
        placement='right'
      >
        <FontAwesomeIcon
          icon={icon({
            name: 'info-circle',
            family: 'classic',
            style: 'regular'
          })}
          fontSize={14}
        />
      </Tooltip>
    )
  }

  const columns = useMemo<MRT_ColumnDef<InventoryDetailsType>[]>(
    () => [
      {
        accessorKey: 'sku',
        header: 'SKU',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'description',
        header: 'Description',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'pricingDetails.unitPrice',
        header: 'Unit Price',
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {formatCurrency(convertToDollars(cell.getValue()))}
            </Typography>
          )
        },
        muiTableBodyCellProps: {
          align: 'right'
        }
      },
      {
        accessorKey: 'unitCount',
        header: 'Unit Count',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        },
        muiTableBodyCellProps: {
          align: 'right'
        }
      }
    ],

    []
  )

  const inventoryConfigurationDialogBody = () => {
    const handleChange = (path: any, value: any) => {
      setFormValues((prev) => {
        const updatedValues = {...prev}
        _.set(updatedValues, path, value)
        return updatedValues
      })
    }

    function mainImage(formValues: {
      sku: string
      taxDetails: {taxGroup: string; taxRate: string}
      taxable: string
      pricingDetails: {
        unitPrice: string
        currency: string
        currencyMultiplier: string
        discount: string
      }
      logoUrl: string
      description: string
      unit: string
      unitCount: string
      upc: string
      recommendedProducts: string[]
      sendEmail: boolean
      ageVerificationRequired: boolean
      requiredMinimumAge: string
    }): Image[] {
      const images: Image[] = []
      if (formValues.logoUrl) {
        images.push({
          id: formValues.sku,
          url: formValues.logoUrl,
          title: ''
        })
      }
      return images
    }

    return (
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <Typography variant='h4'>Details</Typography>
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='SKU'
            variant='outlined'
            required
            value={formValues.sku}
            disabled={isEditMode}
            onChange={(e: any) => handleChange('sku', e.target.value)}
            error={
              inventoryData.some(
                (inventory) => inventory.sku === formValues.sku
              ) && !isEditMode
            }
            helperText={
              inventoryData.some(
                (inventory) => inventory.sku === formValues.sku
              ) && !isEditMode
                ? t('inventory.sku-already-exists')
                : ''
            }
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='SKU Type'
            variant='outlined'
            value={formValues.skuType}
            onChange={(e: any) => handleChange('skuType', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='Description'
            required
            variant='outlined'
            error={checkLength(formValues.description, 256)}
            helperText={
              checkLength(formValues.description, 256)
                ? t('inventory.descrption-length-exceeds')
                : ''
            }
            value={formValues.description}
            onChange={(e: any) => handleChange('description', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id='unit_type' required>
              {t('inventory.unit-type')}
            </InputLabel>
            <Select
              fullWidth
              size='medium'
              label={t('inventory.unit-type')}
              value={formValues.unit}
              onChange={(e: any) => handleChange('unit', e.target.value)}
              displayEmpty
              inputProps={{'aria-label': 'Without label'}}
            >
              {unitTypeList.map(
                ({label, value}: {label: string; value: string}) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='Unit Count'
            type='number'
            required
            variant='outlined'
            value={formValues.unitCount}
            error={checkForNegative(formValues.unitCount)}
            helperText={
              checkForNegative(formValues.unitCount)
                ? t('inventory.unit-count-cant-negative')
                : ''
            }
            onChange={(e: any) => handleChange('unitCount', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='UPC'
            variant='outlined'
            value={formValues.upc}
            onChange={(e: any) => handleChange('upc', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='Category Name'
            variant='outlined'
            value={formValues.categoryName}
            onChange={(e: any) => handleChange('categoryName', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='Class Name'
            variant='outlined'
            value={formValues.className}
            onChange={(e: any) => handleChange('className', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='Sub Class Name'
            variant='outlined'
            value={formValues.subclassName}
            onChange={(e: any) => handleChange('subclassName', e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id='taxable'>Is Taxable?</InputLabel>

            <Select
              fullWidth
              size='medium'
              label='Is Taxable?'
              value={formValues.taxable}
              onChange={(e: any) => handleChange('taxable', e.target.value)}
              displayEmpty
              inputProps={{'aria-label': 'Without label'}}
            >
              <MenuItem key={'Yes'} value={'yes'}>
                Yes
              </MenuItem>
              <MenuItem key={'No'} value={'no'}>
                No
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {formValues.taxable === 'yes' && (
          <>
            <Grid item xs={12}>
              <Typography variant='h4'>Tax Details</Typography>
            </Grid>
            <Grid item xs={6}>
              <StyledInput
                fullWidth
                label='Tax Group'
                variant='outlined'
                value={formValues.taxDetails.taxGroup}
                onChange={(e: any) =>
                  handleChange('taxDetails.taxGroup', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <StyledInput
                fullWidth
                label='Tax Rate (%)'
                variant='outlined'
                type='number'
                error={checkForNegative(formValues.taxDetails.taxRate)}
                helperText={
                  checkForNegative(formValues.taxDetails.taxRate)
                    ? t('inventory.tax-rate-cant-negative')
                    : ''
                }
                value={formValues.taxDetails.taxRate}
                onChange={(e: any) =>
                  handleChange('taxDetails.taxRate', e.target.value)
                }
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Typography variant='h4'>Pricing Details</Typography>
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='Unit Price'
            error={checkForNegative(formValues.pricingDetails.unitPrice)}
            helperText={
              checkForNegative(formValues.pricingDetails.unitPrice)
                ? t('inventory.unit-price-cant-negative')
                : ''
            }
            variant='outlined'
            required
            value={formValues.pricingDetails.unitPrice}
            onChange={(e: any) =>
              handleChange('pricingDetails.unitPrice', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id='currency' required>
              Currency
            </InputLabel>
            <Select
              fullWidth
              size='medium'
              label='Currency'
              value={formValues.pricingDetails?.currency}
              onChange={(e: any) =>
                handleChange('pricingDetails.currency', e.target.value)
              }
              displayEmpty
              inputProps={{'aria-label': 'Without label'}}
            >
              {currencyList.map(
                ({label, value}: {label: string; value: string}) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='Discount'
            variant='outlined'
            error={checkForNegative(formValues.pricingDetails.discount)}
            helperText={
              checkForNegative(formValues.pricingDetails.discount)
                ? t('inventory.discount-cant-negative')
                : ''
            }
            value={formValues.pricingDetails.discount}
            onChange={(e: any) =>
              handleChange('pricingDetails.discount', e.target.value)
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h4'>Miscellaneous</Typography>
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='Recommended SKUs'
            variant='outlined'
            value={formValues.recommendedProducts.join(',')}
            onChange={(e: any) =>
              handleChange('recommendedProducts', e.target.value.split(','))
            }
            helperText='Enter comma separated SKUs'
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={formValues.sendEmail}
                onChange={(e) => {
                  handleChange('sendEmail', e.target.checked)
                }}
              />
            }
            label='Send Email'
          />

          {sendEmailTooltip()}
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={formValues.ageVerificationRequired}
                onChange={(e) => {
                  handleChange('ageVerificationRequired', e.target.checked)
                }}
              />
            }
            label='Age Verification?'
          />
        </Grid>
        {formValues.ageVerificationRequired && (
          <Grid item xs={6}>
            <StyledInput
              fullWidth
              label='Required Minimum Age'
              variant='outlined'
              required
              type='number'
              value={formValues.requiredMinimumAge}
              onChange={(e: any) =>
                handleChange('requiredMinimumAge', e.target.value)
              }
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant='h4'>Images</Typography>
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label='Main Image URL'
            required
            variant='outlined'
            error={!isValidImageLogoUrl(formValues.logoUrl)}
            helperText={
              !isValidImageLogoUrl(formValues.logoUrl) ? 'Invalid URL' : ''
            }
            value={formValues.logoUrl}
            onChange={(e: any) => handleChange('logoUrl', e.target.value)}
          />
        </Grid>
        {formValues.logoUrl && (
          <Grid item xs={6}>
            <MultipleImages images={mainImage(formValues)} />
          </Grid>
        )}
      </Grid>
    )
  }

  const setFormValuesFromTable = (row: InventoryDetailsType) => {
    setFormValues({
      sku: row.sku as string,
      skuType: row.skuType as string,
      categoryName: row.categoryName as string,
      className: row.className as string,
      subclassName: row.subclassName as string,
      taxDetails: {
        taxGroup: row.taxDetails?.taxGroup as string,
        taxRate: row.taxDetails?.taxRate as string
      },
      taxable: row.taxable as string,
      pricingDetails: {
        unitPrice: convertToDollars(row.pricingDetails?.unitPrice as string),
        currency: row.pricingDetails?.currency as string,
        currencyMultiplier: row.pricingDetails?.currencyMultiplier as string,
        discount: convertToDollars(row.pricingDetails?.discount as string)
      },
      logoUrl: row.logoUrl as string,
      description: row.description as string,
      unit: row.unit as string,
      unitCount: row.unitCount as string,
      upc: row.upc as string,
      recommendedProducts: row.recommendedProducts?.map(
        (product) => product.sku
      ) as string[],
      sendEmail: !!row.sendEmail,
      ageVerificationRequired: !!row.ageVerificationRequired,
      requiredMinimumAge: row.requiredMinimumAge as string
    })
  }

  const deleteInventoryDialogBody = () => {
    return (
      <Grid container>
        <Grid item>
          <Typography variant='subtitle2'>
            {t('inventory.delete-confirmation')}
            <strong>
              {formValues.sku}
              {'('}
              {formValues.description}
              {') ?'}
            </strong>
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <StyledTable
        columns={columns}
        data={inventoryData}
        enableRowActions
        onPaginationChange={setPagination}
        manualPagination={true}
        manualFiltering={true}
        onColumnFiltersChange={setColumnFilters as any}
        rowCount={_inventoryData?.fetchLocationInventory?.totalPageCount || 0}
        state={{
          showSkeletons: loadingInventories,
          showProgressBars: loadingInventories,
          pagination: pagination
        }}
        initialState={{
          columnPinning: {
            left: ['mrt-row-expand'],
            right: ['mrt-row-actions']
          }
        }}
        renderTopToolbarCustomActions={() => {
          return (
            <Grid
              style={{
                order: 1
              }}
            >
              <StyledButton onClick={() => setShowAddInventoryDialog(true)}>
                Add Inventory
              </StyledButton>
            </Grid>
          )
        }}
        renderDetailPanel={({row}) => {
          if (loadingInventories) return ''
          return (
            <Box
              style={{
                padding: '10px',
                borderRadius: '10px',
                border: '1px solid',
                borderColor: colors.grayBorder,
                backgroundColor: theme.palette.background.paper
              }}
            >
              <Grid
                container
                spacing={2}
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <Grid item xs={3}>
                  <Typography variant='h3'>Tax Details</Typography>
                  <Typography variant='subtitle2'>
                    Tax Group: {row.original.taxDetails?.taxGroup}
                  </Typography>
                  <Typography variant='subtitle2'>
                    Tax Rate: {row.original.taxDetails?.taxRate}
                  </Typography>
                  <Typography variant='subtitle2'>
                    Taxable:{' '}
                    {row.original.taxable?.toLowerCase() === 'yes'
                      ? 'Yes'
                      : 'No'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant='h3'>Pricing Details</Typography>
                  <Typography variant='subtitle2'>
                    Unit Price:{' '}
                    {formatCurrency(
                      convertToDollars(
                        row.original.pricingDetails.unitPrice as string
                      )
                    )}
                  </Typography>
                  <Typography variant='subtitle2'>
                    Currency: {row.original.pricingDetails.currency}
                  </Typography>
                  <Typography variant='subtitle2'>
                    Discount:{' '}
                    {formatCurrency(
                      convertToDollars(
                        row.original.pricingDetails?.discount as string
                      )
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant='h3'>Unit Details</Typography>
                  <Typography variant='subtitle2'>
                    Unit: {row.original.unit}
                  </Typography>
                  <Typography variant='subtitle2'>
                    Unit Count: {row.original.unitCount}
                  </Typography>
                </Grid>
                {row.original.upc && (
                  <Grid item xs={3}>
                    <Typography variant='h3'>UPC</Typography>
                    <Typography variant='subtitle2'>
                      {row.original.upc}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={3}>
                  <Typography variant='h3'>SKU Type</Typography>
                  <Typography variant='subtitle2'>
                    {row.original.skuType}
                  </Typography>
                </Grid>
                {row.original.categoryName && (
                  <Grid item xs={3}>
                    <Typography variant='h3'>Category Name</Typography>
                    <Typography variant='subtitle2'>
                      {row.original.categoryName}
                    </Typography>
                  </Grid>
                )}
                {row.original.className && (
                  <Grid item xs={3}>
                    <Typography variant='h3'>Class Name</Typography>
                    <Typography variant='subtitle2'>
                      {row.original.className}
                    </Typography>
                  </Grid>
                )}
                {row.original.subclassName && (
                  <Grid item xs={3}>
                    <Typography variant='h3'>Subclass Name</Typography>
                    <Typography variant='subtitle2'>
                      {row.original.subclassName}
                    </Typography>
                  </Grid>
                )}
                {row.original.recommendedProducts &&
                  row.original.recommendedProducts.length > 0 && (
                    <Grid item xs={3}>
                      <Typography variant='h3'>Recommended Skus</Typography>
                      {row.original.recommendedProducts.map(
                        (product, index) => (
                          <Typography variant='subtitle2' key={product.sku}>
                            {product.sku} ({product.description})
                            {index ===
                            (row.original.recommendedProducts
                              ?.length as number) -
                              1
                              ? ''
                              : ', '}
                          </Typography>
                        )
                      )}
                    </Grid>
                  )}

                <Grid item xs={3}>
                  <Typography variant='h3'>Images</Typography>
                  <MultipleImages images={getImages(row.original)} />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant='h3'>
                    Send Email {sendEmailTooltip()}
                  </Typography>
                  <Typography variant='subtitle2'>
                    {row.original.sendEmail ? 'Yes' : 'No'}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant='h3'>Age Verification</Typography>
                  <Typography variant='subtitle2'>
                    {row.original.ageVerificationRequired ? 'Yes' : 'No'}
                  </Typography>
                  {row.original.ageVerificationRequired && (
                    <Typography variant='subtitle2'>
                      Required Minimum Age: {row.original.requiredMinimumAge}{' '}
                      year(s)
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          )
        }}
        renderRowActions={({row}) => (
          <Box>
            <Tooltip title='Edit'>
              <IconButton
                onClick={() => {
                  setIsEditMode(true)
                  setShowAddInventoryDialog(true)
                  setFormValuesFromTable(row.original)
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'edit',
                    family: 'classic',
                    style: 'light'
                  })}
                  fontSize={20}
                  color='black'
                  style={{
                    borderWidth: '1px'
                  }}
                />
              </IconButton>
            </Tooltip>
            {authInfo.permissions?.includes(Permission.INVENTORY_DELETE) && (
              <Tooltip title={t('delete')}>
                <IconButton
                  onClick={() => {
                    setIsDeleteMode(true)
                    setInventoryIdForDelete(row.original.id as string)
                    setFormValuesFromTable(row.original)
                  }}
                >
                  <FontAwesomeIcon
                    icon={icon({
                      name: 'trash-alt',
                      family: 'classic',
                      style: 'light'
                    })}
                    fontSize={20}
                    color='black'
                    style={{
                      borderWidth: '1px'
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      />

      <StyledDialog
        open={showAddInventoryDialog}
        onClose={() => setShowAddInventoryDialog(false)}
        title={isEditMode ? 'Edit Inventory' : 'Add Inventory'}
        successButtonName={isEditMode ? 'Update' : 'Add'}
        cancelButtonName='Cancel'
        cancelCallback={() => {
          setShowAddInventoryDialog(false)
          clearForm()
          setIsEditMode(false)
        }}
        disableSuccessButton={validateForm(formValues)}
        successCallback={() => {
          isEditMode ? updateInventory() : addInventory()
          setShowAddInventoryDialog(false)
          setIsEditMode(false)
        }}
        body={inventoryConfigurationDialogBody()}
      />
      <StyledDialog
        open={isDeleteMode}
        onClose={() => {
          setIsDeleteMode(false)
        }}
        title={t('inventory.delete-inventory-title')}
        successButtonName={t('delete')}
        cancelButtonName={t('cancel')}
        cancelCallback={() => {
          setIsDeleteMode(false)
        }}
        disableSuccessButton={loadingDeleteInventory}
        successCallback={() => {
          deleteInventory(inventoryIdForDelete)
        }}
        body={deleteInventoryDialogBody()}
      />
    </>
  )
}

export default InventoryManager
