import {useLazyQuery, useMutation} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography
} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import AddressFields from 'ui/organisms/AddressFields'
import StyledButton from 'ui/atoms/StyledButton'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledInput from 'ui/atoms/StyledInput'
import StyledTable from 'ui/molecules/StyledTable'
import {LocationStatus} from 'constants/constants'
import {Permission} from 'constants/permissions'
import {LocationType, OperatingHourType} from 'graphql/generatedTypes/graphql'
import {
  ADD_LOCATION,
  UPDATE_LOCATION,
  DEACTIVATE_LOCATION,
  ACTIVATE_LOCATION
} from 'graphql/mutations/location.mutation'
import {GET_LOCATIONS} from 'graphql/queries/location.queries'
import useEmployee from 'hooks/useEmployee'
import useNotify from 'hooks/useNotify'
import usePermission from 'hooks/usePermission'
import _ from 'lodash'
import {MRT_ColumnDef} from 'material-react-table'
import moment from 'moment'
import {tz} from 'moment-timezone'
import {useMemo} from 'react'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useAppSelector} from 'adminPanel/adminPanel.store'
import {
  selectFeatureFlags,
  selectStoreGroupClusters
} from 'clientDashboard/business.selectors'
import {
  selectAppliedLocationGroups,
  selectBusinessId
} from 'clientDashboard/employee.selectors'
import {useHistory, useLocation} from 'react-router-dom'

const defaultTimezone = 'America/Los_Angeles'
const defaultFormValues = {
  name: '',
  brand: '',
  description: '',
  storeNumber: '',
  address: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  },
  phoneNumbers: {
    contactPhone: '',
    supportPhone: ''
  },
  operatingHours: [
    {day: 'monday', fromHour: '10:00', toHour: '18:00'},
    {day: 'tuesday', fromHour: '10:00', toHour: '18:00'},
    {day: 'wednesday', fromHour: '10:00', toHour: '18:00'},
    {day: 'thursday', fromHour: '10:00', toHour: '18:00'},
    {day: 'friday', fromHour: '10:00', toHour: '18:00'},
    {day: 'saturday', fromHour: '10:00', toHour: '18:00'},
    {day: 'sunday', fromHour: '10:00', toHour: '18:00'}
  ],
  timeZone: defaultTimezone,
  status: LocationStatus.ACTIVE,
  notificationEmails: [''] as string[]
}

interface TimeZoneOption {
  label: string
  value: string
}

function LocationManager() {
  const location = useLocation()
  const passedData = location.state
    ? JSON.parse(JSON.stringify(location.state))
    : null
  const navigatedSelectedStoreGroupIds =
    passedData?.selectedStoreGroupIds || null

  const notify = useNotify()
  const {getEmployee} = useEmployee()

  const {t} = useTranslation()
  const [showAddLocationDialog, setShowAddLocationDialog] = useState(false)
  const {authInfo} = useAuth()
  const [locationData, setLocationData] = useState<LocationType[]>([])

  const [formValues, setFormValues] = useState({...defaultFormValues})
  const [locationIdSelectedForEdit, setLocationIdSelectedForEdit] = useState('')
  const [isEditMode, setIsEditMode] = useState(false)
  const featureFlags = useAppSelector(selectFeatureFlags)

  const [
    locationIdForActivateOrDeactivate,
    setLocationIdForActivateOrDeactivate
  ] = useState('')

  const [isActivateOrDeactivateMode, setIsActivateOrDeactivateMode] =
    useState(false)

  const [
    activateOrDeactivateTooltipTitle,
    setActivateOrDeactivateTooltipTitle
  ] = useState('Deactivate')

  const hasCreateLocationPermission = usePermission(Permission.CREATE_LOCATION)
  const hasFullAccess = usePermission(Permission.FULL_ACCESS_LOCATION)

  const storeGroupClusters = useAppSelector(selectStoreGroupClusters)
  const appliedLocationsGroups = useAppSelector(selectAppliedLocationGroups)

  const businessId = useAppSelector(selectBusinessId)
  const history = useHistory()
  const handleLocationGroupRedirect = () => {
    history.push(`/${businessId}/location-grouping`)
  }
  const clearForm = () => {
    setFormValues({
      ...defaultFormValues
    })
  }

  const uniqueTimeZones: Set<string> = new Set()
  const timeZoneList: TimeZoneOption[] = useMemo(() => {
    return tz
      .zonesForCountry('US')
      .reduce((acc: TimeZoneOption[], timeZone) => {
        const currentYear: number = new Date().getFullYear()
        const label: string = moment
          .tz([currentYear, new Date().getMonth()], timeZone)
          .format('zz')
        if (!uniqueTimeZones.has(label)) {
          uniqueTimeZones.add(label)
          acc.push({label: label, value: timeZone})
        }
        return acc
      }, [])
  }, [])

  const [getLocations, {data: _locationData, loading: loadingLocations}] =
    useLazyQuery(GET_LOCATIONS, {
      fetchPolicy: 'no-cache'
    })

  const [_addLocation] = useMutation(ADD_LOCATION, {
    onCompleted: (data) => {
      setLocationData((prev: any) => {
        return [...prev, data.addLocation]
      })

      clearForm()
      setShowAddLocationDialog(false)
      setIsEditMode(false)
    }
  })

  const [_updateLocation] = useMutation(UPDATE_LOCATION, {
    onCompleted: (data) => {
      setLocationData((prev: any) => {
        return prev.map((location: any) => {
          if (location.id === data.updateLocation.id) {
            return data.updateLocation
          }
          return location
        })
      })

      clearForm()
      setShowAddLocationDialog(false)
      setIsEditMode(false)
    },
    onError: (error) => {
      notify.show(error.message, 'error')
    }
  })

  const addLocation = () => {
    _addLocation({
      variables: {
        location: {
          ...formValues,
          business: authInfo.businessId as string
        }
      }
    })
  }

  const updateLocation = () => {
    const _operatingHours = formValues.operatingHours.filter(
      (operatingHour) => {
        return operatingHour.fromHour && operatingHour.toHour
      }
    )

    _updateLocation({
      variables: {
        locationId: locationIdSelectedForEdit,
        location: {
          ...formValues,
          business: authInfo.businessId as string,
          operatingHours: _operatingHours
        }
      }
    })
  }

  const fetchAllLocations = () => {
    getLocations({
      variables: {
        businessId: authInfo.businessId as string,
        storeGroupIds: navigatedSelectedStoreGroupIds
      }
    })
  }

  const [_deactivateLocation] = useMutation(DEACTIVATE_LOCATION, {
    onCompleted: () => {
      notify.show(
        t('location-management.location-deactivated-successfully'),
        'success'
      )
      fetchAllLocations()
      getEmployee()
    },
    onError: (error) => {
      console.log(error)
      notify.show(t('location-management.location-deactivation-error'), 'error')
    }
  })

  const deactivateLocation = () => {
    _deactivateLocation({
      variables: {
        locationId: locationIdForActivateOrDeactivate
      }
    })
  }

  const [_activateLocation] = useMutation(ACTIVATE_LOCATION, {
    onCompleted: () => {
      notify.show(
        t('location-management.location-activated-successfully'),
        'success'
      )
      fetchAllLocations()
      getEmployee()
    },
    onError: (error) => {
      console.log(error)
      notify.show(t('location-management.location-activation-error'), 'error')
    }
  })

  const activateLocation = () => {
    _activateLocation({
      variables: {
        locationId: locationIdForActivateOrDeactivate
      }
    })
  }

  const activateOrDeactivateLocation = () => {
    if (formValues.status === LocationStatus.ACTIVE) {
      deactivateLocation()
    } else {
      activateLocation()
    }
  }

  useEffect(() => {
    fetchAllLocations()
  }, [])

  useEffect(() => {
    fetchAllLocations()
  }, [appliedLocationsGroups])

  useEffect(() => {
    if (_locationData) {
      setLocationData(_locationData.getAllLocations as LocationType[])
    }
  }, [_locationData])

  const allLocationNames = useMemo(() => {
    return new Set(locationData.map((location) => location.name))
  }, [locationData])

  const allLocationStoreNumberSet = useMemo(() => {
    return new Set(locationData.map((location) => location.storeNumber))
  }, [locationData])

  const dynamicStoreGroupColumns = useMemo<
    MRT_ColumnDef<LocationType>[]
  >(() => {
    if (!storeGroupClusters || storeGroupClusters.length === 0) return []
    return storeGroupClusters.map((storeGroupCluster) => {
      // Define your dynamic columns based on storeGroupCluster
      return {
        accessorFn: (row) => {
          const storeGroup = row.storeGroups?.find(
            (storeGroup) =>
              storeGroup.storeGroupCluster.name === storeGroupCluster.name
          )
          return storeGroup ? storeGroup.name : null
        },
        header: storeGroupCluster.name, // Adjust based on your actual data structure
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>{cell.getValue() || ''}</Typography>
          )
        }
      }
    })
  }, [storeGroupClusters])
  const columns = useMemo<MRT_ColumnDef<LocationType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('location-management.name'),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'brand',
        header: t('location-management.brand'),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'storeNumber',
        header: t('location-management.store-number'),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'status',
        header: t('location-management.status'),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      ...dynamicStoreGroupColumns
    ],

    [dynamicStoreGroupColumns]
  )

  const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ]

  const handleChange = (path: any, value: any) => {
    setFormValues((prev) => {
      return _.set({...prev}, path, value)
    })
  }

  const setAddress = (address: {
    street: string
    city: string
    state: string
    zipCode: string
    country: string
  }) => {
    setFormValues((prev) => {
      return {
        ...prev,
        address
      }
    })
  }

  const locationConfigurationDialogBody = () => {
    return (
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <Typography variant='h4'>
            {t('location-management.details')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            required
            label={t('location-management.name')}
            variant='outlined'
            value={formValues.name}
            onChange={(e: any) => handleChange('name', e.target.value)}
            error={allLocationNames.has(formValues.name) && !isEditMode}
            helperText={
              allLocationNames.has(formValues.name) && !isEditMode
                ? t('location-management.name-already-exists')
                : ''
            }
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            required
            label={t('location-management.brand')}
            variant='outlined'
            value={formValues.brand}
            onChange={(e: any) => handleChange('brand', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            required
            label={t('location-management.store-number')}
            variant='outlined'
            value={formValues.storeNumber}
            onChange={(e: any) => handleChange('storeNumber', e.target.value)}
            error={
              allLocationStoreNumberSet.has(formValues.storeNumber) &&
              !isEditMode
            }
            helperText={
              allLocationStoreNumberSet.has(formValues.storeNumber) &&
              !isEditMode
                ? t('location-management.store-number-already-exists')
                : ''
            }
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel id='time-zone'>
              {t('location-management.time-zone')}
            </InputLabel>

            <Select
              fullWidth
              required
              size='medium'
              label={t('location-management.time-zone')}
              value={formValues.timeZone || defaultTimezone}
              onChange={(e: any) => handleChange('timeZone', e.target.value)}
              displayEmpty
              inputProps={{'aria-label': 'Without label'}}
            >
              {timeZoneList.map(
                ({label, value}: {label: string; value: string}) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <StyledInput
            fullWidth
            label={t('location-management.description')}
            variant='outlined'
            multiline
            rows={4}
            value={formValues.description}
            onChange={(e: any) => handleChange('description', e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <StyledInput
            fullWidth
            label={t('location-management.notification-emails')}
            placeholder={t(
              'location-management.notification-emails-placeholder'
            )}
            variant='outlined'
            value={
              formValues.notificationEmails
                ? formValues.notificationEmails.join(',')
                : ''
            }
            onChange={(e: any) => {
              handleChange('notificationEmails', e.target.value.split(','))
            }}
          />
        </Grid>

        {/* Address Fields */}
        <AddressFields address={formValues.address} setAddress={setAddress} />

        {/* Phone Numbers */}
        <Grid item xs={12}>
          <Typography variant='h4'>
            {t('location-management.phone-numbers')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label={t('location-management.contact-phone')}
            variant='outlined'
            value={formValues.phoneNumbers.contactPhone}
            onChange={(e: any) =>
              handleChange('phoneNumbers.contactPhone', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label={t('location-management.support-phone')}
            variant='outlined'
            value={formValues.phoneNumbers.supportPhone}
            onChange={(e: any) =>
              handleChange('phoneNumbers.supportPhone', e.target.value)
            }
          />
        </Grid>
        {/* Operating Hours */}
        <Grid item xs={12}>
          <Typography variant='h4'>
            {t('location-management.operating-hours')}
          </Typography>
        </Grid>

        <Grid
          container
          item
          gap={4}
          style={{display: 'flex', flexDirection: 'column'}}
        >
          {daysOfWeek.map((day, index) => (
            <Grid
              container
              item
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
              spacing={2}
              key={day}
            >
              <Grid
                item
                xs={4}
                sm={4}
                style={{
                  alignSelf: 'center'
                }}
              >
                <Typography variant='subtitle1'>{day}</Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <StyledInput
                  fullWidth
                  label={t('location-management.from-hour')}
                  variant='outlined'
                  type='time'
                  value={formValues.operatingHours[index]?.fromHour}
                  onChange={(e: any) => {
                    handleChange(
                      `operatingHours[${index}].fromHour`,
                      e.target.value
                    )

                    handleChange(
                      `operatingHours[${index}].day`,
                      day.toLowerCase()
                    )
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <StyledInput
                  fullWidth
                  label={t('location-management.to-hour')}
                  variant='outlined'
                  type='time'
                  value={formValues.operatingHours[index]?.toHour}
                  onChange={(e: any) => {
                    handleChange(
                      `operatingHours[${index}].toHour`,
                      e.target.value
                    )
                    handleChange(
                      `operatingHours[${index}].day`,
                      day.toLowerCase()
                    )
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
        {/* Location Groups */}
        {featureFlags.locationGrouping && (
          <Grid item xs={12}>
            <Button
              onClick={() => handleLocationGroupRedirect()}
              sx={{
                paddingLeft: 0,
                justifyContent: 'flex-start'
              }}
            >
              Assign Location Groups
            </Button>
          </Grid>
        )}
      </Grid>
    )
  }

  const activateLocationBody = () => {
    return (
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            {t('location-management.activate-message')} {formValues.storeNumber}
            {' ?'}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const deactivateLocationBody = () => {
    return (
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            {t('location-management.deactivate-message-warning-prefix-note')}
            <ul>
              <li>
                {t(
                  'location-management.deactivate-message-transactions-warning'
                )}
              </li>
              <li>
                {t(
                  'location-management.deactivate-message-employee-access-revoked'
                )}
              </li>
            </ul>
            {t('location-management.deactivate-message')}{' '}
            {formValues.storeNumber}
            {' ?'}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const activateOrDeactivateLocationBody = () => {
    return formValues.status === LocationStatus.ACTIVE
      ? deactivateLocationBody()
      : activateLocationBody()
  }

  const setFormValuesFromTable = (row: LocationType) => {
    setFormValues({
      address: {
        city: (row.address.city as string) || '',
        state: (row.address.state as string) || '',
        street: (row.address.street as string) || '',
        zipCode: (row.address.zipCode as string) || '',
        country: (row.address.country as string) || ''
      },
      brand: row.brand as string,
      description: row.description as string,
      name: row.name as string,
      phoneNumbers: {
        contactPhone: row.phoneNumbers.contactPhone as string,
        supportPhone: row.phoneNumbers.supportPhone as string
      },
      storeNumber: row.storeNumber as string,
      timeZone: row.timeZone as string,
      operatingHours: row.operatingHours.map(
        (operatingHour: OperatingHourType) => {
          return {
            day: operatingHour.day?.toLowerCase() as string,
            fromHour: operatingHour.fromHour as string,
            toHour: operatingHour.toHour as string
          }
        }
      ),
      status: row.status as LocationStatus,
      notificationEmails: row.notificationEmails as string[]
    })
  }

  return (
    <Grid>
      <StyledTable
        columns={columns}
        data={locationData}
        state={{
          showSkeletons: loadingLocations,
          showProgressBars: loadingLocations
        }}
        initialState={{
          columnPinning: {
            right: ['mrt-row-actions']
          },
          columnVisibility: {
            status: false
          },
          sorting: [
            {
              id: 'status',
              desc: false
            }
          ]
        }}
        enableRowActions
        renderTopToolbarCustomActions={() => {
          return (
            <Grid
              style={{
                order: 1
              }}
            >
              <StyledButton
                disabled={!hasCreateLocationPermission || !hasFullAccess}
                onClick={() => {
                  setShowAddLocationDialog(true)
                }}
              >
                {t('location-management.add-location')}
              </StyledButton>
            </Grid>
          )
        }}
        renderRowActions={({row}) => (
          <Box>
            <IconButton
              disabled={!hasFullAccess}
              onClick={() => {
                setLocationIdSelectedForEdit(row.original.id)
                setShowAddLocationDialog(true)
                setIsEditMode(true)
                setFormValuesFromTable(row.original)
              }}
            >
              <Tooltip title={t('location-management.edit')}>
                <FontAwesomeIcon
                  icon={icon({
                    name: 'edit',
                    family: 'classic',
                    style: 'light'
                  })}
                  fontSize={20}
                  color='black'
                  style={{
                    borderWidth: '1px'
                  }}
                />
              </Tooltip>
            </IconButton>
            <IconButton
              disabled={!hasFullAccess}
              onMouseEnter={() => {
                setActivateOrDeactivateTooltipTitle(
                  row.original.status === LocationStatus.ACTIVE
                    ? 'Deactivate'
                    : 'Activate'
                )
              }}
              onClick={() => {
                if (row.original.id === authInfo.locationId) {
                  notify.show(
                    t(
                      'location-management.deactivate-current-location-error-message'
                    ),
                    'warning'
                  )
                  return
                }
                setIsActivateOrDeactivateMode(true)
                setLocationIdForActivateOrDeactivate(row.original.id as string)
                setFormValuesFromTable(row.original)
              }}
            >
              <Tooltip title={activateOrDeactivateTooltipTitle}>
                <Switch
                  checked={
                    row.original.status === LocationStatus.ACTIVE ? true : false
                  }
                  disabled={row.original.id === authInfo.locationId}
                />
              </Tooltip>
            </IconButton>
          </Box>
        )}
      />

      <StyledDialog
        open={showAddLocationDialog}
        onClose={() => {
          setShowAddLocationDialog(false)
          clearForm()
          setIsEditMode(false)
        }}
        title={
          isEditMode
            ? t('location-management.edit-location')
            : t('location-management.add-location')
        }
        successButtonName={
          isEditMode
            ? t('location-management.button-names.update')
            : t('location-management.button-names.add')
        }
        cancelButtonName={t('location-management.button-names.cancel')}
        cancelCallback={() => {
          setShowAddLocationDialog(false)
          clearForm()
          setIsEditMode(false)
        }}
        disableSuccessButton={
          !formValues.name ||
          !formValues.brand ||
          !formValues.storeNumber ||
          (allLocationNames.has(formValues.name) && !isEditMode) ||
          (allLocationStoreNumberSet.has(formValues.storeNumber) && !isEditMode)
        }
        successCallback={() => {
          isEditMode ? updateLocation() : addLocation()
        }}
        body={locationConfigurationDialogBody()}
      />
      <StyledDialog
        open={isActivateOrDeactivateMode}
        onClose={() => {
          setIsActivateOrDeactivateMode(false)
        }}
        title={
          formValues.status === LocationStatus.ACTIVE
            ? t('location-management.deactivate-location-title')
            : t('location-management.activate-location-title')
        }
        successButtonName={
          formValues.status === LocationStatus.ACTIVE
            ? t('location-management.deactivate')
            : t('location-management.activate')
        }
        cancelButtonName={t('cancel')}
        cancelCallback={() => {
          setIsActivateOrDeactivateMode(false)
        }}
        successCallback={() => {
          activateOrDeactivateLocation()
          setIsActivateOrDeactivateMode(false)
        }}
        body={activateOrDeactivateLocationBody()}
      />
    </Grid>
  )
}

export default LocationManager
