import {useAppSelector} from "clientDashboard/clientDashboard.store";
import {selectPermissions} from "clientDashboard/employee.selectors";

function usePermission(requiredPermissions: string[] | string): boolean {
  const employeePermissions = useAppSelector(selectPermissions);

  if (typeof requiredPermissions === "string") {
    return employeePermissions.includes(requiredPermissions)
  }

  return requiredPermissions.some((permission) => employeePermissions.includes(permission))
}

export default usePermission
