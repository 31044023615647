import {useLazyQuery, useMutation} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from '@mui/material'
import StyledCheckBox from 'ui/atoms/StyledCheckbox'
import StyledTable from 'ui/molecules/StyledTable'
import _ from 'lodash'
import {MRT_ColumnDef} from 'material-react-table'
import {useCallback, useMemo} from 'react'
import {useEffect, useState} from 'react'
import AddressFields from 'ui/organisms/AddressFields'

import StyledButton from 'ui/atoms/StyledButton'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledInput from 'ui/atoms/StyledInput'
import {BusinessDetailsType} from 'graphql/generatedTypes/graphql'
import {
  ADD_BUSINESS,
  UPDATE_BUSINESS
} from 'graphql/mutations/business.mutation'
import {
  GET_ALL_ROLES_EMPLOYEES_LOCATIONS,
  GET_BUSINESSES
} from 'graphql/queries/business.queries'
import {notify} from 'ui/molecules/CustomNotifications'
import {isValidEmail} from 'utils/common'
import {t} from 'i18next'
import EmployeeCreateUpdateDialogue from 'adminPanel/EmployeeConfigurationDialog'
import {LocationStatus} from 'constants/constants'

interface AuthMethodChoices {
  [key: string]: string
}
interface FeatureFlags {
  [key: string]: boolean
}

const authMethodChoices: AuthMethodChoices = {
  okta: 'Okta',
  tote: 'Tote'
}

const defaultFormValues = {
  name: '',
  brand: '',
  contactDetails: {
    contactPhone: '',
    contactEmail: '',
    supportPhone: '',
    supportEmail: ''
  },
  address: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  },
  authMethod: 'tote',
  okta: {
    oktaClientId: '',
    oktaIssuer: ''
  },
  locationConfiguration: {
    location: '',
    storeNumber: '01'
  },
  logo: '',
  featureFlags: {} as FeatureFlags,
  employeeDetails: {
    name: '',
    email: '',
    employeeCode: '001'
  }
}

const defaultUpdateFormValues = {
  name: '',
  brand: '',
  contactDetails: {
    contactPhone: '',
    contactEmail: '',
    supportPhone: '',
    supportEmail: ''
  },
  address: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  },
  authMethod: 'tote',
  okta: {
    oktaClientId: '',
    oktaIssuer: ''
  },
  logo: '',
  featureFlags: {} as FeatureFlags
}

function BusinessManager() {
  const [showAddBusinessDialog, setShowAddBusinessDialog] = useState(false)
  const [businessData, setBusinessData] = useState<BusinessDetailsType[]>([])

  const [formValues, setFormValues] = useState({...defaultFormValues})
  const [updateFormValues, setUpdateFormValues] = useState({
    ...defaultUpdateFormValues
  })
  const [businessIdSelectedForEdit, setBusinessIdSelectedForEdit] = useState('')
  const [isEditMode, setIsEditMode] = useState(false)
  const [showCreateEmployeeDialog, setShowCreateEmployeeDialog] =
    useState(false)

  const clearForm = () => {
    setFormValues({
      ...defaultFormValues
    })
  }

  const clearUpdateForm = () => {
    setUpdateFormValues({
      ...defaultUpdateFormValues
    })
  }

  const [getBusinesses, {data: _businessData, loading: loadingBusinesses}] =
    useLazyQuery(GET_BUSINESSES, {
      fetchPolicy: 'no-cache'
    })

  const [_addBusiness] = useMutation(ADD_BUSINESS, {
    onCompleted: () => {
      fetchAllBusinesses()
      clearForm()
    },
    onError: (error) => {
      console.error(error)
      notify.show(error.message, 'error')
    }
  })

  const [_updateBusiness] = useMutation(UPDATE_BUSINESS, {
    onCompleted: () => {
      fetchAllBusinesses()
      clearUpdateForm()
    },
    onError: (error) => {
      console.error(error)
      notify.show(error.message, 'error')
    }
  })

  const addBusiness = () => {
    _addBusiness({
      variables: {
        business: {
          ...formValues
        }
      }
    })
  }

  const updateBusiness = () => {
    _updateBusiness({
      variables: {
        businessId: businessIdSelectedForEdit,
        business: {
          ...updateFormValues
        }
      }
    })
  }

  const fetchAllBusinesses = () => {
    getBusinesses()
  }

  useEffect(() => {
    fetchAllBusinesses()
  }, [])

  useEffect(() => {
    if (_businessData) {
      setBusinessData(_businessData.getBusinesses as BusinessDetailsType[])
    }
  }, [_businessData])

  const columns = useMemo<MRT_ColumnDef<BusinessDetailsType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'brand',
        header: 'Brand',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      }
    ],

    []
  )

  const getFieldValue = (path: any) => {
    if (isEditMode) {
      return _.get(updateFormValues, path)
    }
    return _.get(formValues, path)
  }

  const setAddress = (address: {
    street: string
    city: string
    state: string
    zipCode: string
    country: string
  }) => {
    if (isEditMode) {
      setUpdateFormValues((prev) => {
        return {
          ...prev,
          address
        }
      })
    } else {
      setFormValues((prev) => {
        return {
          ...prev,
          address
        }
      })
    }
  }

  const businessConfigurationDialogBody = () => {
    const handleChange = (path: any, value: any) => {
      if (!isEditMode) {
        if (path === 'name') {
          setFormValues((prev) => {
            const updatedValues = {...prev}
            _.set(
              updatedValues,
              'locationConfiguration.location',
              value + '_hq'
            )
            return updatedValues
          })
        }
        setFormValues((prev) => {
          const updatedValues = {...prev}
          _.set(updatedValues, path, value)
          return updatedValues
        })
      } else {
        setUpdateFormValues((prev) => {
          const updatedValues = {...prev}
          _.set(updatedValues, path, value)
          return updatedValues
        })
      }
    }
    const formUi = () => {
      return (
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <Typography variant='h4'>Details</Typography>
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              fullWidth
              label='Name'
              required
              variant='outlined'
              value={getFieldValue('name')}
              onChange={(e: any) => {
                handleChange('name', e.target.value)
              }}
              error={
                businessData.some(
                  (business) => business.name === getFieldValue('name')
                ) && !isEditMode
              }
              helperText={
                businessData.some(
                  (business) => business.name === getFieldValue('name')
                ) && !isEditMode
                  ? 'Name already exists'
                  : ''
              }
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              fullWidth
              label='Brand'
              variant='outlined'
              value={getFieldValue('brand')}
              onChange={(e: any) => handleChange('brand', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              fullWidth
              label='Logo'
              variant='outlined'
              value={getFieldValue('logo')}
              onChange={(e: any) => handleChange('logo', e.target.value)}
            />
          </Grid>

          {/* Address Fields */}
          <AddressFields
            address={getFieldValue('address')}
            setAddress={setAddress}
          />

          {/* Contact Details */}
          <Grid item xs={12}>
            <Typography variant='h4'>Contact Details</Typography>
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              fullWidth
              label='Contact Phone'
              variant='outlined'
              value={getFieldValue('contactDetails.contactPhone')}
              onChange={(e: any) =>
                handleChange('contactDetails.contactPhone', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              fullWidth
              label='Support Phone'
              variant='outlined'
              value={getFieldValue('contactDetails.supportPhone')}
              onChange={(e: any) =>
                handleChange('contactDetails.supportPhone', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              fullWidth
              label='Contact Email'
              error={
                getFieldValue('contactDetails.contactEmail') &&
                !isValidEmail(getFieldValue('contactDetails.contactEmail'))
              }
              helperText={
                // validate non empty email
                getFieldValue('contactDetails.contactEmail') &&
                !isValidEmail(getFieldValue('contactDetails.contactEmail'))
                  ? t('admin.business.invalid-email')
                  : ''
              }
              variant='outlined'
              value={getFieldValue('contactDetails.contactEmail')}
              onChange={(e: any) =>
                handleChange('contactDetails.contactEmail', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              fullWidth
              label='Support Email'
              error={
                getFieldValue('contactDetails.supportEmail') &&
                !isValidEmail(getFieldValue('contactDetails.supportEmail'))
              }
              helperText={
                getFieldValue('contactDetails.supportEmail') &&
                !isValidEmail(getFieldValue('contactDetails.supportEmail'))
                  ? t('admin.business.invalid-email')
                  : ''
              }
              variant='outlined'
              value={getFieldValue('contactDetails.supportEmail')}
              onChange={(e: any) =>
                handleChange('contactDetails.supportEmail', e.target.value)
              }
            />
          </Grid>

          {/* Auth Method dropdown */}
          <Grid item xs={12}>
            <Typography variant='h4'>Auth Method</Typography>
          </Grid>
          <Grid item xs={6}>
            <Select
              fullWidth
              value={getFieldValue('authMethod')}
              onChange={(e: any) => handleChange('authMethod', e.target.value)}
              variant='outlined'
              placeholder='Auth Method'
            >
              {Object.keys(authMethodChoices).map((choice) => (
                <MenuItem key={choice} value={choice}>
                  {authMethodChoices[choice]}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Okta Configuration */}
          {getFieldValue('authMethod') === 'okta' && (
            <>
              <Grid item xs={12}>
                <Typography variant='h4'>Okta Configuration</Typography>
              </Grid>
              <Grid item xs={6}>
                <StyledInput
                  fullWidth
                  required
                  label='Client ID'
                  variant='outlined'
                  value={getFieldValue('okta.oktaClientId')}
                  onChange={(e: any) =>
                    handleChange('okta.oktaClientId', e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <StyledInput
                  fullWidth
                  required
                  label='Issuer'
                  variant='outlined'
                  value={getFieldValue('okta.oktaIssuer')}
                  onChange={(e: any) =>
                    handleChange('okta.oktaIssuer', e.target.value)
                  }
                />
              </Grid>
            </>
          )}

          {/* Employee Details */}
          {!isEditMode && getFieldValue('authMethod') === 'tote' && (
            <>
              <Grid item xs={12}>
                <Typography variant='h4'>Employee Details</Typography>
              </Grid>
              <Grid item xs={6}>
                <StyledInput
                  fullWidth
                  required
                  label='Name'
                  variant='outlined'
                  value={getFieldValue('employeeDetails.name')}
                  onChange={(e: any) =>
                    handleChange('employeeDetails.name', e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <StyledInput
                  fullWidth
                  required
                  label='Email'
                  error={
                    // validate non empty email
                    getFieldValue('employeeDetails.email') &&
                    !isValidEmail(getFieldValue('employeeDetails.email'))
                  }
                  helperText={
                    // validate non empty email
                    getFieldValue('employeeDetails.email') &&
                    !isValidEmail(getFieldValue('employeeDetails.email'))
                      ? t('admin.business.invalid-email')
                      : ''
                  }
                  variant='outlined'
                  value={getFieldValue('employeeDetails.email')}
                  onChange={(e: any) =>
                    handleChange('employeeDetails.email', e.target.value)
                  }
                />
              </Grid>
            </>
          )}

          {/* Location Configuration */}
          {!isEditMode && (
            <>
              {' '}
              <Grid item xs={12}>
                <Typography variant='h4'>Location Configuration</Typography>
              </Grid>
              <Grid item xs={6}>
                <StyledInput
                  fullWidth
                  label='Hedquarters Location'
                  required
                  variant='outlined'
                  value={getFieldValue('locationConfiguration.location')}
                  onChange={(e: any) =>
                    handleChange(
                      'locationConfiguration.location',
                      e.target.value
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <StyledInput
                  fullWidth
                  required
                  label='Store Number'
                  variant='outlined'
                  value={getFieldValue('locationConfiguration.storeNumber')}
                  onChange={(e: any) =>
                    handleChange(
                      'locationConfiguration.storeNumber',
                      e.target.value
                    )
                  }
                />
              </Grid>
            </>
          )}

          {/* Feature Flags */}
          {isEditMode && (
            <>
              <Grid item xs={12}>
                <Typography variant='h4'>Feature Flags</Typography>
              </Grid>
              {getFieldValue('featureFlags') &&
                Object.keys(getFieldValue('featureFlags')).map((key, index) => {
                  return (
                    <Grid item key={key} xs={6}>
                      <StyledCheckBox
                        checked={getFieldValue(`featureFlags.${key}`)}
                        onChange={(e) => {
                          setUpdateFormValues({
                            ...updateFormValues,
                            featureFlags: {
                              ...updateFormValues.featureFlags,
                              [key]: e.target.checked
                            }
                          })
                        }}
                      />
                      {
                        // change camel case to normal text
                        key
                          .replace(/([A-Z])/g, ' $1')
                          .replace(/^./, function (str) {
                            return str.toUpperCase()
                          })
                      }
                    </Grid>
                  )
                })}
            </>
          )}
        </Grid>
      )
    }
    return <>{formUi()}</>
  }

  const [getAllRolesEmployeesLocations] = useLazyQuery(
    GET_ALL_ROLES_EMPLOYEES_LOCATIONS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setAllRoles(
          data.getAllRoles.map((role: {id: any; name: any}) => {
            return {
              id: role.id,
              name: role.name
            }
          })
        )
        setAllEmployees(data.getAllEmployees.employees)
        setAllLocations(
          data.getAllLocations.map((location: {id: any; name: any}) => {
            return {
              id: location.id,
              lable: location.name
            }
          })
        )
      }
    }
  )

  const fetchAllRolesEmployeesLocations = useCallback(() => {
    getAllRolesEmployeesLocations({
      variables: {
        businessId: businessIdSelectedForEdit,
        fetchAllEmployees: true,
        status: LocationStatus.ACTIVE
      }
    })
  }, [businessIdSelectedForEdit])

  const [allLocations, setAllLocations] = useState<
    {
      id: string
      lable: string
    }[]
  >([])

  const [allRoles, setAllRoles] = useState<
    {
      id: string
      name: string
    }[]
  >([])

  useEffect(() => {
    if (businessIdSelectedForEdit) {
      fetchAllRolesEmployeesLocations()
    }
  }, [businessIdSelectedForEdit])

  const [allEmployees, setAllEmployees] = useState<any>([])

  return (
    <>
      <StyledTable
        columns={columns}
        data={businessData}
        enableRowActions={true}
        initialState={{
          columnPinning: {
            left: ['mrt-row-expand'],
            right: ['mrt-row-actions']
          }
        }}
        state={{
          showSkeletons: loadingBusinesses,
          showProgressBars: loadingBusinesses
        }}
        renderTopToolbarCustomActions={() => {
          return (
            <Grid
              style={{
                order: 1
              }}
            >
              <StyledButton
                onClick={() => {
                  setShowAddBusinessDialog(true)
                }}
              >
                Add Business
              </StyledButton>
            </Grid>
          )
        }}
        renderDetailPanel={({row}) => {
          if (loadingBusinesses) return ''
          return (
            <Box>
              <Typography variant='h3'>Business Id</Typography>
              <Typography variant='subtitle2'>{row.original.id}</Typography>
              <Typography variant='h3'>Address</Typography>

              <Typography variant='subtitle2'>
                Address: {row.original.address?.street}
                {row.original.address?.street && ', '}
                {row.original.address?.city}
                {row.original.address?.city && ', '}
                {row.original.address?.state}
                {row.original.address?.state && ', '}
                {row.original.address?.zipCode}
                {row.original.address?.zipCode && ', '}
                {row.original.address?.country}
              </Typography>

              <Typography variant='h3'>Phone</Typography>
              <Typography variant='subtitle2'>
                Contact Number:{' '}
                {row.original.contactDetails?.contactPhone || 'N/A'}
              </Typography>
              <Typography variant='subtitle2'>
                Support Number:{' '}
                {row.original.contactDetails?.supportPhone || 'N/A'}
              </Typography>
              <Typography variant='subtitle2'>
                Contact Email:{' '}
                {row.original.contactDetails?.contactEmail || 'N/A'}
              </Typography>
              <Typography variant='subtitle2'>
                Support Email:{' '}
                {row.original.contactDetails?.supportEmail || 'N/A'}
              </Typography>
            </Box>
          )
        }}
        renderRowActions={({row}) => (
          <Box>
            <Tooltip title='Edit'>
              <IconButton
                onClick={() => {
                  setBusinessIdSelectedForEdit(row.original.id)
                  setShowAddBusinessDialog(true)
                  setIsEditMode(true)
                  setUpdateFormValues({
                    address: {
                      city: row.original.address?.city as string,
                      state: row.original.address?.state as string,
                      street: row.original.address?.street as string,
                      zipCode: row.original.address?.zipCode as string,
                      country: row.original.address?.country as string
                    },
                    brand: row.original.brand as string,
                    name: row.original.name as string,
                    contactDetails: {
                      contactPhone: row.original.contactDetails
                        ?.contactPhone as string,
                      supportPhone: row.original.contactDetails
                        ?.supportPhone as string,
                      contactEmail: row.original.contactDetails
                        ?.contactEmail as string,
                      supportEmail: row.original.contactDetails
                        ?.supportEmail as string
                    },
                    authMethod: row.original.authMethod as string,
                    okta: {
                      oktaClientId: row.original.okta?.oktaClientId as string,
                      oktaIssuer: row.original.okta?.oktaIssuer as string
                    },
                    logo: row.original.logo as string,
                    featureFlags: row.original.featureFlags as FeatureFlags
                  })
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'edit',
                    family: 'classic',
                    style: 'light'
                  })}
                  fontSize={20}
                  color='black'
                  style={{
                    borderWidth: '1px'
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('admin.business.add-employee')}>
              <IconButton
                onClick={() => {
                  setBusinessIdSelectedForEdit(row.original.id)
                  setShowCreateEmployeeDialog(true)
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'user-plus',
                    family: 'classic',
                    style: 'light'
                  })}
                  fontSize={20}
                  color='black'
                  style={{
                    borderWidth: '1px'
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />

      <StyledDialog
        open={showAddBusinessDialog}
        onClose={() => setShowAddBusinessDialog(false)}
        title={isEditMode ? 'Edit Business' : 'Add Business'}
        successButtonName={isEditMode ? 'Update' : 'Add'}
        cancelButtonName='Cancel'
        cancelCallback={() => {
          setShowAddBusinessDialog(false)
          clearForm()
          setIsEditMode(false)
        }}
        disableSuccessButton={
          !isEditMode &&
          (!getFieldValue('name') ||
            (getFieldValue('authMethod') === 'okta' &&
              (!getFieldValue('okta.oktaClientId') ||
                !getFieldValue('okta.oktaIssuer'))) ||
            (getFieldValue('authMethod') === 'tote' &&
              (!getFieldValue('employeeDetails.name') ||
                !getFieldValue('employeeDetails.email'))))
        }
        successCallback={() => {
          isEditMode ? updateBusiness() : addBusiness()
          setShowAddBusinessDialog(false)
          setIsEditMode(false)
        }}
        body={businessConfigurationDialogBody()}
      />

      <EmployeeCreateUpdateDialogue
        allRoles={allRoles}
        allLocations={allLocations}
        allEmployees={allEmployees}
        businessId={businessIdSelectedForEdit}
        isEditMode={false}
        editEmployeeId={''}
        showCreateEmployeeDialog={showCreateEmployeeDialog}
        setShowCreateEmployeeDialog={setShowCreateEmployeeDialog}
        setAllEmployees={setAllEmployees}
      />
    </>
  )
}

export default BusinessManager
