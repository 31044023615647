import {Box, Typography} from '@mui/material'

import {useAuth} from 'auth/AuthContext'
import {space} from 'config/theme'

const Home = () => {
  const {authInfo} = useAuth()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100dvh',
        backgroundColor: 'transparent'
      }}
    >
      <Box
        sx={{
          backgroundColor: 'transparent',
          padding: space.LARGE,
          borderRadius: '8px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Typography
          variant='h4'
          component='h4'
          sx={{marginBottom: space.MEDIUM}}
        >
          Welcome to {authInfo.businessName || 'Tote Admin'}'s Dashboard
        </Typography>

        <Typography
          variant='h6'
          component='h6'
          sx={{marginBottom: space.SMALL}}
        >
          User Name: {authInfo.userName}
        </Typography>

        <Typography variant='h6' component='h6'>
          User Email: {authInfo.userEmail}
        </Typography>
      </Box>
    </Box>
  )
}

export default Home
