import './i18n'
import './newrelic'
import './tailwind.css'
import './App.css'
import 'react-notifications-component/dist/theme.css'

import Loading from 'ui/molecules/Loading'
import env from 'constants/env'
import {Suspense, useEffect} from 'react'
import {BrowserRouter} from 'react-router-dom'
import AppRoutes from 'app/AppRoutes'
import Providers from 'app/Providers'

function loadGoogleAutocompleteScript() {
  if (env.APP_ENV === 'development' || env.APP_ENV === 'test') return
  const script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?key=${env.REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places`
  document.body.appendChild(script)

  return () => {
    document.body.removeChild(script)
  }
}

function App() {
  useEffect(loadGoogleAutocompleteScript, [])
  return (
    <Providers>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <AppRoutes />
        </Suspense>
      </BrowserRouter>
    </Providers>
  )
}

export default App
