import {useLazyQuery, useMutation, useSubscription} from '@apollo/client'
import {ProductDataType} from 'consumerAgent/ConsumerAgentItemCard'
import client from 'config/apollo.config'
import {LocalStorageKeys} from 'constants/constants'
import {CustomerType, GenieQueryType} from 'graphql/generatedTypes/graphql'
import {
  CREATE_CART_FOR_CONSUMER_AGENT,
  UPDATE_CART_GUEST,
  VERIFY_CART_FOR_CONSUMER_AGENT
} from 'graphql/mutations/cart.mutations'
import {LOGIN_GUEST_FOR_CONSUMER_AGENT} from 'graphql/mutations/login.mutation'
import {
  CREATE_PAYMENT_INTENT,
  ORDER_PAYMENT_STATUS,
  PLACE_ORDER
} from 'graphql/mutations/payments.mutation'
import {ORDER_UPDATE_SUBSCRIPTION} from 'graphql/subscriptions/payments.subscription'
import useLocalStorage from 'hooks/useLocalStorage'
import useNotify from 'hooks/useNotify'
import {jwtDecode, JwtPayload} from 'jwt-decode'
import {debounce} from 'lodash'
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import {useParams} from 'react-router-dom'
import {v4 as randomUUID} from 'uuid'
import {
  FETCH_GENIE_MESSAGE_FOR_CONSUMER_AGENT,
  GENIE_QUERY_FOR_CONSUMER_AGENT
} from 'graphql/queries/customers.queries'
import {GENIE_QUERY_EVENT_FOR_CONSUMER_AGENT} from 'graphql/subscriptions/customers.subscriptions'
import {
  DELETE_CHAT_HISTORY_FOR_CONSUMER_AGENT,
  GENERATE_GENIE_CHAT_SESSION_ID_FOR_CONSUMER_AGENT
} from 'graphql/mutations/customer.mutation'

type ChatMessagesType = {
  query: string
  hasFetched: boolean
  response: any
  messageId: string
  isFetching?: boolean
}

type CartData = {
  subTotalAmount: number
  totalAmount: number
  totalDiscount: number
  totalTax: number
  deliveryFee: number
}

type Address = {
  street: string
  city: string
  state: string
  zipCode: string
  country: string
}

type PaymentMetadata = {
  paymentGatewayReferenceId: string
  paymentId: string
  orderId: string
  isPaymentSuccessful: boolean
}

type ConsumerAgentContextValue = {
  cartItems: ProductDataType[]
  setCartItems: Dispatch<SetStateAction<ProductDataType[]>>
  chatMessages: ChatMessagesType[]
  setChatMessages: Dispatch<SetStateAction<ChatMessagesType[]>>
  cartId: string
  setCartId: Dispatch<SetStateAction<string>>
  resetCartId: () => void
  locationId: string
  setLocationId: Dispatch<SetStateAction<string>>
  chatSessionId: string
  setChatSessionId: Dispatch<SetStateAction<string>>
  businessName: string
  setBusinessName: Dispatch<SetStateAction<string>>
  businessId: string
  setBusinessId: Dispatch<SetStateAction<string>>
  locationName: string
  setLocationName: Dispatch<SetStateAction<string>>
  isLoggedIn: boolean
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>
  loginGuestForConsumerAgent: (locationId: string) => void
  createCartForConsumerAgent: () => void
  verifyCartForConsumerAgent: () => void
  cartData: CartData | null
  selectedPaymentMethod: string
  setSelectedPaymentMethod: Dispatch<SetStateAction<string>>
  address: string
  setAddress: Dispatch<SetStateAction<string>>
  isAuthenticated: boolean
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>
  customer: Partial<CustomerType> | null
  setCustomer: Dispatch<SetStateAction<Partial<CustomerType> | null>>
  sessionToken: string
  setSessionToken: Dispatch<SetStateAction<string>>
  signOut: () => void
  isBottomDrawerOpen: boolean
  setIsBottomDrawerOpen: Dispatch<SetStateAction<boolean>>
  selectedCheckoutStep: ConsumerAgentCheckoutSteps
  setSelectedCheckoutStep: Dispatch<SetStateAction<ConsumerAgentCheckoutSteps>>
  setCartTransferMethod: Dispatch<
    SetStateAction<ConsumerAgentCartTransferMethods>
  >
  cartTransferMethod: ConsumerAgentCartTransferMethods
  addressFields: Address
  setAddressFields: Dispatch<SetStateAction<Address>>
  paymentIntentClientSecret: string
  setPaymentIntentClientSecret: Dispatch<SetStateAction<string>>
  isPaymentSuccessful: boolean
  setIsPaymentSuccessful: Dispatch<SetStateAction<boolean>>
  createPaymentIntent(amount: number, businessId: string): void
  paymentGatewayReferenceId: string
  setPaymentGatewayReferenceId: Dispatch<SetStateAction<string>>
  placeOrder: (paymentGatewayReferenceId: string) => void
  stripePublishableKey: string
  paymentInProgress: boolean
  setPaymentInProgress: Dispatch<SetStateAction<boolean>>
  setPaymentMetadata: Dispatch<SetStateAction<PaymentMetadata>>
  paymentMetadata: PaymentMetadata
  submitGenieQuery: (query: string) => void
  generateChatSessionId: () => void
  deleteChatHistory: () => void
  scrollRef: React.RefObject<HTMLDivElement>
  scrollToBottom: () => void
}

export enum ConsumerAgentCheckoutSteps {
  DELIVERY = 0,
  PAYMENT = 1
}

export enum ConsumerAgentCartTransferMethods {
  QR_CODE = 'QR_CODE',
  NFC_TAP = 'NFC_TAP'
}

const ConsumerAgentContext = createContext<ConsumerAgentContextValue | null>(
  null
)

interface PropsType {
  children: React.ReactNode
}

export const defaultPaymentMetadata = {
  paymentGatewayReferenceId: '',
  paymentId: '',
  orderId: '',
  isPaymentSuccessful: false
}

export const ConsumerAgentProvider: React.FC<PropsType> = (props) => {
  const notify = useNotify()

  const genieResponseIntervalRef = React.useRef<any>(null)

  const scrollRef = useRef<null | HTMLDivElement>(null)

  const scrollToBottom = () => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({
        behavior: 'smooth'
      })
    }, 100)
  }

  const {locationId: locationIdInURL} = useParams<{
    locationId: string
  }>()

  const [cartItems, setCartItems] = useState<ProductDataType[]>([])
  const [cartId, setCartId] = useState<string>('')
  const [locationId, setLocationId] = useState<string>('')
  const [chatSessionId, setChatSessionId] = useState<string>('')
  const [businessName, setBusinessName] = useState<string>('')
  const [businessId, setBusinessId] = useState<string>('')
  const [locationName, setLocationName] = useState<string>('')
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [cartData, setCartData] = useState<CartData | null>(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [customer, setCustomer] = useState<Partial<CustomerType> | null>(null)
  const [sessionToken, setSessionToken] = useState<string>('')
  const [isBottomDrawerOpen, setIsBottomDrawerOpen] = useState<boolean>(false)
  const [stripePublishableKey, setStripePublishableKey] = useState<string>('')

  const [selectedCheckoutStep, setSelectedCheckoutStep] =
    useState<ConsumerAgentCheckoutSteps>(ConsumerAgentCheckoutSteps.DELIVERY)

  const [addressFields, setAddressFields] = useState<Address>({
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  })
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] =
    useState<string>('')

  const [paymentGatewayReferenceId, setPaymentGatewayReferenceId] =
    useState<string>('')

  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState<boolean>(false)
  const [paymentInProgress, setPaymentInProgress] = useState<boolean>(false)

  const [paymentMetadata, setPaymentMetadata] = useState<PaymentMetadata>(
    defaultPaymentMetadata
  )

  const [cartTransferMethod, setCartTransferMethod] =
    useState<ConsumerAgentCartTransferMethods>(
      ConsumerAgentCartTransferMethods.QR_CODE
    )

  const [chatMessages, setChatMessages] = useState<ChatMessagesType[]>([])
  const chatMessagesRef = useRef(chatMessages)
  chatMessagesRef.current = chatMessages

  const [, setGuestAccessToken] = useLocalStorage(
    LocalStorageKeys.GUEST_ACCESS_TOKEN,
    ''
  )
  const [cartIdInStore, setCartIdInStore] = useLocalStorage(
    LocalStorageKeys.CONSUMER_AGENT.CART_ID,
    ''
  )
  const [consumerAgentChat, setConsumerAgentChat] = useLocalStorage(
    LocalStorageKeys.CONSUMER_AGENT.CHAT,
    '[]'
  )

  const [
    sessionTokenInStore,
    setSessionTokenInStore,
    clearSessionTokenInStore
  ] = useLocalStorage(
    LocalStorageKeys.CONSUMER_AGENT.CUSTOMER_SESSION_TOKEN,
    ''
  )

  const [customerDetails, setCustomerDetails, clearCustomerDetails] =
    useLocalStorage(LocalStorageKeys.CONSUMER_AGENT.CUSTOMER_DETAILS, '')

  const [cartInStore, setCartInStore] = useLocalStorage(
    LocalStorageKeys.CONSUMER_AGENT.CART,
    '[]'
  )

  useEffect(() => {
    if (!locationIdInURL) return
    setLocationId(locationIdInURL)
    loginGuestForConsumerAgent(locationIdInURL as string)
  }, [])

  useEffect(() => {
    if (sessionTokenInStore && customerDetails) {
      try {
        const decodedToken: JwtPayload = jwtDecode(sessionTokenInStore)
        const _isAuthenticated =
          Number(decodedToken.exp) > Math.round(Date.now() / 1000)
        setIsAuthenticated(_isAuthenticated)
        if (!_isAuthenticated) {
          // signOut()
          loginGuestForConsumerAgent(locationId)
        }

        setCustomer(JSON.parse(customerDetails))
        setSessionToken(sessionTokenInStore)
      } catch (error) {
        // signOut()
        loginGuestForConsumerAgent(locationId)

        console.error('Error while decoding token: ', error)
      }
    }

    setCartItems(JSON.parse(cartInStore || '[]'))
    setCartId(cartIdInStore as string)
    setChatMessages(JSON.parse(consumerAgentChat || '[]'))
    chatSessionIdInStore && setChatSessionId(chatSessionIdInStore as string)
  }, [])

  useEffect(() => {
    if (sessionToken) {
      setSessionTokenInStore(sessionToken)
    }
  }, [sessionToken])

  useEffect(() => {
    if (customer) setCustomerDetails(JSON.stringify(customer))
  }, [customer])

  useEffect(() => {
    if (cartId) setCartIdInStore(cartId)
  }, [cartId])

  useEffect(() => {
    const _items = cartItems
      .filter((item) => {
        return item.skuType !== 'subscription'
      })
      .map((item) => ({
        ...item,
        __typename: undefined,
        frequency: undefined
      }))

    const _subscriptionItems = cartItems
      .filter((item) => {
        return item.skuType === 'subscription'
      })
      .map((item) => ({
        ...item,
        __typename: undefined
      }))
    setCartInStore(JSON.stringify(cartItems))

    if (!cartId) return
    updateCartDebounce(
      cartId,
      _items,
      _subscriptionItems,
      [],
      addressFields,
      customer?.id ?? null
    )
  }, [cartItems, addressFields])

  useEffect(() => {
    if (!isLoggedIn) return

    if (!cartId && !cartIdInStore) {
      return createCartForConsumerAgent()
    }

    verifyCartForConsumerAgent()
  }, [isLoggedIn, cartId])

  useEffect(() => {
    setConsumerAgentChat(JSON.stringify(chatMessages))
  }, [chatMessages])

  const [_loginGuestForConsumerAgent] = useMutation(
    LOGIN_GUEST_FOR_CONSUMER_AGENT,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setStripePublishableKey(
          data?.loginGuest?.businessConfigs?.stripePublishableKey || ''
        )
        setBusinessName(data?.loginGuest?.location?.business?.name as string)
        setLocationName(data?.loginGuest?.location?.name as string)
        setBusinessId(data?.loginGuest.location?.business?.id as string)
        setGuestAccessToken(data?.loginGuest?.token as string)
        setIsLoggedIn(true)
        client?.clearStore?.()
        setTimeout(generateChatSessionId)
      }
    }
  )
  const [_createCartForConsumerAgent] = useMutation(
    CREATE_CART_FOR_CONSUMER_AGENT,
    {
      onCompleted: (data) => {
        setCartId(data.createCartGuest.id)
        setCartIdInStore(data.createCartGuest.id)
      },
      onError: (error) => {
        console.error('[Consumer Agent] Error in creating cart', error)
      }
    }
  )

  const [_verifyCartForConsumerAgent] = useMutation(
    VERIFY_CART_FOR_CONSUMER_AGENT,
    {
      fetchPolicy: 'no-cache',
      onError: (error) => {
        createCartForConsumerAgent()
        localStorage.removeItem(LocalStorageKeys.GUEST.CHAT_SESSION_ID)
      }
    }
  )

  const loginGuestForConsumerAgent = (_locationId?: string) => {
    _locationId = _locationId || locationId
    if (!_locationId) return

    _loginGuestForConsumerAgent({
      variables: {
        locationId: _locationId
      }
    })
  }

  const createCartForConsumerAgent = () => {
    if (!locationId) return
    _createCartForConsumerAgent({
      variables: {
        locationId: locationId
      }
    })
  }
  const resetCartId = () => {
    setCartId('')
    setCartIdInStore('')
    createCartForConsumerAgent()
  }

  const verifyCartForConsumerAgent = () => {
    if (!locationId) return

    _verifyCartForConsumerAgent({
      variables: {
        cartId: cartId || cartIdInStore,
        locationId: locationId
      }
    })
  }

  const [updateCart] = useMutation(UPDATE_CART_GUEST, {
    onCompleted: (result) => {
      setCartData({
        subTotalAmount: result.updateCartItems.subTotal,
        totalDiscount: result.updateCartItems.totalDiscount,
        totalTax: result.updateCartItems.totalTax,
        totalAmount: result.updateCartItems.totalAmount,
        deliveryFee: 0
      })
    },
    onError: (error) => {
      console.error('[Consumer Agent] Error in updating cart', error)
      if (error.message === 'Signature has expired') {
        loginGuestForConsumerAgent(locationId)
      }
    }
  })
  const updateCartDebounce = useCallback(
    debounce(
      (
        cartId: string,
        items: any,
        subscriptionItems: any = [],
        returnedItems: any = [],
        deliveryAddress: any = null,
        customerId: any = null,
        promotionId: any = null
      ) => {
        updateCart({
          variables: {
            cartId: cartId,
            items: items,
            subscriptionItems: subscriptionItems,
            returnedItems: returnedItems,
            customerId: customerId,
            promotionId: promotionId,
            deliveryAddress: deliveryAddress
          }
        })
      },
      2000
    ),
    []
  )

  const [_createPaymentIntent] = useMutation(CREATE_PAYMENT_INTENT)
  const createPaymentIntent = (amount: number, businessId: string) => {
    _createPaymentIntent({
      variables: {
        amount: amount,
        businessId: businessId
      },
      onCompleted: ({createPaymentIntent}) => {
        setPaymentIntentClientSecret(createPaymentIntent.client_secret || '')
      },
      onError: (error) => {
        notify.show(error.message, 'error')
        console.error('Error in creating payment intent', error)
      }
    })
  }

  const [_placeOrder] = useMutation(PLACE_ORDER, {
    onCompleted: (result) => {
      const paymentId = result.placeOrderConsumerAgent.payments?.[0]?.id || ''
      const orderId = result.placeOrderConsumerAgent.id
      setPaymentMetadata({
        ...paymentMetadata,
        paymentId,
        orderId
      })

      getOrderDetailsInterval(orderId, paymentId)
    },
    onError: (error) => {
      console.error('Error in placing order', error)
      notify.show(error.message, 'error')
      setPaymentInProgress(false)
      setPaymentIntentClientSecret('')
    }
  })

  const getOrderDetailsInterval = async (
    orderId: string,
    paymentId: string
  ) => {
    let orderDetails = null
    let counter = 0
    let intervalId = setInterval(async () => {
      orderDetails = await getOrderDetails(orderId, paymentId)
      if (
        orderDetails?.data?.orderPaymentStatusConsumerAgent?.paymentStatus ===
        'paid'
      ) {
        clearInterval(intervalId)

        setIsPaymentSuccessful(true)

        setPaymentMetadata({
          ...paymentMetadata,
          isPaymentSuccessful: true,
          orderId,
          paymentId
        })
      }
      // run it for 2 minutes
      if (counter === 24) {
        clearInterval(intervalId)
        setIsPaymentSuccessful(false)
      }
      counter++
    }, 5000)
  }

  const placeOrder = (_paymentGatewayReferenceId: string) => {
    _placeOrder({
      variables: {
        amount: cartData?.totalAmount || 0,
        paymentMethod: 'card',
        currency: 'USD',
        nonce: randomUUID(),
        paymentGateway: 'stripe',
        paymentGatewayReferenceId: _paymentGatewayReferenceId,
        businessId,
        locationId,
        cartId
      }
    })
  }

  const {data: orderUpdateData} = useSubscription(ORDER_UPDATE_SUBSCRIPTION, {
    variables: {
      cartId,
      businessId,
      locationId
    },
    shouldResubscribe: true,
    onError: (error) => {
      console.error('Error in order update subscription, ', error)
      setIsPaymentSuccessful(false)
    }
  })
  useEffect(() => {
    if (
      orderUpdateData?.orderUpdateEventsConsumerAgent?.order?.paymentStatus ===
      'paid'
    ) {
      setIsPaymentSuccessful(true)
      setPaymentMetadata({
        ...paymentMetadata,
        isPaymentSuccessful: true
      })
    }
  }, [orderUpdateData])

  useEffect(() => {
    if (!isPaymentSuccessful) return
    setChatMessages((prevChatMessages) => {
      return [
        ...prevChatMessages,
        {
          query: '',
          hasFetched: true,
          messageId: '',
          response: {
            answer: `Your order has been placed successfully. Your order id is ${paymentMetadata.orderId}, payment id is ${paymentMetadata.paymentId}, payment gateway reference id is ${paymentMetadata.paymentGatewayReferenceId}`
          }
        }
      ]
    })

    setCartData(null)
    setCartItems([])
    resetCartId()
    setPaymentInProgress(false)
    setIsPaymentSuccessful(false)
    setPaymentGatewayReferenceId('')
    setPaymentIntentClientSecret('')
  }, [isPaymentSuccessful])

  const [_getOrderDetails] = useMutation(ORDER_PAYMENT_STATUS)

  const getOrderDetails = async (orderId: string, paymentId: string) => {
    let orderDetails = null
    try {
      orderDetails = await _getOrderDetails({
        variables: {
          orderId,
          paymentId,
          businessId,
          locationId
        }
      })
    } catch (error) {
      console.error('Error in getting order details', error)
    }
    return orderDetails
  }

  // write a function which calls the getOrderDetails function and checks the payment status every 5 seconds,
  // if the payment status is paid, then set the isPaymentSuccessful to true
  // clears the interval if the payment status is paid or failed
  // clears the interval after 2 minutes if the payment status is pending

  const signOut = () => {
    setCustomer(null)
    setSessionToken('')
    setChatSessionId('')
    setIsAuthenticated(false)
    clearCustomerDetails()
    clearSessionTokenInStore()
    clearChatSessionIdInStore()
    setCartItems([])
    resetCartId()
    setChatMessages([])
    setPaymentInProgress(false)
    setIsPaymentSuccessful(false)
    setPaymentGatewayReferenceId('')
    setPaymentIntentClientSecret('')
    setStripePublishableKey('')
  }

  const [
    chatSessionIdInStore,
    setChatSessionIdInStore,
    clearChatSessionIdInStore
  ] = useLocalStorage(LocalStorageKeys.CONSUMER_AGENT.CHAT_SESSION_ID, '')

  useEffect(() => {
    if (chatSessionId) {
      setChatSessionIdInStore(chatSessionId)
    }
  }, [chatSessionId])

  const [_generateChatSessionId] = useMutation(
    GENERATE_GENIE_CHAT_SESSION_ID_FOR_CONSUMER_AGENT,
    {
      onCompleted: (data) => {
        setChatSessionId(data.generateGenieChatSessionIdConsumerAgent)
      },
      onError: (error) => {
        console.error('Error in generating chat session id', error)
      }
    }
  )
  const generateChatSessionId = () => {
    _generateChatSessionId()
  }

  const [_deleteChatHistory] = useMutation(
    DELETE_CHAT_HISTORY_FOR_CONSUMER_AGENT
  )
  const deleteChatHistory = () => {
    _deleteChatHistory({
      variables: {
        chatSessionId
      },
      onCompleted: () => {
        generateChatSessionId()
      },
      onError: (error) => {
        generateChatSessionId()
      }
    })
  }

  const [_submitGenieQuery] = useLazyQuery(GENIE_QUERY_FOR_CONSUMER_AGENT, {
    fetchPolicy: 'no-cache',

    onCompleted: async (data) => {
      const messageId = data.genieQueryConsumerAgent.messageId

      setChatMessages((prevChatMessages) => {
        return [
          ...prevChatMessages,
          {
            messageId,
            hasFetched: false,
            response: null,
            query: data.genieQueryConsumerAgent.query as string,
            isFetching: true
          }
        ]
      })
      pollGenieResponse(messageId)
      scrollToBottom()
    },

    onError: (error) => {
      console.error('Error in genie query', error)
      notify.show('Error in processing query. Please try again.', 'error')
    }
  })

  const submitGenieQuery = (query: string) => {
    _submitGenieQuery({
      variables: {
        query,
        chatSessionId,
        locationId
      }
    })
  }

  const [_fetchGenieResponse] = useLazyQuery(
    FETCH_GENIE_MESSAGE_FOR_CONSUMER_AGENT,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const fetchGenieResponse = async (messageId: string) => {
    return await _fetchGenieResponse({
      variables: {
        messageId
      }
    })
  }

  const processGenieResponse = (data: GenieQueryType) => {
    const messageId = data?.messageId

    if (!['processed', 'failed'].includes(data?.status as string)) return

    clearInterval(genieResponseIntervalRef.current) // clear the interval

    const index = chatMessagesRef.current?.findIndex(
      (message) => message.messageId === messageId
    )
    if (index === -1) {
      setChatMessages((prevChatMessages) => {
        return [
          ...prevChatMessages,
          {
            query: data.query as string,
            hasFetched: true,
            isFetching: false,
            response: {
              answer: data.answer,
              products: data.genieChatQuery?.products,
              chatSessionId: data.chatSessionId
            },
            messageId: messageId
          }
        ]
      })
    } else {
      setChatMessages((prevChatMessages) => {
        const newChatMessages = [...prevChatMessages]
        newChatMessages[index].hasFetched = true
        newChatMessages[index].isFetching = false
        newChatMessages[index].response = {
          answer: data.answer,
          products: data.genieChatQuery?.products,
          chatSessionId: data.chatSessionId
        }
        return newChatMessages
      })
    }
    scrollToBottom()
  }

  const pollGenieResponse = (messageId: string) => {
    let genieResponse = null
    let counter = 0
    genieResponseIntervalRef.current = setInterval(async () => {
      try {
        genieResponse = await fetchGenieResponse(messageId)
      } catch (error) {
        genieResponse = {
          data: {
            fetchGenieMessageConsumerAgent: {
              status: 'failed',
              messageId,
              answer:
                'We could not process your request at this time. Please try again.',
              query: '',
              chatSessionId
            }
          }
        }
      }

      processGenieResponse(
        genieResponse?.data?.fetchGenieMessageConsumerAgent as GenieQueryType
      )
      // run it for 60 seconds
      if (counter === 12) {
        clearInterval(genieResponseIntervalRef.current)
        setChatMessages((prevChatMessages) => {
          const newChatMessages = [...prevChatMessages]
          const index = newChatMessages.findIndex(
            (message) => message.messageId === messageId
          )
          newChatMessages[index].isFetching = false
          newChatMessages[index].hasFetched = true
          newChatMessages[index].response = {
            answer:
              'We could not process your request at this time. Please try again.'
          }
          return newChatMessages
        })
        scrollToBottom()
      }
      counter++
    }, 5000)
  }

  useSubscription(GENIE_QUERY_EVENT_FOR_CONSUMER_AGENT, {
    variables: {
      chatSessionId,
      locationId
    },
    shouldResubscribe: true,
    onError: (error) => {
      console.error('Error in genie subscription, ', error)
    },
    onData: (data) => {
      processGenieResponse(
        data?.data?.data?.genieQueryEventConsumerAgent as GenieQueryType
      )
    }
  })

  return (
    <ConsumerAgentContext.Provider
      value={{
        cartItems,
        setCartItems,
        chatMessages,
        setChatMessages,
        cartId,
        setCartId,
        resetCartId,
        locationId,
        setLocationId,
        chatSessionId,
        setChatSessionId,
        businessName,
        setBusinessName,
        businessId,
        setBusinessId,
        locationName,
        setLocationName,
        isLoggedIn,
        setIsLoggedIn,
        loginGuestForConsumerAgent,
        createCartForConsumerAgent,
        verifyCartForConsumerAgent,
        cartData,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        address,
        setAddress,
        isAuthenticated,
        setIsAuthenticated,
        customer,
        setCustomer,
        sessionToken,
        setSessionToken,
        signOut,
        isBottomDrawerOpen,
        setIsBottomDrawerOpen,
        selectedCheckoutStep,
        setSelectedCheckoutStep,
        setCartTransferMethod,
        cartTransferMethod,
        addressFields,
        setAddressFields,
        paymentIntentClientSecret,
        setPaymentIntentClientSecret,
        isPaymentSuccessful,
        setIsPaymentSuccessful,
        createPaymentIntent,
        paymentGatewayReferenceId,
        setPaymentGatewayReferenceId,
        placeOrder,
        stripePublishableKey,
        paymentInProgress,
        setPaymentInProgress,
        setPaymentMetadata,
        paymentMetadata,
        submitGenieQuery,
        generateChatSessionId,
        deleteChatHistory,
        scrollRef,
        scrollToBottom
      }}
    >
      {props.children}
    </ConsumerAgentContext.Provider>
  )
}

const useConsumerAgent = (): ConsumerAgentContextValue => {
  const consumerAgentContext = useContext(ConsumerAgentContext)
  if (!consumerAgentContext) {
    throw new Error(
      'useConsumerAgent must be used within an ConsumerAgentProvider'
    )
  }
  return consumerAgentContext
}

export default useConsumerAgent
