import {AuthMethod} from 'constants/constants'
import React, {createContext, useContext, useState} from 'react'

interface AuthInfo {
  authMethod?: string
  businessId?: string | null
  storeNumber?: string | null
  storeName?: string | null
  storeNumbersMap?: Record<string, any> | null
  locationId?: string | null
  businessName?: string | null
  businessLogo?: string | null
  userName?: string | null
  userEmail?: string | null
  employeeId?: string | null
  okta?: any
  isToteSuperAdmin?: boolean | null
  featureFlags?: any
  allRoles?: any
  role?: any
  permissions?: any
  timeZone?: string | null
  primaryColor?: string | null
  secondaryColor?: string | null
  cashManagementConfig?: any
}

interface AuthContextValue {
  authInfo: AuthInfo
  updateAuthInfo: (newAuthInfo: AuthInfo) => void
}

const AuthContext = createContext<AuthContextValue | null>(null)

interface PropsType {
  children: React.ReactNode
}

export const AuthProvider: React.FC<PropsType> = (props) => {
  const [authInfo, setAuthInfo] = useState<AuthInfo>({
    authMethod: AuthMethod.TOTE,
    businessId: null,
    locationId: null,
    storeName: null,
    storeNumber: null,
    businessName: null,
    businessLogo: null,
    userName: null,
    userEmail: null,
    okta: null,
    storeNumbersMap: null,
    isToteSuperAdmin: null,
    featureFlags: null,
    allRoles: null,
    role: null,
    permissions: null,
    employeeId: null,
    timeZone: null,
    primaryColor: '#0080F9',
    secondaryColor: '#ED2626',
    cashManagementConfig: null
  })

  const updateAuthInfo = (newAuthInfo: AuthInfo) => {
    setAuthInfo((prevAuthInfo) => {
      return {...prevAuthInfo, ...newAuthInfo}
    })
  }

  return (
    <AuthContext.Provider value={{authInfo, updateAuthInfo}}>
      {props.children}
    </AuthContext.Provider>
  )
}

/**
 * @deprecated Access the data from redux store instead.
 */
export const useAuth = (): AuthContextValue => {
  const authContext = useContext(AuthContext)
  if (!authContext) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return authContext
}
