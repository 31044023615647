import {graphql} from 'graphql/generatedTypes'

export const ADD_BUSINESS = graphql(`
  mutation createBusiness($business: BusinessDetailsInput!) {
    createBusiness(business: $business) {
      brand
      id
      logo
      name
      okta {
        oktaClientId
        oktaIssuer
      }
      contactDetails {
        contactPhone
        contactEmail
        supportPhone
        supportEmail
      }
      featureFlags
      address {
        city
        lat
        long
        state
        street
        zipCode
        country
      }
      authMethod
    }
  }
`)

export const UPDATE_BUSINESS = graphql(`
  mutation updateBusiness(
    $businessId: String!
    $business: BusinessDetailsUpdateInput!
  ) {
    updateBusiness(business: $business, businessId: $businessId) {
      brand
      id
      logo
      name
      okta {
        oktaClientId
        oktaIssuer
      }
      contactDetails {
        contactPhone
        contactEmail
        supportPhone
        supportEmail
      }
      featureFlags
      address {
        city
        lat
        long
        state
        street
        zipCode
        country
      }
      authMethod
    }
  }
`)

export const UPDATE_BUSINESS_COLORS = graphql(`
  mutation updateBusinessColors(
    $businessId: String!
    $primary: String!
    $secondary: String!
  ) {
    updateBusinessColors(
      businessId: $businessId
      primary: $primary
      secondary: $secondary
    ) {
      primary
      secondary
    }
  }
`)

export const UPDATE_ORDER_EMAIL_RECEIPT_TEMPLATE = graphql(`
  mutation updateOrderEmailReceiptTemplate($orderReceipt: String!) {
    updateOrderEmailReceiptTemplate(orderReceipt: $orderReceipt)
  }
`)

export const TOGGLE_EMAIL_RECEIPT_ENABLED = graphql(`
  mutation toggleEmailReceiptEnabled($emailReceiptEnabled: Boolean!) {
    toggleEmailReceiptEnabled(emailReceiptEnabled: $emailReceiptEnabled)
  }
`)

export const RESET_ORDER_EMAIL_RECEIPT_TEMPLATE = graphql(`
  mutation resetOrderEmailReceiptTemplate {
    resetOrderEmailReceiptTemplate
  }
`)
