import * as React from 'react'
import Popover from '@mui/material/Popover'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import HierarchySelector from 'clientDashboard/HierarchySelector'
import { updateGroupsAndOptionsForTopBar,
} from 'clientDashboard/employee.slice'
import {
  useAppDispatch,
  useAppSelector
} from 'clientDashboard/clientDashboard.store'
import {selectAppliedLocationGroups} from 'clientDashboard/employee.selectors'
import cx from 'clsx'

const iconLocation = icon({
  name: 'location-dot'
})

const iconDown = icon({
  name: 'chevron-down'
})

const LocationGroupSelector = () => {
  const dispatch = useAppDispatch()
  const appliedLocationGroups = useAppSelector(selectAppliedLocationGroups)

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const openDropdown = (event: any) => {
    setAnchorEl(event.currentTarget)
    dispatch(updateGroupsAndOptionsForTopBar(appliedLocationGroups))
  }

  const onClose = () => {
    setAnchorEl(null)
    dispatch(updateGroupsAndOptionsForTopBar(null))
  }

  const onClickApply = () => {
    setAnchorEl(null)
  }

  const open = !!anchorEl

  return (
    <>
      <div
        className='flex justify-center items-center gap-x-3 py-2 px-3 rounded-[100px] bg-[#E8EEFF]'
        onClick={openDropdown}
      >
        <FontAwesomeIcon icon={iconLocation} />
        <span>Locations</span>
        <FontAwesomeIcon icon={iconDown} />
      </div>
      <Popover
        id={cx(open && 'location-group-selector')}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        slotProps={{
          paper: {
            className: 'mt-1 overflow-visible'
          }
        }}
        elevation={4}
      >
        <HierarchySelector
          onClickApply={onClickApply}
        />
      </Popover>
    </>
  )
}

export default LocationGroupSelector
