import {useLazyQuery} from '@apollo/client'
import {Typography} from '@mui/material'
import StyledTable from 'ui/molecules/StyledTable'
import {REFETCH_INTERVAL} from 'constants/constants'
import {MRT_ColumnDef} from 'material-react-table'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {convertToLocalTimeString} from 'utils/common'

import {useAuth} from 'auth/AuthContext'
import StyledChip from 'ui/atoms/StyledChip'
import {GET_DEVICE_DATA} from 'graphql/queries/peripheral-hub.queries'

type TableData = {
  severity: 'Critical' | 'Warning' | 'Alert'
  serialNumber: string
  deviceName: string
  zoneName: string
  registerName: string
  lastActive: string
  issueDescription: string
  action: string
  id: string
  deviceType: string
}

const PeripheralHubDevices = () => {
  const {t} = useTranslation()
  const {authInfo} = useAuth()
  const history = useHistory()

  const [data, setData] = useState<any>([])

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0
  })

  const [getDeviceData, {loading: loadingDeviceData, data: deviceData}] =
    useLazyQuery(GET_DEVICE_DATA, {
      fetchPolicy: 'no-cache'
    })
  const fetchDeviceData = () => {
    getDeviceData({
      variables: {
        businessId: authInfo.businessId as string,
        locationId: authInfo.locationId as string
      }
    })
  }
  useEffect(() => {
    fetchDeviceData()
    const interval = setInterval(fetchDeviceData, REFETCH_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!deviceData?.getDeviceData?.length) return
    setData(deviceData?.getDeviceData)
  }, [deviceData])

  const columns = useMemo<MRT_ColumnDef<TableData>[]>(
    () => [
      {
        accessorKey: 'serialNumber',
        header: t('peripheral-hub.device-info.serial-number')
      },
      {
        accessorKey: 'deviceName',
        header: t('peripheral-hub.device-info.device-name'),
        Cell: ({cell, row}) => (
          <Typography
            style={{
              color: 'black',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            onClick={() => {
              history.push(
                `/${authInfo.businessId}/peripheral-hub/activity-log/${row.original.id}`
              )
            }}
          >
            {cell.getValue()?.toString()}
          </Typography>
        )
      },
      {
        accessorKey: 'deviceType',
        header: t('peripheral-hub.device-info.device-type')
      },
      {
        accessorKey: 'registerName',
        header: t('peripheral-hub.device-info.register-name')
      },
      {
        accessorKey: 'lastActive',
        header: t('peripheral-hub.device-info.last-active'),

        Cell: ({cell}: any) => {
          return cell.getValue() === 'Active' ? (
            <StyledChip label={cell.getValue()} color='success' />
          ) : (
            <StyledChip
              label={convertToLocalTimeString(cell.getValue())}
              color='error'
            />
          )
        }
      },
      {
        accessorKey: 'issueDescription',
        header: t('peripheral-hub.device-info.issue-description'),
        Cell: ({cell, row}: any) => cell.getValue() || '-'
      },
      {
        accessorKey: 'action',
        header: t('peripheral-hub.device-info.action'),
        Cell: ({cell, row}: any) => cell.getValue() || '-'
      }
    ],
    []
  )

  return (
    <StyledTable
      columns={columns}
      data={data}
      state={{
        showProgressBars: loadingDeviceData,
        pagination: {
          pageSize: pagination.pageSize,
          pageIndex: pagination.pageIndex
        }
      }}
      onPaginationChange={(pagination) => {
        setPagination(pagination)
      }}
    />
  )
}

export default PeripheralHubDevices
