import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {NullablePartial} from 'utils/type.utils'
import _set from 'lodash/set'
import _findIndex from 'lodash/findIndex'
import _find from 'lodash/find'
import _unionBy from 'lodash/unionBy'
import _forEach from 'lodash/forEach'
import {StoreGroupStatuses} from 'clientDashboard/settings/locations/locations.constants'
import _toLower from 'lodash/toLower'
import _cloneDeep from 'lodash/cloneDeep'

export type StoreGroup = {
  id?: string
  name: string
  status: string
  groupItems: any[]
  cluster?: {
    id: string
    name: string
    hierarchyLevel: number
  }
  parentStoreGroup?: StoreGroup
}

export type SaveStoreGroup = {
  id?: string
  name: string
  status: string
  groupItems: any[]
  orphanItems: any[]
  cluster: {
    id: string
    name: string
    hierarchyLevel: number
  }
}

type Locations = {
  saveStoreGroupModal: any
  // list of store groups by cluster name
  // ex - 'region': [storeGroup1, storeGroup2]
  storeGroups: Record<string, StoreGroup[]>
  childStoreGroups: Record<string, StoreGroup[]>
  storeGroupsByIdMap: Record<string, StoreGroup>
  // stores the list of ancestores for each store group
  ancestorsStoreGroupsByStoreGroupIdMap: Record<string, StoreGroup[]>
}

const initialState: NullablePartial<Locations> = {}

export const locationsSlice = createSlice({
  name: 'settings/locations',
  initialState,
  reducers: {
    /* saveStoreGroupModal */
    setSaveStoreGroupModalData: (
      state,
      action: PayloadAction<NullablePartial<StoreGroup>>
    ) => {
      const {id, name, status, groupItems, cluster} = action.payload
      // @ts-ignore
      _set(state, ['saveStoreGroupModal'], {
        id,
        name: name || '',
        status: status || StoreGroupStatuses.Draft,
        groupItems: groupItems || [],
        cluster: cluster || {}
      })
    },
    changeSaveStoreGroupModalField: (state, action) => {
      const {key, value} = action.payload
      _set(state, ['saveStoreGroupModal', key], value)
    },
    closeSaveStoreGroupModal: (state) => {
      state.saveStoreGroupModal = null
    },
    setOrphanItems: (state, action) => {
      _set(state, ['saveStoreGroupModal', 'orphanItems'], action.payload)
    },
    addToGroup: (state, action) => {
      const {id} = action.payload
      state.saveStoreGroupModal.groupItems.push(action.payload)

      const index = _findIndex(state.saveStoreGroupModal.orphanItems, {id})
      state.saveStoreGroupModal.orphanItems[index].isChecked = true
    },

    removeFromGroup: (state, action) => {
      const {id} = action.payload
      const orphanItem = _find(state.saveStoreGroupModal.orphanItems, {id})
      if (orphanItem) {
        orphanItem.isChecked = false
      } else {
        state.saveStoreGroupModal.orphanItems.push(action.payload)
      }

      const groupIndex = _findIndex(state.saveStoreGroupModal.groupItems, {
        id
      })
      state.saveStoreGroupModal.groupItems.splice(groupIndex, 1)
    },
    addAllToGroup: (state) => {
      state.saveStoreGroupModal.orphanItems.forEach((item: any) => {
        if (
          !state.saveStoreGroupModal.groupItems.some(
            (groupItem: any) => groupItem.id === item.id
          )
        ) {
          state.saveStoreGroupModal.groupItems.push(item)
        }
        item.isChecked = true
      })
    },
    undoAddAllToGroup: (state) => {
      const ids = new Set()
      state.saveStoreGroupModal.orphanItems.forEach((item: any) => {
        item.isChecked = false
        ids.add(item.id)
      })

      state.saveStoreGroupModal.groupItems =
        state.saveStoreGroupModal.groupItems.filter(
          (item: any) => !ids.has(item.id)
        )
    },
    removeAllFromGroup: (state) => {
      state.saveStoreGroupModal.orphanItems.push(
        ...state.saveStoreGroupModal.groupItems
      )
      state.saveStoreGroupModal.orphanItems = _unionBy(
        state.saveStoreGroupModal.orphanItems,
        'id'
      )

      _forEach(state.saveStoreGroupModal.orphanItems, (item) => {
        item.isChecked = false
      })

      state.saveStoreGroupModal.groupItems = []
    },
    setStoreGroups: (state, action) => {
      const {storeGroupsList, locationsList} = action.payload
      if (!storeGroupsList || !locationsList) {
        return
      }
      const storeGroups: Record<string, StoreGroup[]> = {}
      const storeGroupsByIdMap = {} as Record<string, StoreGroup>
      let childStoreGroupsMap = {} as Record<string, any>
      storeGroupsList.forEach((_storeGroups: any) => {
        // loop through storeGroup
        // if storeGroup.cluster.name is not in storeGroups, add it
        _storeGroups.forEach((_storeGroup: any) => {
          let _sg = _cloneDeep(_storeGroup)
          let storeGroupClusterName = _toLower(_sg.storeGroupCluster.name)
          if (!storeGroups[storeGroupClusterName]) {
            storeGroups[storeGroupClusterName] = []
          }
          _sg.cluster = {
            id: _sg.storeGroupCluster.id,
            name: _sg.storeGroupCluster.name,
            hierarchyLevel: _sg.storeGroupCluster.hierarchyLevel
          }
          storeGroups[storeGroupClusterName].push(_sg)
          storeGroupsByIdMap[_sg.id] = _sg
          if (_storeGroup.parentStoreGroup?.id) {
            if (!childStoreGroupsMap[_sg.parentStoreGroup.id]) {
              childStoreGroupsMap[_sg.parentStoreGroup.id] = []
            }
            childStoreGroupsMap[_sg.parentStoreGroup.id].push(_sg)
          }
        })
      })
      locationsList.forEach((location: any) => {
        if (!location.storeGroups) {
          return
        }
        let parentStoreGroup =
          location.storeGroups[location.storeGroups.length - 1]
        if (parentStoreGroup) {
          if (!childStoreGroupsMap[parentStoreGroup.id]) {
            childStoreGroupsMap[parentStoreGroup.id] = []
          }
          let storeGroup: StoreGroup = {
            id: location.id,
            name: location.name,
            status: location.status,
            groupItems: []
          }
          childStoreGroupsMap[parentStoreGroup.id].push(storeGroup)
        }
      })
      let ancestorsStoreGroupsByStoreGroupIdMap = {} as Record<string, any>
      _forEach(storeGroupsByIdMap, (storeGroup) => {
        let ancestors = [] as StoreGroup[]
        let currentStoreGroup = storeGroup
        while (currentStoreGroup && currentStoreGroup.parentStoreGroup) {
          ancestors.push(currentStoreGroup.parentStoreGroup)
          currentStoreGroup =
            storeGroupsByIdMap[currentStoreGroup.parentStoreGroup.id as string]
        }
        ancestorsStoreGroupsByStoreGroupIdMap[storeGroup.id as string] =
          ancestors
      })
      state.childStoreGroups = childStoreGroupsMap
      state.storeGroups = storeGroups
      state.storeGroupsByIdMap = storeGroupsByIdMap
      state.ancestorsStoreGroupsByStoreGroupIdMap =
        ancestorsStoreGroupsByStoreGroupIdMap
    }
  }
})

export const {
  setSaveStoreGroupModalData,
  changeSaveStoreGroupModalField,
  setOrphanItems,
  addToGroup,
  removeFromGroup,
  removeAllFromGroup,
  closeSaveStoreGroupModal,
  addAllToGroup,
  undoAddAllToGroup,
  setStoreGroups
} = locationsSlice.actions
