import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Collapse,
  Drawer,
  Grid,
  IconButton,
  List,
  Typography,
  useMediaQuery
} from '@mui/material'
import OktaAuth from '@okta/okta-auth-js'
import {useOktaAuth} from '@okta/okta-react'
import {ReactComponent as AnalyticsIcon} from 'assets/insights.svg'
import {ReactComponent as SubMeuIcon} from 'assets/submenu-item.svg'
import {ReactComponent as SubMenuLastItemIcon} from 'assets/submenu-last-item.svg'
import {NavItem, NavItemText, NavSubItem} from 'ui/organisms/navigationDrawer/NavigationBlocks'
import theme, {space} from 'config/theme'
import {LocalStorageKeys} from 'constants/constants'
import useLocalStorage from 'hooks/useLocalStorage'
import _ from 'lodash'
import {useEffect, useState} from 'react'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useLocation} from 'react-router-dom'
import classes from 'ui/organisms/navigationDrawer/navigation-drawer.styles.module.css'

export const drawerWidth = 280
export const collapsedWidth = 96

function SvgIcon(props: any) {
  return (
    <svg
      width={props.width || 24}
      height={props.height || 24}
      viewBox='2 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      {props.children}
    </svg>
  )
}

type ClientRoutesProps = {
  signOut: (oktaAuth: OktaAuth) => Promise<void>
}

function AdminNavigationDrawer({signOut}: ClientRoutesProps) {
  const {t} = useTranslation('commons')

  const {authState, oktaAuth} = useOktaAuth()

  const [, , clearToteAccessToken] = useLocalStorage(
    LocalStorageKeys.TOTE_ADMIN_ACCESS_TOKEN
  )
  const [navigationOpenFlags, setNavigationOpenFlags] = useLocalStorage(
    LocalStorageKeys.NAVIGATION_OPEN_MENU_FLAGS,
    [false]
  )

  const handleLogout = () => {
    signOut(oktaAuth)
    clearToteAccessToken()
  }

  const basePath = (path: string) => {
    return `/admin/${path}`
  }

  const navOptions: any = []

  navOptions.push({
    name: t('adminPanelNavigationDrawer.business.overview'),
    url: basePath('business-manager'),
    icon: (
      <SvgIcon>
        <AnalyticsIcon />
      </SvgIcon>
    )
  })

  navOptions.push({
    name: t('navigationDrawer.secrets.overview'),
    url: basePath('secrets'),
    icon: (
      <FontAwesomeIcon
        icon={icon({
          name: 'key',
          family: 'classic',
          style: 'light'
        })}
        fontSize={20}
        color='black'
        style={{
          borderWidth: '1px'
        }}
      />
    )
  })

  const {pathname} = useLocation()

  const isMobileScreen = useMediaQuery('(max-width:800px)')

  useEffect(() => {
    setCollapse(isMobileScreen)
  }, [isMobileScreen])

  const [openFlags, setOpenFlags] = useState(
    Array(navOptions.length).fill(false)
  )

  useEffect(() => {
    setOpenFlags(navigationOpenFlags || [])
  }, [navOptions.length])

  useEffect(() => {
    if (!openFlags.length) return

    setNavigationOpenFlags(openFlags)
  }, [openFlags])

  let [collapse, setCollapse] = useState(false)

  const handleExpandMenu = (index: number) => {
    let newOpenFlags = [...openFlags]
    newOpenFlags[index] = !newOpenFlags[index]
    setOpenFlags(newOpenFlags)

    if (collapse && navOptions[index]?.children) {
      setCollapse(false)
    }
  }

  if (!authState?.isAuthenticated) {
    return <div />
  }

  return (
    <Drawer
      sx={{
        width: collapse ? collapsedWidth : drawerWidth,
        flexShrink: 0,
        marginLeft: space.SMALL,
        '& .MuiDrawer-paper': {
          width: collapse ? collapsedWidth : drawerWidth,
          boxSizing: 'border-box',
          top: space.SMALL,
          left: space.SMALL,
          backgroundColor: 'white',
          transition: 'width 0.7s',
          transitionTimingFunction: 'ease-out',
          borderRadius: '12px',
          border: '1px solid',
          borderColor: 'rgba(0, 0, 0, 0.20)'
        },
        transition: 'width 0.7s',
        transitionTimingFunction: 'ease-out'
      }}
      variant='permanent'
      anchor='left'
    >
      <List className={classes.listSection}>
        <Grid>
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: space.MEDIUM,
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <Avatar style={{marginRight: space.SMALL}} src='/logo192.png' />
            {!collapse && (
              <Typography variant='h2' style={{marginLeft: '10px'}}>
                Tote Admin
              </Typography>
            )}
          </Grid>

          {navOptions?.map((navOption: any, index: number) => {
            if (navOption.children) {
              return (
                <div
                  key={index}
                  className={classes.navigationItemWrapper}
                  style={{
                    flexDirection: collapse ? 'inherit' : 'column'
                  }}
                >
                  <NavItem
                    id={`${_.camelCase(navOption.name)}MenuItem`}
                    key={index}
                    icon={navOption.icon}
                    collapse={collapse}
                    to={navOption.url}
                    component={Link}
                    selected={pathname.startsWith(navOption.url)}
                  >
                    <NavItemText
                      className={
                        collapse
                          ? classes.collapsedNavItemText
                          : classes.navItemText
                      }
                    >
                      <Typography variant='body1'>{navOption.name}</Typography>
                    </NavItemText>

                    <IconButton
                      onClick={() => {
                        handleExpandMenu(index)
                      }}
                      disableRipple
                      disableTouchRipple
                      size='small'
                    >
                      {openFlags[index] && !collapse ? (
                        <FontAwesomeIcon
                          icon={icon({
                            name: 'chevron-up',
                            family: 'classic',
                            style: 'solid'
                          })}
                          color={theme.palette.text.secondary}
                          className={
                            collapse
                              ? classes.collapsedNavItemText
                              : classes.navItemText
                          }
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={icon({
                            name: 'chevron-down',
                            family: 'classic',
                            style: 'solid'
                          })}
                          color={theme.palette.text.secondary}
                          className={
                            collapse
                              ? classes.collapsedNavItemText
                              : classes.navItemText
                          }
                        />
                      )}
                    </IconButton>
                  </NavItem>
                  {!collapse && (
                    <Collapse
                      in={openFlags[index]}
                      timeout='auto'
                      unmountOnExit
                    >
                      <List component='div' disablePadding>
                        {navOption.children.map(
                          (child: any, childIndex: any) => {
                            return (
                              <NavSubItem
                                id={`${_.camelCase(child.name)}SubMenuItem`}
                                key={childIndex}
                                component={Link}
                                to={child.url}
                                icon={
                                  childIndex ===
                                  navOption.children.length - 1 ? (
                                    <SubMenuLastItemIcon />
                                  ) : (
                                    <SubMeuIcon />
                                  )
                                }
                                selected={pathname.startsWith(child.url)}
                                onClick={() => {
                                  setCollapse(isMobileScreen)
                                }}
                              >
                                <NavItemText>
                                  <Typography variant='body1'>
                                    {child.name}
                                  </Typography>
                                </NavItemText>
                              </NavSubItem>
                            )
                          }
                        )}
                      </List>
                    </Collapse>
                  )}
                </div>
              )
            }
            return (
              <div key={index} className={classes.navigationItemWrapper}>
                <NavItem
                  id={`${_.camelCase(navOption.name)}MenuItem`}
                  component={Link}
                  to={navOption.url}
                  collapse={collapse}
                  icon={navOption.icon}
                  selected={pathname.startsWith(navOption.url)}
                >
                  <NavItemText
                    className={
                      collapse
                        ? classes.collapsedNavItemText
                        : classes.navItemText
                    }
                  >
                    <Typography variant='body1'>{navOption.name}</Typography>
                  </NavItemText>
                </NavItem>
              </div>
            )
          })}
        </Grid>

        <Grid
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          }}
        >
          <SubMenu
            collapse={collapse}
            icon={icon({
              name: 'circle-question',
              family: 'classic',
              style: 'light'
            })}
            name='Help'
            onClick={() => {}}
          />

          <SubMenu
            collapse={collapse}
            icon={icon({
              name: 'arrow-right-from-bracket',
              family: 'classic',
              style: 'light'
            })}
            name='Logout'
            onClick={() => {
              authState?.isAuthenticated && handleLogout()
            }}
          />
        </Grid>
      </List>
    </Drawer>
  )
}

type SubMenuProps = {
  collapse: boolean
  icon: any
  name: string
  onClick: () => void
}

function SubMenu(props: SubMenuProps) {
  const {icon, name, onClick, collapse} = props

  return (
    <Grid
      style={{
        padding: collapse ? 0 : space.XXS,
        justifyContent: collapse ? 'center' : 'flex-start'
      }}
    >
      <IconButton disableRipple disableTouchRipple onClick={onClick}>
        <FontAwesomeIcon icon={icon} fontSize={24} color='black' />
        {!collapse && (
          <Typography
            variant='body1'
            style={{marginLeft: space.SMALL, color: 'black'}}
          >
            {name}
          </Typography>
        )}
      </IconButton>
    </Grid>
  )
}

export default AdminNavigationDrawer
