import {useLazyQuery, useSubscription} from '@apollo/client'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import StyledTable from 'ui/molecules/StyledTable'
import theme from 'config/theme'
import {OrderType} from 'graphql/generatedTypes/graphql'
import {FETCH_ORDERS} from 'graphql/queries/order.queries'
import _ from 'lodash'
import {MRT_ColumnDef} from 'material-react-table'
import {useMemo} from 'react'
import {useEffect, useState} from 'react'
import React from 'react'
import Barcode from 'react-barcode'
import {
  centsToDollar,
  convertToLocalTimeString,
  formatCurrency
} from 'utils/common'

import {TRANSACTION_EVENT_SUBSCRIPTION} from 'graphql/subscriptions/transaction.subscription'

interface TransactionsProps {
  customerId?: string
}

function Transactions({customerId}: TransactionsProps) {
  const [data, setData] = useState<OrderType[]>([])
  const {authInfo} = useAuth()

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10
  })

  const [getOrders, {data: _ordersData, loading: loadingOrders}] = useLazyQuery(
    FETCH_ORDERS,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const fetchAllOrders = () => {
    getOrders({
      variables: {
        businessId: authInfo.businessId as string,
        locationId: authInfo.locationId as string,
        customerId: customerId as string,
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize
      }
    })
  }

  useEffect(() => {
    fetchAllOrders()
  }, [pagination])

  useEffect(() => {
    if (_ordersData) {
      setData(_ordersData.listOrders.orders as OrderType[])
    }
  }, [_ordersData, pagination])

  const {data: _subscriptionData} = useSubscription(
    TRANSACTION_EVENT_SUBSCRIPTION,
    {
      variables: {
        businessId: authInfo.businessId as string,
        locationId: authInfo.locationId as string
      }
    }
  )

  useEffect(() => {
    if (_subscriptionData) {
      let cashEvent = _subscriptionData.webCashEvent?.cashEvent
      if (cashEvent === 'transactionRefresh') {
        fetchAllOrders()
      }
    }
  }, [_subscriptionData])

  const Receipt = (items: any, row: any) => {
    let subtotal = 0
    let discount = 0
    let tax = 0
    items.forEach((item: any) => {
      subtotal += item.quantity * item.unitPrice
      discount += item.totalDiscount
      tax += item.taxAmount
    })

    return (
      <>
        <Paper elevation={3}>
          <div
            style={{
              marginBottom: '20px',
              backgroundColor: theme.palette.primary.light,
              padding: '10px'
            }}
          >
            <Typography variant='h6' align='center' gutterBottom>
              {row.location.name}
            </Typography>
            <Typography variant='body1' align='center' gutterBottom>
              {row.location.address.street}, {row.location.address.city},{' '}
            </Typography>
          </div>
          {/* Table */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Rate</TableCell>
                  <TableCell>Discount</TableCell>
                  <TableCell align='right'>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(items || []).map((item: any, index: number) => (
                  <TableRow key={item.sku}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>
                      {formatCurrency(
                        centsToDollar(item.unitPrice),
                        row.currency
                      )}
                    </TableCell>
                    <TableCell>
                      {formatCurrency(
                        centsToDollar(item.totalDiscount || 0),
                        row.currency
                      )}
                    </TableCell>
                    <TableCell align='right'>
                      {formatCurrency(centsToDollar(item.total), row.currency)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>Subtotal</TableCell>
                  <TableCell align='right'>
                    {formatCurrency(centsToDollar(subtotal), row.currency)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>Discount</TableCell>
                  <TableCell align='right'>
                    {formatCurrency(centsToDollar(discount), row.currency)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>Tax</TableCell>
                  <TableCell align='right'>
                    {formatCurrency(centsToDollar(tax), row.currency)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>Total</TableCell>
                  <TableCell align='right'>
                    {formatCurrency(
                      centsToDollar(row.totalAmount),
                      row.currency
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* Footer */}
          <Typography variant='h6' align='center' gutterBottom>
            <Barcode
              value={row.orderNumber}
              width={1}
              height={50}
              textPosition={'center'}
            />
          </Typography>
        </Paper>
      </>
    )
  }

  const columns = useMemo<MRT_ColumnDef<OrderType>[]>(
    () => [
      {
        accessorKey: 'createdAt',
        header: 'Date',
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue() ? convertToLocalTimeString(cell.getValue()) : ''}
            </Typography>
          )
        }
      },

      {
        accessorKey: 'employee.name',
        header: 'Employee',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },

      {
        accessorKey: 'register',
        header: 'Register ID',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },

      {
        accessorKey: 'totalAmount',
        header: 'Transaction amount',
        Cell: ({cell, row}: any) => {
          return (
            <Typography variant='subtitle2'>
              {formatCurrency(
                centsToDollar(cell.getValue()),
                row.original.currency
              )}
            </Typography>
          )
        },
        muiTableBodyCellProps: {
          align: 'right'
        }
      },
      {
        accessorKey: 'paymentStatus',
        header: 'Payment Status',
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {_.capitalize(cell.getValue())}
            </Typography>
          )
        },

        enableSorting: true
      },
      {
        accessorKey: 'orderNumber',
        header: 'Transaction ID',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'customer',
        header: 'Customer',
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue()
                ? cell.getValue()?.firstName + ' ' + cell.getValue()?.lastName
                : '-'}
            </Typography>
          )
        }
      }
    ],
    []
  )

  return (
    <StyledTable
      columns={columns}
      data={data}
      manualPagination={true}
      onPaginationChange={setPagination}
      state={{
        pagination,
        showSkeletons: loadingOrders,
        showProgressBars: loadingOrders
      }}
      rowCount={_ordersData?.listOrders.totalPageCount || 0}
      renderDetailPanel={({row}) => {
        if (loadingOrders) return ''
        return (
          <Grid
            container
            spacing={2}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <Grid
              item
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                alignContent: 'stretch',
                gap: 50
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  {Receipt(row.original.items, row.original)}
                </Grid>
                <Grid item xs={4}>
                  {row.original.subscriptionItems &&
                    row.original.subscriptionItems.length > 0 &&
                    Receipt(row.original.subscriptionItems, row.original)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }}
      initialState={{
        columnPinning: {
          left: ['mrt-row-expand']
        },
        columnVisibility: {
          'employee.name': false,
          register: false
        }
      }}
    />
  )
}

export default Transactions
