import {Grid, Typography} from '@mui/material'
import SecretManager from 'adminPanel/SecretManager'

function Secrets() {
  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
      }}
      spacing={4}
    >
      <Grid
        item
        container
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start'
        }}
      >
        <Grid item>
          <Typography variant='h2'>Manage Secrets</Typography>
        </Grid>

        <Grid item>
          <SecretManager />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Secrets
