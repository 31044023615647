import _set from 'lodash/set'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ErrorNotificationType} from 'graphql/generatedTypes/graphql'
import {NullablePartial} from 'utils/type.utils'
import {PaginationState} from '@tanstack/table-core/src/features/RowPagination'
import {
  ZoneType,
  DeviceDataType,
  RegisterDataType
} from 'clientDashboard/peripheralHub/types/peripheral-hub.types'
import {AvailableElementType} from "types/peripheral-hub.types";
import {UNASSIGNED} from "constants/constants";

type ErrorNotificationsPayload = NullablePartial<ErrorNotificationType>[]
type ZoneDevicesSelectedItemsPayload = {
  items?: AvailableElementType[] |  Record<string, AvailableElementType>,
  isTransformPending?: boolean,
}

const initialState = {
  errorNotifications: {
    pagination: {
      pageIndex: 0,
      pageSize: 5
    } as PaginationState,
    data: [] as NullablePartial<ErrorNotificationType>[]
  },

  devices: {} as DeviceDataType,
  registers: {} as RegisterDataType,
  zones: [] as ZoneType[],
  isZoneEditMode: false,
  editZoneModal: null as ZoneType | null,
}

export const peripheralHubSlice = createSlice({
  name: 'peripheralHub',
  initialState,
  reducers: {
    setErrorNotifications: (
      state,
      action: PayloadAction<ErrorNotificationsPayload>
    ) => {
      state.errorNotifications.data = action.payload || []
    },
    setPaginationForErrorNotifications: (
      state,
      action: PayloadAction<PaginationState>
    ) => {
      state.errorNotifications.pagination = action.payload
    },

    setDeviceOverviewData: (state, action: PayloadAction<any>) => {
      state.devices = action.payload.devices || state.devices
      state.registers = action.payload.registers || state.registers
      state.zones = action.payload.zones || state.zones
    },

    toggleZoneEditMode: (state) => {
      state.isZoneEditMode = !state.isZoneEditMode
    },

    updateEditZoneModalData: (state, action: PayloadAction<NullablePartial<ZoneType>>) => {
      state.editZoneModal = Object.assign(state.editZoneModal || {}, action.payload) as ZoneType
    },
    closeEditZoneModal: (state) => {
      state.editZoneModal = null
    },
    setEditZoneModalSelectedItems: (state, action: PayloadAction<ZoneDevicesSelectedItemsPayload>) => {
      const { items, isTransformPending  } = action.payload
      let selectedItems = {}
      if (isTransformPending && items) {
        // @ts-ignore
        selectedItems = items.filter((item: any) => item.zoneId === state.editZoneModal?.id)
          .reduce(
            (
              acc: Record<string, AvailableElementType>,
              item: AvailableElementType
            ) => {
              acc[
                item.deviceId !== UNASSIGNED ? item.deviceId : item.registerId
                ] = item
              return acc
            },
            {}
          )
      } else {
        // @ts-ignore
        selectedItems = items
      }
      _set(state.editZoneModal || {}, ['selectedItems'], selectedItems)
    },

  }
})

export const {
  setErrorNotifications,
  setPaginationForErrorNotifications,
  setDeviceOverviewData,
  toggleZoneEditMode,
  updateEditZoneModalData,
  closeEditZoneModal,
  setEditZoneModalSelectedItems,
} = peripheralHubSlice.actions
