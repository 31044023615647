import React, {useCallback, useEffect, useMemo, useState} from 'react'
import StyledInput from 'ui/atoms/StyledInput'
import AddressFields from './AddressFields'
import {t} from 'i18next'
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete
} from '@mui/material'
import {isValidEmail} from 'utils/common'
import StyledDialog from 'ui/molecules/StyledDialog'
import _ from 'lodash'
import {useMutation} from '@apollo/client'
import {notify} from 'ui/molecules/CustomNotifications'
import {
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE
} from 'graphql/mutations/employee.mutations'
import HierarchySelector from "clientDashboard/settings/employees/HierarchySelector";
import {useAppDispatch, useAppSelector} from "clientDashboard/clientDashboard.store";
import {updateGroupsAndOptionsEmployee} from "clientDashboard/settings/employees/employeeManagement.slice";
import {selectFeatureFlags} from "clientDashboard/business.selectors";
import {withMappedGroups, withMappedLocations} from "clientDashboard/employee.slice.helpers";
import _groupBy from "lodash/groupBy";
import {
  selectAppliedGroupsForEmployeeModal,
  selectAssignedGroupAndLocationIds
} from "clientDashboard/settings/employees/employeeManagement.selectors";
import _isEmpty from "lodash/isEmpty";
import {StoreGroupClusters} from "clientDashboard/constants/employee.constants";

interface EmployeeConfigurationDialogBodyProps {
  allRoles: any[]
  allLocations: any[]
  allEmployees: any[]
  businessId: any
  isEditMode: boolean
  setIsEditMode?: (isEditMode: boolean) => void
  setShowCreateEmployeeDialog: (show: boolean) => void
  showCreateEmployeeDialog: boolean
  setAllEmployees: (allEmployees: any) => void
  editEmployeeId: any
}
const defaultFormValues = {
  name: '',
  employeeCode: '',
  email: '',
  locations: [],
  role: null,
  mobileNumber: '',
  address: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  },
  defaultLocation: null
}
const EmployeeConfigurationDialog = ({
  allRoles,
  allLocations,
  allEmployees,
  businessId,
  isEditMode,
  setIsEditMode = () => {},
  setShowCreateEmployeeDialog,
  showCreateEmployeeDialog,
  setAllEmployees,
  editEmployeeId
}: EmployeeConfigurationDialogBodyProps) => {
  const dispatch = useAppDispatch()
  const featureFlags = useAppSelector(selectFeatureFlags)
  const payloadIds = useAppSelector(selectAssignedGroupAndLocationIds)
  const appliedLocationGroups = useAppSelector(
    selectAppliedGroupsForEmployeeModal
  ) || {}

  const [formValues, setFormValues] = useState({...defaultFormValues})
  const clearForm = () => {
    setFormValues({
      ...defaultFormValues
    })
  }

  useEffect(() => {
    if (!showCreateEmployeeDialog) {
      return
    }
    if (isEditMode) {
      const employee = allEmployees.find(
        (employee: any) => employee.id === editEmployeeId
      )
      setFormValues({
        name: employee.name,
        employeeCode: employee.employeeCode,
        email: employee.email,
        locations:
          employee.locations?.map((location: any) => {
            return {
              id: location.id,
              lable: location.name
            }
          }) || [],
        role: employee.role?.id || null,
        mobileNumber: employee.phoneNumber,
        address: {
          street: employee.fullAddress?.street || '',
          city: employee.fullAddress?.city || '',
          state: employee.fullAddress?.state || '',
          zipCode: employee.fullAddress?.zipCode || '',
          country: employee.fullAddress?.country || ''
        },
        defaultLocation: employee.defaultLocation?.id
      })

      const groupsMapped = withMappedGroups(employee.assignedStoreGroups)
      const locationsMapped = withMappedLocations(employee.locations)
      const appliedGroups = _groupBy([...groupsMapped, ...locationsMapped], 'groupLevel.name')
      dispatch(updateGroupsAndOptionsEmployee(appliedGroups as any))
    } else {
      dispatch(updateGroupsAndOptionsEmployee({} as any))
    }
  }, [isEditMode, editEmployeeId, allEmployees, showCreateEmployeeDialog])

  const allEmailSet = useMemo(() => {
    return new Set(allEmployees.map((employee: any) => employee.email))
  }, [allEmployees])

  const allEmployeeCodeSet = useMemo(() => {
    return new Set(allEmployees.map((employee: any) => employee.employeeCode))
  }, [allEmployees])

  const handleChange = (path: any, value: any) => {
    setFormValues((prev: any) => {
      return _.set({...prev}, path, value)
    })
  }

  const onCloseDialog = () => {
    setShowCreateEmployeeDialog(false)
    clearForm()
    setIsEditMode(false)
    dispatch(updateGroupsAndOptionsEmployee(null))
  }

  const setAddress = (address: {
    street: string
    city: string
    state: string
    zipCode: string
    country: string
  }) => {
    setFormValues((prev) => {
      return {
        ...prev,
        address
      }
    })
  }

  const [_createEmployee] = useMutation(CREATE_EMPLOYEE, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      // efficiently append new employee to the list
      setAllEmployees((prev: any) => {
        return [...prev, data.createEmployee]
      })
      onCloseDialog()
      window.location.reload()
    },
    onError: (error) => {
      notify.show(error.message, 'error')
    }
  })

  const getAssignedGroupsPayload = () => {
    if (featureFlags.locationGrouping) {
      return {
        locations: payloadIds.locationIds,
        assignedStoreGroups: payloadIds.groupIds,
      }
    }

    return {
      locations: formValues.locations.map((location: any) => location.id) || [],
    }
  }

  const createEmployee = useCallback(() => {
    _createEmployee({
      variables: {
        businessId: businessId as string,
        employee: {
          name: formValues.name,
          email: formValues.email.toLocaleLowerCase(),
          employeeCode: formValues.employeeCode,
          phoneNumber: formValues.mobileNumber,
          address: {
            street: formValues.address.street,
            city: formValues.address.city,
            state: formValues.address.state,
            zipCode: formValues.address.zipCode,
            country: formValues.address.country
          },
          role: formValues.role,
          defaultLocation: formValues.defaultLocation,
          ...getAssignedGroupsPayload(),
        }
      }
    })
  }, [_createEmployee, formValues, businessId, payloadIds])

  const [_updateEmployee] = useMutation(UPDATE_EMPLOYEE, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setAllEmployees((prev: any) => {
        return prev.map((employee: any) => {
          if (employee.id === data.updateEmployee.id) {
            return data.updateEmployee
          }
          return employee
        })
      })
      onCloseDialog()
      window.location.reload()
    },
    onError: (error) => {
      notify.show(error.message, 'error')
    }
  })

  const updateEmployee = useCallback(() => {
    _updateEmployee({
      variables: {
        employeeId: editEmployeeId,
        businessId: businessId as string,
        employee: {
          name: formValues.name,
          email: formValues.email,
          employeeCode: formValues.employeeCode,
          phoneNumber: formValues.mobileNumber,
          address: {
            street: formValues.address.street,
            city: formValues.address.city,
            state: formValues.address.state,
            zipCode: formValues.address.zipCode,
            country: formValues.address.country
          },
          role: formValues.role,
          defaultLocation: formValues.defaultLocation,
          ...getAssignedGroupsPayload(),
        }
      }
    })
  }, [_updateEmployee, editEmployeeId, formValues, businessId, payloadIds])

  const getLocationsOptions = () => {
    const locations = appliedLocationGroups[StoreGroupClusters.Location]
    if (featureFlags.locationGrouping && !_isEmpty(locations)) {
      return locations
    }
    return formValues.locations || []
  }

  const employeeConfigurationDialogBody = () => {
    const renderEmailHelperText = () => {
      if (allEmailSet.has(formValues.email) && !isEditMode) {
        return t('employee-management.employee-with-this-email-already-exists')
      } else if (formValues.email && !isValidEmail(formValues.email)) {
        return t('employee-management.invalid-email')
      }
      return ''
    }
    const renderEmailError = () => {
      if (allEmailSet.has(formValues.email) && !isEditMode) {
        return true
      } else if (formValues.email && !isValidEmail(formValues.email)) {
        return true
      }
      return false
    }
    return (
      <>
        {' '}
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <Typography variant='h4'>
              {t('employee-management.details')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              fullWidth
              required
              label={t('employee-management.name')}
              variant='outlined'
              value={formValues.name}
              onChange={(e: any) => handleChange('name', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              fullWidth
              required
              label={t('employee-management.employee-code')}
              variant='outlined'
              value={formValues.employeeCode}
              onChange={(e: any) => {
                handleChange('employeeCode', e.target.value)
              }}
              error={
                allEmployeeCodeSet.has(formValues.employeeCode) && !isEditMode
              }
              helperText={
                allEmployeeCodeSet.has(formValues.employeeCode) && !isEditMode
                  ? t(
                      'employee-management.employee-with-this-code-already-exists'
                    )
                  : ''
              }
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              fullWidth
              label={t('employee-management.email')}
              variant='outlined'
              value={formValues.email}
              onChange={(e: any) => handleChange('email', e.target.value)}
              error={renderEmailError()}
              helperText={renderEmailHelperText()}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              fullWidth
              label={t('employee-management.mobile-number')}
              variant='outlined'
              value={formValues.mobileNumber}
              onChange={(e: any) =>
                handleChange('mobileNumber', e.target.value)
              }
            />
          </Grid>

          {/* Address Fields */}
          <AddressFields address={formValues.address} setAddress={setAddress} />

          <Grid item xs={12}>
            <Typography variant='h4'>
              {t('employee-management.role')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id='role-for-employee' required>
                {t('employee-management.role')}
              </InputLabel>

              <Select
                size='medium'
                label={t('employee-management.role')}
                labelId='role-for-employee'
                value={formValues.role || ''}
                onChange={(e: any) => handleChange('role', e.target.value)}
              >
                {(allRoles || []).map(
                  ({name, id}: {name: string; id: string}) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>



          {
            featureFlags.locationGrouping ? (
              <div className='py-6 pl-2 flex-1'>
                <Typography variant='h4' className='pb-4'>{'Location Groups'}</Typography>

                <HierarchySelector />
              </div>
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography variant='h4'>
                    {t('employee-management.locations')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id='employee-locations'
                    options={allLocations}
                    freeSolo
                    value={formValues.locations}
                    getOptionLabel={(option: any) => option.lable}
                    onChange={(event, newValue) => {
                      handleChange('locations', newValue)
                    }}
                    renderInput={(params) => (
                      <StyledInput
                        required
                        {...params}
                        variant='outlined'
                        label={t('employee-management.locations')}
                        placeholder={t('employee-management.locations')}
                      />
                    )}
                  />
                </Grid>
              </>
            )
          }

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id='default-location'>
                {t('employee-management.default-location')}
              </InputLabel>

              <Select
                size='medium'
                label={t('employee-management.default-location')}
                disabled={_isEmpty(getLocationsOptions())}
                labelId='default-location'
                value={formValues.defaultLocation || ''}
                onChange={(e: any) =>
                  handleChange('defaultLocation', e.target.value)
                }
              >
                {getLocationsOptions().map(
                  ({lable, name, id}: {lable: string, name: string, id: string}) => (
                    <MenuItem key={id} value={id}>
                      {lable || name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </>
    )
  }

  const isSuccessBtnDisabled = () => {
    let isDisabled = !formValues.name ||
      !formValues.employeeCode ||
      !isValidEmail(formValues.email) ||
      !formValues.role ||
      (allEmployeeCodeSet.has(formValues.employeeCode) && !isEditMode) ||
      (allEmailSet.has(formValues.email) && !isEditMode)

    const hasNoLocations = featureFlags.locationGrouping ? _isEmpty(payloadIds.locationIds) : !formValues.locations.length

    return isDisabled || hasNoLocations
  }

  return (
    <StyledDialog
      open={showCreateEmployeeDialog}
      onClose={() => {
        onCloseDialog()
      }}
      title={
        isEditMode
          ? t('employee-management.edit-employee')
          : t('employee-management.create-employee')
      }
      successButtonName={t('employee-management.confirm')}
      cancelButtonName={t('employee-management.cancel')}
      cancelCallback={() => {
        onCloseDialog()
      }}
      disableSuccessButton={
        isSuccessBtnDisabled()
      }
      successCallback={() => {
        isEditMode ? updateEmployee() : createEmployee()
      }}
      maxWidth='md'
      body={employeeConfigurationDialogBody()}
    />
  )
}

export default EmployeeConfigurationDialog
