import StyledTable from 'ui/molecules/StyledTable'
import React, {useEffect} from 'react'
import _isEqual from 'lodash/isEqual'
import _isFunction from 'lodash/isFunction'
import {
  useAppDispatch,
  useAppSelector
} from 'clientDashboard/clientDashboard.store'
import useErrorNotifications from 'clientDashboard/peripheralHub/api/useErrorNotifications'
import {getColumns} from 'clientDashboard/peripheralHub/peripheralHubOverview/errorNotificationTable/errorNotificationTable.helpers'
import {
  selectErrorNotifications,
  selectPaginationForErrorNotifications
} from 'clientDashboard/peripheralHub/peripheralHub.selectors'
import {setPaginationForErrorNotifications} from 'clientDashboard/peripheralHub/peripheralHub.slice'
import {useHistory} from 'react-router-dom'
import {selectBusinessAndLocation} from 'clientDashboard/employee.selectors'

const ErrorNotificationTable = () => {
  const dispatch = useAppDispatch()
  const data = useAppSelector(selectErrorNotifications)
  const pagination = useAppSelector(selectPaginationForErrorNotifications)

  const {fetchErrorNotifications, error, loading} = useErrorNotifications()

  const history = useHistory()
  const {businessId} = useAppSelector(selectBusinessAndLocation)

  useEffect(() => {
    fetchErrorNotifications()
  }, [])

  if (error) {
    return <div>Error! {error.message}</div>
  }

  return (
    <StyledTable
      columns={getColumns({history, businessId})}
      data={data as any}
      onPaginationChange={(updater) => {
        const newPagination = _isFunction(updater)
          ? updater(pagination)
          : updater
        if (!_isEqual(pagination, newPagination)) {
          dispatch(setPaginationForErrorNotifications(newPagination))
        }
      }}
      state={{
        pagination,
        showProgressBars: loading
      }}
    />
  )
}

export default ErrorNotificationTable
