import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Card, Grid, IconButton, Typography} from '@mui/material'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectBusinessId} from 'clientDashboard/employee.selectors'
import {StoreGroupClusterCardList} from 'clientDashboard/settings/StoreGrouping/storeGroups.slice'
import theme, {colors, space} from 'config/theme'
import usePermission from 'hooks/usePermission'
import {useHistory} from 'react-router-dom'
import {Permission} from 'constants/permissions'

type StoreGroupCardCardProps = {
  setShowAddNewStoreGroupModal: (storeGroupCluster: string) => void
  setShowRenameStoreGroupClusterModal: (storeGroupCluster: string) => void
  card: StoreGroupClusterCardList
}

const StoreGroupClusterCard = ({
  card,
  setShowAddNewStoreGroupModal,
  setShowRenameStoreGroupClusterModal
}: StoreGroupCardCardProps) => {
  const hasCreateStoreGroupPermission = usePermission(
    Permission.CREATE_LOCATION_GROUP
  )
  const hasUpdateStoreGroupPermission = usePermission(
    Permission.UPDATE_LOCATION_GROUP
  )
  const businessId = useAppSelector(selectBusinessId)
  const history = useHistory()
  const handleRedirect = () => {
    history.push(`/${businessId}/${card.redirectionUri}`)
  }
  return (
    <Grid>
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: space.LARGE,
          border: '1px solid',
          borderColor: colors.grayBorder,
          height: 'auto',
          boxShadow: 'none',
          borderRadius: '8px',
          backgroundColor: theme.palette.background.default
        }}
      >
        <Grid container justifyContent='space-between'>
          <Grid
            item
            gap={8}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Grid
              item
              container
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <Grid
                item
                gap={2}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Typography variant='h1'>
                  {card.storeGroupCluster.name}
                </Typography>

                {hasUpdateStoreGroupPermission && card.showRenameIcon && (
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setShowRenameStoreGroupClusterModal(
                          card.storeGroupCluster.id
                        )
                      }}
                    >
                      <FontAwesomeIcon
                        icon={icon({
                          name: 'pencil',
                          family: 'classic',
                          style: 'solid'
                        })}
                        fontSize={'12px'}
                        color={theme.palette.text.secondary}
                      />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Typography
                  variant='subtitle1'
                  color={theme.palette.text.secondary}
                >
                  {card.subtext}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              {hasCreateStoreGroupPermission && card.showAddIcon && (
                <Grid item>
                  <IconButton
                    onClick={() => {
                      setShowAddNewStoreGroupModal(card.storeGroupCluster.id)
                    }}
                  >
                    <FontAwesomeIcon
                      icon={icon({
                        name: 'circle-plus',
                        family: 'classic',
                        style: 'solid'
                      })}
                      style={{backgroundColor: 'transparent'}}
                      fontSize={'30px'}
                      color={theme.palette.primary.main}
                    />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            color={theme.palette.background.default}
            alignContent='center'
          >
            {card.showRedirectionIcon && (
              <IconButton
                onClick={() => {
                  handleRedirect()
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'chevron-right',
                    family: 'classic',
                    style: 'solid'
                  })}
                  width={12}
                  height={6}
                  color={theme.palette.text.secondary}
                />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}

export default StoreGroupClusterCard
