import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Box, Card, Grid, Typography, useMediaQuery} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import theme, {colors, space} from 'config/theme'
import {LocalStorageKeys} from 'constants/constants'
import useLocalStorage from 'hooks/useLocalStorage'
import React, {useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {ReactComponent as LocationGroupingIcon} from 'assets/location-grouping.svg'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectFeatureFlags} from 'clientDashboard/business.selectors'
import {selectPermissions} from 'clientDashboard/employee.selectors'
import {Permission} from 'constants/permissions'

type SettingCardProps = {
  title: string
  description: string
  link: string
  iconElement: JSX.Element
}

const SettingCard: React.FC<SettingCardProps> = ({
  title,
  description,
  link,
  iconElement
}) => {
  const history = useHistory()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid
      item
      onClick={() => {
        history.push(link)
      }}
      style={{
        cursor: 'pointer',
        display: 'flex'
      }}
    >
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: space.MEDIUM,
          border: '1px solid',
          borderColor: colors.grayBorder,
          backgroundColor: theme.palette.background.default,
          height: 'auto',
          minWidth: isSmallScreen ? 'auto' : '400px',
          boxShadow: 'none',
          borderRadius: '8px'
        }}
      >
        <Grid container alignItems='center' sx={{gap: 4}}>
          <Box
            sx={{
              alignSelf: 'stretch',
              position: 'relative',
              width: '76px',
              height: '76px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.palette.primary.light,
              borderRadius: '8px'
            }}
          >
            {iconElement}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
              maxWidth: '265px',
              overflowWrap: 'break-word'
            }}
          >
            <Typography variant='h2'>{title}</Typography>
            <Typography
              variant='subtitle1'
              color='text.secondary'
              style={{
                lineHeight: '140%'
              }}
            >
              {description}
            </Typography>
          </Box>
        </Grid>

        <FontAwesomeIcon
          icon={icon({
            name: 'chevron-right',
            family: 'classic',
            style: 'solid'
          })}
          color='grey'
        />
      </Card>
    </Grid>
  )
}

const Settings: React.FC = () => {
  const [businessId] = useLocalStorage(LocalStorageKeys.BUSINESS_ID)
  const {authInfo} = useAuth()
  const basePath = useCallback(
    (path: string) => `/${businessId || authInfo.businessId}/${path}`,
    [businessId, authInfo.businessId]
  )
  const featureFlags = useAppSelector(selectFeatureFlags)
  const permissions = useAppSelector(selectPermissions)

  const managementSettingsArray = []

  const configurationSettingsArray = []

  if (authInfo.permissions?.includes('MANAGE_LOCATION_SETTINGS')) {
    managementSettingsArray.push({
      title: 'Locations',
      description: 'Create and manage store locations',
      link: basePath('location-settings'),
      iconElement: (
        <FontAwesomeIcon
          icon={icon({
            name: 'location-dot',
            family: 'classic',
            style: 'solid'
          })}
          fontSize={'30px'}
          color={theme.palette.primary.main}
        />
      )
    })
  }

  if (authInfo.permissions?.includes('MANAGE_EMPLOYEE')) {
    managementSettingsArray.push({
      title: 'Employees',
      description: 'Add team members and assign roles',
      link: basePath('employee-management'),
      iconElement: (
        <FontAwesomeIcon
          icon={icon({
            name: 'address-card',
            family: 'classic',
            style: 'solid'
          })}
          fontSize={'30px'}
          color={theme.palette.primary.main}
        />
      )
    })
  }

  if (
    authInfo.permissions?.includes('MANAGE_PAYMENTS_CONFIGURATIONS') &&
    authInfo.featureFlags?.payments
  ) {
    configurationSettingsArray.push({
      title: 'Payments',
      description: 'Configure payment gateway and authentication',
      link: basePath('payment-settings'),
      iconElement: (
        <FontAwesomeIcon
          icon={icon({
            name: 'money-check-dollar',
            family: 'classic',
            style: 'solid'
          })}
          fontSize={'30px'}
          color={theme.palette.primary.main}
        />
      )
    })
  }

  if (
    authInfo.permissions?.includes('MANAGE_OMS_CONFIGURATIONS') &&
    authInfo.featureFlags?.oms
  ) {
    configurationSettingsArray.push({
      title: 'OMS',
      description: 'Configure oms',
      link: basePath('OMS-settings'),
      iconElement: (
        <FontAwesomeIcon
          icon={icon({
            name: 'bag-shopping',
            family: 'classic',
            style: 'solid'
          })}
          fontSize={'30px'}
          color={theme.palette.primary.main}
        />
      )
    })
  }

  if (authInfo.permissions?.includes('MANAGE_TAXES')) {
    configurationSettingsArray.push({
      title: 'Taxes',
      description: 'Setup tax rates and categories',
      link: basePath('tax-settings'),
      iconElement: (
        <FontAwesomeIcon
          icon={icon({
            name: 'building-columns',
            family: 'classic',
            style: 'solid'
          })}
          fontSize={'30px'}
          color={theme.palette.primary.main}
        />
      )
    })
  }

  if (authInfo.permissions?.includes('MANAGE_ROLES')) {
    managementSettingsArray.push({
      title: 'Roles and Permissions',
      description: 'Customize access and control',
      link: basePath('roles-and-permissions'),
      iconElement: (
        <FontAwesomeIcon
          icon={icon({
            name: 'users-gear',
            family: 'classic',
            style: 'solid'
          })}
          color={theme.palette.primary.main}
          fontSize={'30px'}
        />
      )
    })
  }

  if (authInfo.permissions?.includes('MANAGE_OAUTH_APPLICATIONS')) {
    configurationSettingsArray.push({
      title: 'Applications',
      description: 'Application description',
      link: basePath('oauth-applications'),
      iconElement: (
        <FontAwesomeIcon
          icon={icon({
            name: 'square-a-lock',
            family: 'classic',
            style: 'solid'
          })}
          fontSize={'30px'}
          color={theme.palette.primary.main}
        />
      )
    })
  }

  if (authInfo.permissions?.includes('MANAGE_BUSINESS_SETTINGS')) {
    configurationSettingsArray.push({
      title: 'Business',
      description: 'Business settings',
      link: basePath('business-settings'),
      iconElement: (
        <FontAwesomeIcon
          icon={icon({
            name: 'gear',
            family: 'classic',
            style: 'solid'
          })}
          fontSize={'30px'}
          color={theme.palette.primary.main}
        />
      )
    })
  }

  if (
    permissions.includes(Permission.READ_LOCATION_GROUP) &&
    featureFlags.locationGrouping
  ) {
    configurationSettingsArray.push({
      title: 'Location Grouping',
      description: 'Configure Location Grouping',
      link: basePath('location-grouping'),
      iconElement: (
        <LocationGroupingIcon
          width={'32px'}
          height={'32px'}
          fontWeight={'bolder'}
        />
      )
    })
  }

  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
      spacing={8}
    >
      <Grid
        item
        container
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start'
        }}
        spacing={1}
      >
        <Grid item>
          <Typography
            variant='body2'
            style={{
              fontSize: '24px'
            }}
          >
            Manage and Configure Settings
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant='subtitle1' color={'text.secondary'}>
            Add information about your store, employees, and customers{' '}
          </Typography>
        </Grid>
      </Grid>

      {!!managementSettingsArray.length && (
        <Grid
          item
          container
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
          spacing={2}
        >
          <Grid item>
            <Typography variant='h2'>Manage</Typography>
          </Grid>

          <Grid
            item
            container
            spacing={4}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'stretch'
            }}
          >
            {managementSettingsArray.map((setting, index) => (
              <SettingCard key={index} {...setting} />
            ))}
          </Grid>
        </Grid>
      )}

      {!!configurationSettingsArray.length && (
        <Grid
          item
          container
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
          spacing={2}
        >
          <Grid item>
            <Typography variant='h2'>Configure</Typography>
          </Grid>

          <Grid item container spacing={4}>
            {configurationSettingsArray.map((setting, index) => (
              <SettingCard key={index} {...setting} />
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default Settings
