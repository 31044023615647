import {useLazyQuery, useMutation} from '@apollo/client'
import {FormControl, Grid, InputLabel, Select} from '@mui/material'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {StyledMenuItem} from 'ui/molecules/StyledSelect'
import {GET_REGISTER_AND_TILL} from 'graphql/queries/cash-register-till.queries'
import {GET_ALL_EMPLOYEES} from 'graphql/queries/employee.queries'
import useNotify from 'hooks/useNotify'
import moment, {Moment} from 'moment'
import {v4 as uuid4} from 'uuid'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useAuth} from 'auth/AuthContext'
import {
  CASH_ADJUSTMENT_EVENT,
  EVENT_OPTIONS,
  REFUND_EVENT,
  REVIEW_EVENT,
  SALE_EVENT,
  TransactionType
} from 'constants/constants'
import {CREATE_CASH_TRANSACTION} from 'graphql/mutations/cash-management.mutations'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledInput from 'ui/atoms/StyledInput'
import Loading from 'ui/molecules/Loading'
import {amountInCents, parseAmount} from 'utils/currency.utils'

interface CashEventProps {
  modelOpen: boolean
  setModelOpen: any
}

const CashEventForm: React.FC<CashEventProps> = ({modelOpen, setModelOpen}) => {
  const {authInfo} = useAuth()
  const notify = useNotify()

  const {t} = useTranslation()

  const [transactionMutation] = useMutation(CREATE_CASH_TRANSACTION)

  const [getRegisterTill, {data: registerTillData}] = useLazyQuery(
    GET_REGISTER_AND_TILL,
    {fetchPolicy: 'no-cache'}
  )

  const [getEmployees, {loading: loadingEmployee, data: employeeData}] =
    useLazyQuery(GET_ALL_EMPLOYEES, {fetchPolicy: 'no-cache'})

  useEffect(() => {
    getEmployees({
      variables: {
        businessId: authInfo.businessId as string,
        locationId: authInfo.locationId as string,
        fetchAll: true
      }
    })
  }, [])

  useEffect(() => {
    getRegisterTill({
      variables: {
        businessId: authInfo.businessId as string,
        locationId: authInfo.locationId as string
      }
    })
  }, [])

  const [register, setRegister] = React.useState<string>('')
  const [till, setTill] = React.useState<string>('')
  const [employee, setEmployee] = React.useState<string>('')
  const [event, setEvent] = React.useState<string>('')
  const [eventAmount, setEventAmount] = React.useState<string>('0')
  const [description, setDescription] = React.useState<string>('')
  const [date, setDate] = React.useState<Moment | null>(moment().local())
  const [transactionType, setTransactionType] = React.useState<string>(
    TransactionType.DEBIT
  )
  const [eventOptions, setEventOptions] = React.useState<any[]>(EVENT_OPTIONS)

  const [transactionId, setTransactionId] = React.useState<string>(
    Math.random().toString(36).substr(2, 14)
  )

  const [eventId] = React.useState<string>(uuid4())

  useEffect(() => {
    if (!description) {
      setDescription(
        eventOptions.find((option) => option.value === event)?.label || ''
      )
    }
  }, [event])

  useMemo(() => {
    // if permission ALLOW_SENDING_TILL_REFUND_SALE_EVENT is not there then remove refund and sale event from EVENT_OPTIONS list
    if (
      !authInfo.permissions.includes('ALLOW_SENDING_TILL_REFUND_SALE_EVENT')
    ) {
      setEventOptions(
        EVENT_OPTIONS.filter(
          (option) =>
            option.value !== REFUND_EVENT && option.value !== SALE_EVENT
        )
      )
    }
  }, [authInfo.permissions])

  const submitTransaction = async () => {
    try {
      console.log('amount in cents', amountInCents(eventAmount))
      await transactionMutation({
        variables: {
          amount: amountInCents(eventAmount),
          location: authInfo.locationId,
          employeeCode: employee,
          till: till,
          register: register,
          businessDate: date?.local().format('YYYY-MM-DD'),
          eventType: event,
          description: description,
          transactionType:
            event === CASH_ADJUSTMENT_EVENT && transactionType
              ? transactionType
              : eventOptions.find((option) => option.value === event)
                  ?.transactionType,
          transactionId: transactionId,
          eventId: eventId
        }
      })
      setModelOpen(false)
      notify.show(t('cash-management.transactions.success'), 'success')
    } catch (error) {
      console.error('Mutation error:', error)
      notify.show(
        (error as any)?.message || 'Something went wrong. Please try again.',
        'error'
      )
    }
  }

  const getCreateTransactionFormBody = () => {
    return (
      <Grid container spacing={4} padding={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel id='register'>Register</InputLabel>
            <Select
              fullWidth
              required
              size='medium'
              label={'Register'}
              labelId='register'
              value={register}
              onChange={(e) => {
                setRegister(e.target.value as string)
              }}
              inputProps={{'aria-label': 'Without label'}}
            >
              {registerTillData?.fetchRegisterList?.map(
                (register: any, index: number) => (
                  <StyledMenuItem key={index} value={register.name}>
                    {register.name}
                  </StyledMenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel id='till'>Till</InputLabel>
            <Select
              fullWidth
              required
              size='medium'
              label={'Till'}
              value={till}
              onChange={(e) => {
                setTill(e.target.value as string)
              }}
              inputProps={{'aria-label': 'Without label'}}
            >
              {registerTillData?.fetchTills?.map((till: any, index: number) => (
                <StyledMenuItem key={index} value={till.name}>
                  {till.name}
                </StyledMenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel id='employee'>Employee</InputLabel>
            <Select
              fullWidth
              required
              size='medium'
              label={'Employee'}
              value={employee}
              onChange={(e) => {
                setEmployee(e.target.value as string)
              }}
              inputProps={{'aria-label': 'Without label'}}
            >
              {employeeData?.getAllEmployees?.employees.map(
                (employee: any, index: number) => (
                  <StyledMenuItem key={index} value={employee.employeeCode}>
                    {employee.name}
                  </StyledMenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel id='transaction-type'>Transaction Type</InputLabel>
            <Select
              fullWidth
              required
              size='medium'
              label={'Transaction Type'}
              value={event}
              onChange={(e) => {
                setEvent(e.target.value as string)
              }}
              inputProps={{'aria-label': 'Without label'}}
            >
              {eventOptions.map((eventOption, index) => (
                <StyledMenuItem key={index} value={eventOption.value}>
                  {eventOption.label}
                </StyledMenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledInput
            fullWidth
            required
            label={'Transaction Amount'}
            value={eventAmount}
            disabled={event === REVIEW_EVENT}
            type='number'
            InputProps={{
              startAdornment: t('currency-symbol')
            }}
            onChange={(e) => {
              setEventAmount(parseAmount(e.target.value))
            }}
            error={
              Number(eventAmount) < 0 ||
              (!eventAmount && event !== REVIEW_EVENT)
            }
            helperText={
              Number(eventAmount) < 0 ||
              (!eventAmount && event !== REVIEW_EVENT)
                ? 'Invalid amount'
                : ''
            }
          />
        </Grid>

        {event === CASH_ADJUSTMENT_EVENT && (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel id='transaction-type'>Type</InputLabel>
              <Select
                fullWidth
                required
                size='medium'
                label={'Type'}
                value={transactionType}
                onChange={(e) => {
                  setTransactionType(e.target.value as string)
                }}
                inputProps={{'aria-label': 'Without label'}}
              >
                {Object.entries(TransactionType).map(([key, value]) => (
                  <StyledMenuItem key={key} value={value}>
                    {key}
                  </StyledMenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <StyledInput
            fullWidth
            required
            label={'Transaction Id'}
            value={transactionId}
            type='text'
            onChange={(e) => {
              setTransactionId(e.target.value as string)
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label='Business Date'
              value={date}
              onChange={(newValue) => {
                setDate(newValue)
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  required: true,
                  label: 'Business Date'
                }
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12}>
          <StyledInput
            fullWidth
            label={'Description'}
            value={description}
            type='text'
            onChange={(e) => {
              setDescription(e.target.value as string)
            }}
            multiline
            minRows={3}
            maxRows={4}
          />
        </Grid>
      </Grid>
    )
  }

  if (loadingEmployee) {
    return <Loading />
  }

  return (
    <>
      <StyledDialog
        open={modelOpen}
        title='Create New Transaction'
        body={getCreateTransactionFormBody()}
        onClose={() => {
          setModelOpen(true)
        }}
        successButtonName='Save'
        cancelButtonName='Dismiss'
        cancelCallback={() => {
          setModelOpen(false)
        }}
        disableSuccessButton={
          !register ||
          !till ||
          !employee ||
          !event ||
          !date ||
          !transactionId ||
          Number(eventAmount) < 0 ||
          (!eventAmount && event !== REVIEW_EVENT)
        }
        successCallback={submitTransaction}
      />
    </>
  )
}

export default CashEventForm
