const LoginRequired = () => {
  return (
    <div style={{textAlign: 'center', marginTop: '50px'}}>
      <h2>Okta Login Error</h2>
      <p>
        To login with Okta, please provide oktaIssuer and oktaClientId as query
        params in the URL.
      </p>
    </div>
  )
}

export default LoginRequired
