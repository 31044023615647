import {useLazyQuery} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Grid, IconButton, Menu, MenuItem, Typography} from '@mui/material'
import EditPeripheralHubNotificationsDialog from 'clientDashboard/peripheralHub/EditPeripheralHubNotificationsDialog'
import StyledTable from 'ui/molecules/StyledTable'
import {REFETCH_INTERVAL} from 'constants/constants'
import {Permission} from 'constants/permissions'
import usePeripheralHubNotificationConfig from 'hooks/usePeripheralHubNotifications'
import usePermission from 'hooks/usePermission'
import {MRT_ColumnDef} from 'material-react-table'
import moment from 'moment'
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {convertToLocalTimeString, diffInMinutes} from 'utils/common'

import {useAuth} from 'auth/AuthContext'
import NotificationBar from 'clientDashboard/peripheralHub/NotificationBar'
import StyledChip from 'ui/atoms/StyledChip'
import {space} from 'config/theme'
import {GET_ERROR_NOTIFICATION} from 'graphql/queries/peripheral-hub.queries'

type NotificationData = {
  timeNotified: string
  timeResolved: string
  timeElapsed: string
  deviceName: string
  location: string
  issueDescription: string
  action: string
  severity: string
}

const PeripheralHubNotifications = () => {
  const {t} = useTranslation()
  const {authInfo} = useAuth()

  const hasNotificationConfigPermission = usePermission(
    Permission.MANAGE_PH_NOTIFICATION_CONFIG
  )

  const {
    peripheralHubNotificationConfig,
    updatePeripheralHubNotificationConfig
  } = usePeripheralHubNotificationConfig()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const [notificationAlerts, setNotificationAlerts] = React.useState<any[]>([])

  const [openEditNotificationsDialog, setOpenEditNotificationsDialog] =
    useState(false)

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0
  })

  const [
    getErrorNotifications,
    {
      loading: loadingErrorNotifications,
      data: errorNotifications,
      error: errorNotificationsError
    }
  ] = useLazyQuery(GET_ERROR_NOTIFICATION, {fetchPolicy: 'no-cache'})

  const fetchErrorNotifications = () => {
    getErrorNotifications({
      variables: {
        businessId: authInfo.businessId as string,
        locationId: authInfo.locationId as string
      }
    })
  }

  useEffect(() => {
    fetchErrorNotifications()
    const interval = setInterval(fetchErrorNotifications, REFETCH_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!errorNotifications?.getErrorNotifications?.length) return
    setNotificationAlerts(errorNotifications.getErrorNotifications)
  }, [errorNotifications])

  const columns = useMemo<MRT_ColumnDef<NotificationData>[]>(
    () => [
      {
        accessorKey: 'timeNotified',
        header: t('peripheral-hub.notifications.table-columns.time-notified'),
        Cell: ({cell}: any) => {
          return cell.getValue()
            ? convertToLocalTimeString(cell.getValue())
            : '-'
        }
      },
      {
        accessorKey: 'timeResolved',
        header: t('peripheral-hub.notifications.table-columns.time-resolved'),
        Cell: ({cell}: any) => {
          return cell.getValue()
            ? convertToLocalTimeString(cell.getValue())
            : '-'
        }
      },
      {
        accessorKey: 'timeElapsed',
        header: t('peripheral-hub.notifications.table-columns.time-elapsed'),
        Cell: ({cell, row}: any) => {
          return row.original.timeResolved
            ? diffInMinutes(
                row.original.timeNotified,
                row.original.timeResolved
              )
            : '-'
        }
      },
      {
        accessorKey: 'deviceName',
        header: t('peripheral-hub.notifications.table-columns.device-name')
      },
      {
        accessorKey: 'zoneName',
        header: t('peripheral-hub.notifications.table-columns.zone-name')
      },
      {
        accessorKey: 'severity',
        header: t('peripheral-hub.notifications.table-columns.severity'),
        Cell: ({cell}) =>
          cell.getValue() === 'Healthy' ? (
            <StyledChip
              label={t(
                'peripheral-hub.notifications.severity-chip-name.active'
              )}
              color='success'
            />
          ) : cell.getValue() === 'Warning' ? (
            <StyledChip
              label={t(
                'peripheral-hub.notifications.severity-chip-name.warning'
              )}
              color='warning'
            />
          ) : (
            <StyledChip
              label={t(
                'peripheral-hub.notifications.severity-chip-name.critical'
              )}
              color='error'
            />
          )
      },
      {
        accessorKey: 'issueDescription',
        header: t(
          'peripheral-hub.notifications.table-columns.issue-description'
        )
      },
      {
        accessorKey: 'action',
        header: t('peripheral-hub.notifications.table-columns.action')
      }
    ],
    []
  )

  if (errorNotificationsError)
    return <div>Error... {errorNotificationsError.message}</div>

  return (
    <Grid
      container
      spacing={4}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      {notificationAlerts
        .filter((notification) => !notification.timeResolved)
        .map((notification, index) => {
          return (
            <Grid item key={index}>
              <NotificationBar
                action={notification.action}
                deviceName={notification.deviceName}
                issue={notification.issueDescription}
                lastActive={convertToLocalTimeString(notification.timeNotified)}
                location={notification.zoneName}
                time={moment(
                  convertToLocalTimeString(notification.timeNotified)
                ).fromNow()}
                activityId={notification.activityId}
                getErrorNotifications={getErrorNotifications}
              />
            </Grid>
          )
        })}

      <Grid
        item
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <FontAwesomeIcon
          style={{
            marginRight: space.SMALL
          }}
          icon={icon({
            name: 'wave-pulse',
            family: 'classic',
            style: 'solid'
          })}
        ></FontAwesomeIcon>
        <Typography variant='h2'>
          {t('peripheral-hub.notifications.notification-history-heading')}
        </Typography>

        {hasNotificationConfigPermission && (
          <IconButton
            disableRipple
            disableTouchRipple
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              setAnchorEl(event.currentTarget)
            }}
            disabled={!hasNotificationConfigPermission}
            aria-controls={open ? 'notification-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <FontAwesomeIcon
              style={{
                marginLeft: space.SMALL
              }}
              icon={icon({
                name: 'ellipsis-h',
                family: 'classic',
                style: 'solid'
              })}
            ></FontAwesomeIcon>
          </IconButton>
        )}
      </Grid>

      <StyledTable
        columns={columns}
        data={notificationAlerts as any}
        onPaginationChange={(pagination) => {
          setPagination(pagination)
        }}
        state={{
          showProgressBars: loadingErrorNotifications,
          pagination: {
            pageSize: pagination.pageSize,
            pageIndex: pagination.pageIndex
          }
        }}
      />

      <Menu
        anchorEl={anchorEl}
        id='notification-menu'
        open={open}
        onClose={() => {
          setAnchorEl(null)
        }}
        onClick={() => {
          setAnchorEl(null)
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              borderRadius: '9.6px'
            }
          }
        }}
        transformOrigin={{horizontal: 'left', vertical: 'top'}}
        anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
      >
        <MenuItem
          onClick={() => {
            setOpenEditNotificationsDialog(true)
            setAnchorEl(null)
          }}
        >
          <Typography variant='subtitle1'>
            {t('peripheral-hub.notifications.edit-notifications')}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            updatePeripheralHubNotificationConfig({
              ...peripheralHubNotificationConfig,
              enabled: !peripheralHubNotificationConfig.enabled
            })
            setAnchorEl(null)
          }}
        >
          <Typography variant='subtitle1'>
            {peripheralHubNotificationConfig.enabled
              ? t('peripheral-hub.notifications.mute-notifications')
              : t('peripheral-hub.notifications.unmute-notifications')}
          </Typography>
        </MenuItem>
      </Menu>

      <EditPeripheralHubNotificationsDialog
        open={openEditNotificationsDialog}
        setOpen={setOpenEditNotificationsDialog}
        devices={[]}
      />
    </Grid>
  )
}

export default PeripheralHubNotifications
