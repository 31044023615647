import {FormControl, InputLabel, Select, MenuItem} from '@mui/material'

interface DurationPickerProps {
  duration: string
  setDuration: (duration: string) => void
}

const DurationPicker = ({duration, setDuration}: DurationPickerProps) => {
  const durations = [
    {name: 'Today', id: 'today'},
    {name: 'Yesterday', id: 'yesterday'},
    {name: 'This month', id: 'thismonth'},
    {name: 'Last month', id: 'lastmonth'},
    {name: 'Last 3 months', id: 'last3months'},
    {name: 'Last 6 months', id: 'last6months'}
  ]
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id='duration'>Duration</InputLabel>

        <Select
          size='medium'
          label={'Duration'}
          labelId={'duration'}
          value={duration || ''}
          onChange={(e: any) => setDuration(e.target.value)}
        >
          {(durations || []).map(({name, id}: {name: string; id: string}) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}
export default DurationPicker
