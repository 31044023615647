import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Card, CardContent, Grid, IconButton, Typography} from '@mui/material'
import theme from 'config/theme'
import React from 'react'
import {getHealthIcon} from 'utils/common'

import StyledChip from 'ui/atoms/StyledChip'

type ActivityLogHeaderProps = {
  id: string
  name: string
  status: string
  metadata: any
}

const ActivityLogHeader: React.FC<ActivityLogHeaderProps> = ({
  id,
  name,
  status,
  metadata
}) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        backgroundColor: theme.palette.primary.light,
        borderRadius: '12px',
        padding: '24px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        {getHealthIcon(status)}
        <StyledChip
          label={<Typography variant='h2'>{name}</Typography>}
          color='primary'
        />
        <IconButton>
          <FontAwesomeIcon
            icon={icon({
              name: 'ellipsis-h',
              family: 'classic',
              style: 'light'
            })}
          />
        </IconButton>
      </Grid>

      {/* The parent container that will ensure all cards match the height of the tallest card. */}
      <Grid
        item
        xs={12}
        md={6}
        container
        spacing={2}
        style={{
          alignSelf: 'flex-end',
          justifyContent: 'flex-end'
        }}
      >
        <Grid item xs={12} md={4} sx={{display: 'flex'}}>
          <Card sx={{borderRadius: '12px', width: '100%'}}>
            <CardContent sx={{height: '100%'}}>
              {Object.keys(metadata).map((key, index) => {
                return (
                  <Grid
                    key={index}
                    container
                    gap={2}
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Grid>
                      <Typography key={index} variant='h2'>
                        {key}:
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        key={index}
                        variant='h2'
                        color={metadata[key] ? 'red' : 'green'}
                      >
                        {metadata[key] ? 'Yes' : 'No'}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              })}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ActivityLogHeader
