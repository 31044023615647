import {gql} from '@apollo/client'

export const ADD_UPDATE_SERVICE_CONFIG = gql`
  mutation AddUpdateServiceConfig($input: ServiceConfigInput!) {
    serviceConfig(input: $input)
  }
`

export const VERIFY_SERVICE_CONFIG = gql`
  mutation VerifyServiceConfig($input: ServiceConfigInput!) {
    verifyServiceConfig(input: $input)
  }
`
