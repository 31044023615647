import {
  DeviceDataType,
  RegisterDataType,
  ZoneType
} from 'types/peripheral-hub.types'

export function getFormattedZoneData(
  zone: ZoneType,
  registerData: RegisterDataType,
  deviceData: DeviceDataType
) {
  return [
    ...zone.registers.map((zoneRegisterId) => ({
      registers: [
        {
          name: registerData[zoneRegisterId].register_name
        }
      ],
      devices: registerData[zoneRegisterId].devices.map((zoneDeviceId) => ({
        name: deviceData[zoneDeviceId].device_name,
        id: zoneDeviceId,
        status: deviceData[zoneDeviceId].status
      }))
    })),
    ...zone.devices.map((zoneDeviceId) => ({
      registers: [],
      devices: [
        {
          name: deviceData[zoneDeviceId].device_name,
          status: deviceData[zoneDeviceId].status,
          id: zoneDeviceId
        }
      ]
    }))
  ]
}
