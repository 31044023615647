import {
  AvailableElementType,
  DeviceDataType,
  RegisterDataType,
  ZoneType
} from 'clientDashboard/peripheralHub/types/peripheral-hub.types'
import _cloneDeep from 'lodash/cloneDeep'
import {
  ALL_DEVICES,
  ALL_REGISTERS,
  ALL_ZONES,
  UNASSIGNED
} from 'constants/constants'
import _findIndex from 'lodash/findIndex'
import _sortBy from 'lodash/sortBy'

export function getUpdatedZonesData(
  zoneData: ZoneType[],
  editZoneData: ZoneType,
  selectedItems: any
) {
  const _zoneData: typeof zoneData = JSON.parse(JSON.stringify(zoneData))
  editZoneData = _cloneDeep(editZoneData)
  // modify editZoneData.registers and editZoneData.devices based on selectedItems
  // 1. if the item is not present in selectedItems, but present in editZoneData.registers, remove it from editZoneData.registers
  // 2. if the item is not present in selectedItems, but present in editZoneData.devices, remove it from editZoneData.devices
  // 3. if the item is present in selectedItems, but not present in editZoneData.registers, add it to editZoneData.registers
  //    3i. if the item is UNASSIGNED, do nothing extra
  //    3ii.if the item is part of a different zone, remove it from that zone's registers
  // 4. if the item is present in selectedItems, but not present in editZoneData.devices, add it to editZoneData.devices
  //    4i. if the item is UNASSIGNED, do nothing extra
  //    4ii.if the item is part of a different zone, remove it from that zone's devices

  // Update registers
  const _selectedItems = Object.values(
    selectedItems || {}
  ) as AvailableElementType[]

  // Note: selectedItems is only populated in Zone Devices Tab
  const selectedRegisterIds = editZoneData.hasOwnProperty('selectedItems') ? _selectedItems
    .filter((item) => item.registerId !== UNASSIGNED)
    .map((item) => item.registerId) : editZoneData.registers

  const _removedRegisterIds = editZoneData.registers.filter(
    (registerId) => !selectedRegisterIds.includes(registerId)
  )

  editZoneData.registers = editZoneData.registers.filter((registerId) =>
    selectedRegisterIds.includes(registerId)
  )

  selectedRegisterIds.forEach((registerId) => {
    if (!editZoneData.registers.includes(registerId)) {
      _zoneData.forEach((zone) => {
        if (
          zone.id !== editZoneData.id &&
          zone.registers.includes(registerId)
        ) {
          zone.registers = zone.registers.filter((id) => id !== registerId)
        }
      })
      editZoneData.registers.push(registerId)
    }
  })

  // add _removedRegisterIds to UNASSIGNED zone registers in _zoneData
  _removedRegisterIds.forEach((registerId) => {
    _zoneData.forEach((zone) => {
      if (zone.id === UNASSIGNED && !zone.registers.includes(registerId)) {
        zone.registers.push(registerId)
      }
    })
  })

  const selectedDeviceIds = _selectedItems
    .filter((item) => item.deviceId !== UNASSIGNED)
    .map((item) => item.deviceId)

  const _removedDeviceIds = editZoneData.devices.filter(
    (deviceId) => !selectedDeviceIds.includes(deviceId)
  )

  editZoneData.devices = editZoneData.devices.filter((deviceId) =>
    selectedDeviceIds.includes(deviceId)
  )

  selectedDeviceIds.forEach((deviceId) => {
    if (!editZoneData.devices.includes(deviceId)) {
      // If the item is part of a different zone, remove it from that zone's devices
      _zoneData.forEach((zone) => {
        if (zone.id !== editZoneData.id && zone.devices.includes(deviceId)) {
          zone.devices = zone.devices.filter((id) => id !== deviceId)
        }
      })
      editZoneData.devices.push(deviceId)
    }
  })

  // add _removedDeviceIds to UNASSIGNED zone devices in _zoneData
  _removedDeviceIds.forEach((deviceId) => {
    _zoneData.forEach((zone) => {
      if (zone.id === UNASSIGNED && !zone.devices.includes(deviceId)) {
        zone.devices.push(deviceId)
      }
    })
  })

  if (editZoneData.id) {
    const editIndex = _findIndex(_zoneData, {id: editZoneData.id})
    _zoneData[editIndex] = editZoneData
  } else {
    _zoneData.push(editZoneData)
  }

  const sortedZoneData = _sortBy(_zoneData, 'name')
  const unassignedIndex = _findIndex(sortedZoneData, {id: UNASSIGNED})
  if (unassignedIndex > -1) {
    const unassignedZone = sortedZoneData[unassignedIndex]
    sortedZoneData.splice(unassignedIndex, 1)
    sortedZoneData.push(unassignedZone)
  }

  return [_zoneData, editZoneData]
}

export function getAvailableElements(
  registerData: RegisterDataType,
  zoneData: ZoneType[],
  deviceData: DeviceDataType,
  currentZone: ZoneType
) {
  // include below items
  // 1. all registers {id:"<registerId>", zoneId: "<zoneId> || UNASSIGNED"}
  // 2. all standalone devices not assigned to any register {id:"<deviceId>", zoneId: "<zoneId> || UNASSIGNED", registerId: "<registerId || UNASSIGNED>"}
  // 3. do not include devices that are already assigned to a register

  const _availableElements: AvailableElementType[] = []
  Object.keys(registerData).forEach((registerId) => {
    const zoneId =
      zoneData.find((zone) => zone.registers.includes(registerId))?.id ||
      UNASSIGNED
    _availableElements.push({
      registerId: registerId,
      zoneId: zoneId,
      deviceId: UNASSIGNED,
      zoneName: zoneData.find((zone) => zone.id === zoneId)?.name || '',
      registerName: registerData[registerId]?.register_name || '',
      deviceName: ''
    })
  })

  const assignedDevices = new Set(
    Object.values(registerData).flatMap((register) => register.devices)
  )

  Object.keys(deviceData).forEach((deviceId) => {
    if (!assignedDevices.has(deviceId)) {
      const zoneId =
        zoneData.find((zone) => zone.devices.includes(deviceId))?.id ||
        UNASSIGNED
      _availableElements.push({
        deviceId: deviceId,
        zoneId: zoneId,
        registerId: UNASSIGNED,
        zoneName: zoneData.find((zone) => zone.id === zoneId)?.name || '',
        registerName: '',
        deviceName: deviceData[deviceId]?.device_name || ''
      })
    }
  })

  const _zoneId = currentZone.id

  // keep the zoneId of the current zone at the top
  return _availableElements.sort((a, b) => {
    return (a.zoneId === _zoneId ? -1 : 0) - (b.zoneId === _zoneId ? -1 : 0)
  })
}

export function getFilteredAvailableElements(
  availableElements: Array<AvailableElementType>,
  filteredZoneId: string,
  filteredDeviceId: string,
  filteredRegisterId: string
) {
  let _filteredAvailableElements: AvailableElementType[] = JSON.parse(
    JSON.stringify(availableElements)
  )
  if (filteredZoneId !== ALL_ZONES) {
    _filteredAvailableElements = _filteredAvailableElements.filter((item) => {
      return item.zoneId === filteredZoneId
    })
  }
  if (filteredDeviceId !== ALL_DEVICES) {
    _filteredAvailableElements = _filteredAvailableElements.filter((item) => {
      return item.deviceId === filteredDeviceId
    })
  }
  if (filteredRegisterId !== ALL_REGISTERS) {
    _filteredAvailableElements = _filteredAvailableElements.filter((item) => {
      return item.registerId === filteredRegisterId
    })
  }

  return _filteredAvailableElements
}

export function getFilteredElementsBySearch(
  items: AvailableElementType[],
  searchTerm: string
) {
  return items.filter((item) => {
    if (searchTerm === '') {
      return true
    }
    return (
      item.zoneName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item.deviceId !== UNASSIGNED &&
        item.deviceName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.registerId !== UNASSIGNED &&
        item.registerName.toLowerCase().includes(searchTerm.toLowerCase()))
    )
  })
}
