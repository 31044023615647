import React, {useEffect, useRef} from 'react'

interface HtmlIframeProps {
  htmlString: string
}

const HtmlIframe: React.FC<HtmlIframeProps> = ({htmlString}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const iframeDoc =
      iframeRef.current?.contentDocument ||
      iframeRef.current?.contentWindow?.document
    if (iframeDoc) {
      iframeDoc.open()
      iframeDoc.write(htmlString)
      iframeDoc.close()
    }
  }, [htmlString])

  return (
    <iframe
      ref={iframeRef}
      style={{width: '100%', height: '500px', border: 'none'}}
      title='HTML Content'
    />
  )
}

export default HtmlIframe
