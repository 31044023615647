import {graphql} from 'graphql/generatedTypes'

export const GET_LOCATIONS = graphql(`
  query getLocations(
    $businessId: String!
    $storeGroupIds: [String!]
    $locationIds: [String!]
  ) {
    getAllLocations(
      businessId: $businessId
      storeGroupIds: $storeGroupIds
      locationIds: $locationIds
    ) {
      id
      name
      brand
      address {
        street
        city
        state
        zipCode
        country
      }
      phoneNumbers {
        contactPhone
        supportPhone
      }
      description
      storeNumber
      operatingHours {
        day
        fromHour
        toHour
      }
      timeZone
      locationConfig {
        stripe {
          displayName
          stripeTerminalLocationId
        }
      }
      notificationEmails
      status
      storeGroups {
        id
        name
        status
        storeGroupCluster {
          id
          name
          hierarchyLevel
        }
      }
    }
  }
`)

export const GET_LOCATIONS_WITH_GROUPS = graphql(`
query getLocationsWithGroups($businessId: String!, $status: String) {
  getAllLocations(businessId: $businessId, status: $status) {
    id
    name
    storeGroups {
      id
      name
      status
      storeGroupCluster {
        id
        name
        hierarchyLevel
      }
    }
  }
}
`)
