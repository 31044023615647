import {Store} from 'react-notifications-component'

function show(message: any, type: any, duration = 5000) {
  Store.removeAllNotifications()
  Store.addNotification({
    message: message,
    type: type === 'error' ? 'danger' : type,
    insert: 'bottom',
    container: 'bottom-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: duration,
      pauseOnHover: true,
      click: false,
      touch: false
    }
  })
}

let notify = {
  show
}

export {notify}
