import {useLazyQuery} from '@apollo/client'
import {Grid} from '@mui/material'
import AtlasDashboard from 'clientDashboard/cashManagement/AtlasDashboard'
import CashManagementStatisticsHeader from 'clientDashboard/cashManagement/cashManagerInsights/CashManagementStatisticsHeader'
import DurationPicker from 'clientDashboard/cashManagement/DurationPicker'
import InsightsLocationPicker from 'clientDashboard/cashManagement/InsightsLocationPicker'
import {GET_INSIGHT} from 'graphql/queries/insights.queries'
import {useEffect, useState} from 'react'
import {
  generateRangeFromDuration,
  getDurationTypeByDurationId
} from 'utils/date.utils'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectCurrentStore} from 'clientDashboard/employee.selectors'

function InsightsDashboard() {
  const currentStore = useAppSelector(selectCurrentStore)
  const [baseStore, setBaseStore] = useState<any>(currentStore)
  const [duration, setDuration] = useState('today')
  const [dashboardData, setDashboardData] = useState<any>([])

  const [getInsights, {data, loading, error}] = useLazyQuery(GET_INSIGHT, {
    onCompleted: (data) => {
      setDashboardData(data.getInsights)
    },
    onError: (error) => {
      console.log('insights error', error)
    }
  })
  useEffect(() => {
    const durationType = getDurationTypeByDurationId(duration)
    const range = generateRangeFromDuration(duration)
    let variables = {
      insightTypes: 'cash-management,sales',
      durationType: durationType,
      storeNumbers: baseStore?.storeNumber,
      locationIds: baseStore?.id,
      duration: range.join(',')
    }
    getInsights({
      variables: variables
    })
  }, [baseStore.storeNumber, duration])

  return (
    <Grid
      key='main-grid'
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
        alignSelf: 'stretch',
        alignItems: 'flex-start',
        gap: '24px',
        flex: '1 0 0'
      }}
    >
      <Grid
        key='filter-grid'
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '12px',
          flex: '1 0 0'
        }}
      >
        <Grid
          item
          style={{
            width: '200px'
          }}
        >
          <InsightsLocationPicker
            label={'Location'}
            store={baseStore}
            setStore={setBaseStore}
          />
        </Grid>
        <Grid
          item
          style={{
            width: '200px'
          }}
        >
          <DurationPicker duration={duration} setDuration={setDuration} />
        </Grid>
      </Grid>
      <Grid
        key={'dashboard-grid'}
        item
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px'
        }}
      >
        {dashboardData &&
          dashboardData.map((dashboard: any, index: number) => (
            <Grid key={dashboard.dashboardId}>
              <CashManagementStatisticsHeader
                title={dashboard.dashboardTitle}
                subtitle={dashboard.dashboardSubtitle}
              />
              <Grid item key={index} test-id={`full-atlas-${index}`}>
                <AtlasDashboard data={dashboard} />
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Grid>
  )
}
export default InsightsDashboard
