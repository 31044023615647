import {Grid} from '@mui/material'
import ConsumerAgentHotkeys from 'consumerAgent/ConsumerAgentHotkeys'
import ConsumerAgentPayments from 'consumerAgent/ConsumerAgentPayments'
import ConsumerAgentQueryInput, {
  ConsumerAgentQueryInputRef
} from 'consumerAgent/ConsumerAgentQueryInput'
import ConsumerAgentChat from 'consumerAgent/ConsumerAgentChat'
import {space} from 'config/theme'
import {Fragment, useEffect, useRef, useState} from 'react'
import useConsumerAgent from 'consumerAgent/ConsumerAgentContext'

const ConsumerAgentChatConversation = () => {
  const queryInputRef = useRef<ConsumerAgentQueryInputRef>(null)

  const [text, setText] = useState('')
  const {chatMessages, cartItems, submitGenieQuery, scrollRef, scrollToBottom} =
    useConsumerAgent()

  const submitText = () => {
    if (!text) return

    submitGenieQuery(text)
    setText('')
    scrollToBottom()
  }

  useEffect(() => {
    scrollToBottom()
  }, [])

  const focusOnInput = () => {
    if (queryInputRef.current) {
      queryInputRef.current.focusOnInput()
    }
  }

  return (
    <Fragment>
      <Grid
        item
        xs
        sx={{
          flexGrow: 1,
          overflowY: 'scroll',
          display: 'flex',
          p: 2
        }}
        padding={space.SMALL}
      >
        <Grid item width={'100%'} marginTop={'auto'}>
          <Grid container width={'100%'}>
            <ConsumerAgentHotkeys
              setText={(text) => {
                setText(text)
                focusOnInput()
              }}
            />

            {chatMessages.map((_, index) => (
              <ConsumerAgentChat
                key={index}
                index={index}
                setText={setText}
                focusOnInput={focusOnInput}
              />
            ))}
          </Grid>
          <div ref={scrollRef} />
        </Grid>
      </Grid>

      {!!cartItems.length && <ConsumerAgentPayments />}

      <ConsumerAgentQueryInput
        ref={queryInputRef}
        text={text}
        setText={setText}
        submitText={submitText}
      />
    </Fragment>
  )
}

export default ConsumerAgentChatConversation
