import {graphql} from 'graphql/generatedTypes'

// To fetch till level data
export const GET_OAUTH_APPLICATIONS = graphql(`
  query getOauthApplications($locationId: String) {
    getOauthApplications(locationId: $locationId) {
      applications {
        appName
        clientId
        clientSecret
        status
      }
      locationId
      message
      success
    }
  }
`)
