import {ApolloError, LazyQueryExecFunction, useLazyQuery} from '@apollo/client'
import {Exact, GetAdminUserQuery} from 'graphql/generatedTypes/graphql'
import {GET_ADMIN_USER} from 'graphql/queries/employee.queries'
import useNotify from 'hooks/useNotify'

import {useAuth} from 'auth/AuthContext'

type UseAdminEmployeeType = {
  getEmployee: LazyQueryExecFunction<
    GetAdminUserQuery,
    Exact<{[key: string]: never}>
  >
  loadingEmployee: boolean
  errorInGetEmployee: ApolloError | undefined
}

const useAdminEmployee = (): UseAdminEmployeeType => {
  const {updateAuthInfo} = useAuth()
  const notify = useNotify()

  const handleAdminEmployeeData = (data: any) => {
    if (!data?.getAdminUser) {
      notify.show('Admin User not found', 'error')
      return
    }
    updateAuthInfo({
      employeeId: data?.getAdminUser?.id as string,
      userName: data?.getAdminUser?.name as string,
      userEmail: data?.getAdminUser?.email as string
    })
  }

  const handleAdminEmployeeDataError = (error: any) => {
    console.error('Error while fetching employee info: ', error)
    notify.show(error.message, 'error')
  }

  const [getEmployee, {loading: loadingEmployee, error: errorInGetEmployee}] =
    useLazyQuery(GET_ADMIN_USER, {
      fetchPolicy: 'no-cache',
      onCompleted: handleAdminEmployeeData,
      onError: handleAdminEmployeeDataError
    })

  return {getEmployee, loadingEmployee, errorInGetEmployee}
}

export default useAdminEmployee
