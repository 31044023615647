import {Grid, Typography} from '@mui/material'
import {space} from 'config/theme'
import {t} from 'i18next'
import StyledInput from 'ui/atoms/StyledInput'
import React from 'react'
import {
  useAppDispatch,
  useAppSelector
} from 'clientDashboard/clientDashboard.store'
import {selectEditZoneModalData} from 'clientDashboard/peripheralHub/peripheralHub.selectors'
import {updateEditZoneModalData} from 'clientDashboard/peripheralHub/peripheralHub.slice'

const ZoneInfoTab = () => {
  const dispatch = useAppDispatch()

  const editZone = useAppSelector(selectEditZoneModalData)
  const {name, description} = editZone || {}

  const onChange = (key: string, value: string) => {
    dispatch(
      updateEditZoneModalData({
        [key]: value
      })
    )
  }

  return (
    <Grid
      container
      spacing={2}
      style={{
        padding: space.MEDIUM,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Grid
        item
        container
        style={{
          padding: space.MEDIUM,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Grid item>
          <Typography variant='subtitle2'>
            {t('peripheral-hub.zone-manager.zone-name')}
          </Typography>
        </Grid>

        <Grid item>
          <StyledInput
            value={name || ''}
            onChange={(e) => onChange('name', e.target.value)}
            placeholder='Name'
            type='text'
          />
        </Grid>
      </Grid>

      <Grid
        item
        container
        style={{
          padding: space.MEDIUM,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Grid item>
          <Typography variant='subtitle2'>
            {t('peripheral-hub.zone-manager.zone-description')}
          </Typography>
        </Grid>

        <Grid item>
          <StyledInput
            value={description || ''}
            onChange={(e) => onChange('description', e.target.value)}
            placeholder='Description'
            multiline
            minRows={3}
            maxRows={4}
            style={{
              minWidth: '200px'
            }}
            type='text'
            size='medium'
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ZoneInfoTab
