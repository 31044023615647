import {useLazyQuery, useMutation} from '@apollo/client'
import {Grid, Typography} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import Loading from 'ui/molecules/Loading'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledInput from 'ui/atoms/StyledInput'
import {TillStatus} from 'constants/constants'
import {CREATE_CASH_DEPOSIT} from 'graphql/mutations/cash-management.mutations'
import {GET_REGISTER_STATUS} from 'graphql/queries/cash-management.queries'
import useNotify from 'hooks/useNotify'
import {t} from 'i18next'
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {centsToDollar} from 'utils/common'
import {
  amountInCents,
  isInvalidInputAmount,
  parseAmount
} from 'utils/currency.utils'

interface CashDepositProps {
  setModelOpen: any
  openModel: boolean
  safeTotalAmount: number
}

const CashDepositForm: React.FC<CashDepositProps> = ({
  openModel,
  setModelOpen,
  safeTotalAmount = 0
}) => {
  const {authInfo} = useAuth()
  const [closedTill, setClosedTill] = useState(false)

  const notify = useNotify()
  const history = useHistory()

  const [
    getRegisterStatus,
    {loading: loadingRegisterData, data: registerStatusData, error}
  ] = useLazyQuery(GET_REGISTER_STATUS, {fetchPolicy: 'cache-and-network'})

  const [createBankDeposit] = useMutation(CREATE_CASH_DEPOSIT)

  useEffect(() => {
    getRegisterStatus({
      variables: {
        businessId: authInfo.businessId as string,
        locationId: authInfo.locationId as string
      }
    })
  }, [])

  const [depositCash, setDepositCash] = React.useState<string>('0')

  const storeFloatAmount = authInfo.cashManagementConfig?.storeFloatAmount

  useEffect(() => {
    if (registerStatusData) {
      if (registerStatusData?.getTillStatus === TillStatus.CLOSED) {
        setClosedTill(true)
      }
    }
  }, [registerStatusData])

  const handleOnclick = () => {
    let value = safeTotalAmount - storeFloatAmount
    if (value < 0) {
      value = 0
    }
    setDepositCash(centsToDollar(value).toString())
  }

  const handleBankDeposit = () => {
    createBankDeposit({
      variables: {
        location: authInfo.locationId,
        amount: amountInCents(depositCash)
      },
      onCompleted: () => {
        notify.show(t('cash-management.bank-deposit.success'), 'success')
        setModelOpen(false)
        history.push(`/${authInfo.businessId}/cash-management/summary`, {
          enableHistory: true
        })
      },
      onError: (error) => {
        notify.show(
          (error as any)?.message || t('something-went-wrong'),
          'error'
        )
      }
    })
  }

  const handleCancel = () => {
    setModelOpen(false)
    setDepositCash('0')
  }

  const isInvalidAmount =
    isInvalidInputAmount(depositCash) ||
    Number(depositCash) > centsToDollar(safeTotalAmount)

  const confirmBox = () => {
    return (
      <Grid>
        <Typography variant='body1' style={{textAlign: 'left'}}>
          Creating a bank deposit is only recommended when all registers are
          closed.
        </Typography>
        <Typography variant='body1' style={{textAlign: 'left'}}>
          Proceed anyways?
        </Typography>
      </Grid>
    )
  }

  const depositForm = () => {
    return (
      <>
        <Grid container spacing={4} padding={2}>
          <Grid item xs={12} sm={12}>
            <StyledInput
              fullWidth
              label='Till Safe Total'
              value={centsToDollar(safeTotalAmount)}
              disabled={true}
              type='number'
              InputProps={{
                startAdornment: t('currency-symbol')
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <StyledInput
              fullWidth
              label='Store Float'
              value={centsToDollar(storeFloatAmount)}
              disabled={true}
              type='number'
              InputProps={{
                startAdornment: t('currency-symbol')
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <StyledInput
              fullWidth
              required
              label='Cash Deposit'
              value={depositCash}
              type='number'
              InputProps={{
                startAdornment: t('currency-symbol')
              }}
              onChange={(e) => {
                setDepositCash(parseAmount(e.target.value))
              }}
              onClick={handleOnclick}
              error={isInvalidAmount}
              helperText={isInvalidAmount ? 'Invalid amount' : ''}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  if (loadingRegisterData) {
    return <Loading />
  }
  if (error) return <p>Error</p>

  return (
    <>
      <StyledDialog
        open={openModel}
        title='Create Bank Deposit'
        body={<Grid>{closedTill ? depositForm() : confirmBox()}</Grid>}
        successButtonName='Confirm'
        cancelButtonName='Dismiss'
        cancelCallback={() => {
          handleCancel()
        }}
        onClose={() => {}}
        disableSuccessButton={
          closedTill && (isInvalidAmount || Number(depositCash) <= 0)
        }
        successCallback={() => {
          closedTill ? handleBankDeposit() : setClosedTill(true)
        }}
      />
    </>
  )
}

export default CashDepositForm
